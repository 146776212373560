import { Request } from "api-types";
import { Spacer, TextField, ConfirmModal } from "ingred-ui";
import * as React from "react";
import { useForm } from "react-hook-form";

import { DemandAdCreativeGroup } from "../../../../../../domain/demandAdCreativeGroup";
import { registerIui } from "../../../../../../utils/registerIui";

import * as Styled from "./styled";

const getErrorText = (errorName: string | undefined): string => {
  switch (errorName) {
    case "required":
      return "入力されていません";
    case "validate":
      return "すでに同じ名前のサイトが存在します";
    default:
      return "";
  }
};

type EditDemandForm = {
  name: string;
};
type Props = {
  isOpen: boolean;
  demandAdCreativeGroup: DemandAdCreativeGroup | null;
  onClose?: () => void;
  onSubmit?: (data: Request.Site.Patch) => void;
  loading?: boolean;
  demandAdCreativeGroups: DemandAdCreativeGroup[];
};

const EditModal: React.FunctionComponent<Props> = ({
  isOpen,
  demandAdCreativeGroup,
  onClose,
  onSubmit,
  loading,
  demandAdCreativeGroups,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EditDemandForm>();
  const submit = (data: EditDemandForm) => {
    if (onSubmit && demandAdCreativeGroup) {
      onSubmit({
        site_id: demandAdCreativeGroup.id,
        ...data,
      });
    }
  };

  const hasDifference =
    demandAdCreativeGroup && demandAdCreativeGroup.name !== watch("name");

  return (
    <ConfirmModal
      isOpen={isOpen}
      title="サイトの編集"
      confirmText="保存する"
      cancelText="キャンセル"
      buttonColor="primary"
      disabled={!hasDifference}
      loading={loading}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit(submit)}
    >
      <Styled.FormContainer>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Spacer pr={5}>サイト名</Spacer>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <TextField
              {...registerIui(
                register("name", {
                  required: true,
                  validate: (value) =>
                    demandAdCreativeGroups.every(
                      (group) => group.name !== value,
                    ),
                }),
              )}
              defaultValue={demandAdCreativeGroup?.name}
              errorText={getErrorText(errors.name?.type)}
            />
          </Styled.FormGroupRight>
        </Styled.FormGroup>
      </Styled.FormContainer>
    </ConfirmModal>
  );
};

export { EditModal };
