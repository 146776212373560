import { Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DeleteDemandAccount
 */
export type DeleteDemandAccountParams = void;
export type DeleteDemandAccountSuccess = { demand_account_id: number };
export type DeleteDemandAccountFailure = Error;

export const deleteDemandAccountAction = actionCreator.async<
  DeleteDemandAccountParams,
  DeleteDemandAccountSuccess,
  DeleteDemandAccountFailure
>("DELETE_DEMAND_ACCOUNT");

export function deleteDemandAccount(
  payload: Request.DemandAccount.Delete,
): ThunkAction<void, any> {
  return async (dispatch, _getState, { demandAccount, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(deleteDemandAccountAction.started());

    try {
      await demandAccount.deleteDemandAccountById(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(
        deleteDemandAccountAction.done({
          result: { demand_account_id: payload.demand_account_id },
        }),
      );
    } catch (err) {
      dispatch(deleteDemandAccountAction.failed(err));
    }
  };
}
