import { Domain } from "api-types";
import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchBidStrapSitesAction } from "../actions/fetchBidStrapSitesAction";

export type BidStrapSiteState = {
  fetchAll: {
    requesting: boolean;
    sites: Domain.Site[] | null;
  };
};

export const initialState: BidStrapSiteState = {
  fetchAll: {
    requesting: false,
    sites: null,
  },
};

export const bidStrapSiteReducer = reducerWithInitialState(initialState)
  /**
   * FetchAll BidStrapSites
   */
  .case(fetchBidStrapSitesAction.started, (state) => ({
    ...state,
    fetchAll: {
      ...state.fetchAll,
      requesting: true,
    },
  }))
  .case(fetchBidStrapSitesAction.done, (state, action) => ({
    ...state,
    fetchAll: {
      requesting: false,
      sites: action.result.sites,
    },
  }))
  .case(fetchBidStrapSitesAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
      sites: null,
    },
  }));
