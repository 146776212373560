// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import * as React from "react";

import { Helmet } from "../../../containers/Helmet";
import { AnalyticsFeatureDashboardUrl } from "../../../domain/analyticsFeature";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../store/utils/dispatchable";

type InjectProps = {
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  dashboardUrl: AnalyticsFeatureDashboardUrl;
  fetchRequesting: boolean;
  fetchSucceeded: boolean;
};

type Props = {};

type InjectedProps = Props & InjectProps;

const AnalyticsFeature: React.FunctionComponent<InjectedProps> = ({
  enqueueSystemNotification,
  dashboardUrl,
  fetchRequesting,
  fetchSucceeded,
}) => {
  const dashboardRef = React.createRef();

  const embed = () => {
    const options = {
      url: dashboardUrl,
      container: dashboardRef.current,
      scrolling: "no",
      height: "AutoFit",
      locale: "ja-JP",
      footerPaddingEnabled: true,
    };
    QuickSightEmbedding.embedDashboard(options);
  };

  React.useEffect(() => {
    if (fetchRequesting === false && fetchSucceeded === false) {
      enqueueSystemNotification({
        message: "分析機能を利用するユーザとして登録されていません",
        variant: SystemNotificationVariant.ERROR,
      });
      return;
    }
    embed();
  });

  return (
    <>
      <Helmet title="分析機能 | DATA STRAP" />
      <div ref={dashboardRef as React.RefObject<HTMLDivElement>} />
    </>
  );
};

export { AnalyticsFeature };
