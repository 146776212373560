import { reducerWithInitialState } from "typescript-fsa-reducers";

import { GamFlattenedHierarchy } from "../../../../domain/gamFlattenedHierarchy";
import { patchGamAdCreativeAction } from "../../gamAdCreative/actions/patchGamAdCreativeAction";
import { postDemandAdCreativesAction } from "../../gamAdCreative/actions/postDemandAdCreativesAction";
import { fetchUnmappedGamFlattenedHierarchiesAction } from "../actions/fetchUnmappedGamFlattenedHierarchiesAction";

export type GamFlattenedHierarchyEntityState = {
  byId: {
    [key: number]: GamFlattenedHierarchy;
  };
  allIds: number[];
};

export const initialState: GamFlattenedHierarchyEntityState = {
  byId: {},
  allIds: [],
};

export const gamFlattenedHierarchyEntityReducer = reducerWithInitialState(
  initialState,
)
  /**
   * Fetch Unmapped gamFlattenedHierarchy
   */
  .case(fetchUnmappedGamFlattenedHierarchiesAction.done, (state, action) => ({
    ...state,
    byId: action.result.gam_flattened_hierarchies.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {},
    ),
    allIds: action.result.gam_flattened_hierarchies.map((item) => item.id),
  }))
  /**
   * Patch GamAdCretive
   */
  .case(patchGamAdCreativeAction.done, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id, is_mappable } = action.result.gam_line_item_creative;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          ...state.byId[id],
          is_mappable,
        },
      },
    };
  })
  /**
   * Post DemandAdCreatives
   */
  .case(postDemandAdCreativesAction.done, (state, action) => {
    const actionId = action.result.gamAdCreativeId;
    const byIdState = { ...state.byId };
    delete byIdState[actionId];
    return {
      ...state,
      byId: {
        ...byIdState,
      },
      allIds: state.allIds.filter((id) => id !== actionId),
    };
  });
