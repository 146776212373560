import styled from "styled-components";

import { Link } from "../../atoms/Link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const ImageContainer = styled.div`
  width: 30%;
  min-width: 300px;
  mix-blend-mode: multiply;
  img {
    display: block;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
`;

export const LinkContainer = styled(Link)`
  text-decoration: none;
`;
