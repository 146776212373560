import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchAllHbDemandTypeAction } from "../actions/fetchAllHbDemandTypeAction";

export type HbDemandTypeState = {
  fetchAll: {
    requesting: boolean;
  };
};

export const initialState: HbDemandTypeState = {
  fetchAll: {
    requesting: false,
  },
};

export const hbDemandTypeReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All HbDemandType
   */
  .case(fetchAllHbDemandTypeAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchAllHbDemandTypeAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchAllHbDemandTypeAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }));
