import { Domain } from "api-types";
import { Column, Typography } from "ingred-ui";
import * as React from "react";

import { BidStrapReport } from "../../../../../domain/bidStrapReport";

type ValueType = "string" | "number" | "first_decimal" | "second_decimal";

export const ColumnFormatMap: {
  [key in Domain.BidStrapReportIndicatorType]: {
    displayName: string;
    valueType: ValueType;
    align: "left" | "center" | "right";
  };
} = {
  date: {
    displayName: "日付",
    valueType: "string",
    align: "left",
  },
  ad_unit_name: {
    displayName: "広告枠",
    valueType: "string",
    align: "left",
  },
  site_name: {
    displayName: "サイト名",
    valueType: "string",
    align: "left",
  },
  bidder: {
    displayName: "bidder",
    valueType: "string",
    align: "left",
  },
  auction_count: {
    displayName: "Prebidリクエスト数",
    valueType: "number",
    align: "right",
  },
  nobid_count: {
    displayName: "非入札数",
    valueType: "number",
    align: "right",
  },
  prebid_win_count: {
    displayName: "勝利数(Prebid)",
    valueType: "number",
    align: "right",
  },
  prebid_win_rate: {
    displayName: "勝率(Prebid)",
    valueType: "second_decimal",
    align: "right",
  },
  bid_win_count: {
    displayName: "勝利数(GAM)",
    valueType: "number",
    align: "right",
  },
  bid_win_rate: {
    displayName: "勝率(GAM)",
    valueType: "second_decimal",
    align: "right",
  },
  valid_bid_count: {
    displayName: "入札数",
    valueType: "number",
    align: "right",
  },
  avg_cpm: {
    displayName: "平均入札CPM",
    valueType: "first_decimal",
    align: "right",
  },
  prebid_win_avg_cpm: {
    displayName: "Prebid勝利時の平均CPM",
    valueType: "first_decimal",
    align: "right",
  },
  gam_win_avg_cpm: {
    displayName: "GAM勝利時の平均CPM",
    valueType: "first_decimal",
    align: "right",
  },
  timeout_count: {
    displayName: "タイムアウト数",
    valueType: "number",
    align: "right",
  },
  demand_price: {
    displayName: "売上(bidder)",
    valueType: "number",
    align: "right",
  },
  demand_imps: {
    displayName: "imps(bidder)",
    valueType: "number",
    align: "right",
  },
  demand_clicks: {
    displayName: "クリック数(bidder)",
    valueType: "number",
    align: "right",
  },
};

export function getFormattedValue(
  row: Partial<Domain.BidStrapReportRecord> & { id: number },
  headerKey: keyof Partial<Domain.BidStrapReportRecord>,
) {
  if (row[headerKey] === "") {
    return "-";
  }
  if (row[headerKey] === 0) {
    return "0";
  }

  const valueType = ColumnFormatMap[headerKey].valueType;

  switch (valueType) {
    case "first_decimal":
      const roundedValue = Math.round(Number(row[headerKey]) * 10) / 10;
      return parseFloat(roundedValue.toFixed(1)).toLocaleString();

    // サーバーからはすべて少数第２位で返される
    case "second_decimal":
      return parseFloat(Number(row[headerKey]).toFixed(2)).toLocaleString();
    case "number":
      return Math.round(Number(row[headerKey])).toLocaleString();
    case "string":
      // 両方Partialな為 as しているが、
      // rowとheaderKeyは必ず整合性がとれた状態のデータが渡される
      return row[headerKey] as string;
  }
}

export function createColumns(data: BidStrapReport) {
  return data.headers.map<
    Column<Partial<Domain.BidStrapReportRecord> & { id: number }>
  >((header) => ({
    name: ColumnFormatMap[header].displayName,
    // selectorで.toLocaleString()すると正しくソート出来ないので
    // renderCellで整形する
    selector: (row) => row[header] as string | number,
    renderCell: (row) => (
      <Typography align={ColumnFormatMap[header].align}>
        {getFormattedValue(row, header)}
      </Typography>
    ),
    sortable: true,
  }));
}
