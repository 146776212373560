import { Information } from "../../../../domain/information";
import { RootState } from "../../../reducer";

export const getInformations = (state: RootState): Information[] => {
  const allIds = state.entities.information.allIds;
  const byId = state.entities.information.byId;
  return allIds.map((id) => byId[id]);
};

export const getInformationById = (
  state: RootState,
  id: number,
): Information | null => {
  const byId = state.entities.information.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
