import { reducerWithInitialState } from "typescript-fsa-reducers";

import { DemandFormConfig } from "../../../../../../api-client/model/demand-form-config";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { fetchDemandAccountSettingsAction } from "../actions/fetchDemandAccountSettingsAction";

export type DemandAccountFormEntityState = {
  byId: {
    [key: number]: DemandFormConfig;
  };
  allIds: number[];
};

export const initialState: DemandAccountFormEntityState = {
  byId: {},
  allIds: [],
};

export const demandAccountFormEntityReducer = reducerWithInitialState(
  initialState,
).case(fetchDemandAccountSettingsAction.done, (state, action) => {
  const demandForms = action.result;
  return {
    ...state,
    byId: demandForms.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.demand.id]: cur,
      }),
      state.byId,
    ),
    allIds: uniquePush(
      state.allIds,
      demandForms.map((df) => df.demand.id),
    ),
  };
});
