import { Domain, Response } from "api-types";

import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchOsType
 */
export type PatchOsTypePayload = {
  osType: Domain.OsType;
  demandAdCreatives: DemandAdCreative[];
};
export type PatchOsTypeParams = void;
export type PatchOsTypeSuccess = Response.DemandAccountCreative.PatchOsType;
export type PatchOsTypeFailure = Error;

export const patchOsTypeAction = actionCreator.async<
  PatchOsTypeParams,
  PatchOsTypeSuccess,
  PatchOsTypeFailure
>("PATCH_OS_TYPE");

export function patchOsType(
  payload: PatchOsTypePayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, demandAccountCreative }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchOsTypeAction.started());

    try {
      const res = await demandAccountCreative.patchOsType(
        apiClient,
        {
          name: payload.osType,
          demand_account_creative_ids: payload.demandAdCreatives.map(
            (dac) => dac.id,
          ),
        },
        currentCompany.company.id,
      );
      dispatch(patchOsTypeAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(patchOsTypeAction.failed({ error }));
      }
    }
  };
}
