import { connect } from "react-redux";

import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { push as pushAction } from "../../../../store/modules/routing/actions/PushAction";
import { RootState } from "../../../../store/reducer";
import { dispatchable } from "../../../../store/utils/dispatchable";

import { Tasks as Component, TaskType } from "./Tasks";

const Tasks = connect(
  (state: RootState) => ({
    currentCompany: state.user.currentCompany as CompanyAttribute,
  }),
  (dispatch) => ({
    push: dispatchable(dispatch, pushAction),
  }),
)(Component);

export { Tasks, TaskType };
