import { Card } from "ingred-ui";
import styled from "styled-components";

export const RefineContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing * 3}px;
`;

export const InputContainer = styled.div<{ width?: string }>`
  min-width: ${({ width }) => (width != null ? "auto" : "240px")};
  max-width: 400px;
  width: ${({ width }) => width || "auto"};
`;

export const LiteGraphs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -${({ theme }) => theme.spacing * 1.5}px;
  padding-bottom: ${({ theme }) => theme.spacing * 1.5}px;
`;

export const LiteGraphContainer = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing * 1.5}px;
`;

export const RowGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -${({ theme }) => theme.spacing * 1.5}px;
  padding-top: ${({ theme }) => theme.spacing * 1.5}px;
`;

export const HalfContent = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing * 1.5}px;
  min-width: 300px;
`;

export const NoteBanner = styled.a`
  height: 122px;
  border-radius: 9px;
  background-color: #41c9b4;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const ImageContainer = styled.div`
  max-width: 500px;
  mix-blend-mode: multiply;
  padding: 0 ${({ theme }) => theme.spacing * 5}px;
  img {
    display: block;
    width: 100%;
  }
`;

export const AlertBox = styled(Card)`
  background-color: ${({ theme }) => theme.palette.danger.highlight};
`;
