import { History } from "history";
import {
  applyMiddleware,
  createStore,
  Store,
  compose,
  PreloadedState,
} from "redux";
import thunk from "redux-thunk";

import { DefaultApi } from "../../../api-client";
import { infrastructure, Infrastructure } from "../infra";
import { GTM } from "../infra/gtm";
import { APIClient } from "../utils/APIClientUtils";

import { createGlobalErrorDetectionMiddleware } from "./modules/app/middlewares/GlobalErrorDetectionMiddleware";
import { createSendToGTMMiddleware } from "./modules/gtm/middlewares/SendToGTMMiddleware";
import { createRouteMiddleware } from "./modules/routing/middlewares/RouteMiddleware";
import { createErrorsMiddleware } from "./modules/systemNotification/middlewares/ErrorsMiddleware";
import { rootReducer, RootState } from "./reducer";

export type RootStore = Store<RootState>;
export type ExtraArguments = Infrastructure & {
  apiClient: APIClient;
  openApiClient: DefaultApi;
};

export const configureStore = ({
  history,
  apiClient,
  openApiClient,
  preloadedState,
}: {
  history: History;
  apiClient: APIClient;
  openApiClient: DefaultApi;
  preloadedState?: PreloadedState<RootState>;
}): RootStore => {
  const extraArguments: ExtraArguments = {
    ...infrastructure,
    apiClient,
    openApiClient,
  };
  const middlewares = [
    thunk.withExtraArgument(extraArguments),
    createRouteMiddleware(history),
    createGlobalErrorDetectionMiddleware(),
    createErrorsMiddleware(),
  ];

  const gtm = new GTM(window.dataLayer);
  middlewares.push(createSendToGTMMiddleware(gtm));

  if (
    process.env.NODE_ENV !== "production" &&
    process.env.NODE_ENV !== "test"
  ) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires
    middlewares.push(require("redux-logger").createLogger());
  }

  return createStore(
    rootReducer,
    preloadedState,
    compose(applyMiddleware(...middlewares)),
  );
};
