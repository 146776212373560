import { DetailReport } from "../../../../../../domain/detailReport";

export const calcTotalIndicators = (
  hasPrice: boolean,
  hasImp: boolean,
  hasVimp: boolean,
  hasClick: boolean,
  data: DetailReport["records"],
) => {
  let totalPrice = 0;
  let totalImp = 0;
  let totalVimp = 0;
  let totalClick = 0;

  data.forEach((row) => {
    if (hasPrice) totalPrice += row.price as number;
    if (hasImp) totalImp += row.imp as number;
    if (hasVimp) totalVimp += row.vimp as number;
    if (hasClick) totalClick += row.click as number;
  });

  let eCPM = 0;
  let ctr = 0;
  let cpc = 0;

  if (totalPrice && totalImp) {
    eCPM = (totalPrice / totalImp) * 1000;
  }

  if (totalClick && totalImp) {
    ctr = (totalClick / totalImp) * 100;
  }

  if (totalPrice && totalClick) {
    cpc = totalPrice / totalClick;
  }

  return { totalPrice, totalImp, totalVimp, totalClick, eCPM, ctr, cpc };
};
