import { reducerWithInitialState } from "typescript-fsa-reducers";

import { SavedQueryDetail } from "../../../../domain/savedQuery";
import { deleteSavedQueryAction } from "../actions/deleteSavedQueryAction";
import { fetchSavedQueriesAction } from "../actions/fetchSavedQueriesAction";
import { fetchSavedQueryAction } from "../actions/fetchSavedQueryAction";
import { patchSavedQueryAction } from "../actions/patchSavedQueryAction";
import { postSavedQueryAction } from "../actions/postSavedQueryAction";

export type SavedQueryState = {
  fetchAll: {
    requesting: boolean;
  };
  fetch: {
    requesting: boolean;
    data: SavedQueryDetail | null;
  };
  post: {
    requesting: boolean;
    success: boolean;
  };
  patch: {
    requesting: boolean;
    success: boolean;
  };
  delete: {
    requesting: boolean;
    success: boolean;
  };
};

export const initialState: SavedQueryState = {
  fetchAll: {
    requesting: false,
  },
  fetch: {
    requesting: false,
    data: null,
  },
  post: {
    requesting: false,
    success: false,
  },
  patch: {
    requesting: false,
    success: false,
  },
  delete: {
    requesting: false,
    success: false,
  },
};

export const savedQueryReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All SavedQuery
   */
  .case(fetchSavedQueriesAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchSavedQueriesAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchSavedQueriesAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  /**
   * Fetch SavedQuery
   */
  .case(fetchSavedQueryAction.started, (state) => ({
    ...state,
    fetch: {
      requesting: true,
      data: null,
    },
  }))
  .case(fetchSavedQueryAction.done, (state, action) => ({
    ...state,
    fetch: {
      requesting: false,
      data: action.result,
    },
  }))
  .case(fetchSavedQueryAction.failed, (state) => ({
    ...state,
    fetch: {
      requesting: false,
      data: null,
    },
  }))
  /**
   * Post SavedQuery
   */
  .case(postSavedQueryAction.started, (state) => ({
    ...state,
    post: {
      requesting: true,
      success: false,
    },
  }))
  .case(postSavedQueryAction.done, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: true,
    },
  }))
  .case(postSavedQueryAction.failed, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Patch SavedQuery
   */
  .case(patchSavedQueryAction.started, (state) => ({
    ...state,
    patch: {
      requesting: true,
      success: false,
    },
  }))
  .case(patchSavedQueryAction.done, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: true,
    },
  }))
  .case(patchSavedQueryAction.failed, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Delete SavedQuery
   */
  .case(deleteSavedQueryAction.started, (state) => ({
    ...state,
    delete: {
      requesting: true,
      success: false,
    },
  }))
  .case(deleteSavedQueryAction.done, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: true,
    },
  }))
  .case(deleteSavedQueryAction.failed, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: false,
    },
  }));
