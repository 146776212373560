import { getAuth0Client } from "../../../../utils/Auth0Utils";
import { ThunkAction } from "../../../type";
import { push } from "../../routing/actions/PushAction";

import { actionCreator } from "./actionCreator";

/**
 * HandleRedirectCallbackAction
 */

export type HandleRedirectCallbackParams = void;
export type HandleRedirectCallbackSuccess = void;
export type HandleRedirectCallbackFailure = Error;

export const handleRedirectCallbackAction = actionCreator.async<
  HandleRedirectCallbackParams,
  HandleRedirectCallbackSuccess,
  HandleRedirectCallbackFailure
>("HANDLE_REDIRECT_CALLBACK");

export function handleRedirectCallback(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState) => {
    const auth0Client = await getAuth0Client();
    dispatch(handleRedirectCallbackAction.started());
    try {
      const { appState } = await auth0Client.handleRedirectCallback();
      dispatch(handleRedirectCallbackAction.done({}));
      dispatch(push((appState && appState.targetUrl) || "/"));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(handleRedirectCallbackAction.failed({ error }));
      }
    }
  };
}
