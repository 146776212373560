import { reducerWithInitialState } from "typescript-fsa-reducers";

import { DemandAccountWithScraperStatus } from "../../../../domain/demandAccount";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { deleteDemandAccountAction } from "../actions/deleteDemandAccountAction";
import { fetchDemandAccountAction } from "../actions/fetchDemandAccountAction";
import { fetchDemandAccountsAction } from "../actions/fetchDemandAccountsAction";
import { patchDemandAccountAction } from "../actions/patchDemandAccountAction";
import { postDemandAccountAction } from "../actions/postDemandAccountAction";

type DemandAccountEntityState = {
  byId: {
    [key: number]: DemandAccountWithScraperStatus;
  };
  allIds: number[];
};

export const initialState: DemandAccountEntityState = {
  byId: {},
  allIds: [],
};

export const demandAccountEntityReducer = reducerWithInitialState(initialState)
  .case(fetchDemandAccountAction.done, (state, action) => {
    const detail = action.result;
    const id = detail.demand_account.id;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          id,
          demand: detail.demand_account.demand,
          scraper_enabled: detail.demand_account.scraper_enabled,
          description: detail.description,
          form_columns: detail.form_columns,
        },
      },
      allIds: uniquePush(state.allIds, id),
    };
  })
  .case(fetchDemandAccountsAction.done, (state, action) => ({
    ...state,
    byId: action.result.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          id: cur.id,
          demand: cur.demand,
          scraper_enabled: cur.scraper_enabled,
          scraper_status: cur.scraper_status,
          description: "",
          form_columns: [],
        },
      }),
      state.byId,
    ),
    allIds: uniquePush(
      state.allIds,
      action.result.map((d) => d.id),
    ),
  }))
  .case(postDemandAccountAction.done, (state, action) => {
    const demandAccount = action.result.demand_account;
    const id = demandAccount.id;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          id,
          demand: demandAccount.demand,
          scraper_enabled: demandAccount.scraper_enabled,
          description: "",
          form_columns: [],
        },
      },
      allIds: uniquePush(state.allIds, id),
    };
  })
  .case(patchDemandAccountAction.done, (state, action) => {
    const demandAccount = action.result.demand_account;
    const id = demandAccount.id;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          id,
          demand: demandAccount.demand,
          scraper_enabled: demandAccount.scraper_enabled,
          description: "",
          form_columns: [],
        },
      },
    };
  })
  .case(deleteDemandAccountAction.done, (state, action) => {
    const actionId = action.result.demand_account_id;
    const byIdState = { ...state.byId };
    delete byIdState[actionId];
    return {
      ...state,
      byId: { ...byIdState },
      allIds: state.allIds.filter((id) => id !== actionId),
    };
  });
