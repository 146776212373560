import { reducerWithInitialState } from "typescript-fsa-reducers";

import { GamAdCreativeByIdState } from "../../../../domain/gamAdCreative";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { fetchUnmappedGamFlattenedHierarchiesAction } from "../../gamFlattenedHierarchy/actions/fetchUnmappedGamFlattenedHierarchiesAction";
import { deleteDemandAdCreativesAction } from "../actions/deleteDemandAdCreativesAction";
import { fetchGamAdCreativesAction } from "../actions/fetchGamAdCreativesAction";
import { postDemandAdCreativesAction } from "../actions/postDemandAdCreativesAction";

export type GamAdCreativeEntityState = {
  byId: {
    [key: number]: GamAdCreativeByIdState;
  };
  allIds: number[];
};

export const initialState: GamAdCreativeEntityState = {
  byId: {},
  allIds: [],
};

export const gamAdCreativeEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All gamAdCreative
   */
  .case(fetchGamAdCreativesAction.done, (state, action) => ({
    ...state,
    byId: action.result.gam_line_item_creative_details.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          // MEMO(kinokoruumu): api responseの型とentityの型が違うのでスプレッド演算子を使用しない
          id: cur.id,
          original_line_item_creative_id: cur.original_line_item_creative_id,
          original_line_item_creative_name:
            cur.original_line_item_creative_name,
          demand_ad_creatives: cur.demand_account_creatives.map(
            (creative) => creative.id,
          ),
        },
      }),
      state.byId,
    ),
    allIds: uniquePush(
      state.allIds,
      action.result.gam_line_item_creative_details.map(
        (creative) => creative.id,
      ),
    ),
  }))
  /**
   * Fetch Unmapped GamFlattenedHierarchy
   */
  .case(fetchUnmappedGamFlattenedHierarchiesAction.done, (state, action) => {
    return {
      ...state,
      byId: action.result.gam_flattened_hierarchies.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: {
            // MEMO(kinokoruumu): api responseの型とentityの型が違うのでスプレッド演算子を使用しない
            id: cur.id,
            original_line_item_creative_id: cur.original_line_item_creative_id,
            original_line_item_creative_name:
              cur.original_line_item_creative_name,
            demand_ad_creatives: state.byId[cur.id]
              ? state.byId[cur.id].demand_ad_creatives
              : [],
          },
        }),
        state.byId,
      ),
      allIds: uniquePush(
        state.allIds,
        action.result.gam_flattened_hierarchies.map((creative) => creative.id),
      ),
    };
  })
  .case(deleteDemandAdCreativesAction.done, (state, action) => {
    const { gamAdCreativeId, demandAdCreativeIds } = action.result;
    return {
      ...state,
      byId: {
        ...state.byId,
        [gamAdCreativeId]: {
          ...state.byId[gamAdCreativeId],
          demand_ad_creatives: state.byId[
            gamAdCreativeId
          ].demand_ad_creatives.filter(
            (creative) => !demandAdCreativeIds.includes(creative),
          ),
        },
      },
    };
  })
  .case(postDemandAdCreativesAction.done, (state, action) => {
    const { gamAdCreativeId, demandAdCreativeIds } = action.result;
    return {
      ...state,
      byId: {
        ...state.byId,
        [gamAdCreativeId]: {
          ...state.byId[gamAdCreativeId],
          demand_ad_creatives: [
            ...state.byId[gamAdCreativeId].demand_ad_creatives,
            ...demandAdCreativeIds,
          ],
        },
      },
    };
  });
