import { GamAdUnit } from "../../../../domain/gamAdUnit";
import { GamLineItem } from "../../../../domain/gamLineItem";
import { RootState } from "../../../reducer";
import { getGamLineItemById } from "../../gamLineItem/selectors/gamLineItemSelector";

export const getGamAdUnits = (state: RootState): GamAdUnit[] => {
  const { allIds, byId } = state.entities.gamAdUnit;
  return allIds.map((id) => ({
    ...byId[id],
    gam_line_items: byId[id].gam_line_items.map(
      (lineItemId) => getGamLineItemById(state, lineItemId) as GamLineItem,
    ),
  }));
};

export const getMappedGamAdUnits = (state: RootState): GamAdUnit[] => {
  const { allIds, byId } = state.entities.gamAdUnit;
  return allIds
    .map((id) => ({
      ...byId[id],
      gam_line_items: byId[id].gam_line_items.map(
        (lineItemId) => getGamLineItemById(state, lineItemId) as GamLineItem,
      ),
    }))
    .filter((unit) => unit.hasCreative);
};

export const getGamAdUnitById = (
  state: RootState,
  id: number,
): GamAdUnit | null => {
  const byId = state.entities.gamAdUnit.byId;
  if (byId[id]) {
    return {
      ...byId[id],
      gam_line_items: byId[id].gam_line_items.map(
        (lineItemId) => getGamLineItemById(state, lineItemId) as GamLineItem,
      ),
    };
  }
  return null;
};
