import { reducerWithInitialState } from "typescript-fsa-reducers";

import { GamLineItemByIdState } from "../../../../domain/gamLineItem";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { fetchGamAdCreativesAction } from "../../gamAdCreative/actions/fetchGamAdCreativesAction";
import { fetchGamLineItemsAction } from "../actions/fetchGamLineItemsAction";
import { patchYieldSettingAction } from "../actions/patchYieldSettingAction";

export type GamLineItemEntityState = {
  byId: {
    [key: number]: GamLineItemByIdState;
  };
  allIds: number[];
};

export const initialState: GamLineItemEntityState = {
  byId: {},
  allIds: [],
};

export const gamLineItemEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All gamLineItem
   */
  .case(fetchGamLineItemsAction.done, (state, action) => ({
    ...state,
    byId: action.result.gam_line_item_details.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          ...cur,
          gam_ad_creatives: [
            ...(state.byId[cur.id] ? state.byId[cur.id].gam_ad_creatives : []),
          ],
        },
      }),
      {},
    ),
    allIds: uniquePush(
      action.result.gam_line_item_details.map((lineItem) => lineItem.id),
      state.allIds,
    ),
  }))
  /**
   * Fetch gamAdCreatives (by gamLineItemId)
   * */
  .case(fetchGamAdCreativesAction.done, (state, action) => {
    const id = action.result.gam_line_item_detail.id;
    const gamLineItem = action.result.gam_line_item_detail;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          id: gamLineItem.id,
          original_line_item_id: gamLineItem.original_line_item_id,
          original_line_item_name: gamLineItem.original_line_item_name,
          gam_ad_creatives: action.result.gam_line_item_creative_details.map(
            (creative) => creative.id,
          ),
          gam_line_item_yield_setting: gamLineItem.gam_line_item_yield_setting,
        },
      },
      allIds: uniquePush(state.allIds, id),
    };
  })
  /**
   * Patch GamLineItems YieldSetting
   */
  .case(patchYieldSettingAction.done, (state, action) => ({
    ...state,
    byId: action.result.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          ...cur,
          gam_ad_creatives: [
            ...(state.byId[cur.id] ? state.byId[cur.id].gam_ad_creatives : []),
          ],
        },
      }),
      state.byId,
    ),
  }));
