import { reducerWithInitialState } from "typescript-fsa-reducers";

import { deleteDemandAccountAction } from "../actions/deleteDemandAccountAction";
import { fetchDemandAccountAction } from "../actions/fetchDemandAccountAction";
import { fetchDemandAccountSettingsAction } from "../actions/fetchDemandAccountSettingsAction";
import { fetchDemandAccountsAction } from "../actions/fetchDemandAccountsAction";
import { patchDemandAccountAction } from "../actions/patchDemandAccountAction";
import { postDemandAccountAction } from "../actions/postDemandAccountAction";

export type DemandAccountState = {
  fetch: {
    requesting: boolean;
  };
  fetchAll: {
    requesting: boolean;
  };
  post: {
    requesting: boolean;
    success: boolean;
  };
  patch: {
    requesting: boolean;
    success: boolean;
  };
  delete: {
    requesting: boolean;
    success: boolean;
  };
  fetchAllSettings: {
    requesting: boolean;
  };
};

export const initialState: DemandAccountState = {
  fetch: {
    requesting: false,
  },
  fetchAll: {
    requesting: false,
  },
  post: {
    requesting: false,
    success: false,
  },
  patch: {
    requesting: false,
    success: false,
  },
  delete: {
    requesting: false,
    success: false,
  },
  fetchAllSettings: {
    requesting: false,
  },
};

export const demandAccountReducer = reducerWithInitialState(initialState)
  /**
   * Fetch DemandAccount
   */
  .case(fetchDemandAccountAction.started, (state) => ({
    ...state,
    fetch: {
      requesting: true,
    },
  }))
  .case(fetchDemandAccountAction.done, (state) => ({
    ...state,
    fetch: {
      requesting: false,
    },
  }))
  .case(fetchDemandAccountAction.failed, (state) => ({
    ...state,
    fetch: {
      requesting: false,
    },
  }))
  /**
   * FetchAll DemandAccounts
   */
  .case(fetchDemandAccountsAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchDemandAccountsAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchDemandAccountsAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  /**
   * Post DemandAccount
   */
  .case(postDemandAccountAction.started, (state) => ({
    ...state,
    post: {
      requesting: true,
      success: false,
    },
  }))
  .case(postDemandAccountAction.done, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: true,
    },
  }))
  .case(postDemandAccountAction.failed, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Patch DemandAccount
   */
  .case(patchDemandAccountAction.started, (state) => ({
    ...state,
    patch: {
      requesting: true,
      success: false,
    },
  }))
  .case(patchDemandAccountAction.done, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: true,
    },
  }))
  .case(patchDemandAccountAction.failed, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Delete DemandAccount
   */
  .case(deleteDemandAccountAction.started, (state) => ({
    ...state,
    delete: {
      requesting: true,
      success: false,
    },
  }))
  .case(deleteDemandAccountAction.done, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: true,
    },
  }))
  .case(deleteDemandAccountAction.failed, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * FetchAll DemandAccountSettings
   */
  .case(fetchDemandAccountSettingsAction.started, (state) => ({
    ...state,
    fetchAllSettings: {
      requesting: true,
    },
  }))
  .case(fetchDemandAccountSettingsAction.done, (state) => ({
    ...state,
    fetchAllSettings: {
      requesting: false,
    },
  }))
  .case(fetchDemandAccountSettingsAction.failed, (state) => ({
    ...state,
    fetchAllSettings: {
      requesting: false,
    },
  }));
