import { SystemNotificationEntry } from "../reducers/systemNotification";

import { actionCreator } from "./actionCreator";

export type EnqueueSystemNotificationPayload = Omit<
  SystemNotificationEntry,
  "cid"
>;

export const enqueueSystemNotification = actionCreator<
  EnqueueSystemNotificationPayload
>("ENQUEUE_SYSTEM_NOTIFICATION");
