import { reducerWithInitialState } from "typescript-fsa-reducers";

import { Category } from "../../../../domain/category";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { arrayToEntityMap } from "../../../../utils/ReducerUtils";
import { deleteCategoryAction } from "../actions/deleteCategoryAction";
import { fetchCategoriesAction } from "../actions/fetchCategoriesAction";
import { patchCategoryAction } from "../actions/patchCategoryAction";
import { postCategoryAction } from "../actions/postCategoryAction";

export type CategoryEntityState = {
  byId: {
    [key: number]: Category;
  };
  allIds: number[];
};

export const initialState: CategoryEntityState = {
  byId: {},
  allIds: [],
};

export const categoryEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All category
   */
  .case(fetchCategoriesAction.done, (state, action) => ({
    ...state,
    byId: arrayToEntityMap(action.result.categories),
    allIds: action.result.categories.map((category) => category.id),
  }))
  /**
   * Post category
   */
  .case(postCategoryAction.done, (state, action) => {
    const category = action.result.category;
    return {
      ...state,
      byId: {
        ...state.byId,
        [category.id]: category,
      },
      allIds: uniquePush(state.allIds, category.id),
    };
  })
  /**
   * Patch category
   */
  .case(patchCategoryAction.done, (state, action) => {
    const category = action.result.category;
    return {
      ...state,
      byId: {
        ...state.byId,
        [category.id]: category,
      },
    };
  })

  /**
   * Delete category
   */
  .case(deleteCategoryAction.done, (state, action) => {
    const actionId = action.result.category_id;
    const byIdState = { ...state.byId };
    delete byIdState[actionId];
    return {
      ...state,
      byId: { ...byIdState },
      allIds: state.allIds.filter((id) => id !== actionId),
    };
  });
