import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { RouteActionContext, RouteActionPayload } from "../../../routing/types";
import { fetchDashboardUrl } from "../../../store/modules/analyticsFeature/actions/fetchDashboardUrlAction";
import { getDashboardUrl } from "../../../store/modules/analyticsFeature/selectors/dashboardUrlSelector";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { AnalyticsFeature as Component } from "./AnalyticsFeature";

const AnalyticsFeature = connect(
  (state: RootState) => ({
    dashboardUrl: getDashboardUrl(state),
    fetchRequesting: state.analyticsFeature.fetch.requesting,
    fetchSucceeded: state.analyticsFeature.fetch.success,
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
  }),
)(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await store.dispatch(fetchDashboardUrl());
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <AnalyticsFeature />,
  };
}
