import { reducerWithInitialState } from "typescript-fsa-reducers";

import { Domain } from "../../../../../../api-types";
import { deleteDemandAdCreativeGroupAction } from "../actions/deleteDemandAdCreativeGroupAction";
import { deleteDemandAdCreativesAction } from "../actions/deleteDemandAdCreativesAction";
import { fetchDemandAdCreativeGroupsAction } from "../actions/fetchDemandAdCreativeGroupsAction";
import { patchDemandAdCreativeGroupAction } from "../actions/patchDemandAdCreativeGroupAction";
import { patchDemandAdCreativesAction } from "../actions/patchDemandAdCreativesAction";
import { postDemandAdCreativeGroupAction } from "../actions/postDemandAdCreativeGroupAction";

export type DemandAdCreativeGroupState = {
  fetch: {
    requesting: boolean;
  };
  fetchAll: {
    requesting: boolean;
    sites: Domain.Site[] | null;
  };
  post: {
    requesting: boolean;
    success: boolean;
  };
  patch: {
    requesting: boolean;
    success: boolean;
  };
  delete: {
    requesting: boolean;
    success: boolean;
  };
  creatives: {
    patch: {
      requesting: boolean;
      success: boolean;
    };
    delete: {
      requesting: boolean;
      success: boolean;
    };
  };
};

export const initialState: DemandAdCreativeGroupState = {
  fetch: {
    requesting: false,
  },
  fetchAll: {
    requesting: false,
    sites: null,
  },
  post: {
    requesting: false,
    success: false,
  },
  patch: {
    requesting: false,
    success: false,
  },
  delete: {
    requesting: false,
    success: false,
  },
  creatives: {
    patch: {
      requesting: false,
      success: false,
    },
    delete: {
      requesting: false,
      success: false,
    },
  },
};

export const demandAdCreativeGroupReducer = reducerWithInitialState(
  initialState,
)
  /**
   * FetchAll DemandAdCreativeGroups
   */
  .case(fetchDemandAdCreativeGroupsAction.started, (state) => ({
    ...state,
    fetchAll: {
      ...state.fetchAll,
      requesting: true,
    },
  }))
  .case(fetchDemandAdCreativeGroupsAction.done, (state, action) => ({
    ...state,
    fetchAll: {
      requesting: false,
      sites: action.result.sites,
    },
  }))
  .case(fetchDemandAdCreativeGroupsAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
      sites: null,
    },
  }))
  /**
   * Post DemandAdCreativeGroup
   */
  .case(postDemandAdCreativeGroupAction.started, (state) => ({
    ...state,
    post: {
      requesting: true,
      success: false,
    },
  }))
  .case(postDemandAdCreativeGroupAction.done, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: true,
    },
  }))
  .case(postDemandAdCreativeGroupAction.failed, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Patch DemandAdCreativeGroup
   */
  .case(patchDemandAdCreativeGroupAction.started, (state) => ({
    ...state,
    patch: {
      requesting: true,
      success: false,
    },
  }))
  .case(patchDemandAdCreativeGroupAction.done, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: true,
    },
  }))
  .case(patchDemandAdCreativeGroupAction.failed, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Delete DemandAdCreativeGroup
   */
  .case(deleteDemandAdCreativeGroupAction.started, (state) => ({
    ...state,
    delete: {
      requesting: true,
      success: false,
    },
  }))
  .case(deleteDemandAdCreativeGroupAction.done, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: true,
    },
  }))
  .case(deleteDemandAdCreativeGroupAction.failed, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Patch DemandAdCreatives
   */
  .case(patchDemandAdCreativesAction.started, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      patch: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(patchDemandAdCreativesAction.done, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      patch: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(patchDemandAdCreativesAction.failed, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      patch: {
        requesting: false,
        success: false,
      },
    },
  }))
  /**
   * Delete DemandAdCreatives
   */
  .case(deleteDemandAdCreativesAction.started, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      delete: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(deleteDemandAdCreativesAction.done, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      delete: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(deleteDemandAdCreativesAction.failed, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      delete: {
        requesting: false,
        success: false,
      },
    },
  }));
