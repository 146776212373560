import { Card, Typography, Spacer } from "ingred-ui";
import * as React from "react";

import { DetailReport } from "../../../../../../domain/detailReport";

import * as Styled from "./styled";
import { calcTotalIndicators } from "./utils";

type Props = {
  isDisplayed: boolean;
  data: DetailReport["records"];
  headers: DetailReport["headers"];
};

export const TotalValueCards: React.FC<Props> = ({
  isDisplayed,
  data,
  headers,
}) => {
  const hasPrice = headers.includes("price") && isDisplayed;
  const hasImp = headers.includes("imp") && isDisplayed;
  const hasVimp = headers.includes("vimp") && isDisplayed;
  const hasClick = headers.includes("click") && isDisplayed;
  const {
    totalPrice,
    totalImp,
    totalVimp,
    totalClick,
    eCPM,
    ctr,
    cpc,
  } = calcTotalIndicators(hasPrice, hasImp, hasVimp, hasClick, data);

  return (
    <Styled.Container>
      <Styled.CardContainer>
        <Spacer pl={2}>
          <Card>
            <Styled.Content>
              <Typography color="secondary" size="sm" weight="bold">
                全体売上
              </Typography>
              <Spacer pt={0.5} />
              <Typography size="xxxxl" weight="bold">
                {hasPrice
                  ? `¥${Number(totalPrice.toFixed()).toLocaleString()}`
                  : "-"}
              </Typography>
            </Styled.Content>
          </Card>
        </Spacer>
        <Spacer pl={2}>
          <Card>
            <Styled.Content>
              <Typography color="secondary" size="sm" weight="bold">
                全体imp
              </Typography>
              <Spacer pt={0.5} />
              <Typography size="xxxxl" weight="bold">
                {hasImp ? totalImp.toLocaleString() : "-"}
              </Typography>
            </Styled.Content>
          </Card>
        </Spacer>
        <Spacer pl={2}>
          <Card>
            <Styled.Content>
              <Typography color="secondary" size="sm" weight="bold">
                クリック
              </Typography>
              <Spacer pt={0.5} />
              <Typography size="xxxxl" weight="bold">
                {hasClick ? totalClick.toLocaleString() : "-"}
              </Typography>
            </Styled.Content>
          </Card>
        </Spacer>
        <Spacer pl={2}>
          <Card>
            <Styled.Content>
              <Typography color="secondary" size="sm" weight="bold">
                vimp
              </Typography>
              <Spacer pt={0.5} />
              <Typography size="xxxxl" weight="bold">
                {hasVimp ? totalVimp.toLocaleString() : "-"}
              </Typography>
            </Styled.Content>
          </Card>
        </Spacer>
        <Spacer pl={2}>
          <Card>
            <Styled.Content>
              <Typography color="secondary" size="sm" weight="bold">
                eCPM
              </Typography>
              <Spacer pt={0.5} />
              <Typography size="xxxxl" weight="bold">
                {hasPrice && hasImp
                  ? `¥${Number(eCPM.toFixed(2)).toLocaleString()}`
                  : "-"}
              </Typography>
            </Styled.Content>
          </Card>
        </Spacer>
        <Spacer pl={2}>
          <Card>
            <Styled.Content>
              <Typography color="secondary" size="sm" weight="bold">
                CTR
              </Typography>
              <Spacer pt={0.5} />
              <Typography size="xxxxl" weight="bold">
                {hasClick && hasImp ? ctr.toFixed(2) : "-"}
              </Typography>
            </Styled.Content>
          </Card>
        </Spacer>
        <Spacer pl={2}>
          <Card>
            <Styled.Content>
              <Typography color="secondary" size="sm" weight="bold">
                CPC
              </Typography>
              <Spacer pt={0.5} />
              <Typography size="xxxxl" weight="bold">
                {hasPrice && hasClick ? cpc.toFixed(1) : "-"}
              </Typography>
            </Styled.Content>
          </Card>
        </Spacer>
      </Styled.CardContainer>
      <Spacer pt={2}>
        <Typography align="right" size="xs" weight="bold">
          ※全体指標は10000行以上の場合は表示できません。
        </Typography>
      </Spacer>
    </Styled.Container>
  );
};
