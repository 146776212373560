import styled from "styled-components";

import { Radius, Color, Size } from "../../../../styles/variables";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing * 3}px;
  background-color: ${Color.background.default};
`;

export const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: ${Radius.MEDIUM};
  background-color: ${Color.gray.light};
  margin-right: ${({ theme }) => theme.spacing}px;
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TasksContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing * 2}px
    ${({ theme }) => theme.spacing * 4}px;
  background-color: ${Color.background.default};
  border-top: ${Size.Border.Small} solid ${Color.gray.light};
`;

export const TaskContainer = styled.div`
  & + & {
    padding-left: ${({ theme }) => theme.spacing * 2}px;
  }
`;
