import * as React from "react";

function SvgTableActive(props: any) {
  return (
    <svg width={140} height={124} viewBox="0 0 140 124" {...props}>
      <rect width="140" height="124" rx="5" fill="#cdf0fe" />
      <g transform="translate(6017 2398)">
        <rect
          width="26"
          height="12"
          transform="translate(-5980 -2370)"
          fill="#0b82f4"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <path
          d="M2,0H12a0,0,0,0,1,0,0V12a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z"
          transform="translate(-5992 -2370)"
          fill="#0b82f4"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5980 -2344)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="12"
          height="14"
          transform="translate(-5992 -2344)"
          fill="#f2f9fc"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5980 -2358)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="12"
          height="14"
          transform="translate(-5992 -2358)"
          fill="#f2f9fc"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5980 -2330)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5980 -2316)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="12"
          height="14"
          transform="translate(-5992 -2330)"
          fill="#f2f9fc"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <path
          d="M0,0H12a0,0,0,0,1,0,0V14a0,0,0,0,1,0,0H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
          transform="translate(-5992 -2316)"
          fill="#f2f9fc"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="12"
          transform="translate(-5954 -2370)"
          fill="#0b82f4"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5954 -2344)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5954 -2358)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5954 -2330)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5954 -2316)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <path
          d="M0,0H24a2,2,0,0,1,2,2V12a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(-5928 -2370)"
          fill="#0b82f4"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5928 -2344)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5928 -2358)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5928 -2330)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <path
          d="M0,0H26a0,0,0,0,1,0,0V12a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(-5928 -2316)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default SvgTableActive;
