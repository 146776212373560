import { Table, TableHeaderCellProps, Flex, Badge } from "ingred-ui";
import * as React from "react";

type Props = TableHeaderCellProps & {
  required?: boolean;
};

const TableHeaderCell: React.FunctionComponent<Props> = ({
  children,
  required,
  ...rest
}) => (
  <Table.HeaderCell {...rest}>
    {required ? (
      <Flex display="flex" justifyContent="space-between" alignItems="center">
        {children}
        <Badge type="pill" fontSize="12px" color="danger" fontWeight="bold">
          必須
        </Badge>
      </Flex>
    ) : (
      children
    )}
  </Table.HeaderCell>
);

export { TableHeaderCell };
