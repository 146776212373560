import {
  Column,
  DataTable,
  Flex,
  Icon,
  Spacer,
  Typography,
  useTheme,
} from "ingred-ui";
import React from "react";

import { Helmet } from "../../../../containers/Helmet";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { GamAdUnit } from "../../../../domain/gamAdUnit";
import { Link } from "../../../atoms/Link";
import { Breadcrumbs } from "../../../elements/Breadcrumbs";
import { PageContainer } from "../../../elements/PageContainer";
import { PageContent } from "../../../elements/PageContent";

type InjectProps = {
  currentCompany: CompanyAttribute;
  gamAdUnits: GamAdUnit[];
};

type Props = {};

type InjectedProps = Props & InjectProps;

type TableData = {
  id: number;
  name: string;
};

const GamAdCreativesManager: React.FunctionComponent<InjectedProps> = ({
  gamAdUnits,
  currentCompany,
}) => {
  const theme = useTheme();
  const [searchText, setSearchText] = React.useState("");
  const handleChange = React.useCallback((value: string) => {
    setSearchText(value);
  }, []);
  const searchedUnits = React.useMemo(() => {
    return gamAdUnits.filter((unit) =>
      unit.original_ad_unit_name
        .toLowerCase()
        .includes(searchText.toLowerCase()),
    );
  }, [gamAdUnits, searchText]);

  const displayData = React.useMemo(() => {
    return searchedUnits.map((unit) => ({
      id: unit.id,
      name: unit.original_ad_unit_name,
    }));
  }, [searchedUnits]);

  const columns: Column<TableData>[] = React.useMemo(
    () => [
      {
        name: "GAM広告ユニット",
        selector: (data) => data.name,
        sortable: true,
        renderCell: (data) => (
          <Flex display="flex" alignItems="center">
            <Spacer pr={1}>
              <Icon name="folder" color={theme.palette.primary.main} />
            </Spacer>
            <Link
              href={`/company/${currentCompany.company.id}/settings/gam_ad_creatives_manager/units/${data.id}`}
            >
              <Typography color="primary">{data.name}</Typography>
            </Link>
          </Flex>
        ),
      },
    ],
    [currentCompany, theme],
  );
  return (
    <>
      <Helmet title="GAM広告ユニット一覧 | DATA STRAP" />
      <PageContainer>
        <Spacer pb={3}>
          <Breadcrumbs
            items={[
              {
                title: "GAM広告クリエイティブ管理",
              },
            ]}
          />
        </Spacer>
        <PageContent
          title="GAM広告ユニット一覧"
          popoverElement={
            <Typography size="sm" lineHeight="1.7">
              GAM広告ユニットとは、Google Ad
              Manager（通称GAM）で管理している「広告ユニット」のことを指します。
              こちらではGAM広告クリエイティブへのデマンド広告クリエイティブの紐づけを管理します。
            </Typography>
          }
          placeholder="GAM広告ユニットを検索"
          searchText={searchText}
          onChange={handleChange}
        >
          <Spacer pt={2} px={2}>
            <DataTable
              dataKey={"id"}
              itemEmptyProps={{
                title: "該当するユニットがありません。",
              }}
              enablePagination={true}
              data={displayData}
              columns={columns}
            />
          </Spacer>
        </PageContent>
      </PageContainer>
    </>
  );
};

export { GamAdCreativesManager };
