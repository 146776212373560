import { Middleware } from "redux";

import { FatalHttpError } from "../../../../utils/HttpUtils";
import { HttpStatusCode } from "../../../../utils/StatusCodeUtils";
import { updateFatal } from "../actions/UpdateFatalAction";
import { updateUnavailable } from "../actions/UpdateUnavailableAction";

export function createGlobalErrorDetectionMiddleware(): Middleware {
  return (store) => (next) => (action) => {
    // for Maintenance
    if (
      action.payload != null &&
      action.payload.status === HttpStatusCode.SERVICE_UNAVAILABLE &&
      action.payload.data?.is_maintenance
    ) {
      return store.dispatch(updateUnavailable(true));
    }

    // for fatal
    if (
      action.error === true &&
      action.meta != null &&
      action.meta.fatal === true
    ) {
      if (
        action.payload == null ||
        action.payload.status !== HttpStatusCode.SERVICE_UNAVAILABLE
      ) {
        store.dispatch(updateFatal(true));
        throw new FatalHttpError();
      }
    }

    return next(action);
  };
}
