import { Request } from "api-types";
import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import {
  MarketTrendEcpm as MarketTrendEcpmType,
  MarketTrendSalesCompositionRatio as MarketTrendSalesCompositionRatioType,
  Site as SiteType,
} from "../../../domain/marketTrend";
import { RouteActionContext, RouteActionPayload } from "../../../routing/types";
import { fetchDemandAdCreativeGroups } from "../../../store/modules/demandAdCreativeGroup/actions/fetchDemandAdCreativeGroupsAction";
import { downloadMarketTrendCsv } from "../../../store/modules/marketTrend/actions/downloadMarketTrendCsvAction";
import { fetchMarketTrendEcpm } from "../../../store/modules/marketTrend/actions/fetchMarketTrendEcpmAction";
import { fetchMarketTrendSalesCompositionRatio } from "../../../store/modules/marketTrend/actions/fetchMarketTrendSalesCompositionRatioAction";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";
import { getDateRange } from "../../../utils/DateRangeUtils";

import { MarketTrend as Component } from "./MarketTrend";

const MarketTrend = connect(
  (state: RootState) => ({
    sitesRequesting: state.demandAdCreativeGroup.fetchAll.requesting,
    ecpmRequesting: state.marketTrend.fetchMarketTrendEcpm.requesting,
    salesCompositionRatioRequesting:
      state.marketTrend.fetchMarketTrendSalesCompositionRatio.requesting,
    downloadCSVRequesting: state.marketTrend.downloadMarketTrendCsv.requesting,
    ecpmData: {
      sites: state.demandAdCreativeGroup.fetchAll.sites as SiteType[],
      ecpms: state.marketTrend.fetchMarketTrendEcpm.data as MarketTrendEcpmType,
    },
    salesCompositionRatioData: state.marketTrend
      .fetchMarketTrendSalesCompositionRatio
      .data as MarketTrendSalesCompositionRatioType,
  }),
  (dispatch) => ({
    fetchSites: dispatchable(dispatch, fetchDemandAdCreativeGroups),
    fetchMarketTrendEcpm: dispatchable(dispatch, fetchMarketTrendEcpm),
    fetchMarketTrendSalesCompositionRatio: dispatchable(
      dispatch,
      fetchMarketTrendSalesCompositionRatio,
    ),
    downloadMarketTrendCsv: dispatchable(dispatch, downloadMarketTrendCsv),
  }),
)(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    const [beginDate, endDate] = getDateRange("last_30_days");
    const ecpmParams: Request.MarketTrend.FetchEcpm = {
      begin_date: beginDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      group_type_name: "day",
      demand_type_name: "adx",
      site_ids: [],
    };
    const salesCompositionRatioParams: Request.MarketTrend.FetchSalesCompositionRatio = {
      begin_date: beginDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      dimension_type_name: "dsp",
    };

    await Promise.all([
      store.dispatch(fetchDemandAdCreativeGroups()),
      store.dispatch(fetchMarketTrendEcpm(ecpmParams)),
      store.dispatch(
        fetchMarketTrendSalesCompositionRatio(salesCompositionRatioParams),
      ),
    ]);
  }
  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <MarketTrend />,
  };
}
