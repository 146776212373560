import { DemandAdCreativeGroup } from "../../../../domain/demandAdCreativeGroup";
import { RootState } from "../../../reducer";

export const getDemandAdCreativeGroups = (
  state: RootState,
): DemandAdCreativeGroup[] => {
  const { allIds, byId } = state.entities.demandAdCreativeGroup;
  return allIds.map((id) => ({
    ...byId[id],
    demand_ad_creatives: [],
  }));
};

export const getDemandAdCreativeGroupById = (
  state: RootState,
  id: number,
): DemandAdCreativeGroup | null => {
  const byId = state.entities.demandAdCreativeGroup.byId;
  if (byId[id]) {
    return {
      ...byId[id],
      demand_ad_creatives: [],
    };
  }
  return null;
};
