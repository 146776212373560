import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { Button, Spacer, Typography, Flex, ErrorText } from "ingred-ui";
import * as React from "react";

import TextMessageLogo from "../../../../assets/images/login_text_message.svg";
import { Helmet } from "../../../containers/Helmet";
import { push } from "../../../store/modules/routing/actions/PushAction";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import { getAuth0Client } from "../../../utils/Auth0Utils";
import { Link } from "../../atoms/Link";
import { Footer } from "../../elements/Footer";

import * as Styled from "./styled";

type Props = {};

type InjectProps = {
  authError: boolean;
  push: DispatchableAction<typeof push>;
};

type InjectedProps = InjectProps & Props;

const Login: React.FunctionComponent<InjectedProps> = ({ authError, push }) => {
  const [auth0Client, setAuth0Client] = React.useState<Auth0Client | null>(
    null,
  );
  const [loginButtonEnable, setLoginButtonEnable] = React.useState<boolean>(
    false,
  );

  const initialAuth0Client = async () => {
    setAuth0Client(await getAuth0Client());
  };

  React.useEffect(() => {
    initialAuth0Client();
  }, []);

  const checkAuth = React.useCallback(async () => {
    if (auth0Client) {
      const isAuthenticated = await auth0Client.isAuthenticated();
      if (isAuthenticated) {
        push("/");
      } else {
        setLoginButtonEnable(true);
      }
    }
  }, [auth0Client, push]);

  React.useEffect(() => {
    checkAuth();
  }, [auth0Client, checkAuth]);

  const handleLogin = async () => {
    if (auth0Client) {
      auth0Client.loginWithRedirect({ max_age: 0 });
    }
  };
  return (
    <>
      <Helmet title="ログイン | DATA STRAP" />
      <Styled.Container>
        <Styled.LeftContainer>
          <Typography size="md" color="primary" align="right">
            <Link
              href="https://lp.data-strap.com/"
              className="gaev-common-link-about_datastrap"
            >
              DATA STRAPについて
            </Link>
          </Typography>
          <Flex
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <Flex display="flex" alignItems="flex-end" flex={1} />
            <Flex
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              {authError && (
                <Styled.AlertBox className="gaev-common-alert-text">
                  <Spacer p={2}>
                    <Spacer pb={3}>
                      <ErrorText>認証に失敗しました</ErrorText>
                    </Spacer>
                    <Styled.AlertText lineHeight="1.7">
                      下記の「ログインの注意点」をご確認の上、再度ログインしてみてください。
                    </Styled.AlertText>
                    <Styled.AlertText lineHeight="1.7">
                      それでもログインできない、もしくは新しくアカウントを登録する場合は、
                    </Styled.AlertText>
                    <Styled.AlertText lineHeight="1.7">
                      DATA STRAP担当者まで
                      <Link
                        href="https://forms.gle/puhA9XAK3KMjuEUp9"
                        target="_blank"
                        className="gaev-contact-link-alert"
                      >
                        <Typography component="span" size="md" color="primary">
                          お問い合わせ
                        </Typography>
                      </Link>
                      ください。
                    </Styled.AlertText>
                  </Spacer>
                </Styled.AlertBox>
              )}
            </Flex>
            <Flex
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Flex display="flex" justifyContent="center">
                <Button
                  inline={true}
                  size="large"
                  className="gaev-common-btn_login"
                  disabled={!loginButtonEnable}
                  onClick={handleLogin}
                >
                  ログイン画面へ
                </Button>
              </Flex>
            </Flex>
            <Flex
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Styled.NoticeBox>
                <Typography component="h4" weight="bold">
                  <Spacer pb={1}>ログイン時の注意点</Spacer>
                </Typography>
                <Spacer pl={3}>
                  <ul>
                    <Typography component="li" lineHeight="1.7">
                      fluct及びDATA
                      STRAPに登録しているメールアドレスでログインしてください。
                    </Typography>
                    <Typography component="li" lineHeight="1.7">
                      Gmailアドレスで登録している場合は、予めGmailアカウントにログインしてからDATA
                      STRAPにログインしてください。
                    </Typography>
                    <Typography component="li" lineHeight="1.7">
                      メールアドレスがわからない場合は、DATA STRAP担当者まで
                      <Link
                        href="https://forms.gle/puhA9XAK3KMjuEUp9"
                        target="_blank"
                        className="gaev-contact-link-fixed"
                      >
                        <Typography component="span" size="md" color="primary">
                          お問い合わせ
                        </Typography>
                      </Link>
                      ください。
                    </Typography>
                  </ul>
                </Spacer>
              </Styled.NoticeBox>
            </Flex>
            <Flex
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-end"
              flex={1}
            >
              <Footer />
            </Flex>
          </Flex>
        </Styled.LeftContainer>
        <Styled.RightContainer>
          <Styled.BackgroundContainer />
          <Styled.DContainer />
          <Styled.TextMessageContainer>
            <TextMessageLogo />
          </Styled.TextMessageContainer>
        </Styled.RightContainer>
      </Styled.Container>
    </>
  );
};

export { Login };
