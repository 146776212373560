import { CompanyAttribute } from "../../../../domain/companyAttribute";

import { actionCreator } from "./actionCreator";

export type UpdateCompanyPayload = {
  company: CompanyAttribute;
};

/**
 * UpdateCompanyAction
 */
export const updateCompanyAction = actionCreator<UpdateCompanyPayload>(
  "UPDATE_COMPANY_ACTION",
);
