import styled from "styled-components";

import { Radius } from "../../../../../../../styles/variables/radius";

export const Container = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: ${Radius.SMALL};
  background-color: ${({ active, theme }) =>
    active ? theme.palette.background.active : theme.palette.background.dark};
`;
