import { Domain } from "api-types";
import {
  Spinner,
  Typography,
  DataTable,
  ContextMenu,
  Spacer,
  Icon,
  Flex,
} from "ingred-ui";
import React, { useState, useEffect, useCallback } from "react";

import { DashboardItem } from "../../../../../domain/dashboardItem";
import { DetailReport } from "../../../../../domain/detailReport";
import { createDetailReport } from "../../../../../domain/detailReport/entity";
import { fetchSavedQueryDataById } from "../../../../../infra/savedQuery/savedQueryClient";
import { MultipleLine } from "../../../../elements/MultipleLine";
import { StackedBar } from "../../../../elements/StackedBar";
import { createColumns } from "../../../DetailReport/internal/ReportTable/utils";

import * as Styled from "./styled";

import ChartData = Domain.ChartData;

export function maxValueFromChartData(chartData: ChartData[]): number {
  const maxValues: number[] = [];
  for (const i in chartData) {
    let max = 0;
    for (const j in chartData[i].data) {
      if (
        Array.isArray(chartData[i].data[j]) === true &&
        (chartData[i].data[j] as any[]).length === 2 &&
        typeof (chartData[i].data[j] as any[])[1] === "number"
      ) {
        if ((chartData[i].data[j] as [number | string, number])[1] > max) {
          max = (chartData[i].data[j] as [number | string, number])[1];
        }
      }
    }
    maxValues.push(max);
  }
  return Math.max(...maxValues);
}

// データの中身に応じて軸ラベルの桁数を調整する
function createYAxisFormatter(chartData: ChartData[]) {
  if (maxValueFromChartData(chartData) > 99999) {
    return function (value: number) {
      return `${value / 10000}万`;
    };
  }
  return function (value: number) {
    return value.toString();
  };
}

type Props = {
  companyId: number;
  item: DashboardItem;
  filter: Domain.AdditionalFilter | null;
  onEdit: (itemId: number) => () => void;
  onDelete: (itemId: number) => () => void;
};

export const DashboardItemCard: React.FC<Props> = ({
  companyId,
  item,
  filter,
  onEdit,
  onDelete,
}) => {
  const [queryData, setQueryData] = useState<DetailReport>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");

  const operations = [
    {
      text: "アイテムを編集",
      onClick: onEdit(item.id),
    },
    {
      text: "削除",
      onClick: onDelete(item.id),
    },
  ];

  const getData = useCallback(async () => {
    setDataLoading(true);

    const data = await fetchSavedQueryDataById(companyId, item.query_id, {
      additional_filters: filter,
    }).catch((error) => {
      setErrorText(error.data.errors[0].message);
    });
    if (!data) return;
    setQueryData(createDetailReport(data));
    setDataLoading(false);
  }, [companyId, filter, item.query_id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Styled.Container span={item.size}>
      <Styled.TitleContainer>
        <Typography weight="bold" size="xl">
          {item.title}
        </Typography>
        <ContextMenu contents={operations} />
      </Styled.TitleContainer>
      <Styled.Content>
        {errorText}
        {queryData && (
          <>
            {item.display_type === "table" && (
              <DataTable
                dataKey={"id"}
                enableRuledLine={true}
                fullWidth={true}
                tableMaxHeight="400px"
                horizontalScrollable={true}
                data={queryData.records}
                columns={createColumns(queryData)}
              />
            )}
            {item.display_type === "bar" && (
              <StackedBar
                data={queryData.chart}
                xAxisType={"datetime"}
                yAxisFormatter={createYAxisFormatter(queryData.chart)}
              />
            )}
            {item.display_type === "line" && (
              <MultipleLine
                data={queryData.chart}
                xAxisType={"datetime"}
                yAxisFormatter={createYAxisFormatter(queryData.chart)}
              />
            )}
            <Spacer pt={2} />
            <Flex display="flex" alignItems="center" justifyContent="flex-end">
              <Styled.QueryDetailLink
                href={`/company/${companyId}/report?saved_query_id=${item.query_id}`}
              >
                クエリの詳細を見る
              </Styled.QueryDetailLink>
              <Icon name="arrow_right" color="active" />
            </Flex>
          </>
        )}
        {dataLoading && (
          <Styled.LoadingContainer>
            <Spinner />
          </Styled.LoadingContainer>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
