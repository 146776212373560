/* eslint-disable react/jsx-handler-names */
import { Flex, RadioButton, Typography, useTheme } from "ingred-ui";
import React from "react";

import * as Styled from "./styled";

type Props = {
  itemSize: number;
  onClick: (item: number) => () => void;
};

export const ItemSizeSelector: React.FunctionComponent<Props> = ({
  itemSize,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <Flex display="flex" justifyContent="space-between">
      <Styled.PictContainer
        width="169"
        height="129"
        viewBox="0 0 169 129"
        onClick={onClick(1)}
      >
        <g id="Illust_half_deselect" transform="translate(8524 1437)">
          <rect
            width="169"
            height="129"
            rx="4"
            transform="translate(-8524 -1437)"
            fill={
              itemSize === 1
                ? theme.palette.primary.highlight
                : theme.palette.gray.light
            }
          />
          <g
            transform="translate(-8483 -1401)"
            fill={theme.palette.text.white}
            stroke={theme.palette.gray.main}
            strokeWidth="2"
          >
            <rect width="87" height="82" stroke="none" />
            <rect x="1" y="1" width="85" height="80" fill="none" />
          </g>
          <rect
            width="86"
            height="2"
            transform="translate(-8482 -1389)"
            fill={theme.palette.gray.main}
          />
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="translate(-8479 -1396)"
            fill={theme.palette.gray.main}
          />
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="translate(-8473 -1396)"
            fill={theme.palette.gray.main}
          />
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="translate(-8467 -1396)"
            fill={theme.palette.gray.main}
          />
          <g
            transform="translate(-8477 -1353)"
            fill={
              itemSize === 1
                ? theme.palette.background.hint
                : theme.palette.gray.light
            }
            stroke={
              itemSize === 1
                ? theme.palette.primary.main
                : theme.palette.gray.dark
            }
            strokeWidth="2"
          >
            <rect width="35" height="25" stroke="none" />
            <rect x="1" y="1" width="33" height="23" fill="none" />
          </g>
          <rect
            width="35"
            height="25"
            transform="translate(-8477 -1382)"
            fill={theme.palette.gray.light}
          />
          <rect
            width="35"
            height="25"
            transform="translate(-8437 -1382)"
            fill={theme.palette.gray.light}
          />
        </g>
        <foreignObject x={29} y={7.5} width="110" height="32">
          <RadioButton checked={itemSize === 1} value={1}>
            <Typography>ハーフサイズ</Typography>
          </RadioButton>
        </foreignObject>
      </Styled.PictContainer>
      <Styled.PictContainer
        width="169"
        height="129"
        viewBox="0 0 169 129"
        onClick={onClick(2)}
      >
        <g id="Illust_full_Deselect" transform="translate(8326 1437)">
          <rect
            width="169"
            height="129"
            rx="4"
            transform="translate(-8326 -1437)"
            fill={
              itemSize === 2
                ? theme.palette.primary.highlight
                : theme.palette.gray.light
            }
          />
          <g
            transform="translate(-8285 -1401)"
            fill={theme.palette.text.white}
            stroke={theme.palette.gray.main}
            strokeWidth="2"
          >
            <rect width="87" height="82" stroke="none" />
            <rect x="1" y="1" width="85" height="80" fill="none" />
          </g>
          <rect
            width="86"
            height="2"
            transform="translate(-8284 -1389)"
            fill={theme.palette.gray.main}
          />
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="translate(-8281 -1396)"
            fill={theme.palette.gray.main}
          />
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="translate(-8275 -1396)"
            fill={theme.palette.gray.main}
          />
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="translate(-8269 -1396)"
            fill={theme.palette.gray.main}
          />
          <g
            transform="translate(-8279 -1353)"
            fill={
              itemSize === 2
                ? theme.palette.background.hint
                : theme.palette.gray.light
            }
            stroke={
              itemSize === 2
                ? theme.palette.primary.main
                : theme.palette.gray.dark
            }
            strokeWidth="2"
          >
            <rect width="75" height="25" stroke="none" />
            <rect x="1" y="1" width="73" height="23" fill="none" />
          </g>
          <rect
            width="35"
            height="25"
            transform="translate(-8279 -1382)"
            fill={theme.palette.gray.light}
          />
          <rect
            width="35"
            height="25"
            transform="translate(-8239 -1382)"
            fill={theme.palette.gray.light}
          />
        </g>
        <foreignObject x={35} y={7.5} width="110" height="32">
          <RadioButton checked={itemSize === 2} value={2}>
            <Typography>フルサイズ</Typography>
          </RadioButton>
        </foreignObject>
      </Styled.PictContainer>
    </Flex>
  );
};
