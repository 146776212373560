import { Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DeleteDemandAdCreativeGroup
 */
export type DeleteDemandAdCreativeGroupParams = void;
export type DeleteDemandAdCreativeGroupSuccess = {
  site_id: number;
  unmappedDemandAdCreativeIds: string[];
};
export type DeleteDemandAdCreativeGroupFailure = Error;

export const deleteDemandAdCreativeGroupAction = actionCreator.async<
  DeleteDemandAdCreativeGroupParams,
  DeleteDemandAdCreativeGroupSuccess,
  DeleteDemandAdCreativeGroupFailure
>("DELETE_DEMAND_AD_CREATIVE_GROUP");

export function deleteDemandAdCreativeGroup(
  payload: Request.Site.Delete,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { site, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(deleteDemandAdCreativeGroupAction.started());

    try {
      await site.deleteSiteById(apiClient, payload, currentCompany.company.id);
      const deletedDemandAdCreative = _getState().entities.demandAdCreativeGroup
        .byId[payload.site_id];
      dispatch(
        deleteDemandAdCreativeGroupAction.done({
          result: {
            site_id: payload.site_id,
            unmappedDemandAdCreativeIds:
              deletedDemandAdCreative.demand_ad_creatives,
          },
        }),
      );
    } catch (err) {
      dispatch(deleteDemandAdCreativeGroupAction.failed(err));
    }
  };
}
