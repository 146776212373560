import { Domain, Request } from "api-types";
import dayjs from "dayjs";
import {
  Card,
  Button,
  Spacer,
  Typography,
  Flex,
  DataTable,
  OptionType,
  ActionButton,
  DateRangePicker,
  DatePicker,
} from "ingred-ui";
import * as React from "react";

import { MarketTrendSalesCompositionRatio } from "../../../../../domain/marketTrend";
import {
  displayPeriodOptions,
  getDateRange,
} from "../../../../../utils/DateRangeUtils";
import { PageContent } from "../../../../elements/PageContent";

import * as Styled from "./styled";
import { createColumns } from "./utils";

type Props = {
  data: MarketTrendSalesCompositionRatio;
  onSubmit: (payload: Request.MarketTrend.FetchSalesCompositionRatio) => void;
  onDownloadCSV: (
    payload: Request.MarketTrend.FetchSalesCompositionRatio & {
      trend_type_name: "mine" | "market";
    },
  ) => void;
};

const dimensionOptions: OptionType<Domain.MtDimensionType>[] = [
  {
    label: "DSP",
    value: "dsp",
  },
  {
    label: "広告主",
    value: "advertiser",
  },
  {
    label: "ドメイン",
    value: "domain",
  },
];

export const SalesCompositionRatio: React.FunctionComponent<Props> = ({
  data,
  onSubmit,
  onDownloadCSV,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);

  const [selectedDimension, setSelectedDimension] = React.useState<
    OptionType<Domain.MtDimensionType>
  >({
    label: "DSP",
    value: "dsp",
  });

  const [displayPeriod, setDisplayPeriod] = React.useState<
    OptionType<Domain.DateRangeType>
  >(displayPeriodOptions[2]);

  const [startDate, setStartDate] = React.useState<dayjs.Dayjs>(
    getDateRange("last_30_days")[0],
  );
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(
    getDateRange("last_30_days")[1],
  );

  const handleDimensionChange = (option: any) => {
    setIsButtonDisabled(false);
    setSelectedDimension(option);
  };

  const handleDisplayPeriodChange = (option: any) => {
    if (option) {
      setIsButtonDisabled(false);
      setDisplayPeriod(option);
      if (option.value === "custom_range") {
        return;
      }
      if (option.value === "custom_single") {
        setStartDate(dayjs().add(-1, "day"));
        return;
      }
      const [startDate, endDate] = getDateRange(option.value);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    setIsButtonDisabled(false);
    setStartDate(startDate);
    setEndDate(endDate);
    setDisplayPeriod({
      label: "カスタム(期間)",
      value: "custom_range",
    });
  };

  const handleDateChange = (date: dayjs.Dayjs) => {
    setStartDate(date);
    setEndDate(date);
  };

  const handleSubmit = () => {
    const params: Request.MarketTrend.FetchSalesCompositionRatio = {
      begin_date: (startDate as dayjs.Dayjs).format("YYYY-MM-DD"),
      end_date: (endDate as dayjs.Dayjs).format("YYYY-MM-DD"),
      dimension_type_name: selectedDimension.value,
    };
    onSubmit(params);
  };

  const handleDownloadCSV = (trendType: "mine" | "market") => {
    const params: Request.MarketTrend.FetchSalesCompositionRatio & {
      trend_type_name: "mine" | "market";
    } = {
      begin_date: (startDate as dayjs.Dayjs).format("YYYY-MM-DD"),
      end_date: (endDate as dayjs.Dayjs).format("YYYY-MM-DD"),
      dimension_type_name: selectedDimension.value,
      trend_type_name: trendType,
    };
    onDownloadCSV(params);
  };
  const actions = React.useMemo(
    () =>
      displayPeriodOptions.map((option) => ({
        text: option.label,
        onClick: () => {
          handleDisplayPeriodChange(option);
        },
      })),
    [],
  );

  return (
    <PageContent
      title="AdXの売上構成比較"
      description="ディメンションごとに売上比率とeCPMの比較ができます。"
    >
      <Spacer p={3}>
        <Styled.SearchBox>
          <Typography weight="bold">設定</Typography>
          <Spacer pt={2} />
          <Card p={2}>
            <Flex display="flex" alignItems="flex-start" flexWrap="wrap">
              <div>
                <Typography color="secondary" size="sm" weight="bold">
                  ディメンション
                </Typography>
                <Spacer pb={1} />
                <Styled.Select
                  width="120px"
                  isClearable={false}
                  value={selectedDimension}
                  options={dimensionOptions}
                  onChange={handleDimensionChange}
                />
              </div>
              <Spacer pr={2} />
              <div>
                <Flex display="flex" alignItems="flex-end" flexWrap="wrap">
                  <div>
                    <Typography color="secondary" size="sm" weight="bold">
                      表示期間
                    </Typography>
                    <Spacer pb={1} />
                    {displayPeriod.value === "custom_single" ? (
                      <DatePicker
                        date={startDate}
                        isOutsideRange={(day: dayjs.Dayjs) =>
                          day.isAfter(dayjs())
                        }
                        defaultClickAction={displayPeriod.label}
                        actions={actions}
                        onDateChange={handleDateChange}
                      />
                    ) : (
                      <DateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        isOutsideRange={(day: dayjs.Dayjs) =>
                          day.isAfter(dayjs())
                        }
                        defaultClickAction={displayPeriod.label}
                        actions={actions}
                        onDatesChange={handleDatesChange}
                      />
                    )}
                  </div>
                  <Spacer pr={3} />
                  <Button
                    disabled={isButtonDisabled}
                    inline={true}
                    size="medium"
                    className="gaev-markettrend-adx-btn-apply"
                    onClick={handleSubmit}
                  >
                    適用
                  </Button>
                </Flex>
              </div>
            </Flex>
          </Card>
        </Styled.SearchBox>
        <Spacer pt={4} />
        <Flex display="flex" justifyContent="space-between">
          <Styled.List>
            <Spacer pb={3}>
              <Flex
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex display="flex">
                  <Typography weight="bold">マーケット</Typography>
                  <Spacer pr={1} />
                  <Typography color="secondary">
                    (計{data.market.all_count}件)
                  </Typography>
                </Flex>
                <ActionButton
                  icon="import"
                  className="gaev-markettrend-adx-market-btn-csvdl"
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={() => {
                    handleDownloadCSV("market");
                  }}
                >
                  CSV形式でダウンロード
                </ActionButton>
              </Flex>
            </Spacer>
            <DataTable
              dataKey={"id"}
              itemEmptyProps={{
                title: "該当するデータがありません",
              }}
              data={data.market.records}
              defaultSortField="売上比率(%)"
              defaultSortOrder="desc"
              columns={createColumns()}
            />
          </Styled.List>
          <Styled.List>
            <Spacer pb={3}>
              <Flex
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex display="flex">
                  <Typography weight="bold">自社</Typography>
                  <Spacer pr={1} />
                  <Typography color="secondary">
                    (計{data.mine.all_count}件)
                  </Typography>
                </Flex>
                <ActionButton
                  icon="import"
                  className="gaev-markettrend-adx-inhouse-btn-csvdl"
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={() => {
                    handleDownloadCSV("mine");
                  }}
                >
                  CSV形式でダウンロード
                </ActionButton>
              </Flex>
            </Spacer>
            <DataTable
              dataKey={"id"}
              itemEmptyProps={{
                title: "該当するデータがありません",
              }}
              data={data.mine.records}
              defaultSortField="売上比率(%)"
              defaultSortOrder="desc"
              columns={createColumns()}
            />
          </Styled.List>
        </Flex>
        <Spacer pt={2}>
          <Styled.AnnotationText size="sm" lineHeight="1.7">
            ※上位20件のみ表示されています。すべてを確認するにはCSV形式でダウンロードをしてください。
          </Styled.AnnotationText>
          <Styled.AnnotationText size="sm" lineHeight="1.7">
            ※現在、fluctアドマネージャーアカウントをご利用の媒体社様は、マーケットと自社のデータで同一のものが表示されている場合があります。
          </Styled.AnnotationText>
        </Spacer>
      </Spacer>
    </PageContent>
  );
};
