import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchDashboardItem
 */
export type PatchDashboardItemParams = void;
export type PatchDashboardItemSuccess = Response.DashboardItem.Patch;
export type PatchDashboardItemFailure = Error;

export const patchDashboardItemAction = actionCreator.async<
  PatchDashboardItemParams,
  PatchDashboardItemSuccess,
  PatchDashboardItemFailure
>("PATCH_DASHBOARD_ITEM");

export function patchDashboardItem(
  payload: Request.DashboardItem.Patch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, dashboardItem }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchDashboardItemAction.started());

    try {
      const res = await dashboardItem.patchDashboardItemById(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(patchDashboardItemAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(patchDashboardItemAction.failed({ error }));
      }
    }
  };
}
