import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DeleteDemandAdCreatives
 */
export type DeleteDemandAdCreativesParams = void;
export type DeleteDemandAdCreativesSuccess = {
  demandAdCreatives: DemandAdCreative[];
};
export type DeleteDemandAdCreativesFailure = Error;

export const deleteDemandAdCreativesAction = actionCreator.async<
  DeleteDemandAdCreativesParams,
  DeleteDemandAdCreativesSuccess,
  DeleteDemandAdCreativesFailure
>("DELETE_DEMAND_AD_CREATIVES");

type DeleteDemandAdCreativesPayload = {
  demandAdCreatives: DemandAdCreative[];
};

export function deleteDemandAdCreatives(
  payload: DeleteDemandAdCreativesPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(deleteDemandAdCreativesAction.started());

    try {
      await openApiClient.rpzApiViewsSiteDeleteSiteCreative(
        currentCompany.company.id,
        {
          demand_account_creative_ids: payload.demandAdCreatives.map(
            (creative) => creative.id,
          ),
        },
      );
      dispatch(
        deleteDemandAdCreativesAction.done({
          result: payload,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteDemandAdCreativesAction.failed({ error }));
      }
    }
  };
}
