import { Typography } from "ingred-ui";
import * as queryString from "query-string";
import * as React from "react";

import { Helmet } from "../../../containers/Helmet";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { User } from "../../../domain/user";
import { push } from "../../../store/modules/routing/actions/PushAction";
import { selectCompany } from "../../../store/modules/user/actions/selectCompanyAction";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import { Scrollbars } from "../../atoms/Scrollbars";

import * as Styled from "./styled";

type Props = {
  user: User;
  selectCompany: DispatchableAction<typeof selectCompany>;
  push: DispatchableAction<typeof push>;
};

const SelectCompany: React.FunctionComponent<Props> = ({
  user,
  selectCompany,
  push,
}) => {
  const handleClick = (company: CompanyAttribute) => () => {
    selectCompany({ company });
    const redirectUri = queryString.parse(location.search).redirect_uri as
      | string
      | undefined;
    if (redirectUri) {
      push(redirectUri);
    } else {
      push("/");
    }
  };
  return (
    <>
      <Helmet title="企業選択 | DATA STRAP" />
      <Styled.Background>
        <Styled.Container>
          <Styled.TitleContainer>
            <Typography component="h1" weight="bold" size="xxxl">
              企業の選択
            </Typography>
          </Styled.TitleContainer>
          <Styled.ContentContainer>
            <Typography color="secondary" size="sm">
              アクセスする企業を一つ選んでください。
            </Typography>
            <Scrollbars maxHeight="70vh">
              <Styled.Ul>
                {user.company_attributes.map((attribute) => (
                  <Styled.Li
                    key={attribute.company.id}
                    onClick={handleClick(attribute)}
                  >
                    <Typography color="primary">
                      {attribute.company.name}
                    </Typography>
                  </Styled.Li>
                ))}
              </Styled.Ul>
            </Scrollbars>
          </Styled.ContentContainer>
        </Styled.Container>
      </Styled.Background>
    </>
  );
};

export { SelectCompany };
