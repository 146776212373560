import { Typography, Spacer, Flex, ActionButton, DataTable } from "ingred-ui";
import React from "react";

import { Helmet } from "../../../../containers/Helmet";
import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { GamAdCreative } from "../../../../domain/gamAdCreative";
import { GamAdUnit } from "../../../../domain/gamAdUnit";
import { GamLineItem } from "../../../../domain/gamLineItem";
import {
  deleteDemandAdCreatives,
  DeleteDemandAdCreativesPayload,
} from "../../../../store/modules/gamAdCreative/actions/deleteDemandAdCreativesAction";
import {
  postDemandAdCreatives,
  PostDemandAdCreativesPayload,
} from "../../../../store/modules/gamAdCreative/actions/postDemandAdCreativesAction";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../../store/utils/dispatchable";
import { Breadcrumbs } from "../../../elements/Breadcrumbs";
import { PageContainer } from "../../../elements/PageContainer";
import { PageContent } from "../../../elements/PageContent";

import { AddModal } from "./internal/AddModal";
import { DeleteModal } from "./internal/DeleteModal";

const getDemandAdCreativeColumnValue = (
  demandAdCreatives: DemandAdCreative[],
) => {
  const count = demandAdCreatives.length;
  if (count === 0) {
    return <Typography>-</Typography>;
  }
  if (count === 1) {
    return (
      <Typography>
        {demandAdCreatives[0].original_name || demandAdCreatives[0].original_id}
      </Typography>
    );
  }
  return <Typography>{count}個</Typography>;
};

const getDemandColumnValue = (demandAdCreatives: DemandAdCreative[]) => {
  const demandNames = Array.from(
    new Set(demandAdCreatives.map((creative) => creative.demand.name)),
  );
  if (demandNames.length === 1) {
    return demandAdCreatives[0].demand.name;
  }
  return "-";
};

type InjectProps = {
  gamAdCreatives: GamAdCreative[];
  demandAdCreatives: DemandAdCreative[];
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  postRequesting: boolean;
  postDemandAdCreatives: DispatchableAction<typeof postDemandAdCreatives>;
  postSucceeded: boolean;
  deleteRequesting: boolean;
  deleteDemandAdCreatives: DispatchableAction<typeof deleteDemandAdCreatives>;
  deleteSucceeded: boolean;
  parentGamAdUnit: GamAdUnit;
  parentGamLineItem: GamLineItem;
};

export type Props = {
  parentGamAdUnitId: number;
  parentGamLineItemId: number;
};

type InjectedProps = Props & InjectProps;

const GamAdCreatives: React.FunctionComponent<InjectedProps> = ({
  gamAdCreatives,
  demandAdCreatives,
  enqueueSystemNotification,
  postRequesting,
  postDemandAdCreatives,
  postSucceeded,
  deleteRequesting,
  deleteDemandAdCreatives,
  deleteSucceeded,
  parentGamAdUnit,
  parentGamLineItem,
}) => {
  const [postToasted, setPostToasted] = React.useState(postSucceeded);
  const [deleteToasted, setDeleteToasted] = React.useState(deleteSucceeded);

  const [addModalId, setAddModalId] = React.useState<number | null>(null);
  const [deleteModalId, setDeleteModalId] = React.useState<number | null>(null);

  const data = gamAdCreatives.map((creative) => ({
    id: creative.id,
    originalCreativeId: creative.original_line_item_creative_id,
    gamCreativeName: creative.original_line_item_creative_name,
    demandAdCreatives: creative.demand_ad_creatives,
  }));
  const [searchText, setSearchText] = React.useState("");
  const searchedItems = data.filter((item) =>
    item.gamCreativeName.toLowerCase().includes(searchText.toLowerCase()),
  );

  React.useEffect(() => {
    if (!postToasted && postSucceeded) {
      setAddModalId(null);
      enqueueSystemNotification({
        message: "紐付けが完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setPostToasted(true);
    }
  }, [enqueueSystemNotification, postToasted, postSucceeded]);

  React.useEffect(() => {
    if (!deleteToasted && deleteSucceeded) {
      setDeleteModalId(null);
      enqueueSystemNotification({
        message: "紐付けを解除しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setDeleteToasted(true);
    }
  }, [enqueueSystemNotification, deleteToasted, deleteSucceeded]);

  const handleChangeAddModalId = (id: number | null) => () => setAddModalId(id);
  const handleChangeDeleteModalId = (id: number | null) => () =>
    setDeleteModalId(id);

  const handleAddSubmit = (data: PostDemandAdCreativesPayload) => {
    postDemandAdCreatives(data);
    setPostToasted(false);
  };

  const handleDeleteSubmit = (data: DeleteDemandAdCreativesPayload) => {
    deleteDemandAdCreatives(data);
    setDeleteToasted(false);
  };

  return (
    <>
      <Helmet
        title={`${parentGamLineItem.original_line_item_name}のGAM広告クリエイティブ一覧 | DATA STRAP`}
      />
      <PageContainer>
        <Spacer pb={3}>
          <Breadcrumbs
            items={[
              {
                title: "GAM広告クリエイティブ管理",
                link: "/settings/gam_ad_creatives_manager",
              },
              {
                title: parentGamAdUnit.original_ad_unit_name,
                link: `/settings/gam_ad_creatives_manager/units/${parentGamAdUnit.id}`,
              },
              {
                title: parentGamLineItem.original_line_item_name,
              },
            ]}
          />
        </Spacer>
        <Flex display="flex" alignItems="flex-start">
          <PageContent
            title={`${parentGamLineItem.original_line_item_name}のGAM広告クリエイティブ一覧`}
            placeholder="GAM広告クリエイティブを検索"
            searchText={searchText}
            onChange={setSearchText} // eslint-disable-line react/jsx-handler-names
          >
            <Spacer pt={2} px={2}>
              <DataTable
                dataKey={"id"}
                itemEmptyProps={{
                  title: "該当するクリエイティブがありません。",
                }}
                enablePagination={true}
                data={searchedItems}
                columns={[
                  {
                    name: "GAM広告クリエイティブ",
                    selector: (data) =>
                      `${data.gamCreativeName}(${data.originalCreativeId})`,
                    sortable: true,
                  },
                  {
                    name: "デマンド広告クリエイティブ",
                    selector: (data) => data.demandAdCreatives.length,
                    sortable: true,
                    renderCell: (data) =>
                      getDemandAdCreativeColumnValue(data.demandAdCreatives),
                  },
                  {
                    name: "デマンド",
                    selector: (data) =>
                      getDemandColumnValue(data.demandAdCreatives),
                    sortable: true,
                    renderCell: (data) => (
                      <Typography>
                        {getDemandColumnValue(data.demandAdCreatives)}
                      </Typography>
                    ),
                  },
                  {
                    name: "操作",
                    selector: (data) => data.id,
                    renderCell: (data) =>
                      data.demandAdCreatives.length === 0 ? (
                        <>
                          <ActionButton
                            icon="pencil"
                            onClick={handleChangeAddModalId(data.id)}
                          >
                            紐付ける
                          </ActionButton>
                          {addModalId === data.id && (
                            <AddModal
                              gamAdUnit={parentGamAdUnit}
                              loading={postRequesting}
                              demandAdCreatives={demandAdCreatives}
                              gamAdCreative={data}
                              onClose={handleChangeAddModalId(null)}
                              onSubmit={handleAddSubmit}
                            />
                          )}
                        </>
                      ) : (
                        <Flex display="flex" alignItems="center">
                          <Spacer pr={0.5}>
                            <ActionButton
                              icon="link"
                              onClick={handleChangeAddModalId(data.id)}
                            >
                              紐付ける
                            </ActionButton>
                            {addModalId === data.id && (
                              <AddModal
                                gamAdUnit={parentGamAdUnit}
                                loading={postRequesting}
                                demandAdCreatives={demandAdCreatives}
                                gamAdCreative={data}
                                onClose={handleChangeAddModalId(null)}
                                onSubmit={handleAddSubmit}
                              />
                            )}
                          </Spacer>
                          <ActionButton
                            icon="unlink"
                            onClick={handleChangeDeleteModalId(data.id)}
                          >
                            紐付けを解除
                          </ActionButton>
                          {addModalId === data.id && (
                            <AddModal
                              gamAdUnit={parentGamAdUnit}
                              loading={postRequesting}
                              demandAdCreatives={demandAdCreatives}
                              gamAdCreative={data}
                              onClose={handleChangeAddModalId(null)}
                              onSubmit={handleAddSubmit}
                            />
                          )}
                          {deleteModalId === data.id && (
                            <DeleteModal
                              loading={deleteRequesting}
                              demandAdCreatives={data.demandAdCreatives}
                              gamAdCreative={data}
                              onClose={handleChangeDeleteModalId(null)}
                              onSubmit={handleDeleteSubmit}
                            />
                          )}
                        </Flex>
                      ),
                  },
                ]}
              />
            </Spacer>
          </PageContent>
        </Flex>
      </PageContainer>
    </>
  );
};

export { GamAdCreatives };
