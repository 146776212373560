import { GamFlattenedHierarchy } from "../../../../domain/gamFlattenedHierarchy";
import { RootState } from "../../../reducer";

export const getGamFlattenedHierarchys = (
  state: RootState,
): GamFlattenedHierarchy[] => {
  const { allIds, byId } = state.entities.gamFlattenedHierarchy;
  return allIds.map((id) => byId[id]);
};

export const getGamFlattenedHierarchyById = (
  state: RootState,
  id: number,
): GamFlattenedHierarchy | null => {
  const byId = state.entities.gamFlattenedHierarchy.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
