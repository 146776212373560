import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchUnmapped
 */
export type FetchUnmappedGamFlattedHierarchiesParams = void;
export type FetchUnmappedGamFlattedHierarchiesSuccess = Response.GamFlattenedHierarchy.FetchUnmapped;
export type FetchUnmappedGamFlattedHierarchiesFailure = Error;

export const fetchUnmappedGamFlattenedHierarchiesAction = actionCreator.async<
  FetchUnmappedGamFlattedHierarchiesParams,
  FetchUnmappedGamFlattedHierarchiesSuccess,
  FetchUnmappedGamFlattedHierarchiesFailure
>("FETCH_UNMAPPED_GAM_FLATTED_HIERARCHIES");

export function fetchUnmappedGamFlattenedHierarchies(): ThunkAction<
  Promise<void>,
  any
> {
  return async (dispatch, _getState, { gamLineItemCreative, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchUnmappedGamFlattenedHierarchiesAction.started());

    try {
      const res = await gamLineItemCreative.fetchUnmappedGamFlattenedHierarchies(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(
        fetchUnmappedGamFlattenedHierarchiesAction.done({ result: res }),
      );
    } catch (err) {
      dispatch(fetchUnmappedGamFlattenedHierarchiesAction.failed(err));
    }
  };
}
