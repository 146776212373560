import { Request, Response } from "api-types";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchDashboardItemsParams = void;
export type FetchDashboardItemsResponse = Response.DashboardItem.FetchAll;

export async function fetchDashboardItems(
  apiClient: APIClient,
  companyId: number,
) {
  const { data } = await apiClient.get<FetchDashboardItemsResponse>(
    `/v1/dashboard`,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );
  return data;
}

export type PostDashboardItemParams = Request.DashboardItem.Post;
export type PostDashboardItemResponse = Response.DashboardItem.Post;

export async function postDashboardItem(
  apiClient: APIClient,
  params: PostDashboardItemParams,
  companyId: number,
) {
  const { data } = await apiClient.post<PostDashboardItemResponse>(
    `/v1/dashboard/item`,
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );
  return data;
}

export type PatchDashboardItemParams = Request.DashboardItem.Patch;
export type PatchDashboardItemResponse = Response.DashboardItem.Patch;

export async function patchDashboardItemById(
  apiClient: APIClient,
  params: PatchDashboardItemParams,
  companyId: number,
) {
  const { data } = await apiClient.post<PatchDashboardItemResponse>(
    `/v1/dashboard/item/${params.item_id}`,
    {
      title: params.title,
      query_id: params.query_id,
      display_type: params.display_type,
      size: params.size,
      order: params.order,
    },
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );
  return data;
}

export type DeleteDashboardItemParams = Request.DashboardItem.Delete;
export type DeleteDashboardItemResponse = void;

export async function deleteDashboardItemById(
  apiClient: APIClient,
  params: DeleteDashboardItemParams,
  companyId: number,
) {
  const { data } = await apiClient.delete<DeleteDashboardItemResponse>(
    `/v1/dashboard/item/${params.item_id}`,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );
  return data;
}
