import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { RouteActionContext, RouteActionPayload } from "../../../routing/types";
import { deleteSavedQuery as deleteSavedQueryAction } from "../../../store/modules/savedQuery/actions/deleteSavedQueryAction";
import { fetchSavedQueries as fetchSavedQueriesAction } from "../../../store/modules/savedQuery/actions/fetchSavedQueriesAction";
import { getSavedQueries } from "../../../store/modules/savedQuery/selectors/savedQuerySelector";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { SavedQueryList as Component } from "./SavedQuery";

const SavedQuery = connect(
  (state: RootState) => ({
    currentCompany: state.user.currentCompany as CompanyAttribute,
    deleteRequesting: state.savedQuery.delete.requesting,
    deleteSucceeded: state.savedQuery.delete.success,
    savedQueries: getSavedQueries(state),
  }),
  (dispatch) => ({
    deleteSavedQuery: dispatchable(dispatch, deleteSavedQueryAction),
  }),
)(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await store.dispatch(fetchSavedQueriesAction());
  }
  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <SavedQuery />,
  };
}
