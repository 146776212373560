import {
  Spacer,
  Icon,
  Flex,
  Typography,
  useTheme,
  DataTable,
  TextField,
} from "ingred-ui";
import * as React from "react";

import { channelTypeName } from "../../../../../../constants/channelType";
import { deviceTypeName } from "../../../../../../constants/deviceType";
import { osTypeName } from "../../../../../../constants/osType";
import { DemandAdCreative } from "../../../../../../domain/demandAdCreative";
import { StepModal } from "../../../../../elements/StepModal";

import * as Styled from "./styled";

const convertToDataTableArray = (creatives: DemandAdCreative[]) =>
  creatives.map((creative) => ({
    ...creative,
    name: creative.original_name || creative.original_id,
  }));

export type ModalType =
  | "change_channel_type"
  | "change_device_type"
  | "change_os_type";

const FormatMap: {
  [key in ModalType]: {
    baseItemName: string;
    selector: (data: ReturnType<typeof convertToDataTableArray>[0]) => string;
  };
} = {
  change_channel_type: {
    baseItemName: "チャネル",
    selector: (data) => channelTypeName[data.channel_type_name] || "",
  },
  change_device_type: {
    baseItemName: "デバイス",
    selector: (data) => deviceTypeName[data.device_type_name] || "",
  },
  change_os_type: {
    baseItemName: "OS",
    selector: (data) => osTypeName[data.os_type_name] || "",
  },
};

export type BaseItem<T = string> = {
  id: number;
  name: string;
  value: T;
};

type Props<T> = {
  onClose: () => void;
  onSubmit: (selectedItem: T) => void;
  loading: boolean;
  baseItems: T[];
  type: ModalType;
  selectedDemandAdCreatives: DemandAdCreative[];
};

const MoveModal = <T extends BaseItem>({
  onClose,
  onSubmit,
  loading,
  baseItems,
  type,
  selectedDemandAdCreatives,
}: Props<T>): JSX.Element => {
  const theme = useTheme();
  const format = FormatMap[type];
  const [searchText, setSearchText] = React.useState("");

  const searchedItems = React.useMemo(
    () =>
      baseItems.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [baseItems, searchText],
  );

  const [selectedItem, setSelectedItem] = React.useState<T | null>(null);

  const [step, setStep] = React.useState<1 | 2>(1);

  const handleChangeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleSelect = (selectedId: number) => {
    const selectedItem = baseItems.find((item) => item.id === selectedId);
    setSelectedItem(selectedItem || null);
  };

  const handleConfirmSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStep(2);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedItem) {
      onSubmit(selectedItem);
    }
  };

  const handlePrev = () => {
    setStep(1);
  };

  return (
    <StepModal
      currentStepKey={step}
      steps={[
        {
          title: `デマンド広告クリエイティブの${format.baseItemName}を変更する`,
          stepKey: 1,
          cancel: {
            text: "キャンセル",
            onClick: onClose,
          },
          submit: {
            text: "確認する",
            onSubmit: handleConfirmSubmit,
            disabled: !selectedItem,
          },
          renderContent: () => (
            <>
              <Styled.TopContainer>
                <Styled.InputContainer>
                  <TextField
                    icon="search"
                    placeholder={`${format.baseItemName}を検索`}
                    value={searchText}
                    onChange={handleChangeSearchText}
                  />
                </Styled.InputContainer>
              </Styled.TopContainer>
              <Spacer pt={2} px={3} pb={15}>
                <DataTable
                  dataKey={"id"}
                  itemEmptyProps={{
                    title: `該当する${format.baseItemName}がありません。`,
                  }}
                  enablePagination={true}
                  per={10}
                  data={searchedItems}
                  columns={[
                    {
                      name: "名前",
                      selector: (data) => data.name,
                      sortable: true,
                      renderCell: (data) => (
                        <Flex display="flex" alignItems="center">
                          <Spacer pr={0.5}>
                            <Icon
                              name="folder"
                              color={theme.palette.primary.main}
                            />
                          </Spacer>
                          <Typography>{data.name}</Typography>
                        </Flex>
                      ),
                    },
                  ]}
                  selectedRow={selectedItem?.id}
                  onRadioChange={handleSelect}
                />
              </Spacer>
            </>
          ),
        },
        {
          title: `下記のデマンド広告クリエイティブの${format.baseItemName}を${selectedItem?.name}に変更しますか？`,
          stepKey: 2,
          cancel: {
            text: "戻る",
            onClick: handlePrev,
          },
          submit: {
            text: "変更する",
            onSubmit: handleSubmit,
            loading: loading,
          },
          renderContent: () => (
            <Spacer pt={2} px={3} pb={15}>
              <DataTable
                dataKey={"id"}
                enablePagination={true}
                per={10}
                data={convertToDataTableArray(selectedDemandAdCreatives)}
                columns={[
                  {
                    name: "クリエイティブ",
                    selector: (data) => data.name,
                    sortable: true,
                  },
                  {
                    name: format.baseItemName,
                    selector: format.selector,
                    sortable: true,
                  },
                ]}
              />
            </Spacer>
          ),
        },
      ]}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
    />
  );
};

export { MoveModal };
