import { Domain } from "api-types";
import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchUserAction } from "../user/actions/fetchUserAction";

import { handleRedirectCallbackAction } from "./actions/handleRedirectCallbackAction";
import { updateAuthError } from "./actions/updateAuthErrorAction";
import { updateAuthenticated } from "./actions/updateAuthenticatedAction";
import { updateLoading } from "./actions/updateLoadingAction";

export type AuthState = {
  error: boolean;
  loading: boolean;
  authenticated: boolean;
  systemRole: Domain.SystemRole | null;
};

export const initialState: AuthState = {
  error: false,
  loading: false,
  authenticated: false,
  systemRole: null,
};

export const authReducer = reducerWithInitialState<AuthState>(initialState)
  .case(fetchUserAction.done, (state, action) => ({
    ...state,
    systemRole: action.result.user.user_system_role.name,
  }))
  .case(updateAuthenticated, (state, payload) => ({
    ...state,
    authenticated: payload,
  }))
  .case(updateLoading, (state, payload) => ({
    ...state,
    loading: payload,
  }))
  .case(handleRedirectCallbackAction.started, (state) => ({
    ...state,
    loading: true,
  }))
  .case(handleRedirectCallbackAction.done, (state) => ({
    ...state,
    loading: false,
    authenticated: true,
  }))
  .case(handleRedirectCallbackAction.failed, (state) => ({
    ...state,
    loading: false,
  }))
  .case(updateAuthError, (state, payload) => ({
    ...state,
    error: payload,
  }));
