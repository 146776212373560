import { reducerWithInitialState } from "typescript-fsa-reducers";

import { DemandAdCreativeGroupByIdState } from "../../../../domain/demandAdCreativeGroup";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { deleteDemandAdCreativeGroupAction } from "../actions/deleteDemandAdCreativeGroupAction";
import { deleteDemandAdCreativesAction } from "../actions/deleteDemandAdCreativesAction";
import { fetchDemandAdCreativeGroupsAction } from "../actions/fetchDemandAdCreativeGroupsAction";
import { patchDemandAdCreativeGroupAction } from "../actions/patchDemandAdCreativeGroupAction";
import { patchDemandAdCreativesAction } from "../actions/patchDemandAdCreativesAction";
import { postDemandAdCreativeGroupAction } from "../actions/postDemandAdCreativeGroupAction";

export type DemandAdCreativeGroupEntityState = {
  byId: {
    [key: number]: DemandAdCreativeGroupByIdState;
  };
  allIds: number[];
};

export const initialState: DemandAdCreativeGroupEntityState = {
  byId: {},
  allIds: [],
};

export const demandAdCreativeGroupEntityReducer = reducerWithInitialState(
  initialState,
)
  .case(fetchDemandAdCreativeGroupsAction.done, (state, action) => ({
    ...state,
    byId: action.result.sites.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          ...cur,
          // MEMO(yutaro1031): 既に取得しているGroupのCreativeを空配列にしないため
          demand_ad_creatives: state.byId[cur.id]
            ? [...state.byId[cur.id].demand_ad_creatives]
            : [],
        },
      }),
      {},
    ),
    allIds: action.result.sites.map((site) => site.id),
  }))
  .case(postDemandAdCreativeGroupAction.done, (state, action) => {
    const id = action.result.site.id;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          ...action.result.site,
          demand_ad_creatives: [],
        },
      },
      allIds: uniquePush(state.allIds, id),
    };
  })
  .case(patchDemandAdCreativeGroupAction.done, (state, action) => {
    const id = action.result.site.id;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          ...action.result.site,
          // NOTE(@kinokoruumu): state.byId[id]は画面の作り上あるはずだが、一応チェックしている
          demand_ad_creatives: state.byId[id]
            ? state.byId[id].demand_ad_creatives
            : [],
        },
      },
    };
  })
  .case(deleteDemandAdCreativeGroupAction.done, (state, action) => {
    const actionId = action.result.site_id;
    const byIdState = { ...state.byId };
    delete byIdState[actionId];
    return {
      ...state,
      byId: {
        ...byIdState,
      },
      allIds: state.allIds.filter((id) => id !== actionId),
    };
  })
  .case(patchDemandAdCreativesAction.done, (state, action) => {
    const { toSite, demandAdCreatives } = action.result;
    // すでに紐付いているサイトを削除
    const resultById = { ...state.byId };
    for (const demandAdCreative of demandAdCreatives) {
      const siteId = demandAdCreative.site ? demandAdCreative.site.id : null;
      if (siteId) {
        resultById[siteId] = {
          ...resultById[siteId],
          demand_ad_creatives: state.byId[siteId].demand_ad_creatives.filter(
            (id) => id !== demandAdCreative.id,
          ),
        };
      }
    }
    return {
      ...state,
      byId: {
        ...resultById,
        [toSite.id]: {
          ...state.byId[toSite.id],
          demand_ad_creatives: uniquePush(
            state.byId[toSite.id].demand_ad_creatives,
            demandAdCreatives.map((demandAdCreative) => demandAdCreative.id),
          ),
        },
      },
    };
  })

  // クリエイティブのサイト紐付け解除
  .case(deleteDemandAdCreativesAction.done, (state, action) => {
    const { demandAdCreatives } = action.result;
    const targetSiteIds = demandAdCreatives
      .filter((dac) => dac.site != null)
      .map((dac) => dac.site?.id) as number[];
    const deletedCreativeIds = demandAdCreatives.map((dac) => dac.id);
    const resultById = { ...state.byId };
    for (const id of targetSiteIds) {
      resultById[id] = {
        ...resultById[id],
        demand_ad_creatives: state.byId[id].demand_ad_creatives.filter(
          (creative) => !deletedCreativeIds.includes(creative),
        ),
      };
    }
    return {
      ...state,
      byId: {
        ...state.byId,
        ...resultById,
      },
    };
  });
