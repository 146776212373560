import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../../containers/AuthedAppShell";
import {
  RouteActionContext,
  RouteActionPayload,
} from "../../../../routing/types";
import { fetchDemandAdCreativesBasedOnGam as fetchDemandAdCreativesBasedOnGamAction } from "../../../../store/modules/demandAdCreative/actions/fetchDemandAdCreativesBasedOnGamAction";
import { getUnmappedToGamDemandAdCreatives } from "../../../../store/modules/demandAdCreative/selectors/demandAdCreativeSelector";
import { patchGamAdCreative as patchGamAdCreativeAction } from "../../../../store/modules/gamAdCreative/actions/patchGamAdCreativeAction";
import { postDemandAdCreatives as postDemandAdCreativesAction } from "../../../../store/modules/gamAdCreative/actions/postDemandAdCreativesAction";
import { fetchUnmappedGamFlattenedHierarchies as fetchUnmappedGamFlattenedHierarchiesAction } from "../../../../store/modules/gamFlattenedHierarchy/actions/fetchUnmappedGamFlattenedHierarchiesAction";
import { getGamFlattenedHierarchys } from "../../../../store/modules/gamFlattenedHierarchy/selectors/gamFlattenedHierarchySelector";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../../store/reducer";
import { dispatchable } from "../../../../store/utils/dispatchable";

import { UnmappedGAMAdCreatives as Component } from "./UnmappedGAMAdCreatives";

const UnmappedGAMAdCreatives = connect(
  (state: RootState) => ({
    gamFlattenedHierarchies: getGamFlattenedHierarchys(state),
    demandAdCreatives: getUnmappedToGamDemandAdCreatives(state),
    postRequesting: state.gamAdCreative.demandAdCreative.post.requesting,
    postSucceeded: state.gamAdCreative.demandAdCreative.post.success,
    patchRequesting: state.gamAdCreative.patch.requesting,
    patchSucceeded: state.gamAdCreative.patch.success,
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    postDemandAdCreatives: dispatchable(dispatch, postDemandAdCreativesAction),
    patchGamAdCreative: dispatchable(dispatch, patchGamAdCreativeAction),
  }),
)(Component);

export async function action({
  store,
  firstOrPush,
}: RouteActionContext): Promise<RouteActionPayload> {
  // MEMO(kinokoruumu): 現状はAuthedAppShellで取得しているが、
  // 今後taskとして扱われる場合AuthedAppShellから取得しないようになる
  if (firstOrPush) {
    await Promise.all([
      store.dispatch(fetchUnmappedGamFlattenedHierarchiesAction()),
      store.dispatch(
        fetchDemandAdCreativesBasedOnGamAction({
          status: "unmapped",
        }),
      ),
    ]);
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <UnmappedGAMAdCreatives />,
  };
}
