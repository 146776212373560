import { reducerWithInitialState } from "typescript-fsa-reducers";

import { AnalyticsFeatureDashboardUrl } from "../../../../domain/analyticsFeature";
import { fetchDashboardUrlAction } from "../actions/fetchDashboardUrlAction";

export type DashboardUrlEntityState = {
  url: AnalyticsFeatureDashboardUrl;
};

export const initialState: DashboardUrlEntityState = {
  url: "",
};

export const dashboardUrlEntityReducer = reducerWithInitialState(
  initialState,
).case(fetchDashboardUrlAction.done, (state, action) => ({
  ...state,
  url: action.result,
}));
