import { ThunkAction } from "../../../type";
import { enqueueSystemNotification } from "../../systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../systemNotification/constants";
import { NetworkStatus } from "../constants";

import { updateNetworkStatus } from "./updateNetworkStatusAction";

/**
 * アプリ実行中、常に監視状態のためイベントの解除はしない
 */
export function watchNetworkCondition(): ThunkAction<void, any> {
  return (dispatch, getState) => {
    window.addEventListener("offline", () => {
      if (getState().network.status !== NetworkStatus.OFFLINE) {
        dispatch(updateNetworkStatus(NetworkStatus.OFFLINE));
        dispatch(
          enqueueSystemNotification({
            variant: SystemNotificationVariant.ERROR,
            message: "ネットワークに接続されていません",
          }),
        );
      }
    });

    window.addEventListener("online", () => {
      if (getState().network.status !== NetworkStatus.ONLINE) {
        dispatch(updateNetworkStatus(NetworkStatus.ONLINE));
        dispatch(
          enqueueSystemNotification({
            variant: SystemNotificationVariant.SUCCESS,
            message: "ネットワークに接続されました",
          }),
        );
      }
    });
  };
}
