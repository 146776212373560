import { DemandAccountCreativeIds } from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DeleteDemandAdCreatives
 */
export type DeleteDemandAdCreativesParams = void;
export type DeleteDemandAdCreativesSuccess = {
  gamAdCreativeId: number;
  demandAdCreativeIds: string[];
};
export type DeleteDemandAdCreativesFailure = Error;

export const deleteDemandAdCreativesAction = actionCreator.async<
  DeleteDemandAdCreativesParams,
  DeleteDemandAdCreativesSuccess,
  DeleteDemandAdCreativesFailure
>("DELETE_DEMAND_AD_CREATIVES");

export type DeleteDemandAdCreativesPayload = {
  gamLineItemCreativeId: number;
  demandAccountCreativeIds: DemandAccountCreativeIds;
};

export function deleteDemandAdCreatives(
  payload: DeleteDemandAdCreativesPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(deleteDemandAdCreativesAction.started());

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsGamDeleteGamDemandCreative(
        currentCompany.company.id,
        payload.gamLineItemCreativeId,
        payload.demandAccountCreativeIds,
      );
      dispatch(
        deleteDemandAdCreativesAction.done({
          result: {
            demandAdCreativeIds: data.map((creative) => creative.id),
            gamAdCreativeId: payload.gamLineItemCreativeId,
          },
        }),
      );
    } catch (err) {
      dispatch(deleteDemandAdCreativesAction.failed(err));
    }
  };
}
