import { Request } from "api-types";
import {
  DataTable,
  ItemEmpty,
  Flex,
  Spacer,
  ActionButton,
  Button,
  Typography,
  Icon,
  useTheme,
  Portal,
  FixedPanel,
} from "ingred-ui";
import * as React from "react";

import { PageContent } from "../../../../components/elements/PageContent";
import { Helmet } from "../../../../containers/Helmet";
import { Category } from "../../../../domain/category";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { useIntersectionObserver } from "../../../../hooks/useIntersectionObserver";
import { deleteCategory } from "../../../../store/modules/category/actions/deleteCategoryAction";
import { patchCategory } from "../../../../store/modules/category/actions/patchCategoryAction";
import { postCategory } from "../../../../store/modules/category/actions/postCategoryAction";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../../store/utils/dispatchable";
import { Link } from "../../../atoms/Link";
import { Breadcrumbs } from "../../../elements/Breadcrumbs";
import { PageContainer } from "../../../elements/PageContainer";

import { CreateModal } from "./internal/CreateModal";
import { DeleteModal } from "./internal/DeleteModal";
import { EditModal } from "./internal/EditModal";

type InjectProps = {
  currentCompany: CompanyAttribute;
  categories: Category[];
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  createCategory: DispatchableAction<typeof postCategory>;
  editCategory: DispatchableAction<typeof patchCategory>;
  deleteCategory: DispatchableAction<typeof deleteCategory>;
  createCategoryRequesting: boolean;
  createSucceeded: boolean;
  editCategoryRequesting: boolean;
  editSucceeded: boolean;
  deleteCategoryRequesting: boolean;
  deleteSucceeded: boolean;
};

type Props = {};

type InjectedProps = Props & InjectProps;

const Categories: React.FunctionComponent<InjectedProps> = ({
  currentCompany,
  categories,
  enqueueSystemNotification,
  createCategory,
  createCategoryRequesting,
  createSucceeded,
  editCategory,
  editCategoryRequesting,
  editSucceeded,
  deleteCategory,
  deleteCategoryRequesting,
  deleteSucceeded,
}) => {
  const theme = useTheme();

  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const showPanel = !useIntersectionObserver(buttonContainerRef);

  const [editModalRow, setEditModalRow] = React.useState<Category | null>(null);
  const [deleteModalRow, setDeleteModalRow] = React.useState<Category | null>(
    null,
  );
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [createToasted, setCreateToasted] = React.useState(createSucceeded);
  const [editToasted, setEditToasted] = React.useState(editSucceeded);
  const [deleteToasted, setDeleteToasted] = React.useState(deleteSucceeded);

  React.useEffect(() => {
    if (!createToasted && createSucceeded) {
      setCreateModalOpen(false);
      enqueueSystemNotification({
        message: "新規登録が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setCreateToasted(true);
    }
  }, [enqueueSystemNotification, createToasted, createSucceeded]);

  React.useEffect(() => {
    if (!editToasted && editSucceeded) {
      setEditModalRow(null);
      enqueueSystemNotification({
        message: "編集が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setEditToasted(true);
    }
  }, [enqueueSystemNotification, editToasted, editSucceeded]);

  React.useEffect(() => {
    if (!deleteToasted && deleteSucceeded) {
      setDeleteModalRow(null);
      enqueueSystemNotification({
        message: "削除が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setDeleteToasted(true);
    }
  }, [enqueueSystemNotification, deleteToasted, deleteSucceeded]);

  const handleChangeEditModalRow = (row: Category | null) => () =>
    setEditModalRow(row);
  const handleChangeDeleteModalRow = (row: Category | null) => () =>
    setDeleteModalRow(row);
  const handleChangeCreateModalOpen = (isOpen: boolean) => () =>
    setCreateModalOpen(isOpen);

  const handleCreate = (data: Request.Category.Post) => {
    createCategory(data);
    setCreateToasted(false);
  };
  const handleEdit = (data: Request.Category.Patch) => {
    editCategory(data);
    setEditToasted(false);
  };
  const handleDelete = (categoryId: number) => {
    deleteCategory({ category_id: categoryId });
    setDeleteToasted(false);
  };

  return (
    <>
      <Helmet title="カテゴリ一覧 | DATA STRAP" />
      <Portal>
        <FixedPanel isOpen={showPanel}>
          <Spacer py={2} px={3}>
            <Flex display="flex" justifyContent="flex-end">
              <Button
                size="large"
                inline={true}
                onClick={handleChangeCreateModalOpen(true)}
              >
                カテゴリの新規作成
              </Button>
            </Flex>
          </Spacer>
        </FixedPanel>
      </Portal>
      <PageContainer>
        <Flex display="flex" justifyContent="space-between" alignItems="center">
          <Breadcrumbs
            items={[
              {
                title: "デマンド広告クリエイティブ管理",
                link: "/settings/demand_ad_creatives_manager",
              },
              {
                title: "カテゴリ一覧",
              },
            ]}
          />
          {/* FIXME(@yutaro1031): <Button />にもforwardRefを適用する */}
          <div ref={buttonContainerRef}>
            <Button inline={true} onClick={handleChangeCreateModalOpen(true)}>
              カテゴリの新規作成
            </Button>
          </div>
        </Flex>
        <Spacer pt={3} />
        <PageContent
          title="カテゴリ一覧"
          popoverElement={
            <Typography size="sm" lineHeight="1.7">
              カテゴリを管理します。カテゴリとはレポート内でクリエイティブを区別するための分類です。
            </Typography>
          }
        >
          <Spacer pt={2} px={2}>
            {categories.length > 0 ? (
              <DataTable
                dataKey={"id"}
                enablePagination={true}
                data={categories}
                columns={[
                  {
                    name: "名前",
                    selector: (row) => row.name,
                    sortable: true,
                    renderCell: (row) => (
                      <Link
                        href={`/company/${currentCompany.company.id}/settings/demand_ad_creatives_manager?column=category&search=${row.name}`}
                      >
                        <Flex display="flex" alignItems="center">
                          <Spacer pr={1}>
                            <Icon
                              name="folder"
                              color={theme.palette.primary.main}
                            />
                          </Spacer>
                          <Typography color="primary">{row.name}</Typography>
                        </Flex>
                      </Link>
                    ),
                  },
                  {
                    name: "操作",
                    width: "180px",
                    selector: (row) => row.id,
                    renderCell: (row) => (
                      <Flex display="flex" alignItems="center">
                        <ActionButton
                          icon="pencil"
                          onClick={handleChangeEditModalRow(row)}
                        >
                          編集
                        </ActionButton>
                        <Spacer pr={0.5} />
                        <ActionButton
                          icon="delete_bin"
                          onClick={handleChangeDeleteModalRow(row)}
                        >
                          削除
                        </ActionButton>
                      </Flex>
                    ),
                  },
                ]}
              />
            ) : (
              <ItemEmpty
                title="カテゴリが登録されていません"
                subtitle="画面右の「新規登録」ボタンをクリックしてカテゴリを登録してください。"
              />
            )}
          </Spacer>
        </PageContent>
        <CreateModal
          isOpen={createModalOpen}
          categories={categories}
          loading={createCategoryRequesting}
          onClose={handleChangeCreateModalOpen(false)}
          onSubmit={handleCreate}
        />
        <EditModal
          isOpen={!!editModalRow}
          loading={editCategoryRequesting}
          categories={categories}
          category={editModalRow}
          onClose={handleChangeEditModalRow(null)}
          onSubmit={handleEdit}
        />
        <DeleteModal
          isOpen={!!deleteModalRow}
          category={deleteModalRow}
          loading={deleteCategoryRequesting}
          onClose={handleChangeDeleteModalRow(null)}
          onSubmit={handleDelete}
        />
      </PageContainer>
    </>
  );
};

export { Categories };
