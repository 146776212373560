import { Domain } from "api-types";
import {
  Table,
  Typography,
  Spacer,
  Flex,
  ErrorText,
  Checkbox,
  RadioButton,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import { PageSection } from "../../../../elements/PageSection";
import { Popover } from "../../../../elements/Popover";
import { TableHeaderCell } from "../../../../elements/TableHeaderCell";
import { DisplayFormat } from "../../DetailReport";
import * as DetailReportStyled from "../../styled";

import * as Styled from "./styled";

type Props = {
  displayFormat: DisplayFormat;
  gamIndicatorOptions: OptionType<keyof Domain.GamIndicator>[];
  demandIndicatorOptions: OptionType<keyof Domain.DemandIndicator>[];
  selectedIndicators: (keyof Domain.ReportIndicator)[];
  selectedDimensions: Domain.ReportDimensionType[];
  errorText?: string;
  onSelect: (selectedIndicators: (keyof Domain.ReportIndicator)[]) => void;
};

const IndicatorSection: React.FunctionComponent<Props> = ({
  displayFormat,
  gamIndicatorOptions,
  demandIndicatorOptions,
  selectedIndicators,
  selectedDimensions,
  errorText,
  onSelect,
}) => {
  const unfilledImpDisabled = (item: OptionType<keyof Domain.GamIndicator>) => {
    return (
      item.value === "unfilled_imp" &&
      selectedDimensions.toString() !== "ad_unit"
    );
  };
  const handleIndicatorsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value as keyof Domain.ReportIndicator;
    let result = [];

    if (displayFormat === "table_only" || displayFormat === "rich_table_only") {
      if (selectedIndicators.includes(value)) {
        result = selectedIndicators.filter((item) => item !== value);
      } else {
        result = [...selectedIndicators, value];
      }
    } else {
      result = [value];
    }

    onSelect(result);
  };
  const handleClearDemandIndicators = (_e: React.MouseEvent<HTMLElement>) => {
    selectedIndicators = selectedIndicators.filter(
      (item) => item.indexOf("gam_") >= 0,
    );
    onSelect(selectedIndicators);
  };
  const handleClearGamIndicators = (_e: React.MouseEvent<HTMLElement>) => {
    selectedIndicators = selectedIndicators.filter(
      (item) => item.indexOf("gam_") == -1,
    );
    onSelect(selectedIndicators);
  };

  React.useEffect(() => {
    // 広告項目がラジオボタンの時のみ
    if (
      !(displayFormat == "table_only" || displayFormat == "rich_table_only")
    ) {
      // 区分で広告枠以外が選択された場合、広告項目のGAMがdisabledになるのでデマンドの1項目を選択する
      if (!selectedDimensions.includes("ad_unit")) {
        onSelect(["price"]);
      }
    }
  }, [selectedDimensions, displayFormat, onSelect]);
  return (
    <Styled.Container>
      <PageSection
        title="指標"
        popoverElement={
          <Typography size="sm" lineHeight="1.7" component="div">
            グラフにおけるY軸に相当する項目となります。売上やimp、eCPMなどを
            <br />
            デマンド及びGAM別に指定することができます。
          </Typography>
        }
      >
        <Table>
          <Table.Body>
            <Table.Row>
              <TableHeaderCell required={true} width="177px">
                <Flex display="flex">
                  広告項目
                  <Spacer pl={1} />
                  <Popover>
                    <Typography size="sm" lineHeight="1.7">
                      ※「GAMのeCPM」とはデマンド計測の売上をGAM計測のimpで割り戻した
                      <br />
                      eCPMです。
                    </Typography>
                  </Popover>
                </Flex>
              </TableHeaderCell>
              <Table.Cell>
                <Typography weight="bold">GAM</Typography>
                <Spacer pb={1} />
                <Flex display="flex" flexWrap="wrap">
                  {gamIndicatorOptions.map((item) => (
                    <Spacer key={item.label} pr={3}>
                      <Flex key={item.label} display="flex">
                        {displayFormat === "table_only" ||
                        displayFormat === "rich_table_only" ? (
                          <Checkbox
                            error={!!errorText}
                            checked={selectedIndicators.includes(item.value)}
                            value={item.value}
                            disabled={unfilledImpDisabled(item)}
                            onChange={handleIndicatorsChange}
                          >
                            {item.label}
                          </Checkbox>
                        ) : (
                          <RadioButton
                            checked={selectedIndicators.includes(item.value)}
                            value={item.value}
                            disabled={unfilledImpDisabled(item)}
                            onChange={handleIndicatorsChange}
                          >
                            {item.label}
                          </RadioButton>
                        )}
                        {item.value === "unfilled_imp" && (
                          <Spacer pl={1}>
                            <Popover>
                              <Typography size="sm" lineHeight="1.7">
                                埋められなかったimpを表示したい場合、ディメンションの区分は「広告枠」のみを選択してください。
                              </Typography>
                            </Popover>
                          </Spacer>
                        )}
                      </Flex>
                    </Spacer>
                  ))}
                </Flex>
                {(displayFormat === "table_only" ||
                  displayFormat === "rich_table_only") && (
                  <DetailReportStyled.ReporSettingtAction
                    onClick={handleClearGamIndicators}
                  >
                    全解除
                  </DetailReportStyled.ReporSettingtAction>
                )}
                <Spacer pt={2} pb={1}>
                  <Typography weight="bold">デマンド</Typography>
                </Spacer>
                <Flex display="flex" flexWrap="wrap">
                  {demandIndicatorOptions.map((item) => (
                    <Spacer key={item.label} pr={3}>
                      {displayFormat === "table_only" ||
                      displayFormat === "rich_table_only" ? (
                        <Checkbox
                          error={!!errorText}
                          checked={selectedIndicators.includes(item.value)}
                          value={item.value}
                          onChange={handleIndicatorsChange}
                        >
                          {item.label}
                        </Checkbox>
                      ) : (
                        <RadioButton
                          checked={selectedIndicators.includes(item.value)}
                          value={item.value}
                          onChange={handleIndicatorsChange}
                        >
                          {item.label}
                        </RadioButton>
                      )}
                    </Spacer>
                  ))}
                </Flex>
                {(displayFormat === "table_only" ||
                  displayFormat === "rich_table_only") && (
                  <DetailReportStyled.ReporSettingtAction
                    onClick={handleClearDemandIndicators}
                  >
                    全解除
                  </DetailReportStyled.ReporSettingtAction>
                )}
                {!!errorText && (
                  <Spacer pt={2} className="gaev-detailreport-indictor-error">
                    <ErrorText>{errorText}</ErrorText>
                  </Spacer>
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Spacer pt={1} />
        <Typography color="secondary" size="xs" lineHeight="1.7">
          ※「GAM
          eCPM」とはデマンド計測の売上をGAM計測のimpで割り戻したeCPMです。
        </Typography>
        <Typography color="secondary" size="xs" lineHeight="1.7">
          ※「売上」は税抜きの数値です。
        </Typography>
      </PageSection>
    </Styled.Container>
  );
};

export { IndicatorSection };
