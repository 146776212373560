import styled from "styled-components";

export const DisplayFormatImageContainer = styled.div`
  width: 140px;
  height: 132px;
  padding-top: ${({ theme }) => theme.spacing}px;
`;

export const DisplayFormatTextContainer = styled.div`
  width: 140px;
  padding-top: ${({ theme }) => theme.spacing * 1.5}px;
`;
