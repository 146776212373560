import {
  CsvExportParams,
  IAggFuncParams,
  ModelUpdatedEvent,
  ValueFormatterFunc,
  ValueGetterFunc,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Domain } from "api-types";
import dayjs from "dayjs";
import * as React from "react";

import { DetailReport } from "../../../../../../domain/detailReport";

import * as Styled from "./styled";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

// AgGrid docs: https://www.ag-grid.com/react-data-grid/
export type GridColumn = {
  displayName: string;
  key: Domain.ReportIndicatorType;
  isDimension: boolean;
  valueGetter: ValueGetterFunc;
  valueFormatter: (isPivotMode: boolean) => ValueFormatterFunc | undefined;
};

type Props = {
  tableMaxHeight: string;
  data: DetailReport["records"];
  columns: GridColumn[];
};

export const DataTable: React.FC<Props> = ({
  tableMaxHeight,
  data,
  columns,
}) => {
  const gridRef = React.useRef(null);
  const [isPivotMode, setIsPivotMode] = React.useState<boolean>(false);

  // Location設定を利用してTableの要素名を変更している。 columnsのlabel設定はsideBarでも設定可能だがこちらが優先される
  const AG_GRID_LOCALE = {
    // Side Bar
    columns: "Edit",
    // columns tool panel
    groups: "グルーピング",
    // Header of the Default Group Column
    group: "グルーピング(個数)",
  };

  const DIMENSION_COLUMN_ORDER = [
    "date",
    "site_name",
    "device_type_name",
    "category_name",
    "category2_name",
    "ad_unit_name",
    "os_type_name",
    "channel_type_name",
    "demand_name",
    "hb_demamd_type_name",
    "demand_unit_name",
  ];

  // getDimensionColumnsはColumnsからディメンションのColumnを取得するがグルーピングの優先順位付けもやっている。
  // 重みの決め方は https://github.com/voyagegroup/fluct_datastrap/issues/4170 参考
  const getDimensionColumns = (columns: GridColumn[]) => {
    const dimensionColumns = columns.filter((column: GridColumn) => {
      return column.isDimension;
    });
    const getWeight = (key: string) => {
      const weight = DIMENSION_COLUMN_ORDER.findIndex(
        (columnName) => columnName === key,
      );
      return weight !== -1 ? weight : 100;
    };
    const sortedDimensionColumns = dimensionColumns
      .map((column) => {
        return { column: column, weight: getWeight(column.key) };
      })
      .sort((a, b) => a.weight - b.weight)
      .map((column) => column.column);

    return sortedDimensionColumns;
  };

  const dimensionColumns = getDimensionColumns(columns);

  const indicatorColumns = columns.filter((column: GridColumn) => {
    return !column.isDimension;
  });

  const handleOnModelUpdated = (params: ModelUpdatedEvent) => {
    params.columnApi.autoSizeAllColumns();
    setIsPivotMode(params.columnApi.isPivotMode());
  };

  const aggregationColumns = [
    "ecpm",
    "ctr",
    "view_rate",
    "cpc",
    "gam_ecpm",
    "gam_view_rate",
    "fill_rate",
    "unfilled_imp_rate",
  ];

  type CalculateColumn = {
    numerator: string; // 分子
    denominator: string; // 分母
    magnification: number; // 倍率(%表示なら100倍, CPMなどは1000倍)
  };

  type CalcuateColumnDetail = {
    key: string;
    numerator: { name: string; value: number };
    denominator: { name: string; value: number };
    magnification: number;
  };

  function getCalcurateColumn(key: string): CalculateColumn {
    if (key === "ecpm") {
      return { numerator: "price", denominator: "imp", magnification: 1000 };
    } else if (key === "gam_ecpm") {
      return {
        numerator: "price",
        denominator: "gam_imp",
        magnification: 1000,
      };
    } else if (key === "ctr") {
      return { numerator: "click", denominator: "imp", magnification: 100 };
    } else if (key === "view_rate") {
      return { numerator: "vimp", denominator: "imp", magnification: 100 };
    } else if (key === "cpc") {
      return { numerator: "price", denominator: "click", magnification: 1 };
    } else if (key === "gam_view_rate") {
      return {
        numerator: "gam_vimp",
        denominator: "gam_imp",
        magnification: 100,
      };
    } else if (key === "fill_rate") {
      return { numerator: "imp", denominator: "gam_imp", magnification: 100 };
    } else if (key === "unfilled_imp_rate") {
      return {
        numerator: "unfilled_imp",
        denominator: "request",
        magnification: 100,
      };
    } else {
      return { numerator: "", denominator: "", magnification: 1 };
    }
  }

  function createRatioValueObject(column: CalcuateColumnDetail) {
    return {
      [column.numerator.name]: column.numerator.value,
      [column.denominator.name]: column.denominator.value,
      // この値でソートされるのでメソッド名はtoStringだがNumberにキャストしてから返す
      toString: () =>
        column.numerator && column.denominator
          ? Number(
              (
                (column.numerator.value / column.denominator.value) *
                column.magnification
              ).toFixed(2),
            )
          : 0,
    };
  }

  function ratioValueGetter(column: string) {
    return function (params: ValueGetterParams) {
      if (params.node?.group) {
        return;
      }
      const calculateColumn = getCalcurateColumn(column);
      const calculateColumnDetails = {
        key: column,
        numerator: {
          name: calculateColumn.numerator,
          value: params.data[calculateColumn.numerator],
        },
        denominator: {
          name: calculateColumn.denominator,
          value: params.data[calculateColumn.denominator],
        },
        magnification: calculateColumn.magnification,
      };
      if (calculateColumnDetails.denominator.value === 0) {
        return;
      }
      return createRatioValueObject(calculateColumnDetails);
    };
  }

  function ratioAggFunc(column: string) {
    return function (params: IAggFuncParams) {
      const calculateColumn = getCalcurateColumn(column);
      let numeratorSum = 0;
      let denominatorSum = 0;
      params.values.forEach((value) => {
        if (value && value[calculateColumn.numerator]) {
          numeratorSum += value[calculateColumn.numerator];
        }
        if (value && value[calculateColumn.denominator]) {
          denominatorSum += value[calculateColumn.denominator];
        }
      });
      if (denominatorSum === 0) {
        return 0;
      }
      return createRatioValueObject({
        key: column,
        numerator: { name: calculateColumn.numerator, value: numeratorSum },
        denominator: {
          name: calculateColumn.denominator,
          value: denominatorSum,
        },
        magnification: calculateColumn.magnification,
      });
    };
  }

  function parentRatioValueGetter(type: { indicator: string }) {
    return (params: ValueGetterParams) => {
      if (params.node?.group) {
        return;
      }
      if (params.node?.parent?.aggData[type.indicator] > 0) {
        return Number(
          (
            (params.data[type.indicator] /
              params.node?.parent?.aggData[type.indicator]) *
            100
          ).toFixed(2),
        );
      } else {
        return "-";
      }
    };
  }

  const csvExportParams: CsvExportParams = {
    fileName: `${dayjs().format()}.csv`,
  };

  const sideBar = React.useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "columns",
    };
  }, []);

  return (
    <Styled.Container>
      <Styled.TableContainer
        className="ag-theme-alpine"
        tableMaxHeight={tableMaxHeight}
      >
        <AgGridReact
          ref={gridRef}
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            sortable: true,
            filter: true,
          }}
          localeText={AG_GRID_LOCALE}
          defaultCsvExportParams={csvExportParams}
          rowData={data}
          groupDefaultExpanded={-1}
          pivotPanelShow="always"
          sideBar={sideBar}
          animateRows={true}
          suppressAggFuncInHeader={true}
          suppressColumnVirtualisation={true}
          enableRangeSelection={true}
          onModelUpdated={handleOnModelUpdated}
        >
          {dimensionColumns.map((column: GridColumn) => {
            let grouping = true;
            let hide = true;
            // 一番末端のdimensionはgroupingせず、行として表示する
            if (dimensionColumns[dimensionColumns.length - 1] == column) {
              grouping = false;
              hide = false;
            }
            return (
              <AgGridColumn
                key={column.key}
                field={column.key}
                headerName={column.displayName}
                valueFormatter={column.valueFormatter(isPivotMode)}
                rowGroup={grouping}
                hide={hide}
              />
            );
          })}
          {indicatorColumns.map((column: GridColumn) => {
            if (aggregationColumns.includes(column.key)) {
              return (
                <AgGridColumn
                  key={column.key}
                  field={column.key}
                  headerName={column.displayName}
                  aggFunc={ratioAggFunc(column.key)}
                  valueGetter={ratioValueGetter(column.key)}
                  valueFormatter={column.valueFormatter(isPivotMode)}
                />
              );
            } else {
              if (column.key.match(/_ratio/)) {
                const m = column.key.match(/(.+)_ratio/);
                if (m === null) return;
                const indicator: string = m[1];
                return (
                  <AgGridColumn
                    key={column.key}
                    headerName={column.displayName}
                    valueGetter={parentRatioValueGetter({ indicator })}
                  />
                );
              } else {
                return (
                  <AgGridColumn
                    key={column.key}
                    field={column.key}
                    headerName={column.displayName}
                    valueFormatter={column.valueFormatter(isPivotMode)}
                    aggFunc="sum"
                  />
                );
              }
            }
          })}
        </AgGridReact>
      </Styled.TableContainer>
    </Styled.Container>
  );
};
