import * as React from "react";

import * as Styled from "./styled";

type Props = {
  /**
   * ページの最大幅を指定します。
   * 横いっぱいに広げたいときは"none"を指定します。
   */
  maxWidth?: string;
  children: React.ReactNode;
};

const PageContainer: React.FunctionComponent<Props> = ({
  maxWidth = "1600px",
  children,
}) => (
  <Styled.Container>
    <Styled.Content maxWidth={maxWidth}>{children}</Styled.Content>
  </Styled.Container>
);

export { PageContainer };
