import { FC, useEffect } from "react";

type Props = {
  title: string;
};

export const Helmet: FC<Props> = ({ title }) => {
  useEffect(() => {
    document.title = title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
