import { Typography, Spacer, Button } from "ingred-ui";
import * as React from "react";

import notfound from "../../../../assets/images/notFound.png";
import { Helmet } from "../../../containers/Helmet";
import { push } from "../../../store/modules/routing/actions/PushAction";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import { HttpStatusCode, AppErrorCode } from "../../../utils/StatusCodeUtils";

import * as Styled from "./styled";

const isDev = process.env.ENV !== "prod";

function getErrorContent(statusCode: HttpStatusCode | AppErrorCode) {
  switch (statusCode) {
    case HttpStatusCode.NOT_FOUND:
      return {
        title: "ページが見つかりませんでした",
        buttonText: "トップページへ戻る",
      };
    case AppErrorCode.NOT_AUTHORIZED_FUNCTION:
      return {
        title: "このページはご利用のプランではお使いいただけません。",
        description:
          "プランの変更に関しては下記ボタンからお問い合わせください。",
        buttonText: "お問い合わせ",
        href: "https://forms.gle/puhA9XAK3KMjuEUp9",
      };
    case AppErrorCode.API_CONNECTION_REFUSED:
      if (isDev) {
        return {
          title: "APIに接続出来ませんでした",
          description: (
            <>
              APIのモックサーバーを起動してください
              <br />
              <br />
              もしくは、env ENV=pre yarn startで起動してください
              <br />
              ENV=pre にするとpre環境のAPIに接続します
              <br />
              <br />
              ※このメッセージは開発環境でのみ表示されます
            </>
          ),
          buttonText: "トップページへ戻る",
        };
      }
      return {
        title: "ページを表示できませんでした",
        description: "時間をあけて、もう一度お試しください",
        buttonText: "トップページへ戻る",
      };
    default:
      return {
        title: "ページを表示できませんでした",
        description: "時間をあけて、もう一度お試しください",
        buttonText: "トップページへ戻る",
      };
  }
}

export type Props = {
  statusCode: number;
};

export type InjectProps = {
  push: DispatchableAction<typeof push>;
};

type InjectedProps = Props & InjectProps;

export const ErrorView: React.FunctionComponent<InjectedProps> = ({
  statusCode,
  push,
}) => {
  const handleClick = () => {
    push("/");
  };
  const { title, description, buttonText, href } = getErrorContent(statusCode);
  return (
    <>
      <Helmet title={`${title} | DATA STRAP`} />
      <Styled.Container>
        <Spacer pt={6} />
        <Styled.ImageContainer>
          <img src={notfound} alt={title} />
        </Styled.ImageContainer>
        <Spacer pt={6}>
          <Typography size="xxxxl" weight="bold">
            {title}
          </Typography>
        </Spacer>
        {description && (
          <Spacer pt={2} pb={5}>
            <Typography size="md" color="secondary" align="center">
              {description}
            </Typography>
          </Spacer>
        )}
        <Spacer pt={5}>
          <Styled.ButtonContainer>
            {href ? (
              <Styled.LinkContainer href={href} target="_blank">
                <Button>{buttonText}</Button>
              </Styled.LinkContainer>
            ) : (
              <Button onClick={handleClick}>{buttonText}</Button>
            )}
          </Styled.ButtonContainer>
        </Spacer>
      </Styled.Container>
    </>
  );
};
