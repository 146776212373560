import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchDemandAccount
 */
export type PatchDemandAccountParams = void;
export type PatchDemandAccountSuccess = Response.DemandAccount.Patch;
export type PatchDemandAccountFailure = Error;

export const patchDemandAccountAction = actionCreator.async<
  PatchDemandAccountParams,
  PatchDemandAccountSuccess,
  PatchDemandAccountFailure
>("PATCH_DEMAND_ACCOUNT");

export function patchDemandAccount(
  payload: Request.DemandAccount.Patch,
): ThunkAction<void, any> {
  return async (dispatch, _getState, { demandAccount, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchDemandAccountAction.started());

    try {
      const res = await demandAccount.patchDemandAccountById(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(patchDemandAccountAction.done({ result: res }));
    } catch (err) {
      dispatch(patchDemandAccountAction.failed(err));
    }
  };
}
