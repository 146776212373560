import { Domain } from "api-types";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { v4 as uuid } from "uuid";

import { enqueueSystemNotification } from "../actions/EnqueueSystemNotificaitonAction";
import { removeSystemNotification } from "../actions/RemoveSystemNotificationAction";
import { SystemNotificationVariant } from "../constants";

export type SystemNotificationEntry = Omit<Domain.Error, "code"> & {
  cid: string;
  variant: SystemNotificationVariant;
};

export type SystemNotificationState = {
  entries: SystemNotificationEntry[];
};

export const initialState = {
  entries: [],
};

export const systemNotificationReducer = reducerWithInitialState<
  SystemNotificationState
>(initialState)
  .case(enqueueSystemNotification, (state, payload) => ({
    ...state,
    entries: [
      ...state.entries,
      {
        cid: uuid(),
        ...payload,
      },
    ],
  }))
  .case(removeSystemNotification, (state, payload) => ({
    ...state,
    entries: state.entries.filter(({ cid }) => cid !== payload),
  }));
