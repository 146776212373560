import styled from "styled-components";

import { Radius, Size } from "../../../styles/variables";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const Th = styled.th`
  white-space: nowrap;
`;

export const Td = styled.td`
  padding-left: 8px;
`;

export const NoticeBox = styled.div`
  padding: ${({ theme }) => theme.spacing * 2}px;
  border: ${Size.Border.Small} solid ${({ theme }) => theme.palette.divider};
  border-radius: ${Radius.SMALL};
`;
