import * as React from "react";
import { connect } from "react-redux";

import { User } from "../../../domain/user";
import { RouteActionContext, RouteActionPayload } from "../../../routing/types";
import { push } from "../../../store/modules/routing/actions/PushAction";
import { selectCompany } from "../../../store/modules/user/actions/selectCompanyAction";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { SelectCompany as Component } from "./SelectCompany";

const SelectCompanyPage = connect(
  (state: RootState) => ({
    // middlewareでユーザー情報を取得済み
    user: state.user.me.data as User,
  }),
  (dispatch) => ({
    selectCompany: dispatchable(dispatch, selectCompany),
    push: dispatchable(dispatch, push),
  }),
)(Component);

export async function action(
  _: RouteActionContext,
): Promise<RouteActionPayload> {
  return {
    content: <SelectCompanyPage />,
  };
}
