import { Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DeleteDashboardItem
 */
export type DeleteDashboardItemParams = void;
export type DeleteDashboardItemSuccess = { item_id: number };
export type DeleteDashboardItemFailure = Error;

export const deleteDashboardItemAction = actionCreator.async<
  DeleteDashboardItemParams,
  DeleteDashboardItemSuccess,
  DeleteDashboardItemFailure
>("DELETE_DASHBOARD_ITEM");

export function deleteDashboardItem(
  payload: Request.DashboardItem.Delete,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, dashboardItem }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(deleteDashboardItemAction.started());

    try {
      await dashboardItem.deleteDashboardItemById(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(
        deleteDashboardItemAction.done({
          result: { item_id: payload.item_id },
        }),
      );
    } catch (err) {
      dispatch(deleteDashboardItemAction.failed(err));
    }
  };
}
