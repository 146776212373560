import { Domain } from "api-types";
import dayjs from "dayjs";
import {
  Button,
  FullSizeConfirmModal,
  FixedPanel,
  Flex,
  Icon,
  Portal,
  Spacer,
  Spinner,
  Typography,
  useTheme,
  ActionButton,
  FloatingTip,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import {
  CustomReportDimension,
  CustomReportFilters,
  CustomReportGroup,
  CustomReportIndicator,
  CustomReportPeriod,
} from "../../../../../api-client";
import switches from "../../../../assets/images/switches.png";
import { Helmet } from "../../../containers/Helmet";
import { Category } from "../../../domain/category";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { DemandAdCreativeGroup } from "../../../domain/demandAdCreativeGroup";
import { DetailReport } from "../../../domain/detailReport";
import { GamAdUnit } from "../../../domain/gamAdUnit";
import { HbDemandType } from "../../../domain/hbDemandType";
import { ReportStatus } from "../../../domain/reportStatus";
import { SavedQueryDetail } from "../../../domain/savedQuery";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import { fetchDemands } from "../../../infra/demand/demandClient";
import { downloadCsv } from "../../../store/modules/detailReport/actions/downloadCsvAction";
import { searchDetailReport } from "../../../store/modules/detailReport/actions/searchDetailReportAction";
import { fetchSavedQuery } from "../../../store/modules/savedQuery/actions/fetchSavedQueryAction";
import { patchSavedQuery } from "../../../store/modules/savedQuery/actions/patchSavedQueryAction";
import { postSavedQuery } from "../../../store/modules/savedQuery/actions/postSavedQueryAction";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import {
  DateRangeType,
  getDateRange,
  getDateRangeErrorMessage,
} from "../../../utils/DateRangeUtils";
import { Link } from "../../atoms/Link";
import { PageContainer } from "../../elements/PageContainer";

import { DimensionSection } from "./internal/DimensionSection/DimensionSection";
import { DisplayFormatSection } from "./internal/DisplayFormatSection";
import { IndicatorSection } from "./internal/IndicatorSection";
import { QuerySaveModal } from "./internal/QuerySaveModal";
import { RefineSection } from "./internal/RefineSection";
import { ReportTable } from "./internal/ReportTable";
import * as Styled from "./styled";

export type DisplayFormat =
  | "table_only"
  | "rich_table_only"
  | "date_axis"
  | "dimension_axis";

const displayFormats: (OptionType<DisplayFormat> & {
  description: string;
})[] = [
  {
    label: "表 (高機能)",
    value: "rich_table_only",
    description: "レポート結果をピボットテーブルや階層で表示",
  },
  {
    label: "表",
    value: "table_only",
    description: "レポート結果をシンプルな表で表示",
  },
  {
    label: "日付軸グラフ + 表",
    value: "date_axis",
    description: "売上・imp等をグラフと表で表示",
  },
  {
    label: "区分軸グラフ + 表",
    value: "dimension_axis",
    description: "広告枠やデマンド毎にグラフと表で表示",
  },
];

const groupTypes: OptionType<Domain.GroupType>[] = [
  {
    label: "合計",
    value: "summary",
  },
  {
    label: "月別",
    value: "month",
  },
  {
    label: "日別",
    value: "day",
  },
];

export function getDimensionOptions(displayFormat: DisplayFormat) {
  // ディメンションオプション表示順も兼ねている
  const dimensionOptions: OptionType<Domain.ReportDimensionType>[] = [
    {
      label: "なし",
      value: "none",
    },
    {
      label: "サイト",
      value: "site",
    },
    {
      label: "カテゴリ1",
      value: "category",
    },
    {
      label: "カテゴリ2",
      value: "category2",
    },
    {
      label: "デマンド",
      value: "demand",
    },
    {
      label: "デバイス",
      value: "device_type",
    },
    {
      label: "チャネル",
      value: "channel_type",
    },
    {
      label: "HBビッダー",
      value: "hb_demand_type",
    },
    {
      label: "広告枠",
      value: "ad_unit",
    },
    {
      label: "デマンド広告枠",
      value: "demand_unit",
    },
    {
      label: "OS",
      value: "os_type",
    },
  ];

  const unUsableDimensions: Domain.ReportDimensionType[] = [];

  // 日付軸グラフは区分を指定しなくても良い
  if (displayFormat != "date_axis") {
    unUsableDimensions.unshift("none");
  }

  return dimensionOptions.filter(
    (opt) => !unUsableDimensions.includes(opt.value),
  );
}

export const gamIndicatorOptions: OptionType<keyof Domain.GamIndicator>[] = [
  {
    label: "imp",
    value: "gam_imp",
  },
  {
    label: "eCPM",
    value: "gam_ecpm",
  },
  {
    label: "vimp",
    value: "gam_vimp",
  },
  {
    label: "View rate",
    value: "gam_view_rate",
  },
  {
    label: "埋められなかったimp",
    value: "unfilled_imp",
  },
];

export const demandIndicatorOptions: OptionType<
  keyof Domain.DemandIndicator
>[] = [
  {
    label: "売上",
    value: "price",
  },
  {
    label: "imp",
    value: "imp",
  },
  {
    label: "eCPM",
    value: "ecpm",
  },
  {
    label: "クリック",
    value: "click",
  },
  {
    label: "CTR",
    value: "ctr",
  },
  {
    label: "vimp",
    value: "vimp",
  },
  {
    label: "View rate",
    value: "view_rate",
  },
  {
    label: "CPC",
    value: "cpc",
  },
];

function getDefaultSelectedDimensions({
  demandAdCreativeGroupsLength,
  categoriesLength,
  selectedDisplayFormat,
}: {
  demandAdCreativeGroupsLength: number;
  categoriesLength: number;
  selectedDisplayFormat: DisplayFormat;
}) {
  let result = getDimensionOptions(selectedDisplayFormat);
  const defaultDimensions: Domain.ReportDimensionType[] = [
    "site",
    "demand",
    "ad_unit",
  ];
  result = result.filter((dimension) =>
    defaultDimensions.includes(dimension.value),
  );
  if (demandAdCreativeGroupsLength === 0) {
    result = result.filter((dimension) => dimension.value != "site");
  }
  if (categoriesLength === 0) {
    result = result.filter((dimension) => dimension.value != "category");
  }
  return result.map((dimension) => dimension.value);
}

function convertToFilter(
  query: SavedQueryDetail["saved_custom_report_filters"],
  setFilters: (filters: Domain.ReportFilters) => void,
  setDisplayFilters: (displayFilters: Domain.ReportFilters) => void,
): void {
  const filters: Domain.ReportFilters = {};
  const displayFilters: Domain.ReportFilters = {};
  if (query) {
    (Object.keys(query) as Array<keyof Domain.ReportFilters>).forEach((key) => {
      switch (key) {
        case "exclude_demand_zero_imp":
          displayFilters[key] = query.exclude_demand_zero_imp;
          break;
        case "exclude_demand_zero_price":
          displayFilters[key] = query.exclude_demand_zero_price;
          break;
        default:
          if (query[key]?.length !== 0) {
            filters[key] = query[key] as number[] & string[];
          }
          break;
      }
    });
  }
  setFilters(filters);
  setDisplayFilters(displayFilters);
}

//FIX ME: generatorで作成した型を全体で使うようになったら変換を外す (2023/1/23)
function convertToSavedQueryDetail(
  groupType: Domain.GroupType,
  dimensions: Domain.ReportDimensionType[],
  indicators: (keyof Domain.ReportIndicator)[],
  filters: Domain.ReportFilters,
  displayFilters: Domain.ReportFilters,
  displayPeriod: DateRangeType,
): SavedQueryDetail {
  return {
    custom_report_period: displayPeriod as CustomReportPeriod,
    custom_report_group: groupType as CustomReportGroup,
    custom_report_dimensions: dimensions.includes("none")
      ? []
      : (dimensions as CustomReportDimension[]),
    custom_report_indicators: indicators as CustomReportIndicator[],
    saved_custom_report_filters: {
      ...filters,
      ...displayFilters,
    } as CustomReportFilters,
  };
}

type InjectProps = {
  currentCompany: CompanyAttribute;
  detailReport: DetailReport | null;
  searchDetailReport: DispatchableAction<typeof searchDetailReport>;
  downloadCsv: DispatchableAction<typeof downloadCsv>;
  fetchSavedQuery: DispatchableAction<typeof fetchSavedQuery>;
  patchSavedQuery: DispatchableAction<typeof patchSavedQuery>;
  postSavedQuery: DispatchableAction<typeof postSavedQuery>;
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  requesting: boolean;
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  categories: Category[];
  categories2: Category[];
  gamAdUnits: GamAdUnit[];
  hbDemandTypes: HbDemandType[];
  reportStatuses: ReportStatus[];
  downloadCsvRequesting: boolean;
  fetchSavedQueryRequesting: boolean;
  postSavedQueryRequesting: boolean;
  postSavedQuerySucceeded: boolean;
  patchSavedQueryRequesting: boolean;
  patchSavedQuerySucceeded: boolean;
  data: SavedQueryDetail;
};

type Props = {
  savedQueryId?: number;
  savedQueryName?: string;
};

type InjectedProps = Props & InjectProps;

/* eslint-disable react/jsx-handler-names */
const DetailReport: React.FunctionComponent<InjectedProps> = ({
  detailReport,
  searchDetailReport,
  downloadCsv,
  fetchSavedQuery,
  postSavedQuery,
  patchSavedQuery,
  enqueueSystemNotification,
  requesting,
  demandAdCreativeGroups,
  categories,
  categories2,
  gamAdUnits,
  hbDemandTypes,
  reportStatuses,
  downloadCsvRequesting,
  fetchSavedQueryRequesting,
  postSavedQueryRequesting,
  postSavedQuerySucceeded,
  patchSavedQueryRequesting,
  patchSavedQuerySucceeded,
  currentCompany,
  savedQueryId,
  savedQueryName,
  data,
}) => {
  const theme = useTheme();
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const reportSectionRef = React.useRef<HTMLDivElement>(null);

  const showPanel = !useIntersectionObserver(buttonContainerRef);
  const [isBatchFinished, setIsBatchFinished] = React.useState<boolean>(true);
  const [selectedDisplayFormat, setSelectedDisplayFormat] = React.useState<
    DisplayFormat
  >("rich_table_only");
  const [reportModalOpen, setReportModalOpen] = React.useState<boolean>(false);
  const [saveQueryModalOpen, setSaveQueryModalOpen] = React.useState<boolean>(
    false,
  );
  const [filters, setFilters] = React.useState<Domain.ReportFilters>({});
  const [initFilters, setInitFilters] = React.useState<Domain.ReportFilters>(
    {},
  );
  const [displayFilters, setDisplayFilters] = React.useState<
    Domain.ReportFilters
  >({});
  const [selectedIndicators, setSelectedIndicators] = React.useState<
    (keyof Domain.ReportIndicator)[]
  >([
    ...gamIndicatorOptions.map((gamIndicator) => gamIndicator.value),
    ...demandIndicatorOptions.map((demandIndicator) => demandIndicator.value),
  ]);
  const [selectedGroupType, setSelectedGroupType] = React.useState<
    Domain.GroupType
  >(groupTypes[0].value);
  const [selectedDimensions, setSelectedDimensions] = React.useState<
    Domain.ReportDimensionType[]
  >(
    getDefaultSelectedDimensions({
      demandAdCreativeGroupsLength: demandAdCreativeGroups.length,
      categoriesLength: categories.length,
      selectedDisplayFormat,
    }),
  );
  const [indicatorErrorText, setIndicatorErrorText] = React.useState<string>(
    "",
  );
  const [displayPeriodErrorText, setDisplayPeriodErrorText] = React.useState<
    string
  >("");
  const [startDate, setStartDate] = React.useState<dayjs.Dayjs>(
    getDateRange("yesterday")[0],
  );
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(
    getDateRange("yesterday")[1],
  );
  const [displayPeriod, setDisplayPeriod] = React.useState<
    Domain.DateRangeType
  >();
  const [postToasted, setPostToasted] = React.useState<boolean>(
    postSavedQuerySucceeded,
  );
  const [patchToasted, setPatchToasted] = React.useState<boolean>(
    patchSavedQuerySucceeded,
  );
  const [demands, setDemands] = React.useState<Domain.Demand[]>([]);

  const [isTipOpen, setIsTipOpen] = React.useState(false);
  const [iconRef, setIconRef] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    for (const status of reportStatuses) {
      if (status.status === "unfinished") {
        setIsBatchFinished(false);
        return;
      }
    }
    setIsBatchFinished(true);
  }, [reportStatuses]);

  React.useEffect(() => {
    if (savedQueryId) {
      fetchSavedQuery({ saved_query_id: savedQueryId });
    }
  }, [savedQueryId, fetchSavedQuery]);

  React.useEffect(() => {
    if (!data) return;
    setSelectedGroupType(data.custom_report_group);
    setDisplayPeriod(data.custom_report_period);
    setStartDate(getDateRange(data.custom_report_period)[0]);
    setEndDate(getDateRange(data.custom_report_period)[1]);
    setSelectedDimensions(
      data.custom_report_dimensions as CustomReportDimension[],
    );
    setSelectedIndicators(data.custom_report_indicators);
    convertToFilter(
      data.saved_custom_report_filters,
      setInitFilters,
      setDisplayFilters,
    );
  }, [data]);

  React.useEffect(() => {
    // グラフ表示は指定できる指標やディメンションが限られるため、選択された場合は初期値を設定する
    switch (selectedDisplayFormat) {
      case "date_axis":
        setSelectedDimensions([
          getDimensionOptions(selectedDisplayFormat)[0].value,
        ]); // なし
        setSelectedIndicators([demandIndicatorOptions[0].value]); // デマンド売上を指定しておく
        setSelectedGroupType(groupTypes[1].value); // 月別
        break;
      case "dimension_axis":
        let defaultSelectedDimensions: Domain.ReportDimensionType[];
        // サイトがあればサイトを選択
        if (demandAdCreativeGroups.length > 0) {
          defaultSelectedDimensions = [
            getDimensionOptions(selectedDisplayFormat)[0].value,
          ];

          // カテゴリがあればカテゴリを選択
        } else if (categories.length > 0) {
          defaultSelectedDimensions = [
            getDimensionOptions(selectedDisplayFormat)[1].value,
          ];

          // 両方なければデマンドを選択
        } else {
          defaultSelectedDimensions = [
            getDimensionOptions(selectedDisplayFormat)[2].value,
          ];
        }
        setSelectedDimensions(defaultSelectedDimensions);
        setSelectedIndicators([demandIndicatorOptions[0].value]); // デマンド売上を指定しておく
        setSelectedGroupType(groupTypes[0].value); // 合計を選択
        break;
    }
  }, [selectedDisplayFormat, demandAdCreativeGroups.length, categories.length]);

  React.useEffect(() => {
    const gamIndicators = gamIndicatorOptions.map((option) => option.value);
    if (
      selectedDisplayFormat === "table_only" ||
      selectedDisplayFormat === "rich_table_only"
    ) {
      const filteredSelectedIdentifer =
        selectedDimensions.toString() !== "ad_unit"
          ? selectedIndicators.filter((i) => i !== "unfilled_imp")
          : selectedIndicators;
      setSelectedIndicators(filteredSelectedIdentifer);
    } else {
      setSelectedIndicators(
        selectedIndicators.filter((i) => !gamIndicators.includes(i as any)), // gamIndicatorsに存在する型しか渡せないのでanyで潰す
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimensions]);

  React.useEffect(() => {
    if (!postToasted && postSavedQuerySucceeded) {
      setSaveQueryModalOpen(false);
      enqueueSystemNotification({
        message: "クエリの保存が完了しました。保存クエリ一覧をご確認ください。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setPostToasted(true);
    }
  }, [enqueueSystemNotification, postToasted, postSavedQuerySucceeded]);

  React.useEffect(() => {
    if (!patchToasted && patchSavedQuerySucceeded) {
      setSaveQueryModalOpen(false);
      enqueueSystemNotification({
        message: "クエリの更新が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setPatchToasted(true);
    }
  }, [enqueueSystemNotification, patchToasted, patchSavedQuerySucceeded]);

  React.useEffect(() => {
    const f = async () => {
      const ds = await fetchDemands(currentCompany.company.id).catch(
        (_error) => {
          enqueueSystemNotification({
            message: "デマンド一覧の取得に失敗しました",
            variant: SystemNotificationVariant.ERROR,
          });
        },
      );
      setDemands(ds ? ds : []);
    };
    f();
  }, [currentCompany, enqueueSystemNotification]);

  const validate = () => {
    const message = getDateRangeErrorMessage(
      startDate,
      endDate,
      selectedGroupType,
    );
    if (message !== "") {
      setDisplayPeriodErrorText(message);
      return false;
    }
    setDisplayPeriodErrorText("");
    if (selectedIndicators.length === 0) {
      setIndicatorErrorText("どれか1つは必ず選択してください。");
      return false;
    }
    setIndicatorErrorText("");
    return true;
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }
    await searchDetailReport({
      item: {
        begin_date: (startDate as dayjs.Dayjs).format("YYYY-MM-DD"),
        end_date: (endDate as dayjs.Dayjs).format("YYYY-MM-DD"),
        group_type_name: selectedGroupType,
        dimension_names: selectedDimensions.includes("none")
          ? []
          : (selectedDimensions as CustomReportDimension[]),
      },
      indicator_names: selectedIndicators as CustomReportIndicator[],
      // 例えば imp 数によるフィルタを表示・非表示に使うフィルタ(displayFilters)として使っているが、API 上では同じフィルタである
      filters: { ...filters, ...displayFilters },
      use_chart:
        selectedDisplayFormat !== "table_only" &&
        selectedDisplayFormat !== "rich_table_only",
    });
    setReportModalOpen(true);
    // MEMO: ページ側のscrollをブロックしてもいいかも
    // setReportModalOpen(false) 時に解除
  };
  const handleCsvDownload = () => {
    if (!validate()) {
      return;
    }
    downloadCsv({
      item: {
        begin_date: (startDate as dayjs.Dayjs).format("YYYY-MM-DD"),
        end_date: (endDate as dayjs.Dayjs).format("YYYY-MM-DD"),
        group_type_name: selectedGroupType,
        dimension_names: selectedDimensions.includes("none")
          ? []
          : (selectedDimensions as CustomReportDimension[]),
      },
      indicator_names: selectedIndicators as CustomReportIndicator[],
      // 例えば imp 数によるフィルタを表示・非表示に使うフィルタ(displayFilters)として使っているが、API 上では同じフィルタである
      filters: { ...filters, ...displayFilters },
    });
  };

  const handleSavedQueryModalOpen = () => {
    setSaveQueryModalOpen(true);
    setReportModalOpen(false);
  };

  const handlePostSavedQuery = (data: {
    period: Domain.DateRangeType;
    name: string;
    asUpdate: boolean;
  }) => {
    const savedQuery = {
      saved_custom_report_detail: convertToSavedQueryDetail(
        selectedGroupType,
        selectedDimensions,
        selectedIndicators,
        filters,
        displayFilters,
        data.period as DateRangeType,
      ),
      name: data.name,
    };
    if (data.asUpdate && savedQueryId) {
      patchSavedQuery({ ...savedQuery, saved_query_id: savedQueryId });
      setPatchToasted(false);
    } else {
      postSavedQuery(savedQuery);
      setPostToasted(false);
    }
  };
  const handleClose = () => {
    setReportModalOpen(false);
  };

  const handleSaveQueryClose = () => {
    setSaveQueryModalOpen(false);
  };

  const handleIsOpen = (isOpen: boolean) => () => {
    setIsTipOpen(isOpen);
  };

  return (
    <>
      <Helmet title="詳細レポート | DATA STRAP" />
      <Portal>
        <FixedPanel isOpen={showPanel}>
          <Spacer py={2} px={3}>
            <Flex display="flex" justifyContent="flex-end">
              <Button
                size="large"
                inline={true}
                className="gaev-detailreport-btn_query_execution"
                onClick={handleSubmit}
              >
                クエリを実行
              </Button>
            </Flex>
          </Spacer>
        </FixedPanel>
      </Portal>
      <PageContainer>
        <Styled.Container>
          <Flex
            display="flex"
            justifyContent={isBatchFinished ? "flex-end" : "space-between"}
          >
            {/* FIXME(@yutaro1031): <Button />にもforwardRefを適用する */}
            {!isBatchFinished && (
              <Flex display="flex" alignItems="center">
                <Spacer pr={0.5}>
                  <Icon
                    name="exclamation"
                    size="md"
                    color={theme.palette.danger.main}
                  />
                </Spacer>
                <Typography color={theme.palette.danger.main}>
                  本日実行分のレポート取得が完了していません。
                  <Styled.Link
                    href={`/company/${currentCompany.company.id}/informations/report_status`}
                  >
                    レポート取得状況
                  </Styled.Link>
                  をご確認ください。
                </Typography>
              </Flex>
            )}
            <div ref={buttonContainerRef}>
              <Button
                inline={true}
                className="btn_query_execution"
                onClick={handleSubmit}
              >
                クエリを実行
              </Button>
            </div>
          </Flex>
          <Spacer pt={3} />
          <DisplayFormatSection
            displayFormats={displayFormats}
            selectedDisplayFormat={selectedDisplayFormat}
            selectedDisplayFilters={displayFilters}
            onDisplayFormatChange={setSelectedDisplayFormat}
            onDisplayFilterChange={setDisplayFilters}
          />
          <Spacer pt={3} />
          <DimensionSection
            displayFormat={selectedDisplayFormat}
            groupTypes={groupTypes}
            selectedDimensions={selectedDimensions}
            selectedGroupType={selectedGroupType}
            selectedDisplayPeriod={displayPeriod}
            displayPeriodErrorText={displayPeriodErrorText}
            startDate={startDate}
            endDate={endDate}
            demandAdCreativeGroups={demandAdCreativeGroups}
            categories={categories}
            onDimensionsChange={setSelectedDimensions}
            onGroupTypeChange={setSelectedGroupType}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            onPeriodChange={setDisplayPeriod}
          />
          <Spacer pt={3} />
          <IndicatorSection
            displayFormat={selectedDisplayFormat}
            errorText={indicatorErrorText}
            gamIndicatorOptions={gamIndicatorOptions}
            demandIndicatorOptions={demandIndicatorOptions}
            selectedIndicators={selectedIndicators}
            selectedDimensions={selectedDimensions}
            onSelect={setSelectedIndicators}
          />
          <Spacer pt={3} />
          <RefineSection
            selectedDimensions={selectedDimensions}
            selectedFilters={initFilters}
            demandAdCreativeGroups={demandAdCreativeGroups}
            demands={demands}
            gamAdUnits={gamAdUnits}
            categories={categories}
            categories2={categories2}
            hbDemandTypes={hbDemandTypes}
            onChange={setFilters}
          />
          <div ref={reportSectionRef} />
          {detailReport != null && (
            <FullSizeConfirmModal
              isOpen={reportModalOpen}
              title="レポート結果"
              disableHorizontalPadding={true}
              loading={downloadCsvRequesting}
              subActions={[
                <div key="tip">
                  <Styled.IconWrapper
                    ref={setIconRef}
                    onClick={handleIsOpen(!isTipOpen)}
                  >
                    <Icon name="question" type="fill" size="lg" />
                  </Styled.IconWrapper>
                  <FloatingTip
                    baseElement={iconRef}
                    isOpen={isTipOpen}
                    onClose={handleIsOpen(false)}
                  >
                    <Typography size="sm" lineHeight="1.7">
                      <img
                        src={switches}
                        height="18px"
                        style={{ verticalAlign: "middle" }}
                      />
                      ボタンが表示されている場合は、こちらからグラフタイプ（棒グラフもしくは折れ線グラフ）を選択することができます。
                      <Spacer pt={1} />
                      <Link href={"https://data-strap.com/contact"}>
                        <Flex display="flex" alignItems="center">
                          グラフに関するご意見ご要望はこちら
                          <Icon name="external_link" size="md" color="active" />
                        </Flex>
                      </Link>
                    </Typography>
                  </FloatingTip>
                </div>,
                <ActionButton
                  key="download-csv"
                  icon="export"
                  type="button"
                  onClick={handleCsvDownload}
                >
                  CSV形式でダウンロード
                </ActionButton>,
                <ActionButton
                  key="save-query"
                  icon="export"
                  type="button"
                  onClick={handleSavedQueryModalOpen}
                >
                  このクエリを保存する
                </ActionButton>,
              ]}
              onClose={handleClose}
            >
              <Spacer pt={2} />
              <ReportTable
                data={detailReport}
                displayFormat={selectedDisplayFormat}
                indicator={selectedIndicators[0]}
                dimension={selectedDimensions[0]}
              />
            </FullSizeConfirmModal>
          )}
          <QuerySaveModal
            period={displayPeriod}
            isOpen={saveQueryModalOpen}
            name={savedQueryName}
            isUpdate={!!savedQueryId}
            loading={postSavedQueryRequesting || patchSavedQueryRequesting}
            onSubmit={handlePostSavedQuery}
            onClose={handleSaveQueryClose}
          />
          {(requesting || fetchSavedQueryRequesting) && (
            <Styled.LoadingContainer>
              <Spinner />
            </Styled.LoadingContainer>
          )}
        </Styled.Container>
      </PageContainer>
    </>
  );
};

export { DetailReport };
