import { Request, Response } from "api-types";

import { APIClient } from "../../utils/APIClientUtils";

export type PostCSVParams = Request.CSVImport.Upload;
export type PostCSVResponse = Response.Error.Errors;

export async function uploadCSV(
  apiClient: APIClient,
  params: PostCSVParams,
  companyId: number,
) {
  // ファイルは multipart/form-data で送信するので FormData オブジェクトにする
  const formData = new FormData();
  formData.append("demand_account_id", params.demand_account_id.toString());
  formData.append("csv_file", params.file);

  const { data } = await apiClient.post<PostCSVResponse>(
    "/v1/csv_import",
    formData,
    {
      headers: {
        "X-Company-Id": companyId,
        "content-type": "multipart/form-data",
      },
    },
  );

  return data;
}
