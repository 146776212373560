import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchInformationsAction } from "../actions/fetchInformationsAction";
import { patchInformationReadAction } from "../actions/patchInformationReadAction";

export type InformationState = {
  fetchAll: {
    requesting: boolean;
  };
  read: {
    patch: {
      requesting: boolean;
      success: boolean;
    };
  };
};

export const initialState: InformationState = {
  fetchAll: {
    requesting: false,
  },
  read: {
    patch: {
      requesting: false,
      success: false,
    },
  },
};

export const informationReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All Information
   */
  .case(fetchInformationsAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchInformationsAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchInformationsAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  /**
   * 既読にする
   */
  .case(patchInformationReadAction.started, (state) => ({
    ...state,
    read: {
      ...state.read,
      patch: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(patchInformationReadAction.done, (state) => ({
    ...state,
    read: {
      ...state.read,
      patch: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(patchInformationReadAction.failed, (state) => ({
    ...state,
    read: {
      ...state.read,
      patch: {
        requesting: false,
        success: false,
      },
    },
  }));
