import { Request } from "api-types";
import { TextField, ConfirmModal } from "ingred-ui";
import * as React from "react";
import { useForm } from "react-hook-form";

import { Category } from "../../../../../../domain/category";
import { registerIui } from "../../../../../../utils/registerIui";

import * as Styled from "./styled";

const getErrorText = (errorName: string | undefined): string => {
  switch (errorName) {
    case "required":
      return "入力されていません";
    case "validate":
      return "すでに同じ名前のカテゴリが存在します";
    default:
      return "";
  }
};

type Props = {
  isOpen: boolean;
  categories: Category[];
  category: Category | null;
  onClose: () => void;
  onSubmit?: (data: Request.Category.Patch) => void;
  loading?: boolean;
};

type EditCategoryForm = {
  name: string;
};

const EditModal: React.FunctionComponent<Props> = ({
  isOpen,
  categories,
  category,
  onClose,
  onSubmit,
  loading,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<EditCategoryForm>();
  const watchName = watch("name");
  React.useEffect(() => {
    if (category) reset({ name: category.name });
  }, [category, reset]);

  const submit = (data: EditCategoryForm) => {
    if (onSubmit && category) {
      onSubmit({ category_id: category.id, name: data.name });
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title="カテゴリの編集"
      confirmText="保存する"
      cancelText="キャンセル"
      buttonColor="primary"
      disabled={!watchName}
      loading={loading}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit(submit)}
    >
      <Styled.FormContainer>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>カテゴリ名</Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <Styled.TextFieldContainer>
              <TextField
                {...registerIui(
                  register("name", {
                    required: true,
                    validate: (value) =>
                      categories.every((group) => group.name !== value),
                  }),
                )}
                errorText={getErrorText(errors.name?.type)}
              />
            </Styled.TextFieldContainer>
          </Styled.FormGroupRight>
        </Styled.FormGroup>
      </Styled.FormContainer>
    </ConfirmModal>
  );
};

export { EditModal };
