import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { ThunkAction } from "../../../type";
import { push } from "../../routing/actions/PushAction";

import { actionCreator } from "./actionCreator";
import { updateCompanyAction } from "./updateCompanyAction";

export type SelectCompanyPayload = {
  company: CompanyAttribute;
};

/**
 * SelectCompanyAction
 */
export const selectCompanyAction = actionCreator<SelectCompanyPayload>(
  "SELECT_COMPANY_ACTION",
);

export function selectCompany(
  payload: SelectCompanyPayload,
): ThunkAction<void, any> {
  return async (dispatch) => {
    dispatch(push(`/company/${payload.company.company.id}`));
    dispatch(updateCompanyAction(payload));
  };
}
