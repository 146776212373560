import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchBidStrapReport
 */
export type FetchBidStrapReportParams = void;
export type FetchBidStrapReportSuccess = Response.BidStrapReport.Fetch;
export type FetchBidStrapReportFailure = Error;

export const fetchBidStrapReportAction = actionCreator.async<
  FetchBidStrapReportParams,
  FetchBidStrapReportSuccess,
  FetchBidStrapReportFailure
>("FETCH_BIDSTRAP_REPORT");

export function fetchBidStrapReport(
  payload: Request.BidStrapReport.Fetch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, bidStrapReport }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchBidStrapReportAction.started());

    try {
      const res = await bidStrapReport.fetchBidStrapReport(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(fetchBidStrapReportAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchBidStrapReportAction.failed({ error }));
      }
    }
  };
}
