import { User } from "../../../../domain/user";

export const getCompanyAttributeByCompanyId = (
  user: User,
  companyId: number,
) => {
  const companyAttribute = user.company_attributes.find(
    (companyAttribute) => companyAttribute.company.id === companyId,
  );
  if (companyAttribute) {
    return companyAttribute;
  }
  return null;
};
