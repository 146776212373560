import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { DemandAdCreativeGroup } from "../../../../domain/demandAdCreativeGroup";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchDemandAdCreatives
 */
export type PatchDemandAdCreativesParams = void;
export type PatchDemandAdCreativesSuccess = {
  toSite: DemandAdCreativeGroup;
  demandAdCreatives: DemandAdCreative[];
};
export type PatchDemandAdCreativesFailure = Error;

export const patchDemandAdCreativesAction = actionCreator.async<
  PatchDemandAdCreativesParams,
  PatchDemandAdCreativesSuccess,
  PatchDemandAdCreativesFailure
>("PATCH_DEMAND_AD_CREATIVES");

export type PatchDemandAdCretivesPayload = {
  toSite: DemandAdCreativeGroup;
  demandAdCreatives: DemandAdCreative[];
};

export function patchDemandAdCreatives(
  payload: PatchDemandAdCretivesPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchDemandAdCreativesAction.started());

    try {
      await openApiClient.rpzApiViewsSiteUpsertSiteCreative(
        currentCompany.company.id,
        payload.toSite.id,
        {
          demand_account_creative_ids: payload.demandAdCreatives.map(
            (demandAdCreative) => demandAdCreative.id,
          ),
        },
      );
      dispatch(
        patchDemandAdCreativesAction.done({
          result: payload,
        }),
      );
    } catch (err) {
      dispatch(patchDemandAdCreativesAction.failed(err));
    }
  };
}
