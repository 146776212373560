import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchGamAdUnits
 */
export type FetchGamAdUnitsParams = void;
export type FetchGamAdUnitsSuccess = Response.GamAdUnit.FetchAll;
export type FetchGamAdUnitsFailure = Error;

export const fetchGamAdUnitsAction = actionCreator.async<
  FetchGamAdUnitsParams,
  FetchGamAdUnitsSuccess,
  FetchGamAdUnitsFailure
>("FETCH_GAM_AD_UNITS");

export function fetchGamAdUnits(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { gamAdUnit, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchGamAdUnitsAction.started());

    try {
      const res = await gamAdUnit.fetchGamAdUnits(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchGamAdUnitsAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchGamAdUnitsAction.failed({ error }));
      }
    }
  };
}
