import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchGamAdUnitsAction } from "../actions/fetchGamAdUnitsAction";
import { fetchMappedGamAdUnitsAction } from "../actions/fetchMappedGamAdUnitsAction";

export type GamAdUnitState = {
  fetchAll: {
    requesting: boolean;
  };
  fetchMapped: {
    requesting: boolean;
  };
};

export const initialState: GamAdUnitState = {
  fetchAll: {
    requesting: false,
  },
  fetchMapped: {
    requesting: false,
  },
};

export const gamAdUnitReducer = reducerWithInitialState(initialState)
  /**
   * Fetch GamAdUnits
   */
  .case(fetchGamAdUnitsAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchGamAdUnitsAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchGamAdUnitsAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  /**
   * Fetch MappedGamAdUnits
   */
  .case(fetchMappedGamAdUnitsAction.started, (state) => ({
    ...state,
    fetchMapped: {
      requesting: true,
    },
  }))
  .case(fetchMappedGamAdUnitsAction.done, (state) => ({
    ...state,
    fetchMapped: {
      requesting: false,
    },
  }))
  .case(fetchMappedGamAdUnitsAction.failed, (state) => ({
    ...state,
    fetchMapped: {
      requesting: false,
    },
  }));
