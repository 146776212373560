import React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { RouteActionPayload, RouteActionContext } from "../../../routing/types";
import { deleteDashboardItem } from "../../../store/modules/dashboardItem/actions/deleteDashboardItemAction";
import { fetchDashboardItems } from "../../../store/modules/dashboardItem/actions/fetchDashboardItemsAction";
import { patchDashboardItem } from "../../../store/modules/dashboardItem/actions/patchDashboardItemAction";
import { postDashboardItem } from "../../../store/modules/dashboardItem/actions/postDashboardItemAction";
import { getDashboardItems } from "../../../store/modules/dashboardItem/selectors/dashboardItemSelector";
import { fetchDemandAdCreativeGroups } from "../../../store/modules/demandAdCreativeGroup/actions/fetchDemandAdCreativeGroupsAction";
import { getDemandAdCreativeGroups } from "../../../store/modules/demandAdCreativeGroup/selectors/demandAdCreativeGroupSelector";
import { fetchSavedQueries as fetchSavedQueriesAction } from "../../../store/modules/savedQuery/actions/fetchSavedQueriesAction";
import { getSavedQueries } from "../../../store/modules/savedQuery/selectors/savedQuerySelector";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { CustomDashboard as Component } from "./CustomDashboard";

const CustomDashboard = connect(
  (state: RootState) => ({
    currentCompany: state.user.currentCompany as CompanyAttribute,
    dashboardItems: getDashboardItems(state),
    demandAdCreativeGroups: getDemandAdCreativeGroups(state),
    savedQueries: getSavedQueries(state),
    fetchItemsRequesting: state.dashboardItem.fetchAll.requesting,
    postItemsRequesting: state.dashboardItem.post.requesting,
    postItemsSucceeded: state.dashboardItem.post.success,
    patchItemsRequesting: state.dashboardItem.patch.requesting,
    patchItemsSucceeded: state.dashboardItem.patch.success,
    deleteItemsRequesting: state.dashboardItem.delete.requesting,
    deleteItemsSucceeded: state.dashboardItem.delete.success,
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    fetchDashboardItems: dispatchable(dispatch, fetchDashboardItems),
    postDashboardItem: dispatchable(dispatch, postDashboardItem),
    patchDashboardItem: dispatchable(dispatch, patchDashboardItem),
    deleteDashboardItem: dispatchable(dispatch, deleteDashboardItem),
  }),
)(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await Promise.all([
      store.dispatch(fetchDashboardItems()),
      store.dispatch(fetchDemandAdCreativeGroups()),
      store.dispatch(fetchSavedQueriesAction()),
    ]);
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <CustomDashboard />,
  };
}
