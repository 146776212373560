import { Card } from "ingred-ui";
import styled from "styled-components";

import { Color, Size, BreakPoint } from "../../../../../styles/variables";
import { Link } from "../../../../atoms/Link";

export const Container = styled(Card)<{ span: number }>`
  min-width: 496px;
  height: 576px;
  grid-column: span 2;
  @media (min-width: ${BreakPoint.X_LARGE}px) {
    grid-column: span ${({ span }) => span};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing * 3}px;
  border-bottom: ${Size.Border.Small} solid ${Color.gray.light};
`;

export const Content = styled.div`
  padding: ${({ theme }) => `${theme.spacing * 3}px`};
`;

export const QueryDetailLink = styled(Link)`
  text-decoration: none;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
`;
