import { connect } from "react-redux";

import { CompanyAttribute } from "../../../domain/companyAttribute";
import { RootState } from "../../../store/reducer";

import { Breadcrumbs as Container } from "./Breadcrumbs";

export const Breadcrumbs = connect((state: RootState) => ({
  // /company/:id 以下で利用するので選択済み
  currentCompany: state.user.currentCompany as CompanyAttribute,
}))(Container);
