import { Typography, Icon } from "ingred-ui";
import React from "react";

import { CompanyAttribute } from "../../../domain/companyAttribute";
import { Link } from "../../atoms/Link";

import * as Styled from "./styled";

type BreadcrumbData = {
  title: string;
  link?: string;
};

type Props = {
  items: BreadcrumbData[];
};

type InjectProps = {
  currentCompany: CompanyAttribute;
};

type InjectedProps = Props & InjectProps;

export const Breadcrumbs: React.FunctionComponent<InjectedProps> = ({
  items,
  currentCompany,
}) => {
  return (
    <Styled.Container>
      {items.map((item, index) => (
        <React.Fragment key={item.title}>
          {item.link ? (
            <Link href={`/company/${currentCompany.company.id}${item.link}`}>
              <Typography color="secondary" size="xs">
                {item.title}
              </Typography>
            </Link>
          ) : (
            <Typography
              color="secondary"
              size="xs"
              weight={index !== items.length - 1 ? "normal" : "bold"}
            >
              {item.title}
            </Typography>
          )}
          {index !== items.length - 1 && <Icon name="arrow_right" size="lg" />}
        </React.Fragment>
      ))}
    </Styled.Container>
  );
};
