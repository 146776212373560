import { Response, Request } from "api-types";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchInformationParams = void;
export type FetchInformationResponse = Response.Information.FetchAll;

export async function fetchInformations(
  apiClient: APIClient,
  companyId: number,
): Promise<FetchInformationResponse> {
  const { data } = await apiClient.get<FetchInformationResponse>(
    "/v1/informations",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type PatchInformationReadParams = Request.Information.Read.Patch;
export type PatchInformationReadResponse = Response.Information.Read.Patch;

export async function patchInformationRead(
  apiClient: APIClient,
  params: PatchInformationReadParams,
  companyId: number,
): Promise<PatchInformationReadResponse> {
  const { data } = await apiClient.post<PatchInformationReadResponse>(
    `/v1/informations/${params.id}/read`,
    {},
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}
