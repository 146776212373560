import { DemandAccountCreativeIds } from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PostDemandAdCreatives
 */
export type PostDemandAdCreativesParams = {
  gamAdUnitId: number;
};
export type PostDemandAdCreativesSuccess = {
  gamAdCreativeId: number;
  demandAdCreativeIds: string[];
};
export type PostDemandAdCreativesFailure = Error;

export const postDemandAdCreativesAction = actionCreator.async<
  PostDemandAdCreativesParams,
  PostDemandAdCreativesSuccess,
  PostDemandAdCreativesFailure
>("POST_DEMAND_AD_CREATIVES");

export type PostDemandAdCreativesPayload = {
  gamAdUnitId: number;
  gamLineItemCreativeId: number;
  demandAccountCreativeIds: DemandAccountCreativeIds;
};

export function postDemandAdCreatives(
  payload: PostDemandAdCreativesPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(
      postDemandAdCreativesAction.started({ gamAdUnitId: payload.gamAdUnitId }),
    );

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsGamCreateGamDemandCreative(
        currentCompany.company.id,
        payload.gamLineItemCreativeId,
        payload.demandAccountCreativeIds,
      );
      dispatch(
        postDemandAdCreativesAction.done({
          params: { gamAdUnitId: payload.gamAdUnitId },
          result: {
            demandAdCreativeIds: data.map((creative) => creative.id),
            gamAdCreativeId: payload.gamLineItemCreativeId,
          },
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          postDemandAdCreativesAction.failed({ params: payload, error }),
        );
      }
    }
  };
}
