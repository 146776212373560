import dayjs from "dayjs";
import { Typography, Spacer, Flex, Icon, Tooltip } from "ingred-ui";
import * as React from "react";

import { Information } from "../../../../../domain/information";
import { patchInformationRead } from "../../../../../store/modules/information/actions/patchInformationReadAction";
import { DispatchableAction } from "../../../../../store/utils/dispatchable";

import * as Styled from "./styled";

type Props = {
  item: Information;
  patchInformationRead: DispatchableAction<typeof patchInformationRead>;
};

const DISPLAY_TEXT_HEIGHT = 46;

const InformationItem: React.FunctionComponent<Props> = ({
  item,
  patchInformationRead,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [textHeight, setTextHeight] = React.useState<number>(0);
  React.useEffect(() => {
    if (ref.current) {
      setTextHeight(ref.current.getBoundingClientRect().height);
    }
  }, [ref]);
  const [isOpen, setIsOpen] = React.useState(false);
  const handleRead = () => {
    patchInformationRead({
      id: item.id,
    });
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!item.is_already_read) handleRead();
  };

  return (
    <Styled.Container>
      <Styled.Content onClick={handleToggle}>
        <Flex display="flex" alignItems="center">
          <Typography size="xl" weight="bold">
            {item.title}
          </Typography>
          <Spacer mr={1} />
          {item.is_already_read ? (
            <Styled.IconContainer isClickable={false}>
              <Icon name="mail_open" size="md" type="fill" />
            </Styled.IconContainer>
          ) : (
            <Tooltip content="既読にする" positionPriority={["right-start"]}>
              <Styled.IconContainer isClickable={true} onClick={handleRead}>
                <Icon name="mail" color="active" size="md" type="fill" />
              </Styled.IconContainer>
            </Tooltip>
          )}
        </Flex>
        <Spacer mt={0.5} />
        <Typography size="sm" color="secondary">
          掲載日 : {dayjs(item.date).format("YYYY/MM/DD")}
        </Typography>
        <Spacer mt={2} />
        <Typography lineHeight="1.7" component="div">
          <Styled.TextContainer
            height={isOpen ? textHeight : DISPLAY_TEXT_HEIGHT}
          >
            <Styled.Text
              ref={ref}
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: item.message,
              }}
            />
          </Styled.TextContainer>
        </Typography>
        {textHeight > DISPLAY_TEXT_HEIGHT && (
          <Spacer mt={2}>
            <Styled.SeeNextButton
              className="gaev-info-btn_open"
              onClick={handleToggle}
            >
              <Typography color="primary" size="md" lineHeight="1">
                {isOpen ? "閉じる" : "続きを読む"}
              </Typography>
              <Styled.ArrowIconContainer isOpen={isOpen}>
                <Icon name="arrow_bottom" color="active" size="md" />
              </Styled.ArrowIconContainer>
            </Styled.SeeNextButton>
          </Spacer>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export { InformationItem };
