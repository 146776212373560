import styled from "styled-components";

import { Radius, Color } from "../../../../styles/variables";
import { Size } from "../../../../styles/variables/size";
import { TOP_NAVIGATION_HEIGHT } from "../../constants";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing * 3}px;
  background-color: ${Color.background.default};
`;

export const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: ${Radius.MEDIUM};
  background-color: ${Color.gray.light};
  margin-right: ${({ theme }) => theme.spacing}px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${TOP_NAVIGATION_HEIGHT};
  padding-right: ${({ theme }) => theme.spacing * 3}px;
  /* SubNavItemの下線部がalign-items:center時にずれるので下にborderを持つ */
  border-bottom: ${Size.Border.Normal} solid transparent;
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  padding-left: ${({ theme }) => theme.spacing * 1}px;
`;
