import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { RouteActionContext, RouteActionPayload } from "../../../routing/types";
import { fetchCSVImportableDemandAccounts } from "../../../store/modules/csvImport/actions/fetchCSVImportableDemandAccounts";
import { postCSV } from "../../../store/modules/csvImport/actions/postCSVAction";
import { getCSVImportableDemandAccounts } from "../../../store/modules/csvImport/selectors/csvImportableDemandAccountsSelector";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { CSVImporter as Component } from "./CSVImporter";

const CSVImporter = connect(
  (state: RootState) => ({
    uploadSucceeded: state.csvImport.postCSV.success,
    fetchCSVImportableDemandAccountsRequesting:
      state.csvImport.fetchCSVImportableDemandAccounts.requesting,
    csvImportableDemandAccounts: getCSVImportableDemandAccounts(state),
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    postCSV: dispatchable(dispatch, postCSV),
  }),
)(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await store.dispatch(fetchCSVImportableDemandAccounts());
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <CSVImporter />,
  };
}
