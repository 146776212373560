import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchGamAdCreativeAction
 */
export type PatchGamAdCreativeActionParams = void;
export type PatchGamAdCreativeActionSuccess = Response.GamLineItemCreative.Patch;
export type PatchGamAdCreativeActionFailure = Error;

export const patchGamAdCreativeAction = actionCreator.async<
  PatchGamAdCreativeActionParams,
  PatchGamAdCreativeActionSuccess,
  PatchGamAdCreativeActionFailure
>("PATCH_GAM_AD_CREATIVE_ACTION");

export function patchGamAdCreative(
  payload: Request.GamLineItemCreative.Patch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { gamLineItemCreative, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchGamAdCreativeAction.started());

    try {
      const res = await gamLineItemCreative.patchGamLineItemCreative(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(patchGamAdCreativeAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(patchGamAdCreativeAction.failed({ error }));
      }
    }
  };
}
