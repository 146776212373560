import { DemandAccountDetail } from "../../../../../../api-client/model/demand-account-detail";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchDemandAccount
 */
export type FetchDemandAccountIdPayload = {
  demand_account_id: number;
};
export type FetchDemandAccountParams = void;
export type FetchDemandAccountSuccess = DemandAccountDetail;
export type FetchDemandAccountFailure = Error;

export const fetchDemandAccountAction = actionCreator.async<
  FetchDemandAccountParams,
  FetchDemandAccountSuccess,
  FetchDemandAccountFailure
>("FETCH_DEMAND_ACCOUNT");

export function fetchDemandAccount(
  payload: FetchDemandAccountIdPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchDemandAccountAction.started());

    try {
      const { data } = await openApiClient.rpzApiViewsDemandAccountShowDetail(
        currentCompany.company.id,
        payload.demand_account_id,
      );
      // TODO 不要なら消す(取得が早すぎるとアニメーションとかぶるので遅らせている)
      setTimeout(() => {
        dispatch(fetchDemandAccountAction.done({ result: data }));
      }, 400);
    } catch (err) {
      dispatch(fetchDemandAccountAction.failed(err));
    }
  };
}
