import { Spinner } from "ingred-ui";
import * as React from "react";

import * as Styled from "./styled";

type Props = {};

const Loading: React.FunctionComponent<Props> = () => (
  <Styled.Container>
    <Spinner />
  </Styled.Container>
);

export { Loading };
