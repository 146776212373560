import { Response } from "api-types";

import { createAPIClient } from "../../utils/APIClientUtils";

export type FetchDemandsParams = void;
export type FetchDemandsResponse = Response.Demand.FetchAll;

export async function fetchDemands(companyId: number) {
  const apiClient = createAPIClient();
  const { data } = await apiClient.get<FetchDemandsResponse>("/v1/demands", {
    headers: {
      "X-Company-Id": companyId,
    },
  });

  return data;
}
