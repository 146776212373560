import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchDemandAdCreativeGroups
 */
export type FetchDemandAdCreativeGroupsParams = void;
export type FetchDemandAdCreativeGroupsSuccess = Response.Site.FetchAll;
export type FetchDemandAdCreativeGroupsFailure = Error;

export const fetchDemandAdCreativeGroupsAction = actionCreator.async<
  FetchDemandAdCreativeGroupsParams,
  FetchDemandAdCreativeGroupsSuccess,
  FetchDemandAdCreativeGroupsFailure
>("FETCH_DEMAND_AD_CREATIVE_GROUPS");

export function fetchDemandAdCreativeGroups(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { site, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchDemandAdCreativeGroupsAction.started());

    try {
      const res = await site.fetchSites(apiClient, currentCompany.company.id);
      dispatch(fetchDemandAdCreativeGroupsAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchDemandAdCreativeGroupsAction.failed({ error }));
      }
    }
  };
}
