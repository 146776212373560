import * as Login from "../../components/pages/Login";
import * as SelectCompany from "../../components/pages/SelectCompany";
import * as Terms from "../../components/pages/Terms";
// import * as Mission from "../../components/pages/landingPages/Mission";
import { AuthorityConstraint } from "../../utils/AuthUtils";
import { RouteWithConstraint } from "../types";
import { action } from "../utils";

export const routes: RouteWithConstraint[] = [
  {
    path: "",
    action: action({ ...Login }),
  },
  {
    path: "/login",
    action: action({ ...Login }),
  },
  {
    path: "/select-company",
    action: action({ ...SelectCompany }),
    constraint: AuthorityConstraint.AUTHED,
  },
  {
    path: "/terms",
    action: action({ ...Terms }),
  },
];
