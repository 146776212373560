import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Ul = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
`;

export const Li = styled.div`
  & + & {
    margin-left: ${({ theme }) => theme.spacing * 3}px;
  }
  a {
    text-decoration: none;
  }
`;
