import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchDemandAdCreativeGroup
 */
export type PatchDemandAdCreativeGroupParams = void;
export type PatchDemandAdCreativeGroupSuccess = Response.Site.Patch;
export type PatchDemandAdCreativeGroupFailure = Error;

export const patchDemandAdCreativeGroupAction = actionCreator.async<
  PatchDemandAdCreativeGroupParams,
  PatchDemandAdCreativeGroupSuccess,
  PatchDemandAdCreativeGroupFailure
>("PATCH_DEMAND_AD_CREATIVE_GROUP");

export function patchDemandAdCreativeGroup(
  payload: Request.Site.Patch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { site, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchDemandAdCreativeGroupAction.started());

    try {
      const res = await site.patchSiteById(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(patchDemandAdCreativeGroupAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(patchDemandAdCreativeGroupAction.failed({ error }));
      }
    }
  };
}
