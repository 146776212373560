import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchGamLineItemsAction } from "../actions/fetchGamLineItemsAction";
import { patchYieldSettingAction } from "../actions/patchYieldSettingAction";

export type GamLineItemState = {
  fetchAll: {
    requesting: boolean;
  };
  yieldSetting: {
    patch: {
      requesting: boolean;
      success: boolean;
    };
  };
};

export const initialState: GamLineItemState = {
  fetchAll: {
    requesting: false,
  },
  yieldSetting: {
    patch: {
      requesting: false,
      success: false,
    },
  },
};

export const gamLineItemReducer = reducerWithInitialState(initialState)
  /**
   * Fetch GamLineItems
   */
  .case(fetchGamLineItemsAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchGamLineItemsAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchGamLineItemsAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  /**
   * Patch GamLineItems YieldSetting
   */
  .case(patchYieldSettingAction.started, (state) => ({
    ...state,
    yieldSetting: {
      ...state.yieldSetting,
      patch: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(patchYieldSettingAction.done, (state) => ({
    ...state,
    yieldSetting: {
      ...state.yieldSetting,
      patch: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(patchYieldSettingAction.failed, (state) => ({
    ...state,
    yieldSetting: {
      ...state.yieldSetting,
      patch: {
        requesting: false,
        success: false,
      },
    },
  }));
