import { ThunkAction } from "../../../type";
import { PatchDemandAdCreativesPayload } from "../actions/patchDemandAdCreativesAction";

import { actionCreator } from "./actionCreator";

/**
 * PatchDemandAdCreatives2
 */
export type PatchDemandAdCreatives2Params = void;
export type PatchDemandAdCreatives2Success = PatchDemandAdCreativesPayload;
export type PatchDemandAdCreatives2Failure = Error;

export const patchDemandAdCreatives2Action = actionCreator.async<
  PatchDemandAdCreatives2Params,
  PatchDemandAdCreatives2Success,
  PatchDemandAdCreatives2Failure
>("PATCH_DEMAND_AD_CREATIVES_2");

export function patchDemandAdCreatives2(
  payload: PatchDemandAdCreativesPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchDemandAdCreatives2Action.started());

    try {
      await openApiClient.rpzApiViewsCategoryUpdateCategoryCreative2(
        currentCompany.company.id,
        payload.toCategory.id,
        {
          demand_account_creative_ids: payload.demandAdCreatives.map(
            (demandAdCreative) => demandAdCreative.id,
          ),
        },
      );
      dispatch(
        patchDemandAdCreatives2Action.done({
          result: payload,
        }),
      );
    } catch (err) {
      dispatch(patchDemandAdCreatives2Action.failed(err));
    }
  };
}
