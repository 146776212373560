import { ValidationRule } from "react-hook-form";

type FormValidation = {
  [form_name: string]: ValidationRule<RegExp>;
};

type DemandFormValidations = {
  [demand_id: number]: FormValidation;
};

// データ構造
// {
//   demand_id: {
//     form_name: {
//       value: "hoge", // バリデーションする正規表現
//       message: "hoge", // エラーメッセージ
//     },
//   }
// }
export const demandFormValidations: DemandFormValidations = {
  // amazon(tam)
  64: {
    s3_url: {
      value: /s3:\/\/aps-reporting-([\w-]+)\/aps-download-publisher-([\w-]+)\/reportId=[\w-]+\/versionId=[\w-]+\/$/,
      message: "正しいURLの形式で入力してください",
    },
  },
  // amazon
  41: {
    s3_url: {
      value: /s3:\/\/aps-reporting-([\w-]+)\/aps-download-publisher-([\w-]+)+\/reportId=[\w-]+\/versionId=[\w-]+\/$/,
      message: "正しいURLの形式で入力してください",
    },
  },
};
