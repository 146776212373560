import * as React from "react";

function SvgGraphaddActive(props: any) {
  return (
    <svg width={143} height={124} viewBox="0 0 143 124" {...props}>
      <defs>
        <filter
          id="長方形_5742"
          x="0"
          y="1"
          width="112"
          height="93"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" in="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor="#041c33" floodOpacity="0.102" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(3)">
        <rect width="140" height="124" rx="5" fill="#cdf0fe" />
        <g transform="translate(6055 2431)">
          <rect
            width="26"
            height="11"
            transform="translate(-5980 -2370)"
            fill="#0b82f4"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <path
            d="M2,0H12a0,0,0,0,1,0,0V11a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z"
            transform="translate(-5992 -2370)"
            fill="#0b82f4"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <rect
            width="26"
            height="13"
            transform="translate(-5980 -2347)"
            fill="#fff"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <rect
            width="12"
            height="13"
            transform="translate(-5992 -2347)"
            fill="#f2f9fc"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <rect
            width="26"
            height="12"
            transform="translate(-5980 -2359)"
            fill="#fff"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <rect
            width="12"
            height="12"
            transform="translate(-5992 -2359)"
            fill="#f2f9fc"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <rect
            width="26"
            height="13"
            transform="translate(-5980 -2334)"
            fill="#fff"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <path
            d="M0,0H12a0,0,0,0,1,0,0V13a0,0,0,0,1,0,0H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
            transform="translate(-5992 -2334)"
            fill="#f2f9fc"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <path
            d="M0,0H24a2,2,0,0,1,2,2v9a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
            transform="translate(-5954 -2370)"
            fill="#0b82f4"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <rect
            width="26"
            height="13"
            transform="translate(-5954 -2347)"
            fill="#fff"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <rect
            width="26"
            height="12"
            transform="translate(-5954 -2359)"
            fill="#fff"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
          <path
            d="M0,0H26a0,0,0,0,1,0,0V11a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
            transform="translate(-5954 -2334)"
            fill="#fff"
            stroke="#e2e8ea"
            strokeWidth="1"
          />
        </g>
        <g transform="translate(5483 2374)">
          <g
            transform="matrix(1, 0, 0, 1, -5486, -2374)"
            filter="url(#長方形_5742)"
          >
            <g
              transform="translate(16 14)"
              fill="#fff"
              stroke="#e2e8ea"
              strokeWidth="1"
            >
              <rect width="80" height="61" rx="2" stroke="none" />
              <rect
                x="-0.5"
                y="-0.5"
                width="81"
                height="62"
                rx="2.5"
                fill="none"
              />
            </g>
          </g>
          <rect
            width="10"
            height="6"
            transform="translate(-5461 -2312)"
            fill="#0b82f4"
          />
          <rect
            width="10"
            height="18"
            transform="translate(-5444 -2324)"
            fill="#0b82f4"
          />
          <rect
            width="10"
            height="9"
            transform="translate(-5427 -2315)"
            fill="#0b82f4"
          />
          <rect
            width="10"
            height="25"
            transform="translate(-5410 -2331)"
            fill="#0b82f4"
          />
          <g transform="translate(-133.375 -32.189)">
            <path
              d="M.564,22.689l22-15.015L39.732,15,56.564,3.4"
              transform="translate(-5328.188 -2322.5)"
              fill="none"
              stroke="#e2e8ea"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              d="M53.26.88l4.727,6.426L61.188,0Z"
              transform="translate(-5328.188 -2322.5)"
              fill="#e2e8ea"
            />
          </g>
          <rect
            width="72"
            height="1"
            transform="translate(-5466 -2306)"
            fill="#e2e8ea"
          />
          <rect
            width="10"
            height="11"
            transform="translate(-5444 -2335)"
            fill="#e2e8ea"
          />
          <rect
            width="10"
            height="13"
            transform="translate(-5427 -2328)"
            fill="#e2e8ea"
          />
          <rect
            width="10"
            height="11"
            transform="translate(-5410 -2342)"
            fill="#e2e8ea"
          />
          <rect
            width="10"
            height="10"
            transform="translate(-5461 -2322)"
            fill="#e2e8ea"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgGraphaddActive;
