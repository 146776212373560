import { Select as OriginSelect, Typography } from "ingred-ui";
import styled from "styled-components";

import { Radius } from "../../../../../styles/variables";
import { colors, Color } from "../../../../../styles/variables/color";

export const SearchBox = styled.div`
  padding: ${({ theme }) => theme.spacing * 2}px;
  background-color: ${colors.basic[100]};
  border: 1px solid ${({ theme }) => theme.palette.text.disabled};
  border-radius: ${Radius.MEDIUM};
`;

export const Select = styled(OriginSelect)<{ width?: string }>`
  min-width: ${({ width }) => (width != null ? "auto" : "240px")};
  max-width: 400px;
  width: ${({ width }) => width || "auto"};
`;

export const AnnotationText = styled(Typography)`
  color: ${Color.text.secondary};
`;
