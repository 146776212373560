import { Request } from "api-types";
import { Spacer, Typography, ConfirmModal, Flex } from "ingred-ui";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { fetchDemandAccount } from "../../../../../../store/modules/demandAccount/actions/fetchDemandAccountAction";
import { getDemandAccountById } from "../../../../../../store/modules/demandAccount/selectors/demandAccountsSelector";
import { RootState } from "../../../../../../store/reducer";
import { DispatchableAction } from "../../../../../../store/utils/dispatchable";
import { colors } from "../../../../../../styles/variables/color";
import { Loading } from "../../../../Loading";

import * as Styled from "./styled";

type Props = {
  isOpen: boolean;
  fetchDemandAccount: DispatchableAction<typeof fetchDemandAccount>;
  onClose?: () => void;
  demandAccountId?: number;
  onSubmit?: (data: Request.DemandAccount.Patch) => void;
  fetchRequesting?: boolean;
  scraperEnabledRequesting?: boolean;
};

const ScraperEnabledModal: React.FunctionComponent<Props> = ({
  isOpen,
  fetchDemandAccount,
  onClose,
  demandAccountId,
  onSubmit,
  fetchRequesting,
  scraperEnabledRequesting,
}) => {
  const demandAccount = useSelector((state: RootState) =>
    demandAccountId ? getDemandAccountById(state, demandAccountId) : null,
  );

  React.useEffect(() => {
    if (!demandAccountId) return;
    fetchDemandAccount({ demand_account_id: demandAccountId });
  }, [fetchDemandAccount, demandAccountId]);

  const { handleSubmit } = useForm();

  const submit = () => {
    if (onSubmit && demandAccountId) {
      onSubmit({
        demand_account_id: demandAccountId,
        form_columns: null,
        scraper_enabled: demandAccount ? !demandAccount.scraper_enabled : null,
      });
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title="レポート自動取得切り替え"
      confirmText="保存する"
      cancelText="キャンセル"
      buttonColor="primary"
      loading={scraperEnabledRequesting}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit(submit)}
    >
      <Styled.FormContainer>
        {fetchRequesting ? (
          <Spacer pt={3} pb={2}>
            <Flex display="flex" justifyContent="center" alignItems="center">
              <Loading />
            </Flex>
          </Spacer>
        ) : (
          <>
            <Styled.FormGroup>
              <Styled.FormGroupLeft>
                <Spacer pr={5}>デマンド</Spacer>
              </Styled.FormGroupLeft>
              <Styled.FormGroupRight>
                <Styled.TextContainer>
                  {demandAccount?.demand.name}
                </Styled.TextContainer>
              </Styled.FormGroupRight>
            </Styled.FormGroup>
            <Styled.FormGroup>
              <Typography color={colors.basic[900]} size="xl">
                {demandAccount?.scraper_enabled
                  ? "レポート自動取得をOFFに変更します"
                  : "レポート自動取得をONに変更します"}
              </Typography>
            </Styled.FormGroup>
          </>
        )}
      </Styled.FormContainer>
    </ConfirmModal>
  );
};

export { ScraperEnabledModal };
