import { Domain } from "api-types";

import { RootState } from "../../../reducer";

export const getSystemRole = (state: RootState): Domain.SystemRole => {
  const currentCompany = state.user.currentCompany;
  if (currentCompany == null) {
    throw new Error("企業が選択されていません");
  }
  const { systemRole } = state.auth;
  if (systemRole === "admin") {
    return "admin";
  } else {
    return "people";
  }
};
