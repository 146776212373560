import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchInformations
 */
export type FetchInformationsParams = void;
export type FetchInformationsSuccess = Response.Information.FetchAll;
export type FetchInformationsFailure = Error;

export const fetchInformationsAction = actionCreator.async<
  FetchInformationsParams,
  FetchInformationsSuccess,
  FetchInformationsFailure
>("FETCH_INFORMATIONS");

export function fetchInformations(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, information }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchInformationsAction.started());

    try {
      const res = await information.fetchInformations(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchInformationsAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchInformationsAction.failed({ error }));
      }
    }
  };
}
