import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchMarketTrendEcpm
 */
export type FetchMarketTrendEcpmParams = void;
export type FetchMarketTrendEcpmSuccess = Response.MarketTrend.FetchEcpm;
export type FetchMarketTrendEcpmFailure = Error;

export const fetchMarketTrendEcpmAction = actionCreator.async<
  FetchMarketTrendEcpmParams,
  FetchMarketTrendEcpmSuccess,
  FetchMarketTrendEcpmFailure
>("FETCH_MARKET_TREND_ECPM");

export function fetchMarketTrendEcpm(
  payload: Request.MarketTrend.FetchEcpm,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { marketTrend, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchMarketTrendEcpmAction.started());

    try {
      const res = await marketTrend.fetchMarketTrendEcpm(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(fetchMarketTrendEcpmAction.done({ result: res }));
    } catch (err) {
      dispatch(fetchMarketTrendEcpmAction.failed(err));
    }
  };
}
