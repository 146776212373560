import { Domain } from "api-types";
import dayjs from "dayjs";
import { TooltipFormatterContextObject } from "highcharts";
import { Theme } from "ingred-ui";
import { css } from "styled-components";

import { Radius, Size } from "../../../app/src/styles/variables";

export function CustomTooltip(
  type: Domain.ChartUnitType = "normal",
  theme: Theme,
  totalEnabled = true,
) {
  return function (this: TooltipFormatterContextObject) {
    const title =
      typeof this.x === "number" ? dayjs(this.x).format("YYYY/MM/DD") : this.x;
    let total = "";
    if (totalEnabled) {
      total = `
        <p class="custom-tooltip-text">
          <span class="circle" style="background-color:${
            theme.palette.gray.dark
          }"></span>
          合計:
          <span class="custom-tooltip-value">
            <b>${
              type === "price"
                ? `¥${this.total?.toLocaleString()}`
                : this.total?.toLocaleString()
            }</b>
          </span>
        </p>
      `;
    }

    // 積み上げ棒グラフのときはparcentageが有効になる
    const parcentage = this.point.percentage
      ? `(${this.point.percentage.toFixed(2)}%)`
      : "";

    return `
      <div class="custom-tooltip-container">
        <p class="custom-tooltip-title">${title}</p>
        <div class="custom-tooltip-content">
          ${total}
          <p class="custom-tooltip-text">
            <span class="circle" style="background-color:${
              this.point.color
            }"></span>
            ${this.series.name}:
            <span class="custom-tooltip-value">
              <b>${
                type === "price"
                  ? `¥${this.point.y?.toLocaleString()}`
                  : this.point.y?.toLocaleString()
              }</b>
              ${parcentage}
            </span>
          </p>
        </div>
      </div>
    `;
  };
}

export const ToolTipStyle = css`
  .custom-tooltip-container {
    background-color: ${({ theme }) => theme.palette.background.default};
    box-shadow: 0px 0px 16px #041c3315;
    border-radius: ${Radius.SMALL};
  }
  .custom-tooltip-title {
    padding: ${({ theme }) => theme.spacing}px
      ${({ theme }) => theme.spacing * 1.5}px;
    border-bottom: ${Size.Border.Small} solid
      ${({ theme }) => theme.palette.gray.light};
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 12px;
  }
  .custom-tooltip-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    .circle {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.gray.light};
      margin-right: ${({ theme }) => theme.spacing * 0.5}px;
    }
    .custom-tooltip-value {
      margin-left: ${({ theme }) => theme.spacing}px;
      font-weight: bold;
    }
  }

  .custom-tooltip-content {
    padding: ${({ theme }) => theme.spacing * 3}px
      ${({ theme }) => theme.spacing * 1.5}px;
  }
`;
