import styled from "styled-components";

import { Size, Color } from "../../../../../styles/variables";

export const FormContainer = styled.div`
  width: 500px;
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing * 2}px 0
    ${({ theme }) => theme.spacing * 2}px;
  & + & {
    border-top: ${Size.Border.Small} solid ${Color.gray.light};
  }
`;

export const InputContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const FormGroupLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  min-height: 40px; /* <TextField/>の高さに合わせる */
`;

export const FormGroupRight = styled.div`
  width: 70%;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 40px;
`;
