import styled from "styled-components";

import { Color } from "../../../../styles/variables";

export const SelectContainer = styled.div`
  width: 280px;
`;

export const ImpossibleLabel = styled.div`
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 ${({ theme }) => theme.spacing}px;
  border-radius: ${({ theme }) => theme.spacing * 2}px;
  background-color: ${Color.danger.highlight};
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
