import { Typography, DataTable, ItemEmpty, Spacer, TextField } from "ingred-ui";
import * as React from "react";

import { DemandAdCreative } from "../../../../../../domain/demandAdCreative";
import { DeleteDemandAdCreativesPayload } from "../../../../../../store/modules/gamAdCreative/actions/deleteDemandAdCreativesAction";
import { StepModal } from "../../../../../elements/StepModal";

import * as Styled from "./styled";

type Props = {
  onClose: () => void;
  onSubmit: (data: DeleteDemandAdCreativesPayload) => void;
  loading: boolean;
  demandAdCreatives: DemandAdCreative[];
  gamAdCreative: {
    id: number;
    originalCreativeId: number;
    gamCreativeName: string;
    demandAdCreatives: DemandAdCreative[];
  };
};

const DeleteModal: React.FunctionComponent<Props> = ({
  onClose,
  onSubmit,
  loading,
  demandAdCreatives,
  gamAdCreative,
}) => {
  const data = demandAdCreatives.map((creative) => ({
    id: creative.id,
    name: creative.original_name || creative.original_id,
  }));

  const [searchText, setSearchText] = React.useState("");
  const searchedCreatives = data.filter((item) =>
    item.name.includes(searchText),
  );

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [step, setStep] = React.useState<1 | 2>(1);

  const handlePrev = () => {
    setStep(1);
  };

  const handleChangeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleConfirmSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStep(2);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      gamLineItemCreativeId: gamAdCreative.id,
      demandAccountCreativeIds: { demand_account_creative_ids: selectedIds },
    });
  };

  return (
    <StepModal
      currentStepKey={step}
      steps={[
        {
          title: `${gamAdCreative.gamCreativeName}から選択したデマンド広告クリエイティブの紐付けを解除する`,
          stepKey: 1,
          cancel: {
            text: "キャンセル",
            onClick: onClose,
          },
          submit: {
            text: "確認する",
            onSubmit: handleConfirmSubmit,
            disabled: selectedIds.length === 0,
          },
          renderContent: () => (
            <>
              <Styled.TopContainer>
                <Styled.InputContainer>
                  <TextField
                    icon="search"
                    placeholder="デマンド広告クリエイティブを検索"
                    value={searchText}
                    onChange={handleChangeSearchText}
                  />
                </Styled.InputContainer>
                <Typography size="lg">
                  {`${searchedCreatives.length}個中`}
                  <Typography
                    component="span"
                    size="lg"
                    color="primary"
                    weight="bold"
                  >
                    {`${selectedIds.length}個`}
                  </Typography>
                  を選択中
                </Typography>
              </Styled.TopContainer>
              {searchedCreatives.length > 0 ? (
                <Spacer px={3} pb={15}>
                  <DataTable
                    dataKey={"id"}
                    enablePagination={true}
                    per={10}
                    data={searchedCreatives}
                    columns={[
                      {
                        name: "名前",
                        selector: (data) => data.name,
                        sortable: true,
                      },
                    ]}
                    selectedRows={selectedIds}
                    onSelectRowsChange={setSelectedIds} // eslint-disable-line react/jsx-handler-names
                  />
                </Spacer>
              ) : (
                <ItemEmpty title="該当するクリエイティブがありません。" />
              )}
            </>
          ),
        },
        {
          title: "下記のデマンド広告クリエイティブの紐付けを解除しますか？",
          stepKey: 2,
          cancel: {
            text: "戻る",
            onClick: handlePrev,
          },
          submit: {
            text: "解除する",
            onSubmit: handleSubmit,
            loading: loading,
          },
          renderContent: () => (
            <Spacer pt={2} px={3} pb={15}>
              <DataTable
                dataKey={"id"}
                enablePagination={true}
                per={10}
                data={data.filter((d) => selectedIds.includes(d.id))}
                columns={[
                  {
                    name: "名前",
                    selector: (data) => data.name,
                    sortable: true,
                  },
                ]}
              />
            </Spacer>
          ),
        },
      ]}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
    />
  );
};

export { DeleteModal };
