import { connect } from "react-redux";

import { updateFatal } from "../../store/modules/app/actions/UpdateFatalAction";
import { sendToGTMAction } from "../../store/modules/gtm/actions/SendToGTMAction";
import { locationChange } from "../../store/modules/routing/actions/LocationChangeAction";
import { replace } from "../../store/modules/routing/actions/ReplaceAction";
import { RootState } from "../../store/reducer";
import { dispatchable } from "../../store/utils/dispatchable";

import { RouteRenderer as Container } from "./RouteRenderer";

export const RouteRenderer = connect(
  (state: RootState) => ({
    app: state.app,
    routing: state.routing,
    user: state.user,
  }),
  (dispatch) => ({
    updateFatal: dispatchable(dispatch, updateFatal),
    replace: dispatchable(dispatch, replace),
    locationChange: dispatchable(dispatch, locationChange),
    sendToGTM: dispatchable(dispatch, sendToGTMAction),
  }),
)(Container);
