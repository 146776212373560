import { Domain } from "api-types";
import {
  Spacer,
  TextField,
  Select,
  ConfirmModal,
  Typography,
  Checkbox,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { registerIui } from "../../../../../utils/registerIui";

import * as Styled from "./styled";

type Props = {
  isOpen: boolean;
  period?: Domain.DateRangeType;
  name?: string;
  isUpdate: boolean;
  onClose?: () => void;
  onSubmit?: (data: {
    period: Domain.DateRangeType;
    name: string;
    asUpdate: boolean;
  }) => void;
  loading?: boolean;
};

// SavedQueryではカスタム期間は選択できない
const displayPeriodOptions: OptionType<Domain.DateRangeType>[] = [
  {
    label: "昨日",
    value: "yesterday",
  },
  {
    label: "過去7日間",
    value: "last_7_days",
  },
  {
    label: "過去30日間",
    value: "last_30_days",
  },
  {
    label: "過去365日間",
    value: "last_365_days",
  },
  {
    label: "過去730日間",
    value: "last_730_days",
  },
  {
    label: "過去3ヶ月間",
    value: "last_3_months",
  },
  {
    label: "過去6ヶ月間",
    value: "last_6_months",
  },
  {
    label: "過去1年間",
    value: "last_12_months",
  },
  {
    label: "今月",
    value: "this_month",
  },
  {
    label: "前月",
    value: "last_month",
  },
  {
    label: "今年",
    value: "this_year",
  },
];

function checkDisplayPeriod(
  displayPeriod?: Domain.DateRangeType,
): Domain.DateRangeType {
  if (
    !displayPeriod ||
    displayPeriod === "custom_range" ||
    displayPeriod === "custom_single"
  )
    return "yesterday";
  return displayPeriod;
}

const QuerySaveModal: React.FunctionComponent<Props> = ({
  isOpen,
  period,
  name,
  isUpdate,
  onClose,
  onSubmit,
  loading,
}) => {
  const [updateCheck, setUpdateCheck] = useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = useState<
    OptionType<Domain.DateRangeType>
  >(
    period
      ? (displayPeriodOptions.find(
          (option) => option.value === checkDisplayPeriod(period),
        ) as OptionType<Domain.DateRangeType>)
      : {
          label: "昨日",
          value: "yesterday",
        },
  );
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{
    period: Domain.DateRangeType;
    name: string;
    asUpdate: boolean;
  }>({ defaultValues: { period: "yesterday", name: name } });
  const watchName = watch("name");
  const handlePeriodChange = (selectedOption: any) => {
    setSelectedPeriod(selectedOption);
  };

  const handleCheck = () => {
    setUpdateCheck(!updateCheck);
  };

  const submit = (data: {
    period: Domain.DateRangeType;
    name: string;
    asUpdate: boolean;
  }) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (!period) return;
    setSelectedPeriod(
      displayPeriodOptions.find(
        (option) => option.value === checkDisplayPeriod(period),
      ) as OptionType<Domain.DateRangeType>,
    );
  }, [period]);

  useEffect(() => {
    setValue("period", selectedPeriod.value);
  }, [selectedPeriod, setValue]);

  useEffect(() => {
    setValue("asUpdate", updateCheck);
  }, [updateCheck, setValue]);

  useEffect(() => {
    register("period");
    register("asUpdate");
  }, [register]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      title="クエリ保存"
      confirmText="保存する"
      cancelText="キャンセル"
      buttonColor="primary"
      overflowYScroll={false}
      disabled={!watchName}
      loading={loading}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit(submit)}
    >
      <Styled.FormContainer>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Spacer pr={5}>クエリ名</Spacer>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <TextField
              {...registerIui(
                register("name", {
                  required: true,
                  maxLength: {
                    value: 30,
                    message: "30文字以内で入力してください",
                  },
                }),
              )}
              errorText={errors.name != null ? errors.name.message : ""}
            />
          </Styled.FormGroupRight>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Spacer pr={5}>期間</Spacer>
          </Styled.FormGroupLeft>
          <Select
            options={displayPeriodOptions}
            isClearable={false}
            minWidth="120px"
            maxMenuHeight={150}
            value={selectedPeriod}
            onChange={handlePeriodChange}
          />
        </Styled.FormGroup>
        {isUpdate && (
          <Styled.FormGroup>
            <Checkbox checked={updateCheck} onChange={handleCheck}>
              クエリを上書き保存する
            </Checkbox>
          </Styled.FormGroup>
        )}
        <Styled.FormGroup>
          <div>
            <Typography color="secondary" size="xs" lineHeight="1.7">
              ※クエリ保存はカスタム期間に対応していません。相対期間を設定して保存してください。
            </Typography>
            {isUpdate && (
              <Typography color="secondary" size="xs" lineHeight="1.7">
                ※カスタムダッシュボードのグラフアイテムに利用中のクエリを上書き保存するとクエリの条件によりグラフが表示されなくなることがあります。
              </Typography>
            )}
          </div>
        </Styled.FormGroup>
      </Styled.FormContainer>
    </ConfirmModal>
  );
};

export { QuerySaveModal };
