import {
  SavedCustomReport,
  PostSavedCustomReport,
} from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchSavedQuery
 */
export type PatchSavedQueryParams = void;
export type PatchSavedQuerySuccess = SavedCustomReport;
export type PatchSavedQueryFailure = Error;

export const patchSavedQueryAction = actionCreator.async<
  PatchSavedQueryParams,
  PatchSavedQuerySuccess,
  PatchSavedQueryFailure
>("PATCH_SAVED_QUERY");

type PostSavedCustomReportPayload = {
  saved_query_id: number;
} & PostSavedCustomReport;

export function patchSavedQuery(
  payload: PostSavedCustomReportPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchSavedQueryAction.started());

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsCustomReportsSavedQueryUpdate(
        currentCompany.company.id,
        payload.saved_query_id,
        {
          saved_custom_report_detail: payload.saved_custom_report_detail,
          name: payload.name,
        },
      );
      dispatch(patchSavedQueryAction.done({ result: data }));
    } catch (err: any) {
      dispatch(patchSavedQueryAction.failed(err));
    }
  };
}
