import { reducerWithInitialState } from "typescript-fsa-reducers";

import { GamAdUnitByIdState } from "../../../../domain/gamAdUnit";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { fetchGamAdCreativesAction } from "../../gamAdCreative/actions/fetchGamAdCreativesAction";
import { postDemandAdCreativesAction } from "../../gamAdCreative/actions/postDemandAdCreativesAction";
import { fetchUnmappedGamFlattenedHierarchiesAction } from "../../gamFlattenedHierarchy/actions/fetchUnmappedGamFlattenedHierarchiesAction";
import { fetchGamLineItemsAction } from "../../gamLineItem/actions/fetchGamLineItemsAction";
import { fetchGamAdUnitsAction } from "../actions/fetchGamAdUnitsAction";
import { fetchMappedGamAdUnitsAction } from "../actions/fetchMappedGamAdUnitsAction";

export type GamAdUnitEntityState = {
  byId: {
    [key: number]: GamAdUnitByIdState;
  };
  allIds: number[];
};

export const initialState: GamAdUnitEntityState = {
  byId: {},
  allIds: [],
};

export const gamAdUnitEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch gamAdUnits
   */
  .case(fetchGamAdUnitsAction.done, (state, action) => ({
    ...state,
    byId: action.result.gam_ad_units.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          ...cur,
          gam_line_items: [
            ...(state.byId[cur.id] ? state.byId[cur.id].gam_line_items : []),
          ],
          hasCreative: state.byId[cur.id]
            ? state.byId[cur.id].hasCreative
            : false,
        },
      }),
      state.byId,
    ),
    allIds: uniquePush(
      action.result.gam_ad_units.map((unit) => unit.id),
      state.allIds,
    ),
  }))
  /**
   * Fetch MappedGamAdUnitsAction
   */
  .case(fetchMappedGamAdUnitsAction.done, (state, action) => ({
    ...state,
    byId: action.result.gam_ad_units.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          ...cur,
          gam_line_items:
            state.byId[cur.id] != null ? state.byId[cur.id].gam_line_items : [],
          hasCreative: true,
        },
      }),
      state.byId,
    ),
    allIds: uniquePush(
      action.result.gam_ad_units.map((unit) => unit.id),
      state.allIds,
    ),
  }))
  /**
   * Fetch gamLineItems (by gamUnitId)
   */
  .case(fetchGamLineItemsAction.done, (state, action) => {
    const id = action.result.gam_ad_unit.id;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          ...action.result.gam_ad_unit,
          gam_line_items: uniquePush(
            [...(state.byId[id] ? state.byId[id].gam_line_items : [])],
            action.result.gam_line_item_details.map((lineItem) => lineItem.id),
          ),
          hasCreative: state.byId[id] ? state.byId[id].hasCreative : false,
        },
      },
      allIds: uniquePush(state.allIds, id),
    };
  })
  /**
   * Fetch gamAdCreatives (by gamLineItemId)
   */
  .case(fetchGamAdCreativesAction.done, (state, action) => {
    const id = action.result.gam_ad_unit.id;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          ...action.result.gam_ad_unit,
          gam_line_items: uniquePush(
            [...(state.byId[id] ? state.byId[id].gam_line_items : [])],
            [action.result.gam_line_item_detail.id],
          ),
          hasCreative: true,
        },
      },
      allIds: uniquePush(state.allIds, id),
    };
  })
  /**
   * Fetch Unmapped gamFlattenedHierarchy
   */
  .case(fetchUnmappedGamFlattenedHierarchiesAction.done, (state, action) => ({
    ...state,
    byId: action.result.gam_flattened_hierarchies.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.gam_ad_unit.id]: {
          ...cur.gam_ad_unit,
          gam_line_items:
            state.byId[cur.gam_ad_unit.id] != null
              ? state.byId[cur.gam_ad_unit.id].gam_line_items
              : [],
          hasCreative:
            state.byId[cur.gam_ad_unit.id] != null
              ? state.byId[cur.gam_ad_unit.id].hasCreative
              : false,
        },
      }),
      state.byId,
    ),
    allIds: uniquePush(
      state.allIds,
      action.result.gam_flattened_hierarchies.map(
        (item) => item.gam_ad_unit.id,
      ),
    ),
  }))
  /**
   * Post DemandAdCreatives
   */
  .case(postDemandAdCreativesAction.done, (state, action) => {
    const id = action.params.gamAdUnitId;
    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: {
          ...state.byId[id],
          hasCreative: true,
        },
      },
    };
  });
