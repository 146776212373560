import {
  Spacer,
  Pager,
  useFilterState,
  getFilteredItems,
  ItemEmpty,
} from "ingred-ui";
import * as React from "react";

import { Helmet } from "../../../containers/Helmet";
import { Information } from "../../../domain/information";
import { patchInformationRead } from "../../../store/modules/information/actions/patchInformationReadAction";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import { PageContainer } from "../../elements/PageContainer";
import { PageContent } from "../../elements/PageContent";

import { InformationItem } from "./internal/InformationItem";
import * as Styled from "./styled";

type InjectProps = {
  informations: Information[];
  patchInformationRead: DispatchableAction<typeof patchInformationRead>;
};

type Props = {};

type InjectedProps = Props & InjectProps;

const Informations: React.FunctionComponent<InjectedProps> = ({
  informations,
  patchInformationRead,
}) => {
  const [filterState, setFilterState] = useFilterState(25);

  const filteredItems = getFilteredItems(informations, filterState);

  const handleChangePager = (index: number) => {
    setFilterState({ ...filterState, index });
  };
  return (
    <>
      <Helmet title="お知らせ | DATA STRAP" />
      <PageContainer>
        <Styled.Container>
          <PageContent title="お知らせ一覧">
            <Spacer pb={3} px={3}>
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <InformationItem
                    key={item.id}
                    item={item}
                    patchInformationRead={patchInformationRead}
                  />
                ))
              ) : (
                <ItemEmpty title="現在お知らせはありません。" />
              )}
              <Spacer pt={10} />
              <Pager
                per={filterState.per}
                total={informations.length}
                index={filterState.index}
                onClick={handleChangePager}
              />
            </Spacer>
          </PageContent>
        </Styled.Container>
      </PageContainer>
    </>
  );
};

export { Informations };
