import * as queryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../../containers/AuthedAppShell";
import {
  RouteActionContext,
  RouteActionPayload,
} from "../../../../routing/types";
import { deleteDemandAccount as deleteDemandAccountAction } from "../../../../store/modules/demandAccount/actions/deleteDemandAccountAction";
import { fetchDemandAccount as getDemandAccountAction } from "../../../../store/modules/demandAccount/actions/fetchDemandAccountAction";
import { fetchDemandAccountSettings as getDemandAccountSettingsAction } from "../../../../store/modules/demandAccount/actions/fetchDemandAccountSettingsAction";
import { fetchDemandAccounts as fetchDemandAccountsAction } from "../../../../store/modules/demandAccount/actions/fetchDemandAccountsAction";
import { patchDemandAccount as editDemandAccountAction } from "../../../../store/modules/demandAccount/actions/patchDemandAccountAction";
import { postDemandAccount as postDemandAccountAction } from "../../../../store/modules/demandAccount/actions/postDemandAccountAction";
import { getDemandAccountForms } from "../../../../store/modules/demandAccount/selectors/demandAccountFormsSelector";
import { getDemandAccounts } from "../../../../store/modules/demandAccount/selectors/demandAccountsSelector";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../../store/reducer";
import { dispatchable } from "../../../../store/utils/dispatchable";

import { Top as Component } from "./Top";

const DemandSettings = connect(
  (state: RootState) => ({
    demandAccounts: getDemandAccounts(state),
    demandForms: getDemandAccountForms(state),
    fetchDemandAccountRequesting: state.demandAccount.fetch.requesting,
    createDemandAccountRequesting: state.demandAccount.post.requesting,
    createSucceeded: state.demandAccount.post.success,
    editDemandAccountRequesting: state.demandAccount.patch.requesting,
    editSucceeded: state.demandAccount.patch.success,
    deleteDemandAccountRequesting: state.demandAccount.delete.requesting,
    deleteSucceeded: state.demandAccount.delete.success,
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    createDemandAccount: dispatchable(dispatch, postDemandAccountAction),
    fetchDemandAccount: dispatchable(dispatch, getDemandAccountAction),
    editDemandAccount: dispatchable(dispatch, editDemandAccountAction),
    deleteDemandAccount: dispatchable(dispatch, deleteDemandAccountAction),
  }),
)(Component);

export async function action({
  store,
  firstOrPush,
  history,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await Promise.all([
      store.dispatch(fetchDemandAccountsAction()),
      store.dispatch(getDemandAccountSettingsAction()),
    ]);
  }

  const params = queryString.parse(history.location.search);
  const demandAccountId = Number(params["demand_account_id"]);

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <DemandSettings demandAccountId={demandAccountId} />,
  };
}
