import { Spacer, DataTable, Typography, Badge } from "ingred-ui";
import { BadgeColor } from "ingred-ui/dist/components/Badge/Badge";
import * as React from "react";

import { Helmet } from "../../../containers/Helmet";
import { ReportStatus } from "../../../domain/reportStatus";
import { ReportStatusType } from "../../../domain/reportStatus/entity";
import { PageContent } from "../../elements/PageContent";

import * as Styled from "./styled";

type InjectProps = {
  reportStatuses: ReportStatus[];
};

type Props = {};

type InjectedProps = Props & InjectProps;

const ReportStatuses: React.FunctionComponent<InjectedProps> = ({
  reportStatuses,
}) => {
  const [searchText, setSearchText] = React.useState("");

  const getRowBadgeColor = (status: ReportStatusType): BadgeColor => {
    switch (status) {
      case "finished":
        return "success";
      case "unfinished":
        return "secondary";
      case "auth_error":
        return "danger";
    }
  };

  const getStatusText = (status: ReportStatusType): string => {
    switch (status) {
      case "finished":
        return "完了";
      case "unfinished":
        return "未完了";
      case "auth_error":
        return "認証エラー";
    }
  };

  const searchedItems = React.useMemo(
    () =>
      reportStatuses.filter((item) =>
        item.demand.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [reportStatuses, searchText],
  );
  return (
    <>
      <Helmet title="レポート取得状況 | DATA STRAP" />
      <Styled.Container>
        <Spacer p={3}>
          <PageContent
            title="本日のレポート取得状況一覧"
            placeholder="デマンドで検索"
            onChange={setSearchText} // eslint-disable-line react/jsx-handler-names
          >
            <Spacer py={2} px={3}>
              <Styled.NoticeBox>
                <table>
                  <tbody>
                    <tr>
                      <Styled.Th>
                        <Spacer py={0.5}>
                          <Typography size="xxl" weight="bold">
                            <Badge type="pill" fontSize="12px" color="success">
                              完了
                            </Badge>
                          </Typography>
                        </Spacer>
                      </Styled.Th>
                      <Styled.Td>
                        <Typography size="sm">
                          レポート取得が完了している状態を示します。
                        </Typography>
                      </Styled.Td>
                    </tr>
                    <tr>
                      <Styled.Th>
                        <Typography size="xxl" weight="bold">
                          <Badge type="pill" fontSize="12px" color="secondary">
                            未完了
                          </Badge>
                        </Typography>
                      </Styled.Th>
                      <Styled.Td>
                        <Typography size="sm">
                          レポート取得完了以外の状態を示します。
                        </Typography>
                      </Styled.Td>
                    </tr>
                    <tr>
                      <Styled.Th>
                        <Typography size="xxl" weight="bold">
                          <Badge type="pill" fontSize="12px" color="danger">
                            認証エラー
                          </Badge>
                        </Typography>
                      </Styled.Th>
                      <Styled.Td>
                        <Typography size="sm">
                          認証情報の有効期限が切れているか、誤った認証情報が入力されている状態を示します。
                        </Typography>
                      </Styled.Td>
                    </tr>
                  </tbody>
                </table>
                <Spacer pt={1} />
                <Typography size="sm" color="secondary">
                  ※ステータスは毎日午前0時0分にリセットされます。
                </Typography>
                <Spacer pt={0.5} />
                <Typography size="sm" color="secondary">
                  ※レポート取得は毎日行われますが、デマンドにより取得タイミングが異なります。
                </Typography>
              </Styled.NoticeBox>
              <Spacer pt={2} />
              <DataTable
                enablePagination={true}
                data={searchedItems}
                dataKey={"id"}
                columns={[
                  {
                    name: "デマンドアカウントID",
                    selector: (row) => row.id,
                    width: "200px",
                    sortable: true,
                  },
                  {
                    name: "デマンド",
                    selector: (row) => row.demand.name,
                    sortable: true,
                  },
                  {
                    name: "ステータス",
                    selector: (row) => `${row.status}`,
                    sortable: true,
                    width: "200px",
                    renderCell: (row) => (
                      <Typography size="xxl" weight="bold">
                        <Badge
                          type="pill"
                          fontSize="12px"
                          color={getRowBadgeColor(row.status)}
                        >
                          {getStatusText(row.status)}
                        </Badge>
                      </Typography>
                    ),
                  },
                ]}
              />
            </Spacer>
          </PageContent>
        </Spacer>
      </Styled.Container>
    </>
  );
};

export { ReportStatuses };
