import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { RouteActionContext, RouteActionPayload } from "../../../routing/types";
import { fetchBidStrapGamAdUnits } from "../../../store/modules/bidStrapGamAdUnit/actions/fetchBidStrapGamAdUnitsAction";
import { getBidStrapGamAdUnits } from "../../../store/modules/bidStrapGamAdUnit/selectors/bidStrapGamAdUnitSelector";
import { fetchBidStrapSites } from "../../../store/modules/bidStrapSite/actions/fetchBidStrapSitesAction";
import { getBidStrapSites } from "../../../store/modules/bidStrapSite/selectors/bidStrapSiteSelector";
import { push as pushAction } from "../../../store/modules/routing/actions/PushAction";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { BidStrapDashBoard as Component } from "./BidStrapDashBoard";

const BidStrapDashBoard = connect(
  (state: RootState) => ({
    currentCompany: state.user.currentCompany as CompanyAttribute,
    sites: getBidStrapSites(state),
    gamAdUnits: getBidStrapGamAdUnits(state),
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    push: dispatchable(dispatch, pushAction),
  }),
)(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await Promise.all([
      store.dispatch(fetchBidStrapGamAdUnits()),
      store.dispatch(fetchBidStrapSites()),
    ]);
  }
  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <BidStrapDashBoard />,
  };
}
