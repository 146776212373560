import { DemandAccountForCsvImport } from "../../../../../../api-client";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

export type fetchCSVImportableDemandAccountsParams = void;
export type fetchCSVImportableDemandAccountsSuccess = DemandAccountForCsvImport[];
export type fetchCSVImportableDemandAccountsFailure = Error;

export const fetchCSVImportableDemandAccountsAction = actionCreator.async<
  fetchCSVImportableDemandAccountsParams,
  fetchCSVImportableDemandAccountsSuccess,
  fetchCSVImportableDemandAccountsFailure
>("FETCH_CSV_IMPORTABLE_DEMAND");

export function fetchCSVImportableDemandAccounts(): ThunkAction<
  Promise<void>,
  any
> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchCSVImportableDemandAccountsAction.started());

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsCsvImportGetDemandAccounts(
        currentCompany.company.id,
      );
      dispatch(fetchCSVImportableDemandAccountsAction.done({ result: data }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchCSVImportableDemandAccountsAction.failed({ error }));
      }
    }
  };
}
