import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";

import { config } from "../config";

const initOptions = {
  domain: config.AUTH0_DOMAIN,
  client_id: config.AUTH0_CLIENT_ID,
  redirect_uri: window.location.origin,
  audience: `https://${config.AUTH0_DOMAIN}/api/v2/`,
};

let auth0Client: Auth0Client;
export const getAuth0Client = async () => {
  if (!auth0Client) {
    auth0Client = await createAuth0Client(initOptions);
  }
  return auth0Client;
};
