import { Middleware } from "redux";

import { notifyError } from "../../../../utils/ErrorReportingUtils";
import { HttpStatusCode } from "../../../../utils/StatusCodeUtils";
import { RootState } from "../../../reducer";
import { NetworkStatus } from "../../network/constants";
import { enqueueSystemNotification } from "../actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../constants";

function showErrors(statusCode: number | null): boolean {
  switch (statusCode) {
    case HttpStatusCode.FORBIDDEN:
    case HttpStatusCode.NOT_FOUND:
      return false;
    default:
      return true;
  }
}

export function createErrorsMiddleware(): Middleware<{}, RootState> {
  return (store) => (next) => (action) => {
    if (
      action.error === true &&
      action.meta == null &&
      action.payload != null
    ) {
      const status = action.payload.status;
      if (showErrors(status)) {
        const errors = action.payload.data?.errors || [];
        if (errors.length > 0) {
          for (const error of errors) {
            notifyError(error.message);
            store.dispatch(
              enqueueSystemNotification({
                ...error,
                variant: SystemNotificationVariant.ERROR,
              }),
            );
          }
        } else {
          if (store.getState().network.status === NetworkStatus.OFFLINE) {
            store.dispatch(
              enqueueSystemNotification({
                message: "ネットワークに接続されていません",
                variant: SystemNotificationVariant.ERROR,
              }),
            );
          } else {
            store.dispatch(
              enqueueSystemNotification({
                message: "サーバーでエラーが発生しました",
                variant: SystemNotificationVariant.ERROR,
              }),
            );
          }
        }
      }
    }

    return next(action);
  };
}
