import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchDashboard
 */
export type FetchDashboardParams = void;
export type FetchDashboardSuccess = Response.Dashboard.Fetch;
export type FetchDashboardFailure = Error;

export const fetchDashboardAction = actionCreator.async<
  FetchDashboardParams,
  FetchDashboardSuccess,
  FetchDashboardFailure
>("FETCH_DASHBOARD");

export function fetchDashboard(
  payload: Request.Dashboard.Fetch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { dashboard, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchDashboardAction.started());

    try {
      const res = await dashboard.fetchDashboard(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(fetchDashboardAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchDashboardAction.failed({ error }));
      }
    }
  };
}
