import { connect } from "react-redux";

import { fetchUnmappedGamFlattenedHierarchies as fetchUnmappedGamFlattenedHierarchiesAction } from "../../store/modules/gamFlattenedHierarchy/actions/fetchUnmappedGamFlattenedHierarchiesAction";
import { getGamFlattenedHierarchys } from "../../store/modules/gamFlattenedHierarchy/selectors/gamFlattenedHierarchySelector";
import { fetchInformations as fetchInformationsAction } from "../../store/modules/information/actions/fetchInformationsAction";
import { getInformations } from "../../store/modules/information/selectors/informationSelector";
import { RootState } from "../../store/reducer";
import { dispatchable } from "../../store/utils/dispatchable";

import { AuthedAppShell as Component } from "./AuthedAppShell";

export const AuthedAppShell = connect(
  (state: RootState) => ({
    currentCompany: state.user.currentCompany,
    gamFlattenedHierarchies: getGamFlattenedHierarchys(state),
    informations: getInformations(state),
    routing: state.routing,
  }),
  (dispatch) => ({
    fetchUnmapped: dispatchable(
      dispatch,
      fetchUnmappedGamFlattenedHierarchiesAction,
    ),
    fetchInformations: dispatchable(dispatch, fetchInformationsAction),
  }),
)(Component);
