import { reducerWithInitialState } from "typescript-fsa-reducers";

import { deleteCategoryAction } from "../actions/deleteCategoryAction";
import { deleteDemandAdCreatives2Action } from "../actions/deleteDemandAdCreatives2Action";
import { deleteDemandAdCreativesAction } from "../actions/deleteDemandAdCreativesAction";
import { fetchCategoriesAction } from "../actions/fetchCategoriesAction";
import { patchCategoryAction } from "../actions/patchCategoryAction";
import { patchDemandAdCreatives2Action } from "../actions/patchDemandAdCreatives2Action";
import { patchDemandAdCreativesAction } from "../actions/patchDemandAdCreativesAction";
import { postCategoryAction } from "../actions/postCategoryAction";

export type CategoryState = {
  fetchAll: {
    requesting: boolean;
  };
  post: {
    requesting: boolean;
    success: boolean;
  };
  patch: {
    requesting: boolean;
    success: boolean;
  };
  delete: {
    requesting: boolean;
    success: boolean;
  };
  creatives: {
    patch: {
      requesting: boolean;
      success: boolean;
    };
    delete: {
      requesting: boolean;
      success: boolean;
    };
  };
  creatives2: {
    patch: {
      requesting: boolean;
      success: boolean;
    };
    delete: {
      requesting: boolean;
      success: boolean;
    };
  };
};

export const initialState: CategoryState = {
  fetchAll: {
    requesting: false,
  },
  post: {
    requesting: false,
    success: false,
  },
  patch: {
    requesting: false,
    success: false,
  },
  delete: {
    requesting: false,
    success: false,
  },
  creatives: {
    patch: {
      requesting: false,
      success: false,
    },
    delete: {
      requesting: false,
      success: false,
    },
  },
  creatives2: {
    patch: {
      requesting: false,
      success: false,
    },
    delete: {
      requesting: false,
      success: false,
    },
  },
};

export const categoryReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All Category
   */
  .case(fetchCategoriesAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchCategoriesAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchCategoriesAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  /**
   * Post Category
   */
  .case(postCategoryAction.started, (state) => ({
    ...state,
    post: {
      requesting: true,
      success: false,
    },
  }))
  .case(postCategoryAction.done, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: true,
    },
  }))
  .case(postCategoryAction.failed, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Patch DemandAccount
   */
  .case(patchCategoryAction.started, (state) => ({
    ...state,
    patch: {
      requesting: true,
      success: false,
    },
  }))
  .case(patchCategoryAction.done, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: true,
    },
  }))
  .case(patchCategoryAction.failed, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Delete Category
   */
  .case(deleteCategoryAction.started, (state) => ({
    ...state,
    delete: {
      requesting: true,
      success: false,
    },
  }))
  .case(deleteCategoryAction.done, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: true,
    },
  }))
  .case(deleteCategoryAction.failed, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * クリエイティブのカテゴリ紐付け
   */
  .case(patchDemandAdCreativesAction.started, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      patch: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(patchDemandAdCreativesAction.done, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      patch: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(patchDemandAdCreativesAction.failed, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      patch: {
        requesting: false,
        success: false,
      },
    },
  }))
  /**
   * クリエイティブのカテゴリ紐付け解除
   */
  .case(deleteDemandAdCreativesAction.started, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      delete: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(deleteDemandAdCreativesAction.done, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      delete: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(deleteDemandAdCreativesAction.failed, (state) => ({
    ...state,
    creatives: {
      ...state.creatives,
      delete: {
        requesting: false,
        success: false,
      },
    },
  }))
  /**
   * クリエイティブのカテゴリ2紐付け
   */
  .case(patchDemandAdCreatives2Action.started, (state) => ({
    ...state,
    creatives2: {
      ...state.creatives2,
      patch: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(patchDemandAdCreatives2Action.done, (state) => ({
    ...state,
    creatives2: {
      ...state.creatives2,
      patch: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(patchDemandAdCreatives2Action.failed, (state) => ({
    ...state,
    creatives2: {
      ...state.creatives2,
      patch: {
        requesting: false,
        success: false,
      },
    },
  }))
  /**
   * クリエイティブのカテゴリ2紐付け解除
   */
  .case(deleteDemandAdCreatives2Action.started, (state) => ({
    ...state,
    creatives2: {
      ...state.creatives2,
      delete: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(deleteDemandAdCreatives2Action.done, (state) => ({
    ...state,
    creatives2: {
      ...state.creatives2,
      delete: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(deleteDemandAdCreatives2Action.failed, (state) => ({
    ...state,
    creatives2: {
      ...state.creatives2,
      delete: {
        requesting: false,
        success: false,
      },
    },
  }));
