import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchBidStrapSites
 */
export type FetchBidStrapSitesParams = void;
export type FetchBidStrapSitesSuccess = Response.Site.FetchAll;
export type FetchBidStrapSitesFailure = Error;

export const fetchBidStrapSitesAction = actionCreator.async<
  FetchBidStrapSitesParams,
  FetchBidStrapSitesSuccess,
  FetchBidStrapSitesFailure
>("FETCH_BID_STRAP_SITES");

export function fetchBidStrapSites(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { site, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchBidStrapSitesAction.started());

    try {
      const res = await site.fetchBidStrapSites(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchBidStrapSitesAction.done({ result: res }));
    } catch (err) {
      dispatch(fetchBidStrapSitesAction.failed(err));
    }
  };
}
