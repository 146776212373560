import { Domain, Request } from "api-types";
import dayjs from "dayjs";
import {
  Button,
  Card,
  Divider,
  DropdownButton,
  ErrorText,
  FixedPanel,
  Flex,
  Icon,
  DatePicker,
  DateRangePicker,
  Portal,
  Select,
  Spacer,
  Spinner,
  Typography,
  useTheme,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import React, { useEffect, useMemo, useRef, useState } from "react";

import Dstnote from "../../../../assets/images/dstnote.svg";
import { Helmet } from "../../../containers/Helmet";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { DashboardItem } from "../../../domain/dashboardItem";
import { DemandAdCreativeGroup } from "../../../domain/demandAdCreativeGroup";
import { SavedQuery } from "../../../domain/savedQuery";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import { deleteDashboardItem } from "../../../store/modules/dashboardItem/actions/deleteDashboardItemAction";
import { fetchDashboardItems } from "../../../store/modules/dashboardItem/actions/fetchDashboardItemsAction";
import { patchDashboardItem } from "../../../store/modules/dashboardItem/actions/patchDashboardItemAction";
import { postDashboardItem } from "../../../store/modules/dashboardItem/actions/postDashboardItemAction";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import {
  displayPeriodOptions,
  getDateRange,
} from "../../../utils/DateRangeUtils";
import { Link } from "../../atoms/Link";
import { PageContainer } from "../../elements/PageContainer";
import { Popover } from "../../elements/Popover";

import { DashboardItemCard } from "./internal/DashboardItemCard";
import { DeleteItemModal } from "./internal/DeleteItemModal";
import { EditItemModal } from "./internal/EditItemModal";
import { PostItemModal } from "./internal/PostItemModal";
import * as Styled from "./styled";

type InjectProps = {
  currentCompany: CompanyAttribute;
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  dashboardItems: DashboardItem[];
  savedQueries: SavedQuery[];
  fetchItemsRequesting: boolean;
  postItemsRequesting: boolean;
  postItemsSucceeded: boolean;
  patchItemsRequesting: boolean;
  patchItemsSucceeded: boolean;
  deleteItemsRequesting: boolean;
  deleteItemsSucceeded: boolean;
  fetchDashboardItems: DispatchableAction<typeof fetchDashboardItems>;
  postDashboardItem: DispatchableAction<typeof postDashboardItem>;
  patchDashboardItem: DispatchableAction<typeof patchDashboardItem>;
  deleteDashboardItem: DispatchableAction<typeof deleteDashboardItem>;
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
};

type Props = {};

type InjectedProps = Props & InjectProps;

function getErrorMessage(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): string {
  // 期間が指定されているときは、start, end 両方とも指定する必要がある
  if (!startDate || !endDate) {
    return "表示期間が指定されていません";
  }
  // クライアントで処理するデータ量が大きくならないように指定する
  if (endDate.diff(startDate, "d") > 365 * 2) {
    return "表示期間は最大2年間までです";
  }
  return "";
}

export const CustomDashboard: React.FC<InjectedProps> = ({
  currentCompany,
  demandAdCreativeGroups,
  dashboardItems,
  savedQueries,
  fetchItemsRequesting,
  enqueueSystemNotification,
  postDashboardItem,
  postItemsRequesting,
  postItemsSucceeded,
  deleteDashboardItem,
  deleteItemsRequesting,
  deleteItemsSucceeded,
  patchDashboardItem,
  patchItemsRequesting,
  patchItemsSucceeded,
}) => {
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const showPanel = !useIntersectionObserver(menuContainerRef);
  const media = window.matchMedia("(max-width: 1384px)");
  const theme = useTheme();
  const options = demandAdCreativeGroups.map((demandAdCreativeGroup) => ({
    label: demandAdCreativeGroup.name,
    value: demandAdCreativeGroup.id.toString(),
  }));
  const [selectedGroups, setSelectedGroups] = useState<OptionType[]>([]);
  const [displayPeriod, setDisplayPeriod] = useState<
    OptionType<Domain.DateRangeType>
  >(displayPeriodOptions[0]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [errorText, setErrorText] = useState("");
  const [
    additionalFilter,
    setAdditionalFilter,
  ] = useState<Domain.AdditionalFilter | null>(null);
  const [deleteToasted, setDeleteToasted] = useState<boolean>(
    deleteItemsSucceeded,
  );
  const [deleteItemModalId, setDeleteItemModalId] = useState<number | null>(
    null,
  );
  const [patchToasted, setPatchToasted] = useState<boolean>(
    patchItemsSucceeded,
  );
  const [patchItemModalId, setPatchItemModalId] = useState<number | null>(null);
  const [postToasted, setPostToasted] = useState<boolean>(postItemsSucceeded);
  const [postItemModalIsOpen, setPostItemModalIsOpen] = useState<boolean>(
    false,
  );
  const [bannerActive, setBannerActive] = useState<boolean>(!media.matches);

  media.addEventListener("change", (event) => {
    setBannerActive(!event.matches);
  });

  const operations = [
    {
      text: "アイテムを追加",
      onClick: () => {
        setPostItemModalIsOpen(true);
      },
    },
  ];

  useEffect(() => {
    if (!postToasted && postItemsSucceeded) {
      setPostItemModalIsOpen(false);
      enqueueSystemNotification({
        message: "ダッシュボードアイテムの追加が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setPostToasted(true);
    }
  }, [enqueueSystemNotification, postToasted, postItemsSucceeded]);

  useEffect(() => {
    if (!patchToasted && patchItemsSucceeded) {
      setPatchItemModalId(null);
      enqueueSystemNotification({
        message: "ダッシュボードアイテムの編集が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setPatchToasted(true);
    }
  }, [enqueueSystemNotification, patchToasted, patchItemsSucceeded]);

  useEffect(() => {
    if (!deleteToasted && deleteItemsSucceeded) {
      setDeleteItemModalId(null);
      enqueueSystemNotification({
        message: "ダッシュボードアイテムの削除が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setDeleteToasted(true);
    }
  }, [enqueueSystemNotification, deleteToasted, deleteItemsSucceeded]);

  const handleClickAddItemButton = () => {
    setPostItemModalIsOpen(true);
  };

  const handleSelectChange = (value: any) => {
    setIsButtonDisabled(false);
    if (value == null) {
      setSelectedGroups([]);
    } else {
      setSelectedGroups(value);
    }
  };
  const handleSubmit = () => {
    const message = getErrorMessage(startDate, endDate);
    if (message !== "") {
      setErrorText(message);
      return;
    }
    setErrorText("");
    setIsButtonDisabled(true);
    const params: Domain.AdditionalFilter = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      start_date: startDate?.format("YYYY-MM-DD"),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      end_date: endDate?.format("YYYY-MM-DD"),
      site_ids: selectedGroups.map((option) => parseInt(option.value, 10)),
    };
    setAdditionalFilter(params);
  };
  const handleDisplayPeriodChange = (option: any) => {
    if (option) {
      setIsButtonDisabled(false);
      setDisplayPeriod(option);
      if (option.value === "custom_range") {
        return;
      }
      if (option.value === "custom_single") {
        setStartDate(dayjs().add(-1, "day"));
        return;
      }
      const [startDate, endDate] = getDateRange(option.value);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };
  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    setIsButtonDisabled(false);
    setStartDate(startDate);
    setEndDate(endDate);
    setDisplayPeriod({
      label: "カスタム(期間)",
      value: "custom_range",
    });
  };
  const handleDateChange = (date: dayjs.Dayjs) => {
    setStartDate(date);
    setEndDate(date);
  };

  const handlePatchItemSubmit = (data: Request.DashboardItem.Patch) => {
    patchDashboardItem(data);
    setPatchToasted(false);
  };

  const handlePatchItemModalId = (itemId: number | null) => () => {
    setPatchItemModalId(itemId);
  };

  const handleDeleteItemSubmit = (data: Request.DashboardItem.Delete) => {
    deleteDashboardItem(data);
    setDeleteToasted(false);
  };

  const handleDeleteItemModalId = (itemId: number | null) => () => {
    setDeleteItemModalId(itemId);
  };

  const handlePostItemModalClose = () => {
    setPostItemModalIsOpen(false);
  };

  const handlePostItemSubmit = (data: Request.DashboardItem.Post) => {
    postDashboardItem(data);
    setPostToasted(false);
  };
  const actions = useMemo(
    () =>
      displayPeriodOptions.map((option) => ({
        text: option.label,
        onClick: () => {
          handleDisplayPeriodChange(option);
        },
      })),
    [],
  );
  return (
    <>
      <Helmet title="ダッシュボード | DATA STRAP" />
      <Portal>
        <FixedPanel isOpen={showPanel}>
          <Spacer py={2} px={3}>
            <Flex
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <div>
                <Flex display="flex" alignItems="flex-start">
                  <Typography color="secondary" size="sm" weight="bold">
                    サイトで絞り込み
                  </Typography>
                  <Spacer ml={1}>
                    <Popover>
                      <Typography size="sm" lineHeight="1.7">
                        指定したサイトと紐付いているクリエイティブのみがサイトとして選択できます。
                        サイトとの紐付けは「
                        <Link
                          href={`/company/${currentCompany.company.id}/settings/demand_ad_creatives_manager`}
                        >
                          設定＞デマンド広告クリエイティブ管理
                        </Link>
                        」から行えます。
                      </Typography>
                    </Popover>
                  </Spacer>
                </Flex>
                <Spacer pb={1} />
                <Styled.InputContainer>
                  <Select
                    isMulti={true}
                    options={options}
                    value={selectedGroups}
                    placeholder="すべて"
                    onChange={handleSelectChange}
                  />
                </Styled.InputContainer>
              </div>
              <Spacer pr={2} />
              <div>
                <Flex display="flex" alignItems="flex-end">
                  <div>
                    <Typography color="secondary" size="sm" weight="bold">
                      表示期間
                    </Typography>
                    <Spacer pb={1} />
                    {displayPeriod?.value === "custom_single" ? (
                      <DatePicker
                        date={startDate}
                        isOutsideRange={(day: dayjs.Dayjs) =>
                          day.isAfter(dayjs())
                        }
                        defaultClickAction={displayPeriod.label}
                        actions={actions}
                        onDateChange={handleDateChange}
                      />
                    ) : (
                      <DateRangePicker
                        errorText={errorText}
                        startDate={startDate}
                        endDate={endDate}
                        isOutsideRange={(day: dayjs.Dayjs) =>
                          day.isAfter(dayjs())
                        }
                        defaultClickAction={displayPeriod.label}
                        actions={actions}
                        onDatesChange={handleDatesChange}
                      />
                    )}
                  </div>
                  <Spacer pr={2} />
                  <Flex display="flex">
                    <Button
                      disabled={isButtonDisabled}
                      inline={true}
                      color="secondary"
                      size="medium"
                      className="gaev-dashboard-btn_apply"
                      onClick={handleSubmit}
                    >
                      適用
                    </Button>
                    <Spacer pr={2} />
                    <Divider orientation="vertical" />
                  </Flex>
                  <Spacer pr={2} />
                  <Flex
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Typography color="secondary" size="sm" weight="bold">
                      ダッシュボード
                    </Typography>
                    <Spacer pb={1} />
                    <DropdownButton color="secondary" contents={operations}>
                      編集
                    </DropdownButton>
                  </Flex>
                </Flex>
                {!!errorText && (
                  <Spacer
                    pt={1}
                    className="gaev-dashboard-error_setting_viewperiod"
                  >
                    <ErrorText>{errorText}</ErrorText>
                  </Spacer>
                )}
              </div>
            </Flex>
          </Spacer>
        </FixedPanel>
      </Portal>
      <PageContainer>
        <Flex display="flex" justifyContent="space-between">
          <Card p={3}>
            <Flex
              ref={menuContainerRef}
              display="flex"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <div>
                <Flex display="flex" alignItems="flex-start" flexWrap="wrap">
                  <Typography color="secondary" size="sm" weight="bold">
                    サイトで絞り込み
                  </Typography>
                  <Spacer ml={1}>
                    <Popover>
                      <Typography size="sm" lineHeight="1.7">
                        指定したサイトと紐付いているクリエイティブのみがサイトとして選択できます。
                        サイトとの紐付けは「
                        <Link
                          href={`/company/${currentCompany.company.id}/settings/demand_ad_creatives_manager`}
                        >
                          設定＞デマンド広告クリエイティブ管理
                        </Link>
                        」から行えます。
                      </Typography>
                    </Popover>
                  </Spacer>
                </Flex>
                <Spacer pb={1} />
                <Styled.InputContainer>
                  <Select
                    isMulti={true}
                    options={options}
                    value={selectedGroups}
                    placeholder="すべて"
                    onChange={handleSelectChange}
                  />
                </Styled.InputContainer>
              </div>
              <Spacer pr={2} />
              <div>
                <Flex display="flex" alignItems="flex-end" flexWrap="wrap">
                  <div>
                    <Typography color="secondary" size="sm" weight="bold">
                      表示期間
                    </Typography>
                    <Spacer pb={1} />
                    {displayPeriod?.value === "custom_single" ? (
                      <DatePicker
                        date={startDate}
                        isOutsideRange={(day: dayjs.Dayjs) =>
                          day.isAfter(dayjs())
                        }
                        defaultClickAction={displayPeriod.label}
                        actions={actions}
                        onDateChange={handleDateChange}
                      />
                    ) : (
                      <DateRangePicker
                        errorText={errorText}
                        startDate={startDate}
                        endDate={endDate}
                        isOutsideRange={(day: dayjs.Dayjs) =>
                          day.isAfter(dayjs())
                        }
                        defaultClickAction={displayPeriod.label}
                        actions={actions}
                        onDatesChange={handleDatesChange}
                      />
                    )}
                  </div>
                  <Spacer pr={2} />
                  <Flex display="flex">
                    <Button
                      disabled={isButtonDisabled}
                      inline={true}
                      color="secondary"
                      size="medium"
                      className="gaev-dashboard-btn_apply"
                      onClick={handleSubmit}
                    >
                      適用
                    </Button>
                    <Spacer pr={2} />
                    <Divider orientation="vertical" />
                  </Flex>
                  <Spacer pr={2} />
                  <Flex
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Typography color="secondary" size="sm" weight="bold">
                      ダッシュボード
                    </Typography>
                    <Spacer pb={1} />
                    <DropdownButton
                      color="secondary"
                      size="medium"
                      contents={operations}
                    >
                      編集
                    </DropdownButton>
                  </Flex>
                </Flex>
                {!!errorText && (
                  <Spacer
                    pt={1}
                    className="gaev-dashboard-error_setting_viewperiod"
                  >
                    <ErrorText>{errorText}</ErrorText>
                  </Spacer>
                )}
              </div>
            </Flex>
          </Card>
          {bannerActive && (
            <>
              <Spacer pr={3} />
              <Styled.NoteBanner
                href="https://note.com/data_strap"
                target="_blank"
              >
                <Flex display="flex" height="100%" alignItems="center">
                  <Dstnote />
                </Flex>
              </Styled.NoteBanner>
            </>
          )}
        </Flex>
        <Spacer pt={3} />
        <Styled.ItemContainer>
          {dashboardItems.map((item) => (
            <React.Fragment key={item.id}>
              <DashboardItemCard
                key={item.id}
                item={item}
                filter={additionalFilter}
                companyId={currentCompany.company.id}
                onDelete={handleDeleteItemModalId}
                onEdit={handlePatchItemModalId}
              />
              <DeleteItemModal
                isOpen={deleteItemModalId === item.id}
                loading={deleteItemsRequesting}
                dashboardItem={item}
                onClose={handleDeleteItemModalId(null)}
                onSubmit={handleDeleteItemSubmit}
              />
              <EditItemModal
                isOpen={patchItemModalId === item.id}
                loading={patchItemsRequesting}
                savedQueries={savedQueries}
                dashboardItem={item}
                onClose={handlePatchItemModalId(null)}
                onSubmit={handlePatchItemSubmit}
              />
            </React.Fragment>
          ))}
          <Styled.ButtonContainer>
            <Styled.AddItemButton onClick={handleClickAddItemButton}>
              <Styled.IconContainer>
                <Icon
                  name="bar_chart"
                  size="lg"
                  color={theme.palette.gray.deepDark}
                />
              </Styled.IconContainer>
              <Spacer pb={2} />
              <Typography color={theme.palette.gray.deepDark}>
                ここをクリックしてダッシュボードにアイテムを追加
              </Typography>
            </Styled.AddItemButton>
          </Styled.ButtonContainer>
        </Styled.ItemContainer>
      </PageContainer>
      <PostItemModal
        isOpen={postItemModalIsOpen}
        loading={postItemsRequesting}
        savedQueries={savedQueries}
        onClose={handlePostItemModalClose}
        onSubmit={handlePostItemSubmit}
      />
      {fetchItemsRequesting && (
        <Styled.LoadingContainer>
          <Spinner />
        </Styled.LoadingContainer>
      )}
    </>
  );
};
