import styled from "styled-components";

import { Color } from "../../../styles/variables";

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 24px; /* iconの高さに合わせる */
  a {
    text-decoration: none;
    color: ${Color.text.secondary};
  }
  a:hover {
    text-decoration: underline;
    text-decoration-color: ${Color.text.secondary};
  }
`;
