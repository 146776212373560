import { Domain } from "api-types";
import dayjs from "dayjs";
import {
  Button,
  Card,
  ErrorText,
  Flex,
  DatePicker,
  DateRangePicker,
  OptionType,
  Select,
  Spacer,
  Typography,
} from "ingred-ui";
import React, { useMemo, useState } from "react";

import { Helmet } from "../../../containers/Helmet";
import { BidStrapGamAdUnit } from "../../../domain/bidStrapGamAdUnit";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { Site } from "../../../domain/site";
import { SearchBidStrapReportParams } from "../../../infra/bidStrapReport/bidStrapReportClient";
import { push } from "../../../store/modules/routing/actions/PushAction";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import {
  displayPeriodOptions,
  getDateRange,
  getDateRangeErrorMessage,
} from "../../../utils/DateRangeUtils";
import { PageContainer } from "../../elements/PageContainer";

import { BidStrapGraphCard } from "./internal/BidStrapGraphCard";
import { BidStrapUnitCompareCard } from "./internal/BidStrapUnitCompareCard";
import * as Styled from "./styled";

type InjectProps = {
  sites: Site[];
  gamAdUnits: BidStrapGamAdUnit[];
  push: DispatchableAction<typeof push>;
  currentCompany: CompanyAttribute;
};

type Props = {};

type InjectedProps = Props & InjectProps;

const BidStrapDashBoard: React.FunctionComponent<InjectedProps> = ({
  sites,
  gamAdUnits,
  currentCompany,
  push,
}) => {
  const INITIAL_DISPLAY_PERIOD = getDateRange("last_30_days");
  const siteOptions = sites.map((site) => ({
    label: site.name,
    value: site.id.toString(),
  }));
  const gamAdUnitOptions = gamAdUnits.map((gamAdUnit) => ({
    label: gamAdUnit.original_ad_unit_name,
    value: gamAdUnit.id.toString(),
  }));
  const [selectedSites, setSelectedSites] = React.useState<number[]>([]);
  const [selectedGamAdUnits, setSelectedGamAdUnits] = React.useState<number[]>(
    [],
  );
  const [displayPeriod, setDisplayPeriod] = React.useState<
    OptionType<Domain.DateRangeType>
  >(displayPeriodOptions[2]); // last_30_days
  const [beginDate, setBeginDate] = React.useState<dayjs.Dayjs>(
    INITIAL_DISPLAY_PERIOD[0],
  );
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(
    INITIAL_DISPLAY_PERIOD[1],
  );
  const [displayInterval, setDisplayInterval] = React.useState<
    OptionType<Domain.GroupType>
  >({
    label: "日別",
    value: "day",
  });
  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);
  const [errorText, setErrorText] = React.useState("");
  const [fetchLineParams1, setFetchLineParams1] = useState<
    SearchBidStrapReportParams
  >({
    begin_date: INITIAL_DISPLAY_PERIOD[0].format("YYYY-MM-DD"),
    end_date: INITIAL_DISPLAY_PERIOD[1].format("YYYY-MM-DD"),
    group_type_name: displayInterval.value,
    dimension_names: ["bidder"],
    indicator_names: ["demand_price"],
    filters: {},
  });
  const [fetchLineParams2, setFetchLineParams2] = useState<
    SearchBidStrapReportParams
  >({
    begin_date: INITIAL_DISPLAY_PERIOD[0].format("YYYY-MM-DD"),
    end_date: INITIAL_DISPLAY_PERIOD[1].format("YYYY-MM-DD"),
    group_type_name: displayInterval.value,
    dimension_names: ["bidder"],
    indicator_names: ["avg_cpm"],
    filters: {},
  });
  const [fetchBarParams, setFetchBarParams] = useState<
    SearchBidStrapReportParams
  >({
    begin_date: INITIAL_DISPLAY_PERIOD[0].format("YYYY-MM-DD"),
    end_date: INITIAL_DISPLAY_PERIOD[1].format("YYYY-MM-DD"),
    group_type_name: "summary",
    dimension_names: ["bidder"],
    indicator_names: [
      "auction_count",
      "valid_bid_count",
      "prebid_win_count",
      "bid_win_count",
      "demand_imps",
    ],
    filters: {},
  });

  const displayIntervalOptions: OptionType<Domain.GroupType>[] = [
    {
      label: "日別",
      value: "day",
    },
    {
      label: "月別",
      value: "month",
    },
  ];

  // 線グラフを使用する指標
  const lineGraphGroup = [
    "prebid_win_rate",
    "bid_win_rate",
    "avg_cpm",
    "prebid_win_avg_cpm",
    "gam_win_avg_cpm",
  ];

  const handleDisplayIntervalChange = (option: any) => {
    setIsButtonDisabled(false);
    setDisplayInterval(option);
  };

  const handleDisplayPeriodChange = (option: any) => {
    if (option) {
      setIsButtonDisabled(false);
      setDisplayPeriod(option);
      if (option.value === "custom_range") {
        return;
      }
      if (option.value === "custom_single") {
        setBeginDate(dayjs().add(-1, "day"));
        return;
      }
      const [begin, end] = getDateRange(option.value);
      if (begin !== null && end !== null) {
        setBeginDate(begin);
        setEndDate(end);
      }
    }
  };

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    if (startDate !== null && endDate !== null) {
      setBeginDate(startDate);
      setEndDate(endDate);
      setIsButtonDisabled(false);
      setDisplayPeriod({
        label: "カスタム(期間)",
        value: "custom_range",
      });
    }
  };

  const handleDateChange = (date: dayjs.Dayjs) => {
    if (date !== null) {
      setBeginDate(date);
      setEndDate(date);
    }
  };

  const handleSiteChange = (value: any) => {
    setIsButtonDisabled(false);
    if (value == null) {
      setSelectedSites([]);
    } else {
      const options = value as OptionType[];
      setSelectedSites(options.map((option) => parseInt(option.value, 10)));
    }
  };

  const handleGamAdUnitChange = (value: any) => {
    setIsButtonDisabled(false);
    if (value == null) {
      setSelectedGamAdUnits([]);
    } else {
      const options = value as OptionType[];
      setSelectedGamAdUnits(
        options.map((option) => parseInt(option.value, 10)),
      );
    }
  };

  const handleSubmit = () => {
    const message = getDateRangeErrorMessage(
      beginDate,
      endDate,
      displayInterval.value,
    );
    if (message !== "") {
      setErrorText(message);
      return;
    }
    if (!beginDate || !endDate) {
      return;
    }

    const lineParams1Copy = JSON.parse(
      JSON.stringify(fetchLineParams1),
    ) as SearchBidStrapReportParams;
    const lineParams2Copy = JSON.parse(
      JSON.stringify(fetchLineParams2),
    ) as SearchBidStrapReportParams;
    const barParamsCopy = JSON.parse(
      JSON.stringify(fetchBarParams),
    ) as SearchBidStrapReportParams;

    lineParams1Copy.begin_date = beginDate.format("YYYY-MM-DD");
    lineParams1Copy.end_date = endDate.format("YYYY-MM-DD");
    lineParams1Copy.group_type_name = displayInterval.value;
    lineParams1Copy.filters = {
      site_ids: selectedSites,
      ad_unit_ids: selectedGamAdUnits,
    };

    lineParams2Copy.begin_date = beginDate.format("YYYY-MM-DD");
    lineParams2Copy.end_date = endDate.format("YYYY-MM-DD");
    lineParams2Copy.group_type_name = displayInterval.value;
    lineParams2Copy.filters = {
      site_ids: selectedSites,
      ad_unit_ids: selectedGamAdUnits,
    };

    barParamsCopy.begin_date = beginDate.format("YYYY-MM-DD");
    barParamsCopy.end_date = endDate.format("YYYY-MM-DD");
    barParamsCopy.filters = {
      site_ids: selectedSites,
      ad_unit_ids: selectedGamAdUnits,
    };

    setFetchLineParams1(lineParams1Copy);
    setFetchLineParams2(lineParams2Copy);
    setFetchBarParams(barParamsCopy);

    setErrorText("");
    setIsButtonDisabled(true);
  };

  const handleClickReportPageLink = (
    fetchParams: SearchBidStrapReportParams,
  ) => {
    const params = btoa(JSON.stringify(fetchParams));
    push(
      `/company/${currentCompany.company.id}/report/bidstrap_report?query=${params}`,
    );
  };

  const handleSelectIndicator1 = (
    indicator: Domain.BidStrapReportIndicatorType,
  ) => {
    const lineParams1Copy = JSON.parse(JSON.stringify(fetchLineParams1));
    lineParams1Copy.indicator_names = [indicator];
    setFetchLineParams1(lineParams1Copy);
  };

  const handleSelectIndicator2 = (
    indicator: Domain.BidStrapReportIndicatorType,
  ) => {
    const lineParams2Copy = JSON.parse(JSON.stringify(fetchLineParams1));
    lineParams2Copy.indicator_names = [indicator];
    setFetchLineParams2(lineParams2Copy);
  };

  const actions = useMemo(
    () =>
      displayPeriodOptions.map((option) => ({
        text: option.label,
        onClick: () => {
          handleDisplayPeriodChange(option);
        },
      })),
    [],
  );

  return (
    <>
      <Helmet title="BID STRAP ダッシュボード | DATA STRAP" />
      <PageContainer>
        <Card p={3}>
          <Flex display="flex" alignItems="flex-start" flexWrap="wrap">
            <div>
              <Flex display="flex" alignItems="flex-start" flexWrap="wrap">
                <Typography color="secondary" size="sm" weight="bold">
                  サイト
                </Typography>
              </Flex>
              <Spacer pb={1} />
              <Styled.InputContainer width="200px">
                <Select
                  isMulti={true}
                  options={siteOptions}
                  placeholder="すべて"
                  onChange={handleSiteChange}
                />
              </Styled.InputContainer>
            </div>
            <Spacer pr={2} />
            <div>
              <Flex display="flex" alignItems="flex-start" flexWrap="wrap">
                <Typography color="secondary" size="sm" weight="bold">
                  ユニット
                </Typography>
              </Flex>
              <Spacer pb={1} />
              <Styled.InputContainer width="336px">
                <Select
                  isMulti={true}
                  options={gamAdUnitOptions}
                  placeholder="すべて"
                  onChange={handleGamAdUnitChange}
                />
              </Styled.InputContainer>
            </div>
            <Spacer pr={2} />
            <div>
              <Typography color="secondary" size="sm" weight="bold">
                表示間隔
              </Typography>
              <Spacer pb={1} />
              <Styled.InputContainer width="84px">
                <Select
                  isClearable={false}
                  value={displayInterval}
                  options={displayIntervalOptions}
                  onChange={handleDisplayIntervalChange}
                />
              </Styled.InputContainer>
            </div>
            <Spacer pr={2} />
            <div>
              <Flex display="flex" alignItems="flex-end" flexWrap="wrap">
                <div>
                  <Typography color="secondary" size="sm" weight="bold">
                    表示期間
                  </Typography>
                  <Spacer pb={1} />
                  {displayPeriod.value === "custom_single" ? (
                    <DatePicker
                      date={beginDate}
                      isOutsideRange={(day: dayjs.Dayjs) =>
                        day.isAfter(dayjs())
                      }
                      defaultClickAction={displayPeriod.label}
                      actions={actions}
                      onDateChange={handleDateChange}
                    />
                  ) : (
                    <DateRangePicker
                      errorText={errorText}
                      startDate={beginDate}
                      endDate={endDate}
                      isOutsideRange={(day: dayjs.Dayjs) =>
                        day.isAfter(dayjs())
                      }
                      defaultClickAction={displayPeriod.label}
                      actions={actions}
                      onDatesChange={handleDatesChange}
                    />
                  )}
                </div>
                <Spacer pr={3} />
                <Button
                  disabled={isButtonDisabled}
                  inline={true}
                  size="medium"
                  className="gaev-dashboard-btn_apply"
                  onClick={handleSubmit}
                >
                  適用
                </Button>
              </Flex>
              {!!errorText && (
                <Spacer
                  pt={1}
                  className="gaev-dashboard-error_setting_viewperiod"
                >
                  <ErrorText>{errorText}</ErrorText>
                </Spacer>
              )}
            </div>
          </Flex>
        </Card>
        <Spacer pb={3} />
        <Styled.Container>
          <BidStrapGraphCard
            companyId={currentCompany.company.id}
            graphType={
              lineGraphGroup.includes(fetchLineParams1.indicator_names[0])
                ? "line"
                : "bar"
            }
            fetchParams={fetchLineParams1}
            onClickReportPageLink={handleClickReportPageLink}
            onSelectIndicator={handleSelectIndicator1}
          />
          <BidStrapGraphCard
            companyId={currentCompany.company.id}
            graphType={
              lineGraphGroup.includes(fetchLineParams2.indicator_names[0])
                ? "line"
                : "bar"
            }
            fetchParams={fetchLineParams2}
            onClickReportPageLink={handleClickReportPageLink}
            onSelectIndicator={handleSelectIndicator2}
          />
          <BidStrapUnitCompareCard
            companyId={currentCompany.company.id}
            span={2}
            fetchParams={fetchBarParams}
            onClickReportPageLink={handleClickReportPageLink}
          />
        </Styled.Container>
      </PageContainer>
    </>
  );
};

export { BidStrapDashBoard };
