import {
  DemandAccount,
  DemandAccountWithScraperStatus,
} from "../../../../domain/demandAccount";
import { RootState } from "../../../reducer";

export const getDemandAccounts = (
  state: RootState,
): DemandAccountWithScraperStatus[] => {
  const { allIds, byId } = state.entities.demandAccount;
  return allIds.map((id) => byId[id]);
};

export const getDemandAccountById = (
  state: RootState,
  id: number,
): DemandAccount | null => {
  const byId = state.entities.demandAccount.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
