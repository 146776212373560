import { Category } from "../../../../domain/category";
import { RootState } from "../../../reducer";

export const getCategories = (state: RootState): Category[] => {
  const allIds = state.entities.category.allIds;
  const byId = state.entities.category.byId;
  return allIds.map((id) => byId[id]);
};

export const getCategoryById = (
  state: RootState,
  id: number,
): Category | null => {
  const byId = state.entities.category.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
