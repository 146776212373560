import { Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PostCSVStatus
 */
export type PostCSVParams = void;
export type PostCSVSuccess = void;
export type PostCSVFailure = Error;

export const postCSVAction = actionCreator.async<
  PostCSVParams,
  PostCSVSuccess,
  PostCSVFailure
>("POST_CSV_STATUS");

export function postCSV(
  payload: Request.CSVImport.Upload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { csvImport, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(postCSVAction.started());

    try {
      await csvImport.uploadCSV(apiClient, payload, currentCompany.company.id);
      dispatch(postCSVAction.done({}));
    } catch (err) {
      dispatch(postCSVAction.failed(err));
    }
  };
}
