import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

export type fetchCSVImportableDemandAccountsParams = void;
export type fetchCSVImportableDemandAccountsSuccess = Response.CSVImport.FetchCSVImportableDemandAccounts;
export type fetchCSVImportableDemandAccountsFailure = Error;

export const fetchCSVImportableDemandAccountsAction = actionCreator.async<
  fetchCSVImportableDemandAccountsParams,
  fetchCSVImportableDemandAccountsSuccess,
  fetchCSVImportableDemandAccountsFailure
>("FETCH_CSV_IMPORTABLE_DEMAND");

export function fetchCSVImportableDemandAccounts(): ThunkAction<
  Promise<void>,
  any
> {
  return async (dispatch, _getState, { csvImport, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchCSVImportableDemandAccountsAction.started());

    try {
      const res = await csvImport.fetchCSVImportableDemandAccounts(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchCSVImportableDemandAccountsAction.done({ result: res }));
    } catch (err) {
      dispatch(fetchCSVImportableDemandAccountsAction.failed(err));
    }
  };
}
