import { Icon, FloatingTip } from "ingred-ui";
import * as React from "react";

import * as Styled from "./styled";

type IconSize = "md" | "lg";

type Place = "right-start" | "right-end" | "left-start" | "left-end";

type Props = {
  size?: IconSize;
  position?: Place;
  children?: React.ReactNode;
};

const Popover: React.FunctionComponent<Props> = ({
  size = "md",
  position = "right-start",
  children,
}) => {
  const [
    iconWrapperElement,
    setIconWrapperElement,
  ] = React.useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleIsOpen = (isOpen: boolean) => () => {
    setIsOpen(isOpen);
  };
  return (
    <Styled.Container>
      <Styled.IconContainer
        ref={setIconWrapperElement}
        onClick={handleIsOpen(!isOpen)}
      >
        <Icon name="question" type="fill" size={size} />
      </Styled.IconContainer>
      <FloatingTip
        baseElement={iconWrapperElement}
        isOpen={isOpen}
        positionPriority={[position]}
        onClose={handleIsOpen(false)}
      >
        <Styled.ContentContainer>{children}</Styled.ContentContainer>
      </FloatingTip>
    </Styled.Container>
  );
};

export { Popover };
