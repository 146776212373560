import { Domain } from "api-types";

export type Site = Domain.Site;
export type MarketTrendEcpm = Domain.MarketTrendEcpm;
export type MarketTrendSalesCompositionRatio = Domain.MarketTrendSalesCompositionRatio<{
  id: number;
}>;

export function createMarketTrendSalesCompositionRatio(
  data: Domain.MarketTrendSalesCompositionRatio,
): MarketTrendSalesCompositionRatio {
  return {
    ...data,
    market: {
      ...data.market,
      records: data.market.records.map((record, i) => ({
        ...record,
        id: i + 1,
      })),
    },
    mine: {
      ...data.mine,
      records: data.mine.records.map((record, i) => ({
        ...record,
        id: i + 1,
      })),
    },
  };
}
