import { SavedCustomReportDetail } from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchSavedQuery
 */
export type FetchSavedQueryParams = void;
export type FetchSavedQuerySuccess = SavedCustomReportDetail;
export type FetchSavedQueryFailure = Error;

export const fetchSavedQueryAction = actionCreator.async<
  FetchSavedQueryParams,
  FetchSavedQuerySuccess,
  FetchSavedQueryFailure
>("FETCH_SAVED_QUERY");

export function fetchSavedQuery(payload: {
  saved_query_id: number;
}): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchSavedQueryAction.started());

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsCustomReportsSavedQueryShow(
        currentCompany.company.id,
        payload.saved_query_id,
      );
      dispatch(fetchSavedQueryAction.done({ result: data }));
    } catch (err: any) {
      dispatch(fetchSavedQueryAction.failed(err));
    }
  };
}
