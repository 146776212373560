type AppConfig = {
  API_DOMAIN: string;
  SENTRY_DSN: string; // 空の場合は送信しない
};

type Auth0Config = {
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
};

type AgGridConfig = {
  AG_GRID_TOKEN: string;
};

export type Config = AppConfig & Auth0Config & AgGridConfig;
export const config: Config = {
  // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
  ...require(`./config.${process.env.ENV || "local_api"}`).config,
};
