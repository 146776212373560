import { reducerWithInitialState } from "typescript-fsa-reducers";

import { Dashboard } from "../../../../domain/dashboard";
import { fetchDashboardAction } from "../actions/fetchDashboardAction";

export type DashboardState = {
  fetch: {
    requesting: boolean;
    data: Dashboard | null;
  };
};

export const initialState: DashboardState = {
  fetch: {
    requesting: false,
    data: null,
  },
};

export const dashboardReducer = reducerWithInitialState(initialState)
  /**
   * Fetch Dashboard
   */
  .case(fetchDashboardAction.started, (state) => ({
    ...state,
    fetch: {
      // requesting時にdataをnullにすると、画面が構成できなくなる為、dataがある場合はそのまま残しておく
      ...state.fetch,
      requesting: true,
    },
  }))
  .case(fetchDashboardAction.done, (state, action) => ({
    ...state,
    fetch: {
      requesting: false,
      data: action.result.demand_reports,
    },
  }))
  .case(fetchDashboardAction.failed, (state) => ({
    ...state,
    fetch: {
      requesting: false,
      data: null,
    },
  }));
