import { Request, Response } from "api-types";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchDemandAccountsParams = void;
export type FetchDemandAccountsResponse = Response.DemandAccount.FetchAll;

export async function fetchDemandAccounts(
  apiClient: APIClient,
  companyId: number,
) {
  const { data } = await apiClient.get<FetchDemandAccountsResponse>(
    "/v1/demand_accounts",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type PostDemandAccountParams = Request.DemandAccount.Create;
export type PostDemandAccountResponse = Response.DemandAccount.Post;

export async function postDemandAccount(
  apiClient: APIClient,
  params: PostDemandAccountParams,
  companyId: number,
) {
  const { data } = await apiClient.post<PostDemandAccountResponse>(
    "/v1/demand_accounts",
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type PatchDemandAccountParams = Request.DemandAccount.Patch;
export type PatchDemandAccountResponse = Response.DemandAccount.Patch;

export async function patchDemandAccountById(
  apiClient: APIClient,
  params: PatchDemandAccountParams,
  companyId: number,
) {
  const { data } = await apiClient.patch<PatchDemandAccountResponse>(
    `/v1/demand_accounts/${params.demand_account_id}`,
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type DeleteDemandAccountParams = Request.DemandAccount.Delete;
export type DeleteDemandAccountResponse = void;

export async function deleteDemandAccountById(
  apiClient: APIClient,
  params: DeleteDemandAccountParams,
  companyId: number,
) {
  const { data } = await apiClient.delete(
    `/v1/demand_accounts/${params.demand_account_id}`,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}
