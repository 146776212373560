import { Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DownloadBidStrapCsv
 */
export type DownloadBidStrapCsvParams = void;
export type DownloadBidStrapCsvSuccess = void;
export type DownloadBidStrapCsvFailure = Error;

export const downloadBidStrapCsvAction = actionCreator.async<
  DownloadBidStrapCsvParams,
  DownloadBidStrapCsvSuccess,
  DownloadBidStrapCsvFailure
>("DOWNLOAD_BIDSTRAP_CSV");

export function downloadBidStrapCsv(
  payload: Request.BidStrapReport.Fetch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, bidStrapReport }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(downloadBidStrapCsvAction.started());

    try {
      await bidStrapReport.downloadBidStrapCSV(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(downloadBidStrapCsvAction.done({}));
    } catch (err) {
      dispatch(downloadBidStrapCsvAction.failed(err));
    }
  };
}
