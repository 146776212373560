import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchGamLineItems
 */
export type FetchGamLineItemsParams = void;
export type FetchGamLineItemsSuccess = Response.GamLineItem.FetchAll;
export type FetchGamLineItemsFailure = Error;

export const fetchGamLineItemsAction = actionCreator.async<
  FetchGamLineItemsParams,
  FetchGamLineItemsSuccess,
  FetchGamLineItemsFailure
>("FETCH_GAM_LINE_ITEMS");

export function fetchGamLineItems(
  payload: Request.GamLineItem.FetchAll,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { gamLineItem, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchGamLineItemsAction.started());

    try {
      const res = await gamLineItem.fetchGamLineItemsByGamAdUnitId(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(fetchGamLineItemsAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchGamLineItemsAction.failed({ error }));
      }
    }
  };
}
