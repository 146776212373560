import { Request, Response } from "api-types";

import {
  CustomReportRequestFromDashboard,
  CustomReportResult,
} from "../../../../api-client/model";
import { APIClient, createAPIClient } from "../../utils/APIClientUtils";

export type FetchSavedQueriesParams = void;
export type FetchSavedQueriesResponse = Response.SavedQuery.FetchAll;

export async function fetchSavedQueries(
  apiClient: APIClient,
  companyId: number,
): Promise<FetchSavedQueriesResponse> {
  const { data } = await apiClient.get<FetchSavedQueriesResponse>(
    "/v1/custom_reports/saved_query",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type FetchSavedQueryParams = Request.SavedQuery.Fetch;
export type FetchSavedQueryResponse = Response.SavedQuery.Fetch;

export async function fetchSavedQueryById(
  apiClient: APIClient,
  params: FetchSavedQueryParams,
  companyId: number,
): Promise<FetchSavedQueryResponse> {
  const { data } = await apiClient.get<FetchSavedQueryResponse>(
    `/v1/custom_reports/saved_query/${params.saved_query_id}`,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type PostSavedQueryParams = Request.SavedQuery.Post;
export type PostSavedQueryResponse = Response.SavedQuery.Post;

export async function postSavedQuery(
  apiClient: APIClient,
  params: PostSavedQueryParams,
  companyId: number,
) {
  const { data } = await apiClient.post<PostSavedQueryResponse>(
    "/v1/custom_reports/saved_query",
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type PatchSavedQueryParams = Request.SavedQuery.Patch;
export type PatchSavedQueryResponse = Response.SavedQuery.Patch;

export async function patchSavedQuery(
  apiClient: APIClient,
  params: PatchSavedQueryParams,
  companyId: number,
) {
  const { data } = await apiClient.post<PatchSavedQueryResponse>(
    `/v1/custom_reports/saved_query/${params.saved_query_id}`,
    {
      saved_custom_report_detail: params.saved_custom_report_detail,
      name: params.name,
    },
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type DeleteSavedQueryParams = Request.SavedQuery.Delete;
export type DeleteSavedQueryResponse = void;

export async function deleteSavedQueryById(
  apiClient: APIClient,
  params: DeleteSavedQueryParams,
  companyId: number,
) {
  const { data } = await apiClient.delete(
    `/v1/custom_reports/saved_query/${params.saved_query_id}`,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );
  return data;
}

// Redux外で利用するAPIClient
export type FetchSavedQueryDataParams = CustomReportRequestFromDashboard;
export type FetchSavedQueryDataResponse = CustomReportResult;

export async function fetchSavedQueryDataById(
  companyId: number,
  savedQueryId: number,
  params: CustomReportRequestFromDashboard,
): Promise<FetchSavedQueryDataResponse> {
  const apiClient = createAPIClient();
  const { data } = await apiClient.post<FetchSavedQueryDataResponse>(
    `/v1/custom_reports/saved_query/${savedQueryId}/data/highcharts`,
    { additional_filters: params.additional_filters },
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}
