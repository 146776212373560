import { Response } from "api-types";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchGamAdUnitsParams = void;
export type FetchGamAdUnitsResponse = Response.GamAdUnit.FetchAll;

export async function fetchGamAdUnits(apiClient: APIClient, companyId: number) {
  const { data } = await apiClient.get<FetchGamAdUnitsResponse>(
    "/v1/gam_ad_units",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type FetchMappedGamAdUnitsParams = void;
export type FetchMappedGamAdUnitsResponse = Response.GamAdUnit.FetchAll;

export async function fetchMappedGamAdUnits(
  apiClient: APIClient,
  companyId: number,
) {
  const { data } = await apiClient.get<FetchMappedGamAdUnitsResponse>(
    "/v1/gam_ad_units/mapped",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type FetchBidStrapGamAdUnitsParams = void;
export type FetchBidStrapGamAdUnitsResponse = Response.GamAdUnit.BSFetchAll;

export async function fetchBidStrapGamAdUnits(
  apiClient: APIClient,
  companyId: number,
) {
  const { data } = await apiClient.get<FetchGamAdUnitsResponse>(
    "/v1/bid_strap/gam_ad_units",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}
