import * as queryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { RouteActionContext, RouteActionPayload } from "../../../routing/types";
import { fetchBidStrapGamAdUnits } from "../../../store/modules/bidStrapGamAdUnit/actions/fetchBidStrapGamAdUnitsAction";
import { getBidStrapGamAdUnits } from "../../../store/modules/bidStrapGamAdUnit/selectors/bidStrapGamAdUnitSelector";
import { downloadBidStrapCsv } from "../../../store/modules/bidStrapReport/actions/downloadBidStrapCsvAction";
import { fetchBidStrapReport } from "../../../store/modules/bidStrapReport/actions/fetchBidStrapReportAction";
import { fetchBidStrapSites } from "../../../store/modules/bidStrapSite/actions/fetchBidStrapSitesAction";
import { getBidStrapSites } from "../../../store/modules/bidStrapSite/selectors/bidStrapSiteSelector";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { BidStrapReport as Component } from "./BidStrapReport";

const BidStrapReport = connect(
  (state: RootState) => ({
    currentCompany: state.user.currentCompany as CompanyAttribute,
    bidStrapReport: state.bidStrapReport.fetch.data,
    requesting: state.bidStrapReport.fetch.requesting,
    downloadCsvRequesting: state.bidStrapReport.downloadBidStrapCsv.requesting,
    sites: getBidStrapSites(state),
    gamAdUnits: getBidStrapGamAdUnits(state),
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    fetchBidStrapReport: dispatchable(dispatch, fetchBidStrapReport),
    downloadCsv: dispatchable(dispatch, downloadBidStrapCsv),
  }),
)(Component);

export async function action({
  history,
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  const params = queryString.parse(history.location.search);
  if (firstOrPush) {
    await Promise.all([
      store.dispatch(fetchBidStrapGamAdUnits()),
      store.dispatch(fetchBidStrapSites()),
    ]);
  }
  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: (
      <BidStrapReport fetchParamQuery={params["query"] as string | undefined} />
    ),
  };
}
