import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchCategory
 */
export type PatchCategoryParams = void;
export type PatchCategorySuccess = Response.Category.Patch;
export type PatchCategoryFailure = Error;

export const patchCategoryAction = actionCreator.async<
  PatchCategoryParams,
  PatchCategorySuccess,
  PatchCategoryFailure
>("PATCH_CATEGORY");

export function patchCategory(
  payload: Request.Category.Patch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, category }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchCategoryAction.started());

    try {
      const res = await category.patchCategoryById(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(patchCategoryAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(patchCategoryAction.failed({ error }));
      }
    }
  };
}
