import { Request } from "api-types";
import { Typography, Spacer, ConfirmModal } from "ingred-ui";
import * as React from "react";

import { NormalizedData } from "../../UnmappedGAMAdCreatives";

type Props = {
  isOpen: boolean;
  gamAdCreative: NormalizedData | null;
  onClose?: () => void;
  onSubmit?: (data: Request.GamLineItemCreative.Patch) => void;
  loading?: boolean;
};

const EditModal: React.FunctionComponent<Props> = ({
  isOpen,
  gamAdCreative,
  onClose,
  onSubmit,
  loading,
}) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (onSubmit && gamAdCreative) {
      onSubmit({
        gam_line_item_creative_id: gamAdCreative.id,
        is_mappable: !gamAdCreative.isMappable,
      });
    }
  };
  return (
    <ConfirmModal
      isOpen={isOpen}
      title={
        gamAdCreative?.isMappable ? "紐付け不要にする" : "紐付け対象に戻す"
      }
      confirmText={
        gamAdCreative?.isMappable ? "紐付け不要にする" : "紐付け対象に戻す"
      }
      cancelText="キャンセル"
      buttonColor={gamAdCreative?.isMappable ? "danger" : "primary"}
      loading={loading}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit}
    >
      <Spacer pt={2}>
        <Typography>
          {gamAdCreative?.creativeName}
          {gamAdCreative?.isMappable
            ? " を紐付け不要にしますか？"
            : " を紐付け対象に戻しますか？"}
        </Typography>
      </Spacer>
    </ConfirmModal>
  );
};

export { EditModal };
