import { Request } from "api-types";
import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { Dashboard as DashboardType } from "../../../domain/dashboard";
import { RouteActionContext, RouteActionPayload } from "../../../routing/types";
import { fetchDashboard } from "../../../store/modules/dashboard/actions/fetchDashboardAction";
import { fetchDemandAccounts as fetchDemandAccountsAction } from "../../../store/modules/demandAccount/actions/fetchDemandAccountsAction";
import { getDemandAccounts } from "../../../store/modules/demandAccount/selectors/demandAccountsSelector";
import { fetchDemandAdCreativeGroups } from "../../../store/modules/demandAdCreativeGroup/actions/fetchDemandAdCreativeGroupsAction";
import { getDemandAdCreativeGroups } from "../../../store/modules/demandAdCreativeGroup/selectors/demandAdCreativeGroupSelector";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";
import { getDateRange } from "../../../utils/DateRangeUtils";

import { Dashboard as Component } from "./Dashboard";

const Dashboard = connect(
  (state: RootState) => ({
    currentCompany: state.user.currentCompany as CompanyAttribute,
    demandAdCreativeGroups: getDemandAdCreativeGroups(state),
    demandAccounts: getDemandAccounts(state),
    requesting: state.dashboard.fetch.requesting,
    data: state.dashboard.fetch.data as DashboardType,
  }),
  (dispatch) => ({
    fetchDashboard: dispatchable(dispatch, fetchDashboard),
  }),
)(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    const [beginDate, endDate] = getDateRange("last_30_days");
    const params: Request.Dashboard.Fetch = {
      begin_date: beginDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD"),
      site_ids: [],
      group_type_name: "day",
    };

    await Promise.all([
      store.dispatch(fetchDashboard(params)),
      store.dispatch(fetchDemandAdCreativeGroups()),
      store.dispatch(fetchDemandAccountsAction()),
    ]);
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <Dashboard />,
  };
}
