import styled from "styled-components";

import { ToolTipStyle } from "../../../html/tooltip";
import { Color } from "../../../styles/variables";

export const Container = styled.div`
  ${ToolTipStyle}
`;

export const GraphAction = styled.a`
  color: ${Color.text.primary};
  font-size: 12px;
  text-decoration: underline;
  padding-left: 10px;
  padding-bottom: 5px;
  cursor: pointer;
`;
