import { Response, Request } from "api-types";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchCategoriesParams = void;
export type FetchCategoriesResponse = Response.Category.FetchAll;

export async function fetchCategories(
  apiClient: APIClient,
  companyId: number,
): Promise<FetchCategoriesResponse> {
  const { data } = await apiClient.get<FetchCategoriesResponse>(
    "/v1/categories",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type PostCategoryParams = Request.Category.Post;
export type PostCategoryResponse = Response.Category.Post;

export async function postCategory(
  apiClient: APIClient,
  params: PostCategoryParams,
  companyId: number,
) {
  const { data } = await apiClient.post<PostCategoryResponse>(
    "/v1/categories",
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type PatchCategoryParams = Request.Category.Patch;
export type PatchCategoryResponse = Response.Category.Patch;

export async function patchCategoryById(
  apiClient: APIClient,
  params: PatchCategoryParams,
  companyId: number,
) {
  const { data } = await apiClient.patch<PatchCategoryResponse>(
    `/v1/categories/${params.category_id}`,
    { name: params.name },
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type DeleteCategoryParams = Request.Category.Delete;
export type DeleteCategoryResponse = void;

export async function deleteCategoryById(
  apiClient: APIClient,
  params: DeleteCategoryParams,
  companyId: number,
) {
  await apiClient.delete(`/v1/categories/${params.category_id}`, {
    headers: {
      "X-Company-Id": companyId,
    },
  });
}

export type PatchDemandAdCreativesParams = Request.Category.PatchDemandAccountCreatives;
export type PatchDemandAdCreativesResponse = Response.Category.PatchDemandAccountCreatives;

export async function patchDemandAdCreatives(
  apiClient: APIClient,
  params: PatchDemandAdCreativesParams,
  companyId: number,
) {
  await apiClient.patch<Response.Category.PatchDemandAccountCreatives>(
    `/v1/categories/${params.category_id}/category_creatives`,
    {
      demand_account_creative_ids: params.demand_account_creative_ids,
    },
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );
}

export type DeleteDemandAdCreativesParams = Request.Category.DeleteDemandAccountCreatives;
export type DeleteDemandAdCreativesResponse = void;

export async function deleteDemandAdCreatives(
  apiClient: APIClient,
  params: DeleteDemandAdCreativesParams,
  companyId: number,
) {
  await apiClient.delete(`/v1/category_creatives`, {
    headers: {
      "X-Company-Id": companyId,
    },
    data: {
      demand_account_creative_ids: params.demand_account_creative_ids,
    },
  });
}
