import { reducerWithInitialState } from "typescript-fsa-reducers";

import { activateDemandAdCreativesAction } from "../actions/activateDemandAdCreativesAction";
import { deleteDemandAdCreativesAction } from "../actions/deleteDemandAdCreativesAction";
import { fetchDemandAdCreativesBasedOnGamAction } from "../actions/fetchDemandAdCreativesBasedOnGamAction";
import { patchChannelTypeAction } from "../actions/patchChannelTypeAction";
import { patchDeviceTypeAction } from "../actions/patchDeviceTypeAction";
import { patchOsTypeAction } from "../actions/patchOsTypeAction";

export type DemandAdCreativeState = {
  relationSearchBasedOnSite: {
    requesting: boolean;
  };
  relationSearchBasedOnGam: {
    requesting: boolean;
  };
  channelType: {
    patch: {
      requesting: boolean;
      success: boolean;
    };
  };
  deviceType: {
    patch: {
      requesting: boolean;
      success: boolean;
    };
  };
  osType: {
    patch: {
      requesting: boolean;
      success: boolean;
    };
  };
  activate: {
    patch: {
      requesting: boolean;
      success: boolean;
    };
  };
  delete: {
    requesting: boolean;
    success: boolean;
  };
};

export const initialState: DemandAdCreativeState = {
  relationSearchBasedOnSite: {
    requesting: false,
  },
  relationSearchBasedOnGam: {
    requesting: false,
  },
  channelType: {
    patch: {
      requesting: false,
      success: false,
    },
  },
  deviceType: {
    patch: {
      requesting: false,
      success: false,
    },
  },
  osType: {
    patch: {
      requesting: false,
      success: false,
    },
  },
  activate: {
    patch: {
      requesting: false,
      success: false,
    },
  },
  delete: {
    requesting: false,
    success: false,
  },
};

export const demandAdCreativeReducer = reducerWithInitialState(initialState)
  /**
   * Fetch DemandAdCreatives RelationSearchBasedOnGam
   */
  .case(fetchDemandAdCreativesBasedOnGamAction.started, (state) => ({
    ...state,
    relationSearchBasedOnGam: {
      requesting: true,
    },
  }))
  .case(fetchDemandAdCreativesBasedOnGamAction.done, (state) => ({
    ...state,
    relationSearchBasedOnGam: {
      requesting: false,
    },
  }))
  .case(fetchDemandAdCreativesBasedOnGamAction.failed, (state) => ({
    ...state,
    relationSearchBasedOnGam: {
      requesting: false,
    },
  }))
  /**
   * チャネルの変更
   */
  .case(patchChannelTypeAction.started, (state) => ({
    ...state,
    channelType: {
      ...state.channelType,
      patch: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(patchChannelTypeAction.done, (state) => ({
    ...state,
    channelType: {
      ...state.channelType,
      patch: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(patchChannelTypeAction.failed, (state) => ({
    ...state,
    channelType: {
      ...state.channelType,
      patch: {
        requesting: false,
        success: false,
      },
    },
  }))
  /**
   * デバイスの変更
   */
  .case(patchDeviceTypeAction.started, (state) => ({
    ...state,
    deviceType: {
      ...state.deviceType,
      patch: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(patchDeviceTypeAction.done, (state) => ({
    ...state,
    deviceType: {
      ...state.deviceType,
      patch: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(patchDeviceTypeAction.failed, (state) => ({
    ...state,
    deviceType: {
      ...state.deviceType,
      patch: {
        requesting: false,
        success: false,
      },
    },
  }))
  /**
   * OSの変更
   */
  .case(patchOsTypeAction.started, (state) => ({
    ...state,
    osType: {
      ...state.osType,
      patch: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(patchOsTypeAction.done, (state) => ({
    ...state,
    osType: {
      ...state.osType,
      patch: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(patchOsTypeAction.failed, (state) => ({
    ...state,
    osType: {
      ...state.osType,
      patch: {
        requesting: false,
        success: false,
      },
    },
  }))
  /**
   * DACを利用中にする
   */
  .case(activateDemandAdCreativesAction.started, (state) => ({
    ...state,
    activate: {
      ...state.activate,
      patch: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(activateDemandAdCreativesAction.done, (state) => ({
    ...state,
    activate: {
      ...state.activate,
      patch: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(activateDemandAdCreativesAction.failed, (state) => ({
    ...state,
    activate: {
      ...state.activate,
      patch: {
        requesting: false,
        success: false,
      },
    },
  }))
  /**
   * DACを利用停止する
   */
  .case(deleteDemandAdCreativesAction.started, (state) => ({
    ...state,
    delete: {
      requesting: true,
      success: false,
    },
  }))
  .case(deleteDemandAdCreativesAction.done, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: true,
    },
  }))
  .case(deleteDemandAdCreativesAction.failed, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: false,
    },
  }));
