import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DeleteSavedQuery
 */
export type DeleteSavedQueryParams = void;
export type DeleteSavedQuerySuccess = { saved_query_id: number };
export type DeleteSavedQueryFailure = Error;

export const deleteSavedQueryAction = actionCreator.async<
  DeleteSavedQueryParams,
  DeleteSavedQuerySuccess,
  DeleteSavedQueryFailure
>("DELETE_SAVED_QUERY");

export function deleteSavedQuery(payload: {
  saved_query_id: number;
}): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(deleteSavedQueryAction.started());

    try {
      await openApiClient.rpzApiViewsCustomReportsSavedQueryDelete(
        currentCompany.company.id,
        payload.saved_query_id,
      );
      dispatch(
        deleteSavedQueryAction.done({
          result: { saved_query_id: payload.saved_query_id },
        }),
      );
    } catch (err: any) {
      dispatch(deleteSavedQueryAction.failed(err));
    }
  };
}
