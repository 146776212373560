import { Domain } from "api-types/domain";

import {
  CustomReportRecord,
  CustomReportResult,
} from "../../../../api-client/model";

type CustomReportResultAddedId = {
  records: Array<CustomReportRecord & { id: number }>;
} & Domain.DetailReport;
export type DetailReport = CustomReportResultAddedId;

export function createDetailReport(
  data: CustomReportResult,
): CustomReportResultAddedId {
  return {
    ...data,
    records: data.records.map((record, i) => ({
      ...record,
      id: i + 1,
    })),
  };
}
