import { Typography } from "ingred-ui";
import styled from "styled-components";

import dotImage from "../../../../assets/images/dot.png";
import { Color, BreakPoint, Radius, Size } from "../../../styles/variables";
import { colors } from "../../../styles/variables/color";

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const LeftContainer = styled.div`
  width: 38%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing * 4}px;
  background-color: ${Color.background.default};
  @media (max-width: ${BreakPoint.MEDIUM}px) {
    width: 100%;
  }
`;

export const Ul = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
`;

export const Li = styled.div`
  & + & {
    margin-left: ${({ theme }) => theme.spacing * 3}px;
  }
  a {
    text-decoration: none;
  }
`;

export const RightContainer = styled.div`
  position: relative;
  width: 62%;
  height: 100%;
  background-color: ${colors.blue[40]};
  padding: ${({ theme }) => theme.spacing * 3}px 0;
  overflow: hidden;
  @media (max-width: ${BreakPoint.MEDIUM}px) {
    display: none;
  }
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${dotImage});
  background-size: 126px;
`;

export const DContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 731 800'%3E%3Cg transform='translate(-476 -8)'%3E%3Cpath      fill='%23041c33'      d='M221.137.206H735.2c435.495-5.572,593.007,98.747,527.564,350.511l-65.036,292.1c-46.66,251.749-248,354.672-683.65,350.511H0ZM525.847,813.885c213.906-1.382,257.977-30.6,297.478-197.509l53.266-239.222c34.831-166.926,3.754-196.127-209.527-197.509H561.435L420.2,813.885Z' transform='translate(476 -67.995)' /%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;
`;

export const TextMessageContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 126px;
  height: 252px;
  background-color: ${colors.blue[40]};
`;

export const NoticeBox = styled.div`
  padding: ${({ theme }) => theme.spacing * 2}px;
  border: ${Size.Border.Small} solid ${({ theme }) => theme.palette.divider};
  border-radius: ${Radius.SMALL};
`;

export const AlertBox = styled.div`
  background-color: ${colors.red[100]};
  border-radius: ${Radius.SMALL};
  width: 100%;
`;

export const AlertText = styled(Typography)`
  color: ${colors.red[500]};
`;
