import Router from "universal-router";

import { AuthorityConstraint } from "../utils/AuthUtils";

import { companyRoutes } from "./routes/companyRoutes";
import { routes as subRoutes } from "./routes/routes";
import { RouterContext, Middleware, RouteWithConstraint } from "./types";
import { resolveRoute } from "./utils";
export function createRouter(
  context: RouterContext,
  rootMiddleware: Middleware,
  companyMiddleware: Middleware,
): Router<any, RouterContext> {
  return new Router(
    {
      path: "",
      action: rootMiddleware,
      children: [
        ...subRoutes,
        {
          path: "/company/:id",
          action: companyMiddleware,
          children: companyRoutes,
          constraint: AuthorityConstraint.AUTHED,
        } as RouteWithConstraint,
      ],
    },
    {
      context,
      resolveRoute,
    },
  );
}
