import styled from "styled-components";

import { Color, Radius, Size } from "../../../styles/variables";

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${Color.background.dark};
`;

export const Container = styled.div`
  min-width: 400px;
  border-radius: ${Radius.MEDIUM};
  background-color: ${Color.background.default};
`;

export const TitleContainer = styled.div`
  padding: ${({ theme }) => theme.spacing * 3}px
    ${({ theme }) => theme.spacing * 4}px;
  border-bottom: ${Size.Border.Small} solid ${Color.gray.light};
`;

export const ContentContainer = styled.div`
  padding: ${({ theme }) => theme.spacing * 3}px
    ${({ theme }) => theme.spacing * 4}px ${({ theme }) => theme.spacing * 7}px;
`;

export const Ul = styled.ul`
  list-style: none;
`;

export const Li = styled.li`
  padding: ${({ theme }) => theme.spacing * 2}px 0
    ${({ theme }) => theme.spacing * 3}px;
  border-bottom: ${Size.Border.Small} solid ${Color.gray.light};
  cursor: pointer;
  p {
    display: inline;
    border-bottom: ${Size.Border.Small} solid ${Color.primary.main};
  }
`;
