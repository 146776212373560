import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { RootState } from "../../../reducer";
import { getCategoryById } from "../../category/selectors/categorySelector";
import { getDemandAdCreativeGroupById } from "../../demandAdCreativeGroup/selectors/demandAdCreativeGroupSelector";

export const getDemandAdCreatives = (state: RootState): DemandAdCreative[] => {
  const { allIds, byId } = state.entities.demandAdCreative;

  return allIds.map((id) => ({
    ...byId[id],
    site:
      byId[id].site != null
        ? getDemandAdCreativeGroupById(state, byId[id].site as number)
        : null,
    category:
      byId[id].category != null
        ? getCategoryById(state, byId[id].category as number)
        : null,
    category2:
      byId[id].category2 != null
        ? getCategoryById(state, byId[id].category2 as number)
        : null,
  }));
};

export const getDemandAdCreativeById = (
  state: RootState,
  id: string,
): DemandAdCreative | null => {
  const { byId } = state.entities.demandAdCreative;
  if (byId[id]) {
    return {
      ...byId[id],
      site:
        byId[id].site != null
          ? getDemandAdCreativeGroupById(state, byId[id].site as number)
          : null,
      category:
        byId[id].category != null
          ? getCategoryById(state, byId[id].category as number)
          : null,
      category2:
        byId[id].category2 != null
          ? getCategoryById(state, byId[id].category2 as number)
          : null,
    };
  }
  return null;
};

export const getUnmappedToGroupDemandAdCreatives = (
  state: RootState,
): DemandAdCreative[] => {
  return getDemandAdCreatives(state).filter(
    (creative) => !creative.mappedToGroup,
  );
};

export const getUnmappedToGamDemandAdCreatives = (
  state: RootState,
): DemandAdCreative[] => {
  return getDemandAdCreatives(state).filter(
    (creative) => !creative.mappedToGam && creative.is_active,
  );
};
