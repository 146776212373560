import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import {
  RouteActionPayload,
  RouteActionContext,
} from "../../../../routing/types";
import { deleteCategory as deleteCategoryAction } from "../../../../store/modules/category/actions/deleteCategoryAction";
import { fetchCategories as fetchCategoriesAction } from "../../../../store/modules/category/actions/fetchCategoriesAction";
import { patchCategory as editCategoryAction } from "../../../../store/modules/category/actions/patchCategoryAction";
import { postCategory as postCategoryAction } from "../../../../store/modules/category/actions/postCategoryAction";
import { getCategories } from "../../../../store/modules/category/selectors/categorySelector";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../../store/reducer";
import { dispatchable } from "../../../../store/utils/dispatchable";

import { Categories as Component } from "./Categories";

const Categories = connect(
  (state: RootState) => ({
    // /company/:id 以下なので選択済み
    currentCompany: state.user.currentCompany as CompanyAttribute,
    categories: getCategories(state),
    createCategoryRequesting: state.category.post.requesting,
    createSucceeded: state.category.post.success,
    editCategoryRequesting: state.category.patch.requesting,
    editSucceeded: state.category.patch.success,
    deleteCategoryRequesting: state.category.delete.requesting,
    deleteSucceeded: state.category.delete.success,
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    createCategory: dispatchable(dispatch, postCategoryAction),
    editCategory: dispatchable(dispatch, editCategoryAction),
    deleteCategory: dispatchable(dispatch, deleteCategoryAction),
  }),
)(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await store.dispatch(fetchCategoriesAction());
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <Categories />,
  };
}
