import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchCSVImportableDemandAccountsAction } from "../actions/fetchCSVImportableDemandAccounts";
import { postCSVAction } from "../actions/postCSVAction";

export { csvImportableDemandAccountsEntityReducer } from "./csvImportableDemandAccountsEntity";

export type CSVImportState = {
  fetchCSVImportableDemandAccounts: {
    requesting: boolean;
  };
  postCSV: {
    requesting: boolean;
    success: boolean;
  };
};

export const initialState: CSVImportState = {
  fetchCSVImportableDemandAccounts: {
    requesting: false,
  },
  postCSV: {
    requesting: false,
    success: false,
  },
};

export const csvImportReducer = reducerWithInitialState(initialState)
  /**
   * Fetch CSV Importable Demands
   */
  .case(fetchCSVImportableDemandAccountsAction.started, (state) => ({
    ...state,
    fetch: {
      requesting: true,
    },
  }))
  .case(fetchCSVImportableDemandAccountsAction.done, (state) => ({
    ...state,
    fetch: {
      requesting: false,
    },
  }))
  .case(fetchCSVImportableDemandAccountsAction.failed, (state) => ({
    ...state,
    fetch: {
      requesting: false,
    },
  }))
  /**
   * Post CSV
   */
  .case(postCSVAction.started, (state) => ({
    ...state,
    postCSV: {
      requesting: true,
      success: false,
    },
  }))
  .case(postCSVAction.done, (state) => ({
    ...state,
    postCSV: {
      requesting: false,
      success: true,
    },
  }))
  .case(postCSVAction.failed, (state) => ({
    ...state,
    postCSV: {
      requesting: false,
      success: false,
    },
  }));
