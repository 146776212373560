import { Card, Typography, Spacer } from "ingred-ui";
import * as React from "react";

import { Popover } from "../Popover";

import * as Styled from "./styled";

type Props = {
  title: string;
  popoverElement?: JSX.Element;
  paddingDisabled?: boolean;
  children?: React.ReactNode;
};

const PageSection: React.FunctionComponent<Props> = ({
  title,
  popoverElement,
  children,
  paddingDisabled = false,
}) => {
  return (
    <Styled.Container>
      <Card>
        <Styled.TitleContainer>
          <Typography weight="bold" size="xl">
            {title}
          </Typography>
          {popoverElement && (
            <Spacer ml={1}>
              <Popover>{popoverElement}</Popover>
            </Spacer>
          )}
        </Styled.TitleContainer>
        <Styled.Content paddingDisabled={paddingDisabled}>
          {children}
        </Styled.Content>
      </Card>
    </Styled.Container>
  );
};

export { PageSection };
