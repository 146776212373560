import * as React from "react";
import { Route, RouteParams } from "universal-router";

import { ErrorView } from "../components/pages/ErrorView";
import { AuthedAppShell } from "../containers/AuthedAppShell/index";
import { User } from "../domain/user";
import { updateAuthenticated } from "../store/modules/auth/actions/updateAuthenticatedAction";
import { updateCompanyAction } from "../store/modules/user/actions/updateCompanyAction";
import { getAuth0Client } from "../utils/Auth0Utils";
import {
  AuthorityConstraint,
  isAuthorizationRequired,
  hasAuthorityRequirements,
  getUserAndSyncStoreState,
} from "../utils/AuthUtils";
import { getCurrentCompany } from "../utils/CompanyUtils";
import { notifyError } from "../utils/ErrorReportingUtils";
import { NotFoundHttpError, FatalHttpError } from "../utils/HttpUtils";
import { HttpStatusCode, AppErrorCode } from "../utils/StatusCodeUtils";

import {
  ChildRouteAction,
  PageRoute,
  RouteActionPayload,
  OwnerRouteActionContext,
  RouteWithConstraint,
} from "./types";

export function combineRoutePath(route: Route): string {
  let path = "";

  if (route.parent != null) {
    const parent = combineRoutePath(route.parent);
    path = `${parent === "/" ? "" : parent}${route.path}`;
  } else {
    path = route.path === "" ? "/" : (route.path as string);
  }

  return path === "" ? "/" : path;
}

export function action(pageRoute: PageRoute): ChildRouteAction {
  return ({ params, route }) => ({
    ...pageRoute,
    path: combineRoutePath(route as Route),
    params,
  });
}

export function getResponseIfUserNotFound(user: User | null, fatal: boolean) {
  if (user == null) {
    // fatalな場合はAPIに接続出来ていない
    if (fatal) {
      return {
        statusCode: AppErrorCode.API_CONNECTION_REFUSED,
        content: <ErrorView statusCode={AppErrorCode.API_CONNECTION_REFUSED} />,
      };
    }
    return {
      redirectTo: "/login",
    };
  }
  return null;
}

export function createErrorResponse(
  e: Error,
  authenticated: boolean,
): RouteActionPayload {
  // handle 404
  if (e instanceof NotFoundHttpError) {
    if (authenticated) {
      return {
        statusCode: HttpStatusCode.NOT_FOUND,
        content: (
          <AuthedAppShell>
            <ErrorView statusCode={HttpStatusCode.NOT_FOUND} />
          </AuthedAppShell>
        ),
      };
    }
    return {
      statusCode: HttpStatusCode.NOT_FOUND,
      content: <ErrorView statusCode={HttpStatusCode.NOT_FOUND} />,
    };
  }

  // handle 50x
  if (e instanceof FatalHttpError) {
    return {
      statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
      content: <ErrorView statusCode={HttpStatusCode.INTERNAL_SERVER_ERROR} />,
    };
  }

  notifyError(e);

  return {
    statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
    content: <ErrorView statusCode={HttpStatusCode.INTERNAL_SERVER_ERROR} />,
  };
}

// AuthMiddleware的な役割。
// middlewareが走る前に、認証・認可周りを解決する
export async function resolveRoute(
  context: OwnerRouteActionContext,
  params: RouteParams,
) {
  const route = context.route as RouteWithConstraint;

  const constraint = route.constraint || AuthorityConstraint.ANYONE;
  const store = context.store;
  const dispatch = store.dispatch;

  // "/" もしくは 認証が必要なURLの場合
  if (context.pathname === "/" || isAuthorizationRequired(constraint)) {
    let authenticated = store.getState().auth.authenticated;

    // Auth0から最新の認証状態を取得する
    if (!authenticated) {
      const auth0Client = await getAuth0Client();
      authenticated = await auth0Client.isAuthenticated();
      dispatch(updateAuthenticated(authenticated));
    }

    // "/" で 未認証の場合は "/login" へリダイレクトする
    // それ以外は Auth0の認証URL へ飛ばす
    if (!authenticated) {
      if (context.pathname === "/") {
        return {
          redirectTo: "/login",
        };
      } else {
        const auth0Client = await getAuth0Client();
        const authorizeUrl = await auth0Client.buildAuthorizeUrl({
          appState: { targetUrl: context.pathname },
          max_age: "0",
        });
        return {
          redirectTo: authorizeUrl,
        };
      }
    }

    // ユーザー情報を取得
    const { user, app } = await getUserAndSyncStoreState(
      store.getState,
      dispatch,
    );
    const response = getResponseIfUserNotFound(user.me.data, app.fatal);
    if (response != null) {
      return response;
    }

    if (context.pathname === "/") {
      const currentCompany = getCurrentCompany(user.me.data);
      if (currentCompany != null) {
        dispatch(updateCompanyAction({ company: currentCompany }));
        return {
          redirectTo: `/company/${currentCompany.company.id}`,
        };
      }
      return {
        redirectTo: "/select-company",
      };
    }
  }

  /**
   * URLごとの機能利用制限
   *
   * /company/:id/*でしか利用制限をかけない前提
   * もしuserやcurrentCompany.companyがなければ未認証のURLに制限がかかっている
   *
   * 今後 AUTHEDに対して制限をかける場合はcurrentCompanyのnullを許容した実装にする
   */
  if (context.pathname.match(/^\/company\/.+\/?/)) {
    const { user } = store.getState();

    // TODO: stateを参照でいいんでは？
    const currentCompany = getCurrentCompany(user.me.data);
    if (currentCompany != null) {
      if (
        !hasAuthorityRequirements(
          constraint,
          user.me.data as User,
          currentCompany.company,
        )
      ) {
        return {
          statusCode: HttpStatusCode.FORBIDDEN,
          content: (
            <AuthedAppShell>
              <ErrorView statusCode={AppErrorCode.NOT_AUTHORIZED_FUNCTION} />
            </AuthedAppShell>
          ),
        };
      }
    }
  }

  // resolveRouteのデフォルト実装
  if (typeof context.route.action === "function") {
    return context.route.action(context, params);
  }

  return undefined;
}

export function getTopPagePath(id: number) {
  return `/company/${id}/dashboard`;
}
