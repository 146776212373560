import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchInformationRead
 */
export type PatchInformationReadParams = void;
export type PatchInformationReadSuccess = Response.Information.Read.Patch;
export type PatchInformationReadFailure = Error;

export const patchInformationReadAction = actionCreator.async<
  PatchInformationReadParams,
  PatchInformationReadSuccess,
  PatchInformationReadFailure
>("PATCH_INFORMATION_READ");

export function patchInformationRead(
  payload: Request.Information.Read.Patch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, information }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchInformationReadAction.started());

    try {
      const res = await information.patchInformationRead(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(patchInformationReadAction.done({ result: res }));
    } catch (err) {
      dispatch(patchInformationReadAction.failed(err));
    }
  };
}
