import { UseFormRegisterReturn } from "react-hook-form";

/**
 * register in INGRED-UI component
 * @param UseFormRegisterReturn
 * @returns {
 *   inputRef: RefCallback;
 *   onChange: ChangeHandler;
 *   onBlur: ChangeHandler;
 *   name: string;
 * }
 */
export const registerIui = ({ ref, ...rest }: UseFormRegisterReturn): any => ({
  inputRef: ref,
  ...rest,
});
