import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchAllHbDemandType
 */
export type FetchAllHbDemandTypeParams = void;
export type FetchAllHbDemandTypeSuccess = Response.HbDemandType.FetchAll;
export type FetchAllHbDemandTypeFailure = Error;

export const fetchAllHbDemandTypeAction = actionCreator.async<
  FetchAllHbDemandTypeParams,
  FetchAllHbDemandTypeSuccess,
  FetchAllHbDemandTypeFailure
>("FETCH_ALL_HB_DEMAND_TYPE");

export function fetchAllHbDemandType(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, hbDemandType }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchAllHbDemandTypeAction.started());

    try {
      const res = await hbDemandType.fetchAllHbDemandType(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchAllHbDemandTypeAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchAllHbDemandTypeAction.failed({ error }));
      }
    }
  };
}
