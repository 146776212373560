type Entity = {
  id: number;
};

export type EntityMap<E extends Entity> = { [id: number]: E };

export function arrayToEntityMap<E extends Entity>(arr: E[]): EntityMap<E> {
  return arr.reduce<EntityMap<E>>(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur,
    }),
    {},
  );
}
