import { GTMDataLayer } from "./GTMDataLayer";
import { GTMPayload } from "./GTMPayload";

export class GTM {
  private dataLayer: GTMDataLayer;

  constructor(dataLayer: GTMDataLayer) {
    this.dataLayer = dataLayer;
  }

  public send(payload: GTMPayload): void {
    this.dataLayer.push(payload);
  }
}
