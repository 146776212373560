import {
  CustomReportResult,
  CustomReportSearch,
} from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * SearchDetailReport
 */
export type SearchDetailReportParams = void;
export type SearchDetailReportSuccess = CustomReportResult;
export type SearchDetailReportFailure = Error;

export const searchDetailReportAction = actionCreator.async<
  SearchDetailReportParams,
  SearchDetailReportSuccess,
  SearchDetailReportFailure
>("SEARCH_DETAIL_REPORT");

export function searchDetailReport(
  payload: CustomReportSearch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(searchDetailReportAction.started());

    try {
      const { data } = await openApiClient.rpzApiViewsCustomReportsSearch(
        currentCompany.company.id,
        payload,
      );
      dispatch(searchDetailReportAction.done({ result: data }));
    } catch (err) {
      dispatch(searchDetailReportAction.failed(err));
    }
  };
}
