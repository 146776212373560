import { History } from "history";
import React from "react";
import styled from "styled-components";

import {
  HistoryContext,
  HistoryContextValues,
} from "../../../store/modules/routing/HistoryContext";
import { push } from "../../../store/modules/routing/actions/PushAction";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import { Color } from "../../../styles/variables";

const HTTP_URL_REG = /^https?:\/\//;
const HASH_URL_REG = /^#/;

const Wrapper = styled.a`
  color: ${Color.primary.main};
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export function isModifiedMouseEvent(
  e: MouseEvent | React.MouseEvent<any>,
): boolean {
  return e.metaKey || e.altKey || e.ctrlKey || e.shiftKey || e.defaultPrevented;
}

export type Props = React.ComponentPropsWithoutRef<"a">;

export type InjectProps = {
  push: DispatchableAction<typeof push>;
};

type InjectedProps = Props & InjectProps;

type State = {};

export class Link extends React.PureComponent<InjectedProps, State> {
  public render(): React.ReactNode {
    return (
      <HistoryContext.Consumer>{this.renderInContext}</HistoryContext.Consumer>
    );
  }

  private renderInContext = ({
    history,
  }: HistoryContextValues): React.ReactNode => {
    const { target, rel, ...rest } = this.props;

    this.history = history as History;

    return (
      <Wrapper
        {...rest}
        target={target}
        rel={target === "_blank" && rel == null ? "noopener noreferrer" : rel}
        onClick={this.handleClick}
      />
    );
  };

  private history: History | null = null;

  private handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }

    if (
      this.history == null ||
      this.props.href == null ||
      !!this.props.target ||
      e.button !== 0 ||
      isModifiedMouseEvent(e)
    ) {
      return;
    }

    // ハッシュはデフォルトの挙動を取る
    if (HASH_URL_REG.test(this.props.href)) {
      return;
    }

    // `http` を含む URL の場合は HTMLAnchorElement の動作を止めない
    // (親要素で `preventDefault` が呼ばれている場合は別)
    if (HTTP_URL_REG.test(this.props.href)) {
      return;
    }

    e.preventDefault();

    this.props.push(this.props.href);
  };
}
