import { SavedCustomReport } from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchSavedQueries
 */
export type FetchSavedQueriesParams = void;
export type FetchSavedQueriesSuccess = SavedCustomReport[];
export type FetchSavedQueriesFailure = Error;

export const fetchSavedQueriesAction = actionCreator.async<
  FetchSavedQueriesParams,
  FetchSavedQueriesSuccess,
  FetchSavedQueriesFailure
>("FETCH_SAVED_QUERIES");

export function fetchSavedQueries(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchSavedQueriesAction.started());

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsCustomReportsSavedQueryList(
        currentCompany.company.id,
      );
      dispatch(fetchSavedQueriesAction.done({ result: data }));
    } catch (err: any) {
      dispatch(fetchSavedQueriesAction.failed(err));
    }
  };
}
