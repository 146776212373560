import {
  Typography,
  Spacer,
  Flex,
  TextField,
  SplitAnnotation,
} from "ingred-ui";
import * as React from "react";

import { Popover } from "../Popover";

import * as Styled from "./styled";

type Props = {
  title?: string;
  subtitle?: string;
  placeholder?: string;
  searchText?: string;
  description?: string;
  popoverElement?: React.ReactNode;
  children?: React.ReactNode;
  onChange?: (value: string) => void;
};

const PageContent: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  placeholder,
  searchText,
  popoverElement,
  description,
  onChange,
  children,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event.target.value);
  };

  return (
    <Styled.Container>
      {title != null && (
        <Styled.TitleContainer>
          <div>
            <Flex display="flex" alignItems="flex-end" flexWrap="wrap">
              <Typography weight="bold" size="xl">
                {title}
              </Typography>
              {popoverElement && (
                <Spacer ml={1}>
                  <Popover>{popoverElement}</Popover>
                </Spacer>
              )}
              <Spacer pr={1} />
              {description && (
                <SplitAnnotation>
                  <Typography size="sm">{description}</Typography>
                </SplitAnnotation>
              )}
            </Flex>
            {subtitle && (
              <Spacer pt={1}>
                <Typography color="secondary" size="sm">
                  {subtitle}
                </Typography>
              </Spacer>
            )}
          </div>
          {onChange && (
            <Styled.InputContainer>
              <TextField
                icon="search"
                placeholder={placeholder}
                value={searchText}
                onChange={handleChange}
              />
            </Styled.InputContainer>
          )}
        </Styled.TitleContainer>
      )}
      <Styled.ContentContainer>{children}</Styled.ContentContainer>
    </Styled.Container>
  );
};

export { PageContent };
