import { Domain, Response } from "api-types";

import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchDeviceType
 */
export type PatchDeviceTypePayload = {
  deviceType: Domain.DeviceType;
  demandAdCreatives: DemandAdCreative[];
};
export type PatchDeviceTypeParams = void;
export type PatchDeviceTypeSuccess = Response.DemandAccountCreative.PatchDeviceType;
export type PatchDeviceTypeFailure = Error;

export const patchDeviceTypeAction = actionCreator.async<
  PatchDeviceTypeParams,
  PatchDeviceTypeSuccess,
  PatchDeviceTypeFailure
>("PATCH_DEVICE_TYPE");

export function patchDeviceType(
  payload: PatchDeviceTypePayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, demandAccountCreative }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchDeviceTypeAction.started());

    try {
      const res = await demandAccountCreative.patchDeviceType(
        apiClient,
        {
          name: payload.deviceType,
          demand_account_creative_ids: payload.demandAdCreatives.map(
            (dac) => dac.id,
          ),
        },
        currentCompany.company.id,
      );
      dispatch(patchDeviceTypeAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(patchDeviceTypeAction.failed({ error }));
      }
    }
  };
}
