import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { GamAdCreative } from "../../../../domain/gamAdCreative";
import { RootState } from "../../../reducer";
import { getDemandAdCreativeById } from "../../demandAdCreative/selectors/demandAdCreativeSelector";

export const getGamAdCreativeById = (
  state: RootState,
  id: number,
): GamAdCreative | null => {
  const { gamAdCreative } = state.entities;
  const byId = gamAdCreative.byId;
  if (byId[id]) {
    return {
      ...byId[id],
      demand_ad_creatives: byId[id].demand_ad_creatives.map(
        (creativeId) =>
          getDemandAdCreativeById(state, creativeId) as DemandAdCreative,
      ),
    };
  }
  return null;
};

export const getGamAdCreativesByGamLineItemId = (
  state: RootState,
  id: number,
): GamAdCreative[] => {
  const { gamLineItem } = state.entities;
  if (gamLineItem.byId[id]) {
    return gamLineItem.byId[id].gam_ad_creatives.map(
      (creativeId) => getGamAdCreativeById(state, creativeId) as GamAdCreative,
    );
  }
  return [];
};
