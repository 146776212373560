import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * deleteDemandAdCreatives2
 */
export type DeleteDemandAdCreativesPayload = {
  demandAdCreatives: DemandAdCreative[];
};
export type DeleteDemandAdCreatives2Params = void;
export type DeleteDemandAdCreatives2Success = DeleteDemandAdCreativesPayload;
export type DeleteDemandAdCreatives2Failure = Error;

export const deleteDemandAdCreatives2Action = actionCreator.async<
  DeleteDemandAdCreatives2Params,
  DeleteDemandAdCreatives2Success,
  DeleteDemandAdCreatives2Failure
>("DELETE_DEMAND_AD_CREATIVES_2");

export function deleteDemandAdCreatives2(
  payload: DeleteDemandAdCreativesPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(deleteDemandAdCreatives2Action.started());

    try {
      await openApiClient.rpzApiViewsCategoryDeleteCategoryCreative2(
        currentCompany.company.id,
        {
          demand_account_creative_ids: payload.demandAdCreatives.map(
            (demandAdCreative) => demandAdCreative.id,
          ),
        },
      );
      dispatch(
        deleteDemandAdCreatives2Action.done({
          result: payload,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteDemandAdCreatives2Action.failed({ error }));
      }
    }
  };
}
