import { Domain } from "api-types";
import { ActionButton, Flex, Select, Spacer, Table } from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import { BidStrapGamAdUnit } from "../../../../../../../domain/bidStrapGamAdUnit";
import { Site } from "../../../../../../../domain/site";
import { FilterItemStruct, FilterType } from "../../RefineSection";

export const filterOptions: OptionType<FilterType>[] = [
  {
    label: "Bidder",
    value: "bidder",
  },
  {
    label: "サイト",
    value: "site",
  },
  {
    label: "GAM広告ユニット",
    value: "gam_ad_unit",
  },
];

function createOptionsByDimensions({
  sites,
  gamAdUnits,
  bidders,
}: {
  sites: Site[];
  gamAdUnits: BidStrapGamAdUnit[];
  bidders: Domain.Demand[];
}) {
  const optionsByDimensions: {
    [key in FilterType]: OptionType<number>[];
  } = {
    bidder: bidders.map((bidder) => ({
      label: bidder.name,
      value: bidder.id,
    })),
    site: sites.map((site) => ({
      label: site.name,
      value: site.id,
    })),
    gam_ad_unit: gamAdUnits.map((gamAdUnit) => ({
      label: gamAdUnit.original_ad_unit_name,
      value: gamAdUnit.id,
    })),
  };
  return optionsByDimensions;
}

export type Props = {
  id: string;
  selectedKeys: string[];
  filterItem?: FilterItemStruct;
  onEdit: (id: string, value: Partial<Omit<FilterItemStruct, "id">>) => void;
  onDelete: (id: string) => void;
  bidders: Domain.Demand[];
  sites: Site[];
  gamAdUnits: BidStrapGamAdUnit[];
};

const FilterItem: React.FunctionComponent<Props> = ({
  id,
  selectedKeys,
  filterItem,
  onEdit,
  onDelete,
  bidders,
  sites,
  gamAdUnits,
}) => {
  const [selectedDimension, setSelectedDimension] = React.useState<OptionType<
    FilterType
  > | null>(null);
  const [selectedOptions, setSelectedOptions] = React.useState<
    OptionType<number>[]
  >([]);
  const [currentOptions, setCurrentOptions] = React.useState<
    OptionType<number>[]
  >([]);
  // Props からオプションを生成する
  // デマンド広告枠はフィルタ選択後に値をロードするため、propsでは渡されない
  const optionsFromProps = createOptionsByDimensions({
    sites,
    gamAdUnits,
    bidders,
  });

  const updateCurrentOptions = async (key: FilterType) => {
    setCurrentOptions(optionsFromProps[key]);
    return optionsFromProps[key];
  };

  React.useEffect(() => {
    if (!filterItem) return;
    const dimension = filterOptions.find(
      (option) => option.value === filterItem.key,
    ) as OptionType<FilterType>;

    updateCurrentOptions(filterItem.key as FilterType).then((options) => {
      const opts = filterItem.values.map(
        (value) =>
          options.find((item) => item.value == +value) as OptionType<number>,
      );
      setSelectedDimension(dimension);
      setSelectedOptions(opts);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItem]);

  const handleDimensionChange = (option: any) => {
    updateCurrentOptions(option.value);
    setSelectedDimension(option);
    setSelectedOptions([]);
    onEdit(id, {
      key: option.value,
      values: [],
    });
  };
  const handleDelete = () => {
    onDelete(id);
  };
  const handleSelect = (options: any | null) => {
    setSelectedOptions(options);
    if (options) {
      onEdit(id, {
        values: (options as OptionType<number>[]).map((option) => option.value),
      });
    } else {
      onEdit(id, {
        values: [],
      });
    }
  };
  return (
    <Table.Row>
      <Table.HeaderCell width="177px">フィルタアイテム</Table.HeaderCell>
      <Table.Cell>
        <Flex
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Flex display="flex" alignItems="center">
            <Select
              minWidth="220px"
              isClearable={false}
              placeholder="区分を選択してください"
              options={filterOptions.filter(
                (option) => !selectedKeys.includes(option.value),
              )}
              value={selectedDimension}
              onChange={handleDimensionChange}
            />
            {selectedDimension != null && (
              <Spacer pl={2}>
                <Select
                  minWidth="340px"
                  isMulti={true}
                  options={currentOptions}
                  value={selectedOptions}
                  onChange={handleSelect}
                />
              </Spacer>
            )}
          </Flex>
          <ActionButton
            icon="delete_bin"
            className="gaev-detailreport-btn-delete_filteritem"
            onClick={handleDelete}
          >
            削除
          </ActionButton>
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

export { FilterItem };
