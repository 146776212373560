import { Domain } from "api-types";
import {
  Table,
  RadioButton,
  Flex,
  Spacer,
  Typography,
  Checkbox,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import { PageSection } from "../../../../elements/PageSection";
import { Popover } from "../../../../elements/Popover";
import { TableHeaderCell } from "../../../../elements/TableHeaderCell";
import { DisplayFormat } from "../../DetailReport";

import { DisplayFormatImage } from "./internal/DisplayFormatImage";
import * as Styled from "./styled";

type Props = {
  displayFormats: (OptionType<DisplayFormat> & { description: string })[];
  selectedDisplayFormat: DisplayFormat;
  selectedDisplayFilters: Domain.ReportFilters;
  onDisplayFormatChange: (value: DisplayFormat) => void;
  onDisplayFilterChange: (filters: Domain.ReportFilters) => void;
};

const DisplayFormatSection: React.FunctionComponent<Props> = ({
  displayFormats,
  selectedDisplayFormat,
  selectedDisplayFilters,
  onDisplayFormatChange,
  onDisplayFilterChange,
}) => {
  const handleDisplayFormatChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onDisplayFormatChange(event.target.value as DisplayFormat);
  };

  const handleDisplayFormatClick = (graphType: DisplayFormat) => () => {
    onDisplayFormatChange(graphType);
  };

  const handleToggleZeroImpFilter = () => {
    const b = selectedDisplayFilters.exclude_demand_zero_imp == true;
    onDisplayFilterChange({
      ...selectedDisplayFilters,
      exclude_demand_zero_imp: !b,
    });
  };

  const handleToggleZeroPriceFilter = () => {
    const b = selectedDisplayFilters.exclude_demand_zero_price == true;
    onDisplayFilterChange({
      ...selectedDisplayFilters,
      exclude_demand_zero_price: !b,
    });
  };

  return (
    <PageSection title="レポート表示設定">
      <Table>
        <Table.Body>
          <Table.Row>
            <TableHeaderCell width="177px" required={true}>
              <Flex display="flex">
                表示形式
                <Spacer pl={1} />
                <Popover>
                  <Typography size="sm" lineHeight="1.7">
                    レポート結果の表示形式を用途別に3種類から選択できます。
                    <Spacer pt={1} />
                    ※ディメンション及び指標の選択内容によっては、棒グラフが積み上げ式棒グラフで表示される場合もあります。
                  </Typography>
                </Popover>
              </Flex>
            </TableHeaderCell>
            <Table.Cell>
              <Flex display="flex" alignItems="center" flexWrap="wrap">
                {displayFormats.map((displayFormat) => (
                  <Spacer key={displayFormat.value} pr={3}>
                    <RadioButton
                      name="display_format_name"
                      value={displayFormat.value}
                      checked={selectedDisplayFormat === displayFormat.value}
                      onChange={handleDisplayFormatChange}
                    >
                      {displayFormat.label}
                    </RadioButton>
                    <Styled.DisplayFormatImageContainer
                      onClick={handleDisplayFormatClick(displayFormat.value)}
                    >
                      <DisplayFormatImage
                        type={displayFormat.value}
                        active={selectedDisplayFormat === displayFormat.value}
                      />
                    </Styled.DisplayFormatImageContainer>
                    <Styled.DisplayFormatTextContainer>
                      <Typography size="xs" color="secondary" lineHeight="20px">
                        {displayFormat.description}
                      </Typography>
                    </Styled.DisplayFormatTextContainer>
                  </Spacer>
                ))}
              </Flex>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <TableHeaderCell width="177px">
              <Flex display="flex">表示オプション</Flex>
            </TableHeaderCell>
            <Table.Cell>
              <Flex display="flex" alignItems="center" flexWrap="wrap">
                <Spacer pr={1}>
                  <Checkbox
                    checked={selectedDisplayFilters.exclude_demand_zero_imp}
                    onChange={handleToggleZeroImpFilter}
                  >
                    デマンドimpが0の項目を非表示
                  </Checkbox>
                </Spacer>
                <Spacer pr={1}>
                  <Checkbox
                    checked={selectedDisplayFilters.exclude_demand_zero_price}
                    onChange={handleToggleZeroPriceFilter}
                  >
                    デマンド売上が0の項目を非表示
                  </Checkbox>
                </Spacer>
              </Flex>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </PageSection>
  );
};

export { DisplayFormatSection };
