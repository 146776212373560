import { Domain } from "api-types";
import dayjs from "dayjs";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Theme } from "ingred-ui";
import React, { Ref, RefObject } from "react";
import { ThemeContext } from "styled-components";

import { ChartColors } from "../../../constants/chartColors";
import { CustomTooltip } from "../../../html/tooltip";

import * as Styled from "./styled";

function getOptions({
  series,
  unitType,
  colors,
  yAxisFormatter,
  xAxis,
  stacking,
  theme,
}: {
  series: Highcharts.SeriesOptionsType[];
  unitType: Domain.ChartUnitType;
  colors: string[];
  yAxisFormatter?: (value: number) => string;
  xAxis: Highcharts.Options["xAxis"];
  stacking?: Highcharts.OptionsStackingValue;
  theme: Theme;
}) {
  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      zoomType: "xy",
    },
    title: {
      text: "",
    },
    colors,
    xAxis,
    yAxis: [
      {
        labels: {
          formatter: function () {
            if (this.value === 0) {
              return "0";
            }
            if (stacking === "percent") {
              return this.value.toString();
            }
            if (yAxisFormatter) {
              return yAxisFormatter(this.value);
            }
            return this.value.toString();
          },
        },
        title: {
          text: "",
        },
      },
    ],
    legend: {
      reversed: true,
      align: "left",
      backgroundColor: "#F5F7F8",
      padding: 10,
      symbolRadius: 0,
      width: "100%",
    },
    tooltip: {
      useHTML: true,
      formatter: CustomTooltip(unitType, theme),
      shared: false, // shared: trueにすると今のformatterは使えない
      backgroundColor: "",
      borderWidth: 0,
      padding: 0,
    },
    plotOptions: {
      column: {
        stacking: stacking ? stacking : "normal",
        dataLabels: {
          enabled: false,
        },
        borderWidth: 0,
      },
    },
    series: series,
  };

  return options;
}

type Props = {
  data: Domain.ChartData[];
  unitType?: Domain.ChartUnitType;
  yAxisFormatter?: (value: number) => string;
  xAxisType?: "datetime" | "dimension";
};

type State = {
  chartRef: Ref<{
    chart: Highcharts.Chart;
    container: RefObject<HTMLDivElement>;
  }>;
  allLegendsShowable: boolean;
  parcentageStackable: boolean;
};

class StackedBar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleAllLegendsShowable = this.handleAllLegendsShowable.bind(this);
    this.handleParcentageStackable = this.handleParcentageStackable.bind(this);
    this.state = {
      chartRef: React.createRef(),
      allLegendsShowable: false,
      parcentageStackable: false,
    };
  }

  public render() {
    const {
      data,
      unitType = "normal",
      yAxisFormatter,
      xAxisType = "datetime",
    } = this.props;
    const colors =
      data.length < ChartColors.length
        ? ChartColors.slice(-data.length)
        : ChartColors;
    const diff = data.length - colors.length;
    const getColorIndex = (index: number) => {
      if (diff > 0) {
        if (diff - index < 0) {
          return index - diff;
        } else {
          return 0;
        }
      }
      return index;
    };
    const series: Highcharts.SeriesOptionsType[] = data.map((d, i) => ({
      ...d,
      type: d.type != null ? d.type : "column",
      color: d.color != null ? d.color : colors[getColorIndex(i)],
    }));
    let xAxis: Highcharts.Options["xAxis"];
    switch (xAxisType) {
      case "datetime":
        xAxis = {
          type: "datetime",
          labels: {
            formatter: function () {
              return dayjs(this.value).format("M/D");
            },
          },
        };
        break;
      case "dimension":
        xAxis = {
          categories: data[0].data.map((d) => (d as [string, number])[0]),
        };
        break;
    }
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Styled.Container>
            <HighchartsReact
              ref={this.state.chartRef}
              highcharts={Highcharts}
              options={getOptions({
                series,
                unitType,
                colors,
                yAxisFormatter,
                xAxis,
                theme,
                stacking: this.state.parcentageStackable ? "percent" : "normal",
              })}
            />
            <Styled.GraphAction onClick={this.handleAllLegendsShowable}>
              {this.state.allLegendsShowable ? "全て選択" : "全て選択解除"}
            </Styled.GraphAction>
            <Styled.GraphAction onClick={this.handleParcentageStackable}>
              {this.state.parcentageStackable ? "通常棒グラフ" : "割合表示"}
            </Styled.GraphAction>
          </Styled.Container>
        )}
      </ThemeContext.Consumer>
    );
  }

  handleAllLegendsShowable(_e: React.MouseEvent<HTMLElement>) {
    const chartRef = this.state.chartRef as RefObject<{
      chart: Highcharts.Chart;
      container: RefObject<HTMLDivElement>;
    }>;
    if (this.state.allLegendsShowable) {
      chartRef.current?.chart.series.forEach((s) => {
        s.show();
      });
      this.setState({
        allLegendsShowable: false,
      });
    } else {
      if (!this.state.chartRef) return;
      chartRef.current?.chart.series.forEach((s) => {
        s.hide();
      });
      this.setState({
        allLegendsShowable: true,
      });
    }
  }
  handleParcentageStackable(_e: React.MouseEvent<HTMLElement>) {
    if (this.state.parcentageStackable) {
      this.setState({
        parcentageStackable: false,
      });
    } else {
      this.setState({
        parcentageStackable: true,
      });
    }
  }
}

export { StackedBar };
