/* eslint-disable react/jsx-handler-names */
import { Spacer, TextField, Button, ConfirmModal, Flex } from "ingred-ui";
import * as React from "react";
import { useForm } from "react-hook-form";

import { DemandAccount } from "../../../../../../domain/demandAccount";
import { registerIui } from "../../../../../../utils/registerIui";
import { Loading } from "../../../../Loading";

import * as Styled from "./styled";

type YahooAuthData = {
  refresh_token: string;
};

type EditDemandForm = {
  [key: string]: string;
};

type Props = {
  demandAccount: DemandAccount | null;
  isOpen: boolean;
  editRequesting?: boolean;
  fetchRequesting?: boolean;
  onClose?: () => void;
  onSubmit: (data: YahooAuthData) => void;
};

const generateRandomString = (length = 10) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const YahooAuthModal: React.FC<Props> = ({
  demandAccount,
  isOpen,
  editRequesting,
  fetchRequesting,
  onClose,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EditDemandForm>();
  const [randomState, setRandomState] = React.useState<string>(
    generateRandomString(),
  );
  const watchedAuthorizationCode = watch("authorization_code");

  React.useEffect(() => {
    if (isOpen) {
      setRandomState(generateRandomString());
    }
  }, [isOpen]);

  const getColumnValue = (name: string) => {
    return demandAccount?.form_columns?.find((column) => column.name === name)
      ?.value;
  };

  const onOpenYahooLogin = (e: React.MouseEvent) => {
    //別タブを開いたときに、モーダルが閉じてしまう挙動を防止するため。

    e.preventDefault();
    const clientId = getColumnValue("client_id");

    //認可処理の1に当たる。
    if (clientId) {
      const yahooAuthURL = `https://biz-oauth.yahoo.co.jp/oauth/v1/authorize?response_type=code&client_id=${clientId}&redirect_uri=http://localhost&scope=yahooads_network_partner&state=${randomState}`;
      window.open(yahooAuthURL, "_blank");
    } else {
      // eslint-disable-next-line no-console
      console.error("client_id is missing from demandAccount.form_columns");
    }
  };

  const onOpenAccessToken = (e: React.MouseEvent) => {
    e.preventDefault();

    const clientId = getColumnValue("client_id");
    const clientSecret = getColumnValue("client_secret");

    if (clientId && clientSecret) {
      const yahooTokenURL = `https://biz-oauth.yahoo.co.jp/oauth/v1/token?grant_type=authorization_code&client_id=${clientId}&client_secret=${clientSecret}&redirect_uri=http://localhost&code=${watchedAuthorizationCode}`;
      window.open(yahooTokenURL, "_blank");
    } else {
      if (!clientId) {
        console.error("client_id is missing from demandAccount.form_columns"); // eslint-disable-line no-console
      }
      if (!clientSecret) {
        console.error(
          "client_secret is missing from demandAccount.form_columns",
        ); // eslint-disable-line no-console
      }
    }
  };

  const handleFinalSubmit = (data: EditDemandForm) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { authorization_code, ...restData } = data;
    if (onSubmit && demandAccount) {
      onSubmit(restData as YahooAuthData);
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title="yahooAPI利用のためのOAthe認証"
      confirmText="保存する"
      cancelText="キャンセル"
      buttonColor="primary"
      loading={editRequesting}
      onClose={onClose}
      onSubmit={handleSubmit(handleFinalSubmit)}
    >
      <Styled.FormContainer>
        {fetchRequesting ? (
          <Spacer pt={3} pb={2}>
            <Flex display="flex" justifyContent="center" alignItems="center">
              <Loading />
            </Flex>
          </Spacer>
        ) : (
          <Spacer pb={0.5}>
            <Styled.FormGroup>
              <Styled.FormGroupLeft>
                <strong>STEP 1</strong>
              </Styled.FormGroupLeft>
              <Styled.FormGroupRight>
                <Button
                  inline
                  color="primary"
                  size="medium"
                  type="button"
                  onClick={onOpenYahooLogin}
                >
                  yahooへログインし「approve」
                </Button>
              </Styled.FormGroupRight>
            </Styled.FormGroup>

            <Styled.FormGroup>
              <Styled.FormGroupLeft>
                <strong>STEP 2</strong>
              </Styled.FormGroupLeft>
              <Styled.FormGroupRight>
                <TextField
                  {...registerIui(register("authorization_code"))}
                  placeholder="認可コード"
                />
                <Styled.TextContainer>
                  注意:
                  認可コードは10分で無効になります。早めに入力し、STEP3へ進んでください。
                </Styled.TextContainer>
              </Styled.FormGroupRight>
            </Styled.FormGroup>

            <Styled.FormGroup>
              <Styled.FormGroupLeft>
                <strong>STEP 3</strong>
              </Styled.FormGroupLeft>
              <Styled.FormGroupRight>
                <Button
                  inline
                  color="primary"
                  size="medium"
                  type="button"
                  onClick={onOpenAccessToken}
                >
                  認可コードを入力したらクリック
                </Button>
              </Styled.FormGroupRight>
            </Styled.FormGroup>

            <Styled.FormGroup>
              <Styled.FormGroupLeft>
                <strong>STEP 4</strong>
              </Styled.FormGroupLeft>
              <Styled.FormGroupRight>
                <TextField
                  {...registerIui(
                    register("refresh_token" as const, { required: true }),
                  )}
                  placeholder="refresh_token"
                  errorText={
                    errors["refresh_token"] ? "入力されていません" : ""
                  }
                />
                <Styled.TextContainer>
                  注意:
                  access_tokenは不要です。refresh_tokenを入力してください。
                </Styled.TextContainer>
              </Styled.FormGroupRight>
            </Styled.FormGroup>
            <Styled.TextContainer>
              注意:
              このモーダルを閉じると、セッションがリセットされます。再度開始する場合は最初から手続きを行ってください。
            </Styled.TextContainer>
          </Spacer>
        )}
      </Styled.FormContainer>
    </ConfirmModal>
  );
};

export default YahooAuthModal;
