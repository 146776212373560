import styled from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const Content = styled.div<{ maxWidth: string }>`
  margin: ${({ maxWidth }) => (maxWidth === "none" ? 0 : "auto")};
  padding: ${({ theme }) => theme.spacing * 3}px;
  max-width: ${({ maxWidth }) => maxWidth};
`;
