import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchBidStrapGamAdUnits
 */
export type FetchBidStrapGamAdUnitsParams = void;
export type FetchBidStrapGamAdUnitsSuccess = Response.GamAdUnit.BSFetchAll;
export type FetchBidStrapGamAdUnitsFailure = Error;

export const fetchBidStrapGamAdUnitsAction = actionCreator.async<
  FetchBidStrapGamAdUnitsParams,
  FetchBidStrapGamAdUnitsSuccess,
  FetchBidStrapGamAdUnitsFailure
>("FETCH_BID_STRAP_GAM_AD_UNITS");

export function fetchBidStrapGamAdUnits(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { gamAdUnit, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchBidStrapGamAdUnitsAction.started());

    try {
      const res = await gamAdUnit.fetchBidStrapGamAdUnits(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchBidStrapGamAdUnitsAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchBidStrapGamAdUnitsAction.failed({ error }));
      }
    }
  };
}
