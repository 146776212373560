import { Typography, Icon, Spacer } from "ingred-ui";
import * as React from "react";

import { Popover } from "../../../../components/elements/Popover";
import { SelectCompany } from "../../../../components/elements/SelectCompany";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { User } from "../../../../domain/user";
import { selectCompany } from "../../../../store/modules/user/actions/selectCompanyAction";
import { DispatchableAction } from "../../../../store/utils/dispatchable";
import { ActiveNavItem } from "../../AuthedAppShell";
import { Task } from "../Tasks/Tasks";

import * as Styled from "./styled";

type Props = {
  user: User;
  currentCompany: CompanyAttribute;
  navItem: ActiveNavItem;
  selectCompany: DispatchableAction<typeof selectCompany>;
  tasks: Task[];
};

const TopNavigation: React.FunctionComponent<Props> = ({
  user,
  currentCompany,
  navItem,
  selectCompany,
}) => {
  return (
    <Styled.Container>
      <Styled.LeftContent>
        <Styled.TitleContainer>
          <Styled.IconContainer>
            <Icon name={navItem.iconName} type="fill" />
          </Styled.IconContainer>
          <Typography
            component="h1"
            size="xxxl"
            weight="bold"
            color="secondary"
            lineHeight="1"
          >
            {navItem.title}
          </Typography>
          {navItem.popoverText && (
            <Spacer ml={1}>
              <Popover size="lg">
                <Typography size="sm" lineHeight="1.7">
                  {navItem.popoverText}
                </Typography>
              </Popover>
            </Spacer>
          )}
          <Spacer pr={1} />
          {navItem.description && (
            <Styled.DescriptionContainer>
              <Typography size="sm">{navItem.description}</Typography>
            </Styled.DescriptionContainer>
          )}
        </Styled.TitleContainer>
      </Styled.LeftContent>
      <SelectCompany
        currentCompany={currentCompany}
        user={user}
        selectCompany={selectCompany}
      />
    </Styled.Container>
  );
};

export { TopNavigation };
