import {
  SavedCustomReport,
  PostSavedCustomReport,
} from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PostSavedQuery
 */
export type PostSavedQueryParams = void;
export type PostSavedQuerySuccess = SavedCustomReport;
export type PostSavedQueryFailure = Error;

export const postSavedQueryAction = actionCreator.async<
  PostSavedQueryParams,
  PostSavedQuerySuccess,
  PostSavedQueryFailure
>("POST_SAVED_QUERY");

export function postSavedQuery(
  payload: PostSavedCustomReport,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(postSavedQueryAction.started());

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsCustomReportsSavedQueryCreate(
        currentCompany.company.id,
        payload,
      );
      dispatch(postSavedQueryAction.done({ result: data }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(postSavedQueryAction.failed({ error }));
      }
    }
  };
}
