import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchDemandAccounts
 */
export type FetchDemandAccountsParams = void;
export type FetchDemandAccountsSuccess = Response.DemandAccount.FetchAll;
export type FetchDemandAccountsFailure = Error;

export const fetchDemandAccountsAction = actionCreator.async<
  FetchDemandAccountsParams,
  FetchDemandAccountsSuccess,
  FetchDemandAccountsFailure
>("FETCH_DEMAND_ACCOUNTS");

export function fetchDemandAccounts(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { demandAccount, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchDemandAccountsAction.started());

    try {
      const res = await demandAccount.fetchDemandAccounts(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchDemandAccountsAction.done({ result: res }));
    } catch (err) {
      dispatch(
        fetchDemandAccountsAction.failed(err, {
          fatal: true,
        }),
      );
    }
  };
}
