import { Config } from "./";

export const config: Config = {
  API_DOMAIN: "https://api.data-strap.com",
  AUTH0_DOMAIN: "fluct.auth0.com",
  AUTH0_CLIENT_ID: "USqD6FhDDsFxfEZFP0wjcGQwT7K5GALT",
  SENTRY_DSN: "https://8dafabc63ee8462e901c73002d702b01@sentry.io/1882252",
  AG_GRID_TOKEN:
    "CompanyName=fluct, Inc.,LicensedApplication=DATA STRAP,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-023546,ExpiryDate=16_December_2022_[v2]_MTY3MTE0ODgwMDAwMA==8b5dda0e2967921d6dbc12532ae0c5ab",
};
