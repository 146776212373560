import { Domain } from "api-types";
import dayjs from "dayjs";
import {
  Table,
  Flex,
  Spacer,
  RadioButton,
  Select,
  ErrorText,
  Checkbox,
  Typography,
  DateRangePicker,
  DatePicker,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import { getDateRange } from "../../../../../utils/DateRangeUtils";
import { PageSection } from "../../../../elements/PageSection";
import { TableHeaderCell } from "../../../../elements/TableHeaderCell";
import { dimensionOptions } from "../../BidStrapReport";
import * as DetailReportStyled from "../../styled";

const displayPeriodOptions: OptionType<Domain.DateRangeType>[] = [
  {
    label: "昨日",
    value: "yesterday",
  },
  {
    label: "過去7日間",
    value: "last_7_days",
  },
  {
    label: "過去30日間",
    value: "last_30_days",
  },
  {
    label: "過去3ヶ月間",
    value: "last_3_months",
  },
  {
    label: "過去6ヶ月間",
    value: "last_6_months",
  },
  {
    label: "過去1年間",
    value: "last_12_months",
  },
  {
    label: "今月",
    value: "this_month",
  },
  {
    label: "前月",
    value: "last_month",
  },
  {
    label: "カスタム(期間)",
    value: "custom_range",
  },
  {
    label: "カスタム(単一日)",
    value: "custom_single",
  },
];

function getDisplayPeriod(selectedDisplayPeriod?: Domain.DateRangeType) {
  if (!selectedDisplayPeriod) return displayPeriodOptions[0];

  const selectedPeriodOption = displayPeriodOptions.find(
    (option) => option.value === selectedDisplayPeriod,
  );
  return selectedPeriodOption ? selectedPeriodOption : displayPeriodOptions[0];
}

type Props = {
  groupTypes: OptionType<Domain.GroupType>[];
  selectedDimensions: Domain.BidStrapReportDimensionType[];
  selectedDisplayPeriod?: Domain.DateRangeType;
  selectedGroupType: Domain.GroupType;
  displayPeriodErrorText?: string;
  dimensionErrorText?: string;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  onDimensionsChange: (groupType: Domain.BidStrapReportDimensionType[]) => void;
  onPeriodChange: (period: Domain.DateRangeType) => void;
  onGroupTypeChange: (groupType: Domain.GroupType) => void;
  onStartDateChange: (date: dayjs.Dayjs) => void;
  onEndDateChange: (date: dayjs.Dayjs) => void;
};
const DimensionSection: React.FunctionComponent<Props> = ({
  groupTypes,
  selectedDimensions,
  selectedDisplayPeriod,
  selectedGroupType,
  displayPeriodErrorText,
  dimensionErrorText,
  startDate,
  endDate,
  onDimensionsChange,
  onPeriodChange,
  onGroupTypeChange,
  onStartDateChange,
  onEndDateChange,
}) => {
  const [displayPeriod, setDisplayPeriod] = React.useState<
    OptionType<Domain.DateRangeType>
  >(displayPeriodOptions[0]);

  React.useEffect(() => {
    setDisplayPeriod(getDisplayPeriod(selectedDisplayPeriod));
  }, [selectedDisplayPeriod]);

  React.useEffect(() => {
    onPeriodChange(displayPeriod.value);
  }, [displayPeriod, onPeriodChange]);

  const handleDisplayPeriodChange = (option: any) => {
    if (option) {
      setDisplayPeriod(option);
      if (option.value === "custom_range") {
        return;
      }
      if (option.value === "custom_single") {
        onStartDateChange(dayjs().add(-1, "day"));
        return;
      }
      const [startDate, endDate] = getDateRange(option.value);
      onStartDateChange(startDate);
      onEndDateChange(endDate);
    }
  };
  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    onStartDateChange(startDate);
    onEndDateChange(endDate);
    setDisplayPeriod({
      label: "カスタム(期間)",
      value: "custom_range",
    });
  };
  const handleDateChange = (date: dayjs.Dayjs) => {
    onStartDateChange(date);
    onEndDateChange(date);
  };
  const handleGroupTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onGroupTypeChange(event.target.value as Domain.GroupType);
  };
  const handleDimensionsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value as Domain.BidStrapReportDimensionType;
    let result = [];

    if (selectedDimensions.includes(value)) {
      result = selectedDimensions.filter((item) => item !== value);
    } else {
      result = [...selectedDimensions, value];
    }

    onDimensionsChange(result);
  };
  const handleClearDimensions = (_e: React.MouseEvent<HTMLElement>) => {
    selectedDimensions = [];
    onDimensionsChange([]);
  };
  const actions = React.useMemo(
    () =>
      displayPeriodOptions.map((option) => ({
        text: option.label,
        onClick: () => {
          handleDisplayPeriodChange(option);
        },
      })),
    [handleDisplayPeriodChange],
  );
  return (
    <PageSection
      title="ディメンション"
      popoverElement={
        <Typography size="sm" lineHeight="1.7">
          グラフにおけるX軸に相当する項目となります。
          集計単位や表示期間の指定などはこちらで行います。
        </Typography>
      }
    >
      <Table>
        <Table.Body>
          <Table.Row>
            <TableHeaderCell required={true} width="177px">
              集計単位
            </TableHeaderCell>
            <Table.Cell>
              <Flex display="flex" alignItems="center">
                {groupTypes.map((groupType) => (
                  <Spacer key={groupType.value} pr={3}>
                    <RadioButton
                      name="group_type_name"
                      value={groupType.value}
                      checked={selectedGroupType === groupType.value}
                      onChange={handleGroupTypeChange}
                    >
                      {groupType.label}
                    </RadioButton>
                  </Spacer>
                ))}
              </Flex>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <TableHeaderCell required={true} width="177px">
              期間
            </TableHeaderCell>
            <Table.Cell>
              <Flex display="flex">
                <Select
                  isClearable={false}
                  minWidth="152px"
                  value={displayPeriod}
                  options={displayPeriodOptions}
                  maxMenuHeight={300} // 全項目表示するために十分大きく設定している
                  onChange={handleDisplayPeriodChange}
                />
                <Spacer pr={1} />
                {displayPeriod.value === "custom_single" ? (
                  <DatePicker
                    date={startDate}
                    isOutsideRange={(day: dayjs.Dayjs) => day.isAfter(dayjs())}
                    defaultClickAction={displayPeriod.label}
                    actions={actions}
                    onDateChange={handleDateChange}
                  />
                ) : (
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    isOutsideRange={(day: dayjs.Dayjs) => day.isAfter(dayjs())}
                    defaultClickAction={displayPeriod.label}
                    actions={actions}
                    onDatesChange={handleDatesChange}
                  />
                )}
              </Flex>

              {!!displayPeriodErrorText && (
                <Spacer pt={1} className="gaev-detailreport-dimension-error">
                  <ErrorText>{displayPeriodErrorText}</ErrorText>
                </Spacer>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell width="177px">区分</Table.HeaderCell>
            <Table.Cell>
              <Flex display="flex" alignItems="center">
                {dimensionOptions.map((dimension) => (
                  <Spacer key={dimension.value} pr={3} py={1}>
                    <Checkbox
                      checked={selectedDimensions.includes(dimension.value)}
                      value={dimension.value}
                      onChange={handleDimensionsChange}
                    >
                      {dimension.label}
                    </Checkbox>
                  </Spacer>
                ))}
                <DetailReportStyled.ReporSettingtAction
                  onClick={handleClearDimensions}
                >
                  全解除
                </DetailReportStyled.ReporSettingtAction>
                {!!dimensionErrorText && (
                  <Spacer pt={1} className="gaev-detailreport-dimension-error">
                    <ErrorText>{dimensionErrorText}</ErrorText>
                  </Spacer>
                )}
              </Flex>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </PageSection>
  );
};

export { DimensionSection };
