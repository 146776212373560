import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchMappedGamAdUnits
 */
export type FetchMappedGamAdUnitsParams = void;
export type FetchMappedGamAdUnitsSuccess = Response.GamAdUnit.Mapped;
export type FetchMappedGamAdUnitsFailure = Error;

export const fetchMappedGamAdUnitsAction = actionCreator.async<
  FetchMappedGamAdUnitsParams,
  FetchMappedGamAdUnitsSuccess,
  FetchMappedGamAdUnitsFailure
>("FETCH_MAPPED_GAM_AD_UNITS");

export function fetchMappedGamAdUnits(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, gamAdUnit }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchMappedGamAdUnitsAction.started());

    try {
      const res = await gamAdUnit.fetchMappedGamAdUnits(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchMappedGamAdUnitsAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchMappedGamAdUnitsAction.failed({ error }));
      }
    }
  };
}
