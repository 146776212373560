import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchBidStrapGamAdUnitsAction } from "../actions/fetchBidStrapGamAdUnitsAction";

export type BidStrapGamAdUnitState = {
  fetchAll: {
    requesting: boolean;
  };
};

export const initialState: BidStrapGamAdUnitState = {
  fetchAll: {
    requesting: false,
  },
};

export const bidStrapGamAdUnitReducer = reducerWithInitialState(initialState)
  /**
   * Fetch BidStrapGamAdUnits
   */
  .case(fetchBidStrapGamAdUnitsAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchBidStrapGamAdUnitsAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchBidStrapGamAdUnitsAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }));
