import { Domain } from "api-types";

export type BidStrapReport = Domain.BidStrapReport<{ id: number }>;

export function createBidStrapReport(
  data: Domain.BidStrapReport,
): BidStrapReport {
  return {
    ...data,
    records: data.records.map((record, i) => ({
      ...record,
      id: i + 1,
    })),
  };
}
