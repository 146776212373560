import { reducerWithInitialState } from "typescript-fsa-reducers";

import { ReportStatus } from "../../../../domain/reportStatus";
import { arrayToEntityMap } from "../../../../utils/ReducerUtils";
import { fetchAllReportStatusAction } from "../actions/fetchAllReportStatusAction";

export type ReportStatusEntityState = {
  byId: {
    [key: number]: ReportStatus;
  };
  allIds: number[];
};

export const initialState: ReportStatusEntityState = {
  byId: {},
  allIds: [],
};

export const reportStatusEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All reportStatus
   */
  .case(fetchAllReportStatusAction.done, (state, action) => ({
    ...state,
    byId: arrayToEntityMap(action.result),
    allIds: action.result.map((item) => item.id),
  }));
