import { LicenseManager } from "ag-grid-enterprise";
import {
  NavigationRail,
  NavigationRailContext,
  Flex,
  Spacer,
  Icon,
} from "ingred-ui";
import { IconName } from "ingred-ui/dist/components/Icon/Icon";
import * as React from "react";

import Logo from "../../../assets/images/logo.svg";
import LogoText from "../../../assets/images/logo_text.svg";
import Manual from "../../../assets/manual_datastrap.pdf";
import { Link } from "../../components/atoms/Link/index";
import { Footer } from "../../components/elements/Footer";
import { config } from "../../config";
import { StorageKey } from "../../constants/storageKeys";
import { CompanyAttribute } from "../../domain/companyAttribute";
import { GamFlattenedHierarchy } from "../../domain/gamFlattenedHierarchy";
import { Information } from "../../domain/information";
import { fetchUnmappedGamFlattenedHierarchies } from "../../store/modules/gamFlattenedHierarchy/actions/fetchUnmappedGamFlattenedHierarchiesAction";
import { fetchInformations } from "../../store/modules/information/actions/fetchInformationsAction";
import { RouteState } from "../../store/modules/routing/reducer";
import { DispatchableAction } from "../../store/utils/dispatchable";

import { Tasks, TaskType } from "./internal/Tasks";
import TopNavigation from "./internal/TopNavigation";
import * as Styled from "./styled";

LicenseManager.setLicenseKey(config.AG_GRID_TOKEN);

export enum SideNavWidth {
  NARROW = "74px",
  WIDE = "186px",
}

export type NavItem = {
  title: string;
  path: string;
  isActive: boolean;
  taskTypes?: TaskType[];
  popoverText?: string;
  description?: string;
  notificationCount?: number;
};

export type NavigationRailItem = Omit<NavItem, "path"> & {
  iconName: IconName;
  path?: string;
  expansionList?: NavItem[];
};

export type ActiveNavItem = NavItem & {
  iconName: IconName;
};

const createTasks = ({
  gamFlattenedHierarchies,
}: {
  gamFlattenedHierarchies: GamFlattenedHierarchy[];
}) => {
  return [
    {
      title: "紐付け未完了のGAM広告クリエイティブ",
      count: gamFlattenedHierarchies.filter((item) => item.is_mappable).length,
      taskType: TaskType.UNMAPPED_GAM_CREATIVES,
    },
  ];
};

const createNavigationRailContents = (
  path: string,
  {
    informations,
    gamFlattenedHierarchies,
  }: {
    informations: Information[];
    gamFlattenedHierarchies: GamFlattenedHierarchy[];
  },
): NavigationRailItem[] => {
  const tasks = createTasks({
    gamFlattenedHierarchies,
  });
  const routes: NavigationRailItem[] = [
    {
      title: "ダッシュボード",
      iconName: "dashboard",
      isActive: path.includes("/dashboard"),
      expansionList: [
        {
          title: "デフォルト",
          path: "/dashboard",
          isActive: path === "/dashboard",
        },
        {
          title: "カスタム",
          path: "/dashboard/custom",
          isActive: path.includes("/dashboard/custom"),
        },
      ],
    },
    {
      title: "レポート",
      iconName: "bar_chart",
      isActive: path.includes("/report"),
      expansionList: [
        {
          title: "詳細レポート",
          path: "/report",
          isActive: path === "/report",
          popoverText:
            "詳細レポートでは「ディメンション」や「指標」を指定することにより、ダッシュボードより更に詳細なデータを抽出することができます。",
        },
        {
          title: "保存クエリ一覧",
          path: "/report/saved_query",
          isActive: path.includes("/report/saved_query"),
        },
        {
          title: "マーケットトレンド",
          path: "/report/market_trend",
          isActive: path.includes("/report/market_trend"),
          description: "マーケットと自社の比較から収益改善の余地が分かります。",
          popoverText:
            "こちらのページで表示しているマーケットデータはfluct社が提供するGoogle Ad Exchange及びyahooのデータを用いています。",
        },
      ],
    },
    {
      title: "BID STRAP",
      iconName: "bid_strap",
      isActive: path.includes("/bidstrap"),
      expansionList: [
        {
          title: "ダッシュボード",
          path: "/bidstrap",
          isActive: path === "/bidstrap",
          popoverText:
            "HedderBiddingの入札状況を分析して確認することができます。BID STRAPの利用には別途契約が必要です。",
        },
        {
          title: "詳細レポート",
          path: "/bidstrap/bidstrap_report",
          isActive: path.includes("/bidstrap/bidstrap_report"),
        },
      ],
    },
    {
      title: "分析機能",
      iconName: "analytics",
      isActive: path.includes("/analytics_feature"),
      expansionList: [
        {
          title: "ダッシュボード",
          path: "/analytics_feature",
          isActive: path === "/analytics_feature",
          popoverText:
            "分析機能のダッシュボードです。利用には別途契約が必要です。",
        },
      ],
    },
    {
      title: "お知らせ",
      iconName: "notification",
      isActive: path.includes("/informations"),
      expansionList: [
        {
          title: "お知らせ",
          isActive: path === "/informations",
          path: "/informations",
          notificationCount: informations.filter(
            (information) => !information.is_already_read,
          ).length,
        },
        {
          title: "レポート取得状況",
          path: "/informations/report_status",
          isActive: path.includes("/informations/report_status"),
        },
      ],
    },
    {
      title: "設定",
      iconName: "setting",
      isActive: path.includes("/settings"),
      expansionList: [
        {
          title: "デマンド設定",
          path: "/settings",
          isActive: path === "/settings",
        },
        {
          title: "広告枠管理",
          path: "/settings/demand_ad_creatives_manager",
          isActive: path.includes("/settings/demand_ad_creatives_manager"),
        },
        {
          title: "GAMユニット管理",
          path: "/settings/gam_ad_creatives_manager",
          isActive: path.includes("/settings/gam_ad_creatives_manager"),
          taskTypes: [TaskType.UNMAPPED_GAM_CREATIVES],
        },
        {
          title: "CSVインポート機能",
          path: "/settings/csv_importer",
          isActive: path.includes("/settings/csv_importer"),
        },
      ],
    },
  ];

  return routes.map((route) => {
    if (route.expansionList) {
      let sumExpantionCount = 0;
      for (const expantion of route.expansionList) {
        const count = tasks
          .filter((task) => expantion.taskTypes?.includes(task.taskType))
          .reduce(
            (acc, cur) => (acc += cur.count),
            expantion.notificationCount || 0,
          );
        expantion.notificationCount = count;
        sumExpantionCount += count;
      }
      route.notificationCount = sumExpantionCount;
    } else {
      const count = tasks
        .filter((task) => route.taskTypes?.includes(task.taskType))
        .reduce((acc, cur) => (acc += cur.count), route.notificationCount || 0);
      route.notificationCount = count;
    }
    return route;
  });
};

const getPath = (pathname: string) => {
  const matched = pathname.match(/^\/company\/\d+(\/.*)/);
  if (matched && matched[1]) {
    return matched[1];
  }
  return "";
};

const getSelectedNavItem = (navItems: NavigationRailItem[], path: string) => {
  let selectedNavItem: ActiveNavItem | null = null;
  for (const navItem of navItems) {
    if (navItem.expansionList != null) {
      // 完全一致
      let matchedItem = navItem.expansionList.find(
        (item) => item.path === path,
      );

      // 完全一致がなければ部分一致かつ最後に一致した要素を利用
      if (matchedItem == null) {
        for (const item of navItem.expansionList) {
          if (path.includes(item.path)) {
            matchedItem = item;
          }
        }
      }

      if (matchedItem) {
        selectedNavItem = {
          ...matchedItem,
          iconName: navItem.iconName,
        };
      }
    } else {
      // 最長一致
      if (path.includes(navItem.path as string)) {
        selectedNavItem = {
          title: navItem.title,
          path: navItem.path as string,
          isActive: navItem.isActive,
          taskTypes: navItem.taskTypes,
          iconName: navItem.iconName,
          description: navItem.description,
        };
      }
    }
  }
  return selectedNavItem;
};

export type Props = {
  children: React.ReactNode;
};

export type InjectProps = {
  currentCompany: CompanyAttribute | null;
  gamFlattenedHierarchies: GamFlattenedHierarchy[];
  informations: Information[];
  routing: RouteState;
  fetchUnmapped: DispatchableAction<
    typeof fetchUnmappedGamFlattenedHierarchies
  >;
  fetchInformations: DispatchableAction<typeof fetchInformations>;
};

type InjectedProps = Props & InjectProps;

type State = {
  defaultFixed: boolean;
};

class AuthedAppShell extends React.PureComponent<InjectedProps, State> {
  constructor(props: InjectedProps) {
    super(props);
    const navBarState = localStorage.getItem(StorageKey.NAVIGATION_BAR_STATE);
    this.state = {
      defaultFixed: navBarState === "open" || navBarState == null,
    };
  }

  public componentDidMount() {
    this.props.fetchUnmapped();
    this.props.fetchInformations();
  }

  public render() {
    const {
      children,
      gamFlattenedHierarchies,
      routing: { pathname },
      currentCompany,
      informations,
    } = this.props;
    const { defaultFixed } = this.state;

    const path = getPath(pathname);

    const navItems = createNavigationRailContents(path, {
      gamFlattenedHierarchies,
      informations,
    });

    // initial, 404時はnavItemsのどれにも一致しない
    const selectedNavItem = getSelectedNavItem(navItems, path);

    const tasks = createTasks({
      gamFlattenedHierarchies,
    });
    const applicableTasks = tasks.filter((task) =>
      selectedNavItem != null
        ? selectedNavItem.taskTypes?.includes(task.taskType)
        : false,
    );
    const hasTasks = applicableTasks.length > 0;

    return (
      <Styled.Container>
        <NavigationRail.Container
          defaultFixed={defaultFixed}
          onChangeFixed={this.handleChangeFixed}
        >
          <NavigationRail>
            <NavigationRail.Header>
              <NavigationRailContext.Consumer>
                {({ isOpen }) => (
                  <Styled.NavHeaderContainer>
                    <Link href="/">
                      <Styled.LogoContainer isOpen={isOpen}>
                        <Styled.Logo isOpen={isOpen}>
                          <Logo />
                        </Styled.Logo>
                        <LogoText />
                      </Styled.LogoContainer>
                    </Link>
                  </Styled.NavHeaderContainer>
                )}
              </NavigationRailContext.Consumer>
            </NavigationRail.Header>
            <NavigationRail.Content>
              {navItems.map((item) => (
                <React.Fragment key={item.title}>
                  {item.expansionList ? (
                    <NavigationRail.ExpansionMenu
                      key={item.title}
                      title={item.title}
                      isActive={item.isActive}
                      iconName={item.iconName}
                      notificationCount={item.notificationCount}
                      expansionList={item.expansionList.map((expantion) => (
                        <Styled.Link
                          key={expantion.title}
                          href={`/company/${currentCompany?.company.id}${expantion.path}`}
                        >
                          <NavigationRail.ExpansionMenuItem
                            isActive={expantion.isActive}
                            title={expantion.title}
                            notificationCount={expantion.notificationCount}
                          />
                        </Styled.Link>
                      ))}
                    />
                  ) : (
                    <Styled.Link
                      key={item.title}
                      href={`/company/${currentCompany?.company.id}${item.path}`}
                    >
                      <NavigationRail.Menu
                        title={item.title}
                        isActive={item.isActive}
                        iconName={item.iconName}
                        notificationCount={item.notificationCount}
                      />
                    </Styled.Link>
                  )}
                </React.Fragment>
              ))}
              <NavigationRail.ExpansionMenu
                key="ヘルプ"
                title="ヘルプ"
                isActive={path.includes("/contact")}
                iconName="question"
                notificationCount={0}
                expansionList={[
                  <Styled.Link
                    key="管理画面マニュアル"
                    target="blank"
                    href={Manual}
                  >
                    <NavigationRail.ExpansionMenuItem
                      isActive={path.includes("/contact")}
                      title={
                        <Flex
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          管理画面マニュアル
                          <Spacer pr={0.25} />
                          <Icon name="external_link" size="md" color="auto" />
                        </Flex>
                      }
                      notificationCount={0}
                    />
                  </Styled.Link>,
                  <Styled.Link
                    key="お問い合わせ"
                    href="https://forms.gle/puhA9XAK3KMjuEUp9"
                    target="_blank"
                  >
                    <NavigationRail.ExpansionMenuItem
                      isActive={path.includes("/contact")}
                      title="お問い合わせ"
                      notificationCount={0}
                    />
                  </Styled.Link>,
                ]}
              />
            </NavigationRail.Content>
            <NavigationRail.Footer>
              <NavigationRail.Fixture />
            </NavigationRail.Footer>
          </NavigationRail>
          <NavigationRail.MainContent>
            {/* TODO: selectedNavItemがnullの時はTopNavigationのheightを考慮しない */}
            <Styled.TopNavigationContainer showTasks={hasTasks}>
              {selectedNavItem != null && (
                <>
                  <TopNavigation navItem={selectedNavItem} tasks={tasks} />
                  {hasTasks && <Tasks tasks={applicableTasks} />}
                </>
              )}
            </Styled.TopNavigationContainer>
            <Styled.Content showTasks={hasTasks}>{children}</Styled.Content>
            <Styled.FooterContainer>
              <Footer space={1} />
            </Styled.FooterContainer>
          </NavigationRail.MainContent>
        </NavigationRail.Container>
      </Styled.Container>
    );
  }

  public handleChangeFixed = (isFixed: boolean) => {
    localStorage.setItem(
      StorageKey.NAVIGATION_BAR_STATE,
      isFixed ? "open" : "close",
    );
    this.setState({
      defaultFixed: isFixed,
    });
  };
}

export { AuthedAppShell };
