import { Response, Request } from "api-types";
import { AxiosError } from "axios";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PostDemandAccount
 */
export type PostDemandAccountParams = void;
export type PostDemandAccountSuccess = Response.DemandAccount.Post;
export type PostDemandAccountFailure = AxiosError<Response.Error.Errors>;

export const postDemandAccountAction = actionCreator.async<
  PostDemandAccountParams,
  PostDemandAccountSuccess,
  PostDemandAccountFailure
>("POST_DEMAND_ACCOUNT");

export function postDemandAccount(
  payload: Request.DemandAccount.Create,
): ThunkAction<void, any> {
  return async (dispatch, _getState, { demandAccount, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(postDemandAccountAction.started());

    try {
      const res = await demandAccount.postDemandAccount(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(postDemandAccountAction.done({ result: res }));
    } catch (err) {
      dispatch(postDemandAccountAction.failed(err));
    }
  };
}
