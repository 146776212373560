import { Domain, Request } from "api-types";
import { Typography, Spacer, ConfirmModal } from "ingred-ui";
import * as React from "react";

type Props = {
  isOpen: boolean;
  dashboardItem: Domain.DashboardItem | null;
  onClose?: () => void;
  onSubmit?: (data: Request.DashboardItem.Delete) => void;
  loading?: boolean;
};

const DeleteItemModal: React.FunctionComponent<Props> = ({
  isOpen,
  dashboardItem,
  onClose,
  onSubmit,
  loading,
}) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (onSubmit && dashboardItem) {
      onSubmit({ item_id: dashboardItem.id });
    }
  };
  return (
    <ConfirmModal
      isOpen={isOpen}
      title="アイテムを削除"
      confirmText="削除する"
      cancelText="キャンセル"
      buttonColor="danger"
      loading={loading}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit}
    >
      <Spacer pt={2}>
        <Typography lineHeight="1.7">
          {dashboardItem?.title}を削除してもよろしいですか？
        </Typography>
      </Spacer>
    </ConfirmModal>
  );
};

export { DeleteItemModal };
