import { Domain } from "api-types";

import { DemandAccountCreative } from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchDemandAdCreativesBasedOnGam
 */
export type FetchDemandAdCreativesBasedOnGamParams = FetchDemandAdCreativesBasedOnGamPayload;
export type FetchDemandAdCreativesBasedOnGamSuccess = DemandAccountCreative[];
export type FetchDemandAdCreativesBasedOnGamFailure = Error;

export const fetchDemandAdCreativesBasedOnGamAction = actionCreator.async<
  FetchDemandAdCreativesBasedOnGamParams,
  FetchDemandAdCreativesBasedOnGamSuccess,
  FetchDemandAdCreativesBasedOnGamFailure
>("FETCH_DEMAND_AD_CREATIVES_BASED_ON_GAM");

export type FetchDemandAdCreativesBasedOnGamPayload = {
  status: Domain.DemandAccountCreativeStatus;
};

export function fetchDemandAdCreativesBasedOnGam(
  payload: FetchDemandAdCreativesBasedOnGamPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchDemandAdCreativesBasedOnGamAction.started(payload));
    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsDemandAccountCreativeRelationSearch(
        currentCompany.company.id,
        "gam_line_item_creative",
        payload.status,
      );
      dispatch(
        fetchDemandAdCreativesBasedOnGamAction.done({
          params: payload,
          result: data,
        }),
      );
    } catch (err) {
      dispatch(fetchDemandAdCreativesBasedOnGamAction.failed(err));
    }
  };
}
