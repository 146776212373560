import * as React from "react";
import { Scrollbars as CustomScrollbars } from "react-custom-scrollbars-2";

import * as Styled from "./styled";

type Props = {
  minHeight?: number | string;
  maxHeight?: number | string;
  children?: React.ReactNode;
};

const Scrollbars: React.FunctionComponent<Props> = ({
  children,
  minHeight,
  maxHeight,
}) => (
  <Styled.Container>
    <CustomScrollbars
      autoHeight={true}
      autoHeightMin={minHeight}
      autoHeightMax={maxHeight}
    >
      {children}
    </CustomScrollbars>
  </Styled.Container>
);

export { Scrollbars };
