import { Domain } from "api-types";
import { ActionButton, Flex, Select, Spacer, Table } from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import { Category } from "../../../../../../../domain/category";
import { Demand } from "../../../../../../../domain/demand";
import { DemandAdCreativeGroup } from "../../../../../../../domain/demandAdCreativeGroup";
import { GamAdUnit } from "../../../../../../../domain/gamAdUnit";
import { HbDemandType } from "../../../../../../../domain/hbDemandType";
import { fetchMyDemandAccountCreatives } from "../../../../../../../infra/demandAccountCreative/demandAccountCreativeClient";
import { FilterItemStruct, FilterType } from "../../RefineSection";

export const filterOptions: OptionType<FilterType>[] = [
  {
    label: "サイト",
    value: "site",
  },
  {
    label: "カテゴリ1",
    value: "category",
  },
  {
    label: "カテゴリ2",
    value: "category2",
  },
  {
    label: "デマンド",
    value: "demand",
  },
  {
    label: "デバイス",
    value: "device_type",
  },
  {
    label: "チャネル",
    value: "channel_type",
  },
  {
    label: "HBビッダー",
    value: "hb_demand_type",
  },
  {
    label: "デマンド広告枠",
    value: "demand_ad_creative",
  },
  {
    label: "GAM広告ユニット",
    value: "gam_ad_unit",
  },
  {
    label: "OS",
    value: "os_type",
  },
];

const deviceTypes: { label: Domain.DeviceType; value: Domain.DeviceType }[] = [
  {
    label: "unknown",
    value: "unknown",
  },
  {
    label: "sp_web",
    value: "sp_web",
  },
  {
    label: "pc_web",
    value: "pc_web",
  },
  {
    label: "sp_amp",
    value: "sp_amp",
  },
  {
    label: "app",
    value: "app",
  },
];

// ChannelType名とlabel, valueは今の所同じなので、ChannelTypesを利用する
// 個別で変えたくなるものがあればここにベタ書き
// MEMO: constants/channelTypeのChannelTypeNameをlabelに利用してもいいかもしれない
const channelTypes: {
  label: Domain.ChannelType;
  value: Domain.ChannelType;
}[] = Domain.ChannelTypes.map((ct) => {
  return {
    label: ct,
    value: ct,
  };
});

function createOptionsByDimensions({
  demandAdCreativeGroups,
  categories,
  categories2,
  demands,
  gamAdUnits,
  hbDemandTypes,
}: {
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  categories: Category[];
  categories2: Category[];
  demands: Demand[];
  gamAdUnits: GamAdUnit[];
  hbDemandTypes: HbDemandType[];
}) {
  const optionsByDimensions: {
    [key in FilterType]: OptionType<string | number>[];
  } = {
    site: demandAdCreativeGroups.map((group) => ({
      label: group.name,
      value: group.id,
    })),
    category: categories.map((category) => ({
      label: category.name,
      value: category.id,
    })),
    category2: categories2.map((category) => ({
      label: category.name,
      value: category.id,
    })),
    demand: demands.map((d) => ({
      label: d.name,
      value: d.id,
    })),
    gam_ad_unit: gamAdUnits.map((gamAdUnit) => ({
      label: gamAdUnit.original_ad_unit_name,
      value: gamAdUnit.id,
    })),
    hb_demand_type: hbDemandTypes.map((hbDemandType) => ({
      label: hbDemandType.name,
      value: hbDemandType.name,
    })),
    os_type: Domain.osTypes.map((osType) => ({
      label: osType.name,
      value: osType.value,
    })),
    demand_ad_creative: [], // demand_ad_creativeのオプションはcreateDemandAdCreativeOptionsで作る
    device_type: deviceTypes,
    channel_type: channelTypes,
  };
  return optionsByDimensions;
}

export type Props = {
  id: string;
  selectedKeys: string[];
  filterItem?: FilterItemStruct;
  onEdit: (id: string, value: Partial<Omit<FilterItemStruct, "id">>) => void;
  onDelete: (id: string) => void;
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  categories: Category[];
  categories2: Category[];
  demands: Demand[];
  gamAdUnits: GamAdUnit[];
  hbDemandTypes: HbDemandType[];
};

const FilterItem: React.FunctionComponent<Props> = ({
  id,
  selectedKeys,
  filterItem,
  onEdit,
  onDelete,
  demandAdCreativeGroups,
  categories,
  categories2,
  demands,
  gamAdUnits,
  hbDemandTypes,
}) => {
  const [selectedDimension, setSelectedDimension] = React.useState<OptionType<
    FilterType
  > | null>(null);
  const [selectedOptions, setSelectedOptions] = React.useState<
    OptionType<string>[]
  >([]);
  const [currentOptions, setCurrentOptions] = React.useState<
    OptionType<string | number>[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // Props からオプションを生成する
  // デマンド広告枠はフィルタ選択後に値をロードするため、propsでは渡されない
  const optionsFromProps = createOptionsByDimensions({
    demandAdCreativeGroups,
    demands,
    gamAdUnits,
    categories,
    categories2,
    hbDemandTypes,
  });

  // DemandAdCreativeからオプションを作る
  // 現在のところほとんどのフィルタは Props から作られるのでoptionsFromPropsを利用しているが
  // DACはフィルタ選択後に値を決定しているためoptionsFromPropsを利用できない(他の項目と比較して項目が多いので、最初にロードすると画面全体が固まることがあった)
  const createDemandAdCreativeOptions = (
    dacs: Domain.DemandAccountCreativeDetail[],
  ): OptionType<string>[] => {
    return dacs.map((d) => ({
      label: d.demand_account_creative.original_name || "",
      value: d.demand_account_creative.id,
    }));
  };

  const updateCurrentOptions = async (key: FilterType) => {
    if (key == "demand_ad_creative") {
      setLoading(true);
      const dacs = await fetchMyDemandAccountCreatives();
      const options = createDemandAdCreativeOptions(dacs);
      setCurrentOptions(options);
      setLoading(false);
      return options;
    } else {
      setCurrentOptions(optionsFromProps[key]);
      return optionsFromProps[key];
    }
  };

  React.useEffect(() => {
    if (!filterItem) return;
    const dimension = filterOptions.find(
      (option) => option.value === filterItem.key,
    ) as OptionType<FilterType>;

    updateCurrentOptions(filterItem.key as FilterType).then((options) => {
      const opts = filterItem.values.map(
        (value) =>
          options.find((item) => item.value === value) as OptionType<string>,
      );
      setSelectedDimension(dimension);
      setSelectedOptions(opts);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItem]);

  const handleDimensionChange = (option: any) => {
    updateCurrentOptions(option.value);
    setSelectedDimension(option);
    setSelectedOptions([]);
    onEdit(id, {
      key: option.value,
      values: [],
    });
  };
  const handleDelete = () => {
    onDelete(id);
  };
  const handleSelect = (options: any | null) => {
    setSelectedOptions(options);
    if (options) {
      onEdit(id, {
        values: (options as OptionType[]).map((option) => option.value),
      });
    } else {
      onEdit(id, {
        values: [],
      });
    }
  };
  return (
    <Table.Row>
      <Table.HeaderCell width="177px">フィルタアイテム</Table.HeaderCell>
      <Table.Cell>
        <Flex
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Flex display="flex" alignItems="center">
            <Select
              minWidth="220px"
              isClearable={false}
              placeholder="区分を選択してください"
              options={filterOptions.filter(
                (option) => !selectedKeys.includes(option.value),
              )}
              value={selectedDimension}
              onChange={handleDimensionChange}
            />
            {selectedDimension != null && (
              <Spacer pl={2}>
                <Select
                  minWidth="340px"
                  isMulti={true}
                  isLoading={loading}
                  placeholder={
                    loading
                      ? `${selectedDimension?.label}を読み込み中です`
                      : `${selectedDimension?.label}を選択してください`
                  }
                  options={currentOptions}
                  value={selectedOptions}
                  onChange={handleSelect}
                />
              </Spacer>
            )}
          </Flex>
          <ActionButton
            icon="delete_bin"
            className="gaev-detailreport-btn-delete_filteritem"
            onClick={handleDelete}
          >
            削除
          </ActionButton>
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

export { FilterItem };
