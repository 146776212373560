import * as React from "react";

function SvgLichTableFill(props: any) {
  return (
    <svg width={140} height={124} viewBox="0 0 140 124" {...props}>
      <defs>
        <filter
          id="楕円形_207"
          x="71"
          y="50"
          width="65"
          height="65"
          filterUnits="userSpaceOnUse"
        >
          <feOffset in="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodColor="#041c33" floodOpacity="0.239" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <rect width="140" height="124" rx="5" fill="#f5f7f8" />
      <g transform="translate(6017 2398)">
        <rect
          width="26"
          height="12"
          transform="translate(-5980 -2370)"
          fill="#959fa9"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <path
          d="M2,0H12a0,0,0,0,1,0,0V12a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z"
          transform="translate(-5992 -2370)"
          fill="#959fa9"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5980 -2344)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="12"
          height="14"
          transform="translate(-5992 -2344)"
          fill="#f5f7f8"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5980 -2358)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="12"
          height="14"
          transform="translate(-5992 -2358)"
          fill="#f5f7f8"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5980 -2330)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5980 -2316)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="12"
          height="14"
          transform="translate(-5992 -2330)"
          fill="#f5f7f8"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <path
          d="M0,0H12a0,0,0,0,1,0,0V14a0,0,0,0,1,0,0H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
          transform="translate(-5992 -2316)"
          fill="#f5f7f8"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="12"
          transform="translate(-5954 -2370)"
          fill="#959fa9"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5954 -2344)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5954 -2358)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5954 -2330)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5954 -2316)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <path
          d="M0,0H24a2,2,0,0,1,2,2V12a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(-5928 -2370)"
          fill="#959fa9"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5928 -2344)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5928 -2358)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <rect
          width="26"
          height="14"
          transform="translate(-5928 -2330)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
        <path
          d="M0,0H26a0,0,0,0,1,0,0V12a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(-5928 -2316)"
          fill="#fff"
          stroke="#e2e8ea"
          strokeWidth="1"
        />
      </g>
      <g transform="translate(6036.138 2409.138)">
        <g
          transform="matrix(1, 0, 0, 1, -6036.14, -2409.14)"
          filter="url(#楕円形_207)"
        >
          <circle
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(86 65)"
            fill="#959fa9"
          />
        </g>
        <path
          d="M32.852,16.278l-6.9-6.9-6.9,6.9h4.38V21.5a2.151,2.151,0,0,1-2.148,2.148H17.414v-4.38l-6.9,6.9,6.9,6.9v-4.38h3.874A7.191,7.191,0,0,0,28.471,21.5V16.278Z"
          transform="translate(-5954.352 -2348.24)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgLichTableFill;
