import { Typography, Flex, Icon, Spacer } from "ingred-ui";
import React from "react";

import { Link } from "../../atoms/Link";

type Props = {
  href: string;
  color?: string;
  className?: string;
  iconSize?: "sm" | "md" | "lg";
  children?: React.ReactNode;
};

export const LinkWithBlank: React.FC<Props> = ({
  href,
  className,
  iconSize = "sm",
  color = "secondary",
  children,
}) => (
  <Link
    href={href}
    target="blank"
    rel="noopener noreferrer"
    className={className}
  >
    <Flex display="flex" alignItems="center">
      <Typography component="div" size="xs" color={color}>
        {children}
      </Typography>
      <Spacer pr={0.5} />
      <Icon
        name="external_link"
        size={iconSize}
        color={color === "primary" ? "active" : "line"}
      />
    </Flex>
  </Link>
);
