import styled from "styled-components";

import { BreakPoint } from "../../../styles/variables";

export const Container = styled.div`
  padding: 40px ${({ theme }) => theme.spacing * 10}px;
  @media (max-width: ${BreakPoint.MEDIUM}px) {
    padding: 30px ${({ theme }) => theme.spacing * 3}px 0;
  }
`;

export const TextContainer = styled.div`
  padding: ${({ theme }) => theme.spacing * 2}px 0
    ${({ theme }) => theme.spacing * 3.5}px 0;
  line-height: 24px;

  & ol {
    counter-reset: count;
    display: table;
    & li {
      counter-increment: count;
      display: table-row;
      &::before {
        display: table-cell;
      }
    }
  }
  & > ol {
    border-spacing: 2px ${({ theme }) => theme.spacing}px;
    & > li::before {
      content: counter(count) ".";
    }
    & > li > ol {
      border-spacing: ${({ theme }) => theme.spacing}px 3px;
      & > li::before {
        content: "(" counter(count) ")";
      }
    }
  }
`;
