import { Client } from "@sentry/types";

import { config } from "../config";

// TODO: anyやめる
let client: any | null = null;

export async function provideSentry(): Promise<any> {
  if (client != null) {
    return client;
  }

  const sentry = await import("@sentry/browser");

  sentry.init({
    dsn: config.SENTRY_DSN,
    environment: process.env.ENV,
  });

  client = sentry;

  return sentry;
}

export type NotifiableError = Error | string;

/**
 * Sentryへエラーの送信
 */
export async function notifyError(err: NotifiableError): Promise<void> {
  const sentry = (await provideSentry()) as Client;

  if (err instanceof Error) {
    sentry.captureException(err);
  } else {
    sentry.captureMessage(err);
  }
}
