import { Domain } from "api-types";
import { TweenMax, Power3 } from "gsap";
import { Card, Typography, Spacer, ErrorText } from "ingred-ui";
import * as React from "react";

import * as Styled from "./styled";

type Props = {
  title: string;
  value: number;
  forecast_value?: number;
  forecast_status?: Domain.Forecast["status"];
  unitType?: Domain.ChartUnitType;
  digits?: number; // 小数点の桁数
};

const LiteGraph: React.FunctionComponent<Props> = ({
  title,
  value,
  forecast_value,
  forecast_status,
  unitType = "normal",
  digits = 0,
}) => {
  const [displayValue, setDisplayValue] = React.useState(0);
  React.useEffect(() => {
    const from = 0;
    const to = value;
    const obj = { count: from };
    TweenMax.to(obj, 2, {
      count: to,
      ease: Power3.easeInOut,
      onUpdate: () => {
        setDisplayValue(Math.floor(obj.count * 10 ** digits) / 10 ** digits);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [displayForecastValue, setDisplayForecastValue] = React.useState<
    number
  >(0);
  React.useEffect(() => {
    const from = 0;
    const to = forecast_value;
    const obj = { count: from };
    TweenMax.to(obj, 2, {
      count: to,
      ease: Power3.easeInOut,
      onUpdate: () => {
        setDisplayForecastValue(
          Math.floor(obj.count * 10 ** digits) / 10 ** digits,
        );
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecast_value]);

  return (
    <Card flex={1}>
      <Styled.Container>
        <Typography color="secondary" size="sm" weight="bold">
          {title}
        </Typography>
        <Spacer pt={0.5} />
        <Typography size="xxxxxl" weight="bold">
          {unitType === "price"
            ? `¥${displayValue.toLocaleString()}`
            : displayValue.toLocaleString()}
        </Typography>
        {forecast_status ? (
          <Spacer pt={0.5}>
            {forecast_status == "ok" && (
              <Typography color="secondary" size="sm">
                当月着予 |{" "}
                {unitType === "price"
                  ? `¥${displayForecastValue.toLocaleString()}`
                  : displayForecastValue.toLocaleString()}
              </Typography>
            )}
            {forecast_status == "insufficient_data" && (
              <Typography color="secondary" size="sm">
                着予は当月3日以降に表示可能になります
              </Typography>
            )}
            {forecast_status == "loading" && (
              <Typography color="secondary" size="sm">
                着予読込中...
              </Typography>
            )}
            {forecast_status == "error" && (
              <ErrorText>着予データの取得に失敗しました</ErrorText>
            )}
          </Spacer>
        ) : (
          <Spacer pt={3} />
        )}
      </Styled.Container>
    </Card>
  );
};

export { LiteGraph };
