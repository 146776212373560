import { Domain } from "api-types";
import { Typography, Spacer, Flex, ErrorText, Checkbox } from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import { PageSection } from "../../../../elements/PageSection";
import * as DetailReportStyled from "../../styled";

import * as Styled from "./styled";

type Props = {
  indicatorOptions: OptionType<keyof Domain.BidStrapReportIndicator>[];
  selectedIndicators: (keyof Domain.BidStrapReportIndicator)[];
  errorText?: string;
  onSelect: (
    selectedIndicators: (keyof Domain.BidStrapReportIndicator)[],
  ) => void;
};

const IndicatorSection: React.FunctionComponent<Props> = ({
  indicatorOptions,
  selectedIndicators,
  errorText,
  onSelect,
}) => {
  const handleIndicatorsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value as keyof Domain.BidStrapReportIndicator;
    let result = [];

    if (selectedIndicators.includes(value)) {
      result = selectedIndicators.filter((item) => item !== value);
    } else {
      result = [...selectedIndicators, value];
    }

    onSelect(result);
  };

  const handleClearIndicators = (_e: React.MouseEvent<HTMLElement>) => {
    selectedIndicators = selectedIndicators.filter(
      (item) => item.indexOf("gam_") >= 0,
    );
    onSelect(selectedIndicators);
  };

  return (
    <Styled.Container>
      <PageSection
        title="指標"
        popoverElement={
          <Typography size="sm" lineHeight="1.7" component="div">
            グラフにおけるY軸に相当する項目となります。売上やimp、eCPMなどを
            <br />
            デマンド及びGAM別に指定することができます。
          </Typography>
        }
      >
        <Flex display="flex" flexWrap="wrap">
          {indicatorOptions.map((item) => (
            <Spacer key={item.label} pr={3} pb={1}>
              <Flex key={item.label} display="flex">
                <Checkbox
                  error={!!errorText}
                  checked={selectedIndicators.includes(item.value)}
                  value={item.value}
                  onChange={handleIndicatorsChange}
                >
                  {item.label}
                </Checkbox>
              </Flex>
            </Spacer>
          ))}
        </Flex>
        <DetailReportStyled.ReporSettingtAction onClick={handleClearIndicators}>
          全解除
        </DetailReportStyled.ReporSettingtAction>
        {!!errorText && (
          <Spacer pt={2} className="gaev-detailreport-indictor-error">
            <ErrorText>{errorText}</ErrorText>
          </Spacer>
        )}
      </PageSection>
    </Styled.Container>
  );
};

export { IndicatorSection };
