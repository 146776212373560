import * as React from "react";

import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { push } from "../../../../store/modules/routing/actions/PushAction";
import { DispatchableAction } from "../../../../store/utils/dispatchable";

import { TaskItem } from "./internal/TaskItem";
import * as Styled from "./styled";

export enum TaskType {
  UNMAPPED_GAM_CREATIVES = "unmapped_gam_creatives",
}

export type Task = {
  title: string;
  count: number;
  taskType: TaskType;
};

type Props = {
  tasks: Task[];
};

type InjectProps = {
  currentCompany: CompanyAttribute;
  push: DispatchableAction<typeof push>;
};

type InjectedProps = Props & InjectProps;

const Tasks: React.FunctionComponent<InjectedProps> = ({
  currentCompany,
  push,
  tasks,
}) => {
  const handleClick = () => {
    push(
      `/company/${currentCompany.company.id}/settings/gam_ad_creatives_manager/unmapped_creatives`,
    );
  };
  return (
    <Styled.TasksContainer>
      {tasks.map((task) => (
        <Styled.TaskContainer key={task.title}>
          <TaskItem
            title={task.title}
            count={task.count}
            onClick={handleClick}
          />
        </Styled.TaskContainer>
      ))}
    </Styled.TasksContainer>
  );
};

export { Tasks };
