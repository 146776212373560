import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { Domain } from "api-types";
import { Column, Typography } from "ingred-ui";
import * as React from "react";

import { DetailReport } from "../../../../../domain/detailReport";

import { GridColumn } from "./DataTable/DataTable";

type ValueType = "string" | "number" | "first_decimal" | "second_decimal";

export const ColumnFormatMap: {
  [key in Domain.ReportIndicatorType]: {
    displayName: string;
    valueType: ValueType;
    align: "left" | "center" | "right";
  };
} = {
  site_name: {
    displayName: "サイト",
    valueType: "string",
    align: "left",
  },
  category_name: {
    displayName: "カテゴリ1",
    valueType: "string",
    align: "left",
  },
  category2_name: {
    displayName: "カテゴリ2",
    valueType: "string",
    align: "left",
  },
  ad_unit_name: {
    displayName: "広告枠",
    valueType: "string",
    align: "left",
  },
  demand_unit_name: {
    displayName: "デマンド広告枠",
    valueType: "string",
    align: "left",
  },
  demand_name: {
    displayName: "デマンド",
    valueType: "string",
    align: "left",
  },
  device_type_name: {
    displayName: "デバイス",
    valueType: "string",
    align: "left",
  },
  channel_type_name: {
    displayName: "チャネル",
    valueType: "string",
    align: "left",
  },
  hb_demand_type_name: {
    displayName: "HBビッダー",
    valueType: "string",
    align: "left",
  },
  os_type_name: {
    displayName: "OS",
    valueType: "string",
    align: "left",
  },
  date: {
    displayName: "日付",
    valueType: "string",
    align: "left",
  },
  price: {
    displayName: "売上",
    valueType: "number",
    align: "right",
  },
  price_ratio: {
    displayName: "売上比率 (%)",
    valueType: "second_decimal",
    align: "right",
  },
  imp: {
    displayName: "imp",
    valueType: "number",
    align: "right",
  },
  imp_ratio: {
    displayName: "imp比率 (%)",
    valueType: "second_decimal",
    align: "right",
  },
  ecpm: {
    displayName: "eCPM",
    valueType: "first_decimal",
    align: "right",
  },
  click: {
    displayName: "クリック",
    valueType: "number",
    align: "right",
  },
  ctr: {
    displayName: "CTR (%)",
    valueType: "second_decimal",
    align: "right",
  },
  vimp: {
    displayName: "vimp",
    valueType: "number",
    align: "right",
  },
  view_rate: {
    displayName: "View rate (%)",
    valueType: "first_decimal",
    align: "right",
  },
  cpc: {
    displayName: "CPC",
    valueType: "first_decimal",
    align: "right",
  },
  gam_imp: {
    displayName: "GAM imp",
    valueType: "number",
    align: "right",
  },
  gam_ecpm: {
    displayName: "GAM eCPM",
    valueType: "first_decimal",
    align: "right",
  },
  gam_vimp: {
    displayName: "GAM vimp",
    valueType: "number",
    align: "right",
  },
  gam_view_rate: {
    displayName: "GAM View rate (%)",
    valueType: "first_decimal",
    align: "right",
  },
  fill_rate: {
    displayName: "fill rate (%)",
    valueType: "first_decimal",
    align: "right",
  },
  request: {
    displayName: "合計コード配信数",
    valueType: "number",
    align: "right",
  },
  unfilled_imp: {
    displayName: "埋められなかったimp",
    valueType: "number",
    align: "right",
  },
  unfilled_imp_rate: {
    displayName: "埋められなかったimpの比率（%）",
    valueType: "first_decimal",
    align: "right",
  },
};

export function getFormattedValue(
  row: Partial<Domain.ReportRecord> & { id: number },
  headerKey: keyof Partial<Domain.ReportRecord>,
) {
  if (row[headerKey] === "") {
    return "-";
  }
  if (row[headerKey] === 0) {
    return "0";
  }

  const valueType = ColumnFormatMap[headerKey].valueType;

  switch (valueType) {
    case "first_decimal":
      const roundedValue = Math.round(Number(row[headerKey]) * 10) / 10;
      return parseFloat(roundedValue.toFixed(1)).toLocaleString();

    // サーバーからはすべて少数第２位で返される
    case "second_decimal":
      return parseFloat(Number(row[headerKey]).toFixed(2)).toLocaleString();
    case "number":
      return Math.round(Number(row[headerKey])).toLocaleString();
    case "string":
      // 両方Partialな為 as しているが、
      // rowとheaderKeyは必ず整合性がとれた状態のデータが渡される
      return row[headerKey] as string;
  }
}

export function createColumns(data: DetailReport) {
  return data.headers.map<
    Column<Partial<Domain.ReportRecord> & { id: number }>
  >((header) => ({
    name: ColumnFormatMap[header].displayName,
    // selectorで.toLocaleString()すると正しくソート出来ないので
    // renderCellで整形する
    selector: (row) => row[header] as string | number,
    renderCell: (row) => (
      <Typography align={ColumnFormatMap[header].align}>
        {getFormattedValue(row, header)}
      </Typography>
    ),
    sortable: true,
  }));
}

function createHeaders(data: DetailReport) {
  const headers: Domain.ReportIndicatorType[] = [];
  data.headers.forEach((header: Domain.ReportIndicatorType) => {
    headers.push(header);
    if (header === "imp" || header === "price") {
      const ratio = `${header}_ratio` as Domain.ReportIndicatorType;
      headers.push(ratio);
    }
  });
  return headers;
}

export function createGridColumns(data: DetailReport): GridColumn[] {
  const headers = createHeaders(data);
  return headers.map((header) => ({
    displayName: ColumnFormatMap[header].displayName,
    key: header,
    isDimension: Domain.reportDimensions.includes(header),
    valueGetter: (params: ValueGetterParams) => {
      if (!params.node?.group) {
        return getFormattedValue(params.data, header);
      } else {
        return "";
      }
    },
    valueFormatter: (isPivotMode: boolean) => {
      if (isPivotMode) {
        // pivotModeの場合、aggDataのkeyが動的に生成されどの指標か判別がつかないため何もしない
        return;
      }
      return (params: ValueFormatterParams) => {
        if (!params.node?.group) {
          return getFormattedValue(params.data, header);
        } else {
          return getFormattedValue(params.node?.aggData, header);
        }
      };
    },
  }));
}
