import { Domain, Response } from "api-types";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import {
  Typography,
  Spinner,
  Spacer,
  Flex,
  Icon,
  ErrorText,
  DropdownButton,
} from "ingred-ui";
import { ContentProp } from "ingred-ui/dist/components/MenuList/MenuList";
import React, { useCallback, useEffect, useState } from "react";

import {
  fetchBidStrapReport,
  SearchBidStrapReportParams,
} from "../../../../../infra/bidStrapReport/bidStrapReportClient";
import { createAPIClient } from "../../../../../utils/APIClientUtils";
import { groupBy } from "../../../../../utils/ArrayUtils";
import { MultipleLine } from "../../../../elements/MultipleLine";
import { StackedBar } from "../../../../elements/StackedBar";
import { indicatorOptions } from "../../../BidStrapReport/BidStrapReport";

import * as Styled from "./styled";

type Props = {
  companyId: number;
  span?: number;
  graphType: "bar" | "line";
  fetchParams: SearchBidStrapReportParams;
  title?: string;
  onClickReportPageLink: (params: SearchBidStrapReportParams) => void;
  onSelectIndicator: (indicator: Domain.BidStrapReportIndicatorType) => void;
  makeChartData?: (
    records: Partial<Domain.BidStrapReportRecord>[],
  ) => Domain.ChartData[];
  yAxisFormatter?: (value: number) => string;
};

export const defaultMakeChartData = (
  records: Partial<Domain.BidStrapReportRecord>[],
  indicatorName: Domain.BidStrapReportIndicatorType,
) => {
  const reportsGroupByBidder = groupBy<
    string | undefined,
    Partial<Domain.BidStrapReportRecord>
  >(records, (record) => record.bidder);
  return reportsGroupByBidder.map((report) => ({
    name: report[0]?.replace("bidder_", ""),
    data: report[1].map((record) => [
      dayjs(record.date).valueOf(),
      record[indicatorName],
    ]),
  }));
};

export const BidStrapGraphCard: React.FC<Props> = ({
  companyId,
  span = 1,
  graphType,
  fetchParams,
  title,
  onClickReportPageLink,
  onSelectIndicator,
  makeChartData,
  yAxisFormatter,
}) => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [chartData, setChartData] = useState<Domain.ChartData[]>([
    // MultipleBarは初期値がないとエラーが出てしまう
    {
      name: "",
      data: [],
    },
  ]);

  const indicatorMenuList = indicatorOptions.map((indicator) => {
    return {
      text: indicator.label,
      onClick: () => {
        onSelectIndicator(indicator.value);
      },
      type: "default",
    } as ContentProp;
  });

  const makeTitle = (indicatorName: string, groupType: string) => {
    const option = indicatorOptions.find(
      (option) =>
        option.value === (indicatorName as Domain.BidStrapReportIndicatorType),
    );
    const group = groupType === "day" ? "日別" : "月別";
    return `${option?.label}(${group})`;
  };

  const getData = useCallback(async () => {
    setDataLoading(true);
    setErrorText("");
    const data = await fetchBidStrapReport(
      createAPIClient(),
      fetchParams,
      companyId,
    ).catch((error: AxiosError<Response.Error.Errors>) => {
      setErrorText(
        error.response?.data.errors?.[0]?.message ?? "エラーが発生しました",
      );
    });
    if (!data) return;

    if (makeChartData) {
      setChartData(makeChartData(data.bidstrap_reports.records));
    } else {
      setChartData(
        defaultMakeChartData(
          data.bidstrap_reports.records,
          fetchParams.indicator_names[0],
        ) as Domain.ChartData[],
      );
    }
    setDataLoading(false);
  }, [companyId, fetchParams, makeChartData]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!chartData.length) setErrorText("データが存在しません");
  }, [chartData]);

  const handleClick = () => {
    onClickReportPageLink(fetchParams);
  };

  return (
    <Styled.Container span={span}>
      <Styled.TitleContainer>
        <Typography weight="bold" size="xl">
          {title
            ? title
            : makeTitle(
                fetchParams.indicator_names[0],
                fetchParams.group_type_name,
              )}
        </Typography>
        <DropdownButton
          contents={indicatorMenuList}
          color="secondary"
          size="small"
        >
          指標を変更する
        </DropdownButton>
      </Styled.TitleContainer>
      <Styled.Content>
        {errorText && <ErrorText>{errorText}</ErrorText>}
        {chartData.length ? (
          <div>
            {!dataLoading && (
              <div>
                {graphType === "line" ? (
                  <MultipleLine
                    data={chartData}
                    unitType="normal"
                    yAxisFormatter={yAxisFormatter}
                  />
                ) : (
                  <StackedBar
                    data={chartData}
                    yAxisFormatter={yAxisFormatter}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div />
        )}
        <Spacer pt={2}>
          <Flex display="flex" alignItems="center" justifyContent="flex-end">
            <Styled.QueryDetailLink onClick={handleClick}>
              <Typography color="link">クエリの詳細を見る</Typography>
              <Icon name="arrow_right" color="active" />
            </Styled.QueryDetailLink>
          </Flex>
        </Spacer>

        {dataLoading && (
          <Styled.LoadingContainer>
            <Spinner />
          </Styled.LoadingContainer>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
