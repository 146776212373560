import { Domain } from "api-types";
import { reducerWithInitialState } from "typescript-fsa-reducers";

import { resetErrors } from "../actions/ResetErrorsAction";
import { updateErrors } from "../actions/UpdateErrorsAction";
import { updateFatal } from "../actions/UpdateFatalAction";
import { updateUnavailable } from "../actions/UpdateUnavailableAction";

export type AppState = {
  fatal: boolean;
  unavailable: boolean;
  errors: Domain.Error[];
};

export const initialState = {
  fatal: false,
  unavailable: false,
  errors: [],
};

export const appReducer = reducerWithInitialState<AppState>(initialState)
  .case(updateFatal, (state, payload) => ({
    ...state,
    fatal: payload,
  }))
  .case(updateErrors, (state, payload) => ({
    ...state,
    errors: payload,
  }))
  .case(resetErrors, (state) => ({
    ...state,
    errors: [],
  }))
  .case(updateUnavailable, (state, payload) => ({
    ...state,
    unavailable: payload,
  }));
