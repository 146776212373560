import { Request, Response } from "api-types";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchSitesParams = void;
export type FetchSitesResponse = Response.Site.FetchAll;

export async function fetchSites(apiClient: APIClient, companyId: number) {
  const { data } = await apiClient.get<FetchSitesResponse>("/v1/sites", {
    headers: {
      "X-Company-Id": companyId,
    },
  });

  return data;
}

export type FetchBidStrapSitesParams = void;
export type FetchBidStrapSitesResponse = Response.Site.FetchAll;

export async function fetchBidStrapSites(
  apiClient: APIClient,
  companyId: number,
) {
  const { data } = await apiClient.get<FetchBidStrapSitesResponse>(
    "/v1/bid_strap/sites",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type PostSiteParams = Request.Site.Post;
export type PostSiteResponse = Response.Site.Post;

export async function postSite(
  apiClient: APIClient,
  params: PostSiteParams,
  companyId: number,
) {
  const { data } = await apiClient.post<PostSiteResponse>("/v1/sites", params, {
    headers: {
      "X-Company-Id": companyId,
    },
  });

  return data;
}

export type PatchSiteParams = Request.Site.Patch;
export type PatchSiteResponse = Response.Site.Patch;

export async function patchSiteById(
  apiClient: APIClient,
  params: PatchSiteParams,
  companyId: number,
) {
  const { data } = await apiClient.patch<PatchSiteResponse>(
    `/v1/sites/${params.site_id}`,
    {
      name: params.name,
    },
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type DeleteSiteParams = Request.Site.Delete;
export type DeleteSiteResponse = void;

export async function deleteSiteById(
  apiClient: APIClient,
  params: DeleteSiteParams,
  companyId: number,
) {
  const { data } = await apiClient.delete(`/v1/sites/${params.site_id}`, {
    headers: {
      "X-Company-Id": companyId,
    },
  });

  return data;
}
