import styled from "styled-components";

export const Container = styled.div`
  display: inline-flex;

  div[class$="-control"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  input {
    border-left: 1px solid ${({ theme }) => theme.palette.divider};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
