import { useEffect, useState } from "react";

/**
 * 単一の要素がviewport上に表示されているかを監視する
 */
export function useIntersectionObserver(
  ref: React.RefObject<HTMLElement>,
  defaultIntersecting = false,
) {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(
    defaultIntersecting,
  );

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver((entries) => {
      setIsIntersecting(entries[0].isIntersecting);
    });
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
