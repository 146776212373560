import * as AnalyticsFeature from "../../components/pages/AnalyticsFeature";
import * as BidStrapDashBoard from "../../components/pages/BidStrapDashBoard";
import * as BidStrapReport from "../../components/pages/BidStrapReport";
import * as CSVImporter from "../../components/pages/CSVImporter";
import * as CustomDashboard from "../../components/pages/CustomDashboard";
import * as Dashboard from "../../components/pages/Dashboard";
import * as DetailReport from "../../components/pages/DetailReport";
import * as Informations from "../../components/pages/Informations";
import * as MarketTrend from "../../components/pages/MarketTrend";
import * as ReportStatuses from "../../components/pages/ReportStatuses";
import * as SavedQuery from "../../components/pages/SavedQuery";
import * as Categories from "../../components/pages/demandSettings/Categories";
import * as DemandAdCreativesManager from "../../components/pages/demandSettings/DemandAdCreativesManager";
import * as GamAdCreatives from "../../components/pages/demandSettings/GamAdCreatives";
import * as GamAdCreativesManager from "../../components/pages/demandSettings/GamAdCreativesManager";
import * as GamLineItems from "../../components/pages/demandSettings/GamLineItems";
import * as Sites from "../../components/pages/demandSettings/Sites";
import * as DemandSettings from "../../components/pages/demandSettings/Top";
import * as UnmappedGAMAdCreatives from "../../components/pages/demandSettings/UnmappedGAMAdCreatives";
import { AuthorityConstraint } from "../../utils/AuthUtils";
import { RouteWithConstraint } from "../types";
import { action } from "../utils";

// /company/:id/* のルーティングを定義
// constraintは全てAuthorityConstraint.AUTHED
export const companyRoutes: RouteWithConstraint[] = [
  {
    path: "/dashboard",
    children: [
      {
        path: "",
        action: action({ ...Dashboard }),
      },
      {
        path: "/custom",
        action: action({ ...CustomDashboard }),
      },
    ],
  },
  {
    path: "/report",
    children: [
      {
        path: "",
        action: action({ ...DetailReport }),
      },
      {
        path: "/saved_query",
        action: action({ ...SavedQuery }),
      },
      {
        path: "/bidstrap_report",
        action: action({ ...BidStrapReport }),
        // TODO: BIDSTRAP契約済ユーザーだけがみえるようにする必要がある
      },
      {
        path: "/market_trend",
        action: action({ ...MarketTrend }),
      },
    ],
  },
  {
    path: "/bidstrap",
    children: [
      {
        path: "",
        action: action({ ...BidStrapDashBoard }),
      },
      {
        path: "/bidstrap_report",
        action: action({ ...BidStrapReport }),
      },
    ],
  },
  {
    path: "/analytics_feature",
    children: [
      {
        path: "",
        action: action({ ...AnalyticsFeature }),
        constraint: AuthorityConstraint.AUTHED_ANALYTICS_FEATURE_ALLOWED,
      },
    ],
  },
  {
    path: "/informations",
    children: [
      {
        path: "",
        action: action({ ...Informations }),
      },
      {
        path: "/report_status",
        action: action({ ...ReportStatuses }),
      },
    ],
  },
  {
    path: "/settings",
    children: [
      {
        path: "",
        action: action({ ...DemandSettings }),
        constraint: AuthorityConstraint.AUTHED_DEMAND_SETTING_ALLOWED,
      },
      {
        path: "/demand_ad_creatives_manager",
        children: [
          {
            path: "",
            action: action({ ...DemandAdCreativesManager }),
          },
          {
            path: "/sites",
            action: action({ ...Sites }),
          },
          {
            path: "/categories",
            action: action({ ...Categories }),
          },
        ],
      },
      {
        path: "/gam_ad_creatives_manager",
        children: [
          {
            path: "",
            action: action({ ...GamAdCreativesManager }),
          },
          {
            path: "/units",
            children: [
              {
                path: "/:unit_id",
                children: [
                  {
                    path: "",
                    action: action({ ...GamLineItems }),
                  },
                  {
                    path: "/line_items",
                    children: [
                      {
                        path: "/:line_item_id",
                        action: action({ ...GamAdCreatives }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "/unmapped_creatives",
            action: action({ ...UnmappedGAMAdCreatives }),
          },
        ],
      },
      {
        path: "/csv_importer",
        action: action({ ...CSVImporter }),
      },
    ],
  },
];
