import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PostDemandAdCreativeGroup
 */
export type PostDemandAdCreativeGroupParams = void;
export type PostDemandAdCreativeGroupSuccess = Response.Site.Post;
export type PostDemandAdCreativeGroupFailure = Error;

export const postDemandAdCreativeGroupAction = actionCreator.async<
  PostDemandAdCreativeGroupParams,
  PostDemandAdCreativeGroupSuccess,
  PostDemandAdCreativeGroupFailure
>("POST_DEMAND_AD_CREATIVE_GROUP");

export function postDemandAdCreativeGroup(
  payload: Request.Site.Post,
): ThunkAction<void, any> {
  return async (dispatch, _getState, { site, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(postDemandAdCreativeGroupAction.started());

    try {
      const res = await site.postSite(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(postDemandAdCreativeGroupAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(postDemandAdCreativeGroupAction.failed({ error }));
      }
    }
  };
}
