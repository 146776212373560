import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchCategories
 */
export type FetchCategoriesParams = void;
export type FetchCategoriesSuccess = Response.Category.FetchAll;
export type FetchCategoriesFailure = Error;

export const fetchCategoriesAction = actionCreator.async<
  FetchCategoriesParams,
  FetchCategoriesSuccess,
  FetchCategoriesFailure
>("FETCH_CATEGORIES");

export function fetchCategories(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, category }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchCategoriesAction.started());

    try {
      const res = await category.fetchCategories(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchCategoriesAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchCategoriesAction.failed({ error }));
      }
    }
  };
}
