import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchUnmappedGamFlattenedHierarchiesAction } from "../actions/fetchUnmappedGamFlattenedHierarchiesAction";

export type GamFlattenedHierarchyState = {
  fetchUnmapped: {
    requesting: boolean;
  };
};

export const initialState: GamFlattenedHierarchyState = {
  fetchUnmapped: {
    requesting: false,
  },
};

export const gamFlattenedHierarchyReducer = reducerWithInitialState(
  initialState,
)
  /**
   * Fetch Unmapped GamFlattenedHierarchy
   */
  .case(fetchUnmappedGamFlattenedHierarchiesAction.started, (state) => ({
    ...state,
    fetchUnmapped: {
      requesting: true,
    },
  }))
  .case(fetchUnmappedGamFlattenedHierarchiesAction.done, (state) => ({
    ...state,
    fetchUnmapped: {
      requesting: false,
    },
  }))
  .case(fetchUnmappedGamFlattenedHierarchiesAction.failed, (state) => ({
    ...state,
    fetchUnmapped: {
      requesting: false,
    },
  }));
