import styled from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
`;
