import * as React from "react";

import { RouteActionPayload } from "../../../routing/types";

import { Terms } from "./Terms";

export async function action(): Promise<RouteActionPayload> {
  return {
    content: <Terms />,
  };
}
