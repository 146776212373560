import { Domain } from "api-types";

export const channelTypeName: { [key in Domain.ChannelType]: string } = {
  thirdparty: "3rd Party",
  prebid: "Prebid",
  openbidding: "Open Bidding",
  tam: "TAM",
  adx: "AdX",
  firstparty: "1st Party",
  prebid_flux: "FLUX(Prebid)",
  prebid_bidstrap: "BID STRAP(Prebid)",
  admob_network: "AdMob Network",
  admob_network_waterfall: "AdMob Network Waterfall",
};
