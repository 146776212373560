import styled from "styled-components";

import { Color, BreakPoint, Radius, Depth } from "../../styles/variables";

export const Main = styled.main`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`;

export const DevIconContainer = styled.div`
  position: fixed;
  right: ${({ theme }) => theme.spacing * 2}px;
  bottom: ${({ theme }) => theme.spacing * 3}px;
  z-index: ${Depth.devIcon};
  @media (max-width: ${BreakPoint.MEDIUM}px) {
    top: calc(57px / 2);
    left: auto;
    right: ${36 + 21 + 36}px;
    bottom: auto;
    transform: translateY(-50%);
  }
`;

export const DevIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${Color.primary.main}66;
  box-shadow: 0px 3px 16px #0b82f466;
  pointer-events: none;
  @media (max-width: ${BreakPoint.MEDIUM}px) {
    width: auto;
    height: auto;
    padding: ${({ theme }) => theme.spacing}px
      ${({ theme }) => theme.spacing * 2}px;
    border-radius: ${Radius.MEDIUM};
  }
`;
