import { User } from "../../domain/user";
import { updateCompanyAction } from "../../store/modules/user/actions/updateCompanyAction";
import { getCompanyAttributeByCompanyId } from "../../store/modules/user/selector/companyAttributeSelector";
import {
  getCurrentCompanyIdByUrl,
  getCurrentCompany,
} from "../../utils/CompanyUtils";
import { Middleware } from "../types";
import { getTopPagePath } from "../utils";

export function createCompanyMiddleware(): Middleware {
  return async (context) => {
    const store = context.store;
    const dispatch = store.dispatch;
    const user = store.getState().user.me.data;

    const currentCompanyIdByUrl = getCurrentCompanyIdByUrl(context.pathname);

    // :idにstringが入ってる場合
    if (currentCompanyIdByUrl == null) {
      const currentCompany = getCurrentCompany(user);

      // localStorageにccidがある かつ そこに所属している
      // もしくは 所属企業が一つしかない場合
      if (currentCompany != null) {
        dispatch(updateCompanyAction({ company: currentCompany }));
        return {
          redirectTo: getTopPagePath(currentCompany.company.id),
        };
      }
      return {
        redirectTo: "/select-company",
      };
    }

    const currentCompany = store.getState().user.currentCompany;

    // currentCompanyが未選択 もしくは currentCompanyが変更された場合
    if (
      currentCompany == null ||
      currentCompany.company.id !== currentCompanyIdByUrl
    ) {
      const companyAttribute = getCompanyAttributeByCompanyId(
        user as User,
        currentCompanyIdByUrl,
      );

      if (companyAttribute != null) {
        dispatch(updateCompanyAction({ company: companyAttribute }));
      } else {
        // 所属してない企業のidがurlにあるとき
        const currentCompany = getCurrentCompany(user);
        if (currentCompany != null) {
          dispatch(updateCompanyAction({ company: currentCompany }));
          return {
            redirectTo: getTopPagePath(currentCompany.company.id),
          };
        }
        return {
          redirectTo: "/select-company",
        };
      }
    }

    // /company/:id -> /company/:id${TOP_PAGE}へリダイレクト
    if (context.pathname.match(/^\/company\/\d+\/?$/)) {
      return {
        redirectTo: getTopPagePath(currentCompanyIdByUrl),
      };
    }

    return context.next();
  };
}
