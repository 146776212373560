import { Typography, Icon, Spacer, useTheme } from "ingred-ui";
import * as React from "react";

import * as Styled from "./styled";

type Props = {
  title: string;
  count: number;
  onClick: () => void;
};

const TaskItem: React.FunctionComponent<Props> = ({
  title,
  count,
  onClick,
}) => {
  const theme = useTheme();
  return (
    // eslint-disable-next-line react/jsx-handler-names
    <Styled.Container onClick={onClick}>
      <Spacer pr={3}>
        <Typography color="primary" weight="bold">
          {title}
        </Typography>
      </Spacer>
      <Styled.Count active={count > 0}>{count}</Styled.Count>
      <Icon name="arrow_right" color={theme.palette.primary.main} size="lg" />
    </Styled.Container>
  );
};

export { TaskItem };
