import { Card } from "ingred-ui";
import styled, { keyframes } from "styled-components";

import { Size } from "../../../styles/variables";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.depth.modal};
`;

const slideIn = keyframes`
  0% {
    transform: translate(-50%, calc(-50% + 8px));
  }
  100% {
    transform: translate(-50%, -50%);
  }
`;
export const ModalContainer = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.palette.background.default};
  animation: ${slideIn} 0.4s;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
`;

export const ScrollContainer = styled.div`
  height: 100%;
  padding-bottom: ${({ theme }) =>
    theme.spacing * 2 * 2 +
    40}px; /* ModalFooterの高さ(padding上下 + Button size="medium"の高さ) */
  overflow-y: scroll;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing * 3}px;
  border-bottom: ${Size.Border.Small} solid
    ${({ theme }) => theme.palette.gray.light};
`;

export const TitleContainer = styled.div`
  max-width: 70%;
`;

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 77px); /* header分を引く */
  overflow-x: hidden;
`;

export const StepContainer = styled.div<{
  right: string;
  isCurrentStep: boolean;
}>`
  visibility: ${({ isCurrentStep }) => (isCurrentStep ? "visible" : "hidden")};
  position: absolute;
  top: 0;
  right: ${({ right }) => right};
  width: 100%;
  height: 100%;
  transition: right 0.3s;
`;

export const ModalFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing * 2}px
    ${({ theme }) => theme.spacing * 3}px;
  background-color: ${({ theme }) => theme.palette.gray.light};
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
