import { Domain } from "api-types";
import { Column, Typography } from "ingred-ui";
import * as React from "react";

type ValueType =
  | "string"
  | "number"
  | "first_decimal"
  | "second_decimal"
  | "percentage";

export const ColumnFormatMap: {
  [key in Domain.SCRatioIndicatorType]: {
    displayName: string;
    valueType: ValueType;
    align: "left" | "center" | "right";
  };
} = {
  name: {
    displayName: "広告主",
    valueType: "string",
    align: "left",
  },
  ratio: {
    displayName: "売上比率(%)",
    valueType: "percentage",
    align: "left",
  },
  ecpm: {
    displayName: "eCPM(円)",
    valueType: "second_decimal",
    align: "left",
  },
};

export function getFormattedValue(
  row: Partial<Domain.SaleCompositionRatioData> & { id: number },
  headerKey: keyof Partial<Domain.SaleCompositionRatioData>,
) {
  if (row[headerKey] === "") {
    return "-";
  }
  if (row[headerKey] === 0) {
    return "0";
  }

  const valueType = ColumnFormatMap[headerKey].valueType;

  switch (valueType) {
    case "first_decimal":
      const roundedValue = Math.round(Number(row[headerKey]) * 10) / 10;
      return parseFloat(roundedValue.toFixed(1)).toLocaleString();

    // サーバーからはすべて少数第２位で返される
    case "second_decimal":
      return parseFloat(Number(row[headerKey]).toFixed(2)).toLocaleString();
    case "number":
      return Math.round(Number(row[headerKey])).toLocaleString();
    case "percentage":
      return parseFloat(
        (Number(row[headerKey]) * 100).toFixed(1),
      ).toLocaleString();
    case "string":
      // 両方Partialな為 as しているが、
      // rowとheaderKeyは必ず整合性がとれた状態のデータが渡される
      return row[headerKey] as string;
  }
}

const headers: Domain.SCRatioIndicatorType[] = ["name", "ratio", "ecpm"];

export function createColumns() {
  return headers.map<
    Column<Partial<Domain.SaleCompositionRatioData> & { id: number }>
  >((header) => ({
    name: ColumnFormatMap[header].displayName,
    // selectorで.toLocaleString()すると正しくソート出来ないので
    // renderCellで整形する
    selector: (row) => row[header] as string | number,
    renderCell: (row) => (
      <Typography align={ColumnFormatMap[header].align}>
        {getFormattedValue(row, header)}
      </Typography>
    ),
    sortable: true,
  }));
}
