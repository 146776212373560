import styled from "styled-components";

import { Color, Radius } from "../../../../../../styles/variables";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing * 2}px;
  background-color: ${Color.background.hint};
  border-radius: ${Radius.MEDIUM};
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${Color.background.active};
  }
  a {
    text-decoration: none;
  }
`;

export const Count = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 0 8px 1px;
  border-radius: 10px;
  background-color: ${({ active }) =>
    active ? Color.danger.main : Color.gray.main};
  color: ${Color.white};
  font-size: 13px;
  line-height: 1;
`;
