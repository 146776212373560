import * as queryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import {
  RouteActionContext,
  RouteActionPayload,
} from "../../../../routing/types";
import { deleteDemandAdCreatives2 as unlinkCategory2DemandAdCreativesAction } from "../../../../store/modules/category/actions/deleteDemandAdCreatives2Action";
import { deleteDemandAdCreatives as unlinkCategoryDemandAdCreativesAction } from "../../../../store/modules/category/actions/deleteDemandAdCreativesAction";
import { fetchCategories as fetchCategoriesAction } from "../../../../store/modules/category/actions/fetchCategoriesAction";
import { patchDemandAdCreatives2 as linkCategory2DemandAdCreativesAction } from "../../../../store/modules/category/actions/patchDemandAdCreatives2Action";
import { patchDemandAdCreatives as linkCategoryDemandAdCreativesAction } from "../../../../store/modules/category/actions/patchDemandAdCreativesAction";
import { postCategory as postCategoryAction } from "../../../../store/modules/category/actions/postCategoryAction";
import { getCategories } from "../../../../store/modules/category/selectors/categorySelector";
import { fetchDemandAccount as getDemandAccountAction } from "../../../../store/modules/demandAccount/actions/fetchDemandAccountAction";
import { activateDemandAdCreatives as activateDemandAdCreativesAction } from "../../../../store/modules/demandAdCreative/actions/activateDemandAdCreativesAction";
import { deleteDemandAdCreatives as deleteDemandAdCreativesAction } from "../../../../store/modules/demandAdCreative/actions/deleteDemandAdCreativesAction";
import { fetchDemandAdCreatives as fetchDemandAdCreativesAction } from "../../../../store/modules/demandAdCreative/actions/fetchDemandAdCreativesAction";
import { patchChannelType as patchChannelTypeAction } from "../../../../store/modules/demandAdCreative/actions/patchChannelTypeAction";
import { patchDeviceType as patchDeviceTypeAction } from "../../../../store/modules/demandAdCreative/actions/patchDeviceTypeAction";
import { patchOsType as patchOsTypeAction } from "../../../../store/modules/demandAdCreative/actions/patchOsTypeAction";
import { getDemandAdCreatives } from "../../../../store/modules/demandAdCreative/selectors/demandAdCreativeSelector";
import { deleteDemandAdCreatives as unlinkSiteDemandAdCreativesAction } from "../../../../store/modules/demandAdCreativeGroup/actions/deleteDemandAdCreativesAction";
import { fetchDemandAdCreativeGroups as fetchDemandAdCreativeGroupsAction } from "../../../../store/modules/demandAdCreativeGroup/actions/fetchDemandAdCreativeGroupsAction";
import { patchDemandAdCreatives as linkSiteDemandAdCreativesAction } from "../../../../store/modules/demandAdCreativeGroup/actions/patchDemandAdCreativesAction";
import { postDemandAdCreativeGroup as postDemandAdCreativeGroupAction } from "../../../../store/modules/demandAdCreativeGroup/actions/postDemandAdCreativeGroupAction";
import { getDemandAdCreativeGroups } from "../../../../store/modules/demandAdCreativeGroup/selectors/demandAdCreativeGroupSelector";
import { push as pushAction } from "../../../../store/modules/routing/actions/PushAction";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../../store/reducer";
import { dispatchable } from "../../../../store/utils/dispatchable";

import {
  DemandAdCreativesManager as Component,
  RefineColumn,
} from "./DemandAdCreativesManager";

const DemandAdCreativesManager = connect(
  (state: RootState) => ({
    // /company/:id 以下なので選択済み
    currentCompany: state.user.currentCompany as CompanyAttribute,
    demandAdCreativeGroups: getDemandAdCreativeGroups(state),
    demandAdCreatives: getDemandAdCreatives(state),
    categories: getCategories(state),
    postDemandAdCreativeGroupState: state.demandAdCreativeGroup.post,
    postCategoryState: state.category.post,
    linkSiteDemandAdCreativesState: state.demandAdCreativeGroup.creatives.patch,
    linkCategoryDemandAdCreativesState: state.category.creatives.patch,
    unlinkSiteDemandAdCreativesState:
      state.demandAdCreativeGroup.creatives.delete,
    unlinkCategoryDemandAdCreativesState: state.category.creatives.delete,
    linkCategory2DemandAdCreativesState: state.category.creatives2.patch,
    unlinkCategory2DemandAdCreativesState: state.category.creatives2.delete,
    patchChannelTypeState: state.demandAdCreative.channelType.patch,
    patchDeviceTypeState: state.demandAdCreative.deviceType.patch,
    patchOsTypeState: state.demandAdCreative.osType.patch,
    activateDemandAdCreativesState: state.demandAdCreative.activate.patch,
    deleteDemandAdCreativesState: state.demandAdCreative.delete,
  }),
  (dispatch) => ({
    push: dispatchable(dispatch, pushAction),
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    postDemandAdCreativeGroup: dispatchable(
      dispatch,
      postDemandAdCreativeGroupAction,
    ),
    postCategory: dispatchable(dispatch, postCategoryAction),
    getDemandAccount: dispatchable(dispatch, getDemandAccountAction),
    linkSiteDemandAdCreatives: dispatchable(
      dispatch,
      linkSiteDemandAdCreativesAction,
    ),
    linkCategoryDemandAdCreatives: dispatchable(
      dispatch,
      linkCategoryDemandAdCreativesAction,
    ),
    unlinkSiteDemandAdCreatives: dispatchable(
      dispatch,
      unlinkSiteDemandAdCreativesAction,
    ),
    unlinkCategoryDemandAdCreatives: dispatchable(
      dispatch,
      unlinkCategoryDemandAdCreativesAction,
    ),
    linkCategory2DemandAdCreatives: dispatchable(
      dispatch,
      linkCategory2DemandAdCreativesAction,
    ),
    unlinkCategory2DemandAdCreatives: dispatchable(
      dispatch,
      unlinkCategory2DemandAdCreativesAction,
    ),
    patchChannelType: dispatchable(dispatch, patchChannelTypeAction),
    patchDeviceType: dispatchable(dispatch, patchDeviceTypeAction),
    patchOsType: dispatchable(dispatch, patchOsTypeAction),
    activateDemandAdCreatives: dispatchable(
      dispatch,
      activateDemandAdCreativesAction,
    ),
    deleteDemandAdCreatives: dispatchable(
      dispatch,
      deleteDemandAdCreativesAction,
    ),
  }),
)(Component);

export async function action({
  store,
  firstOrPush,
  history,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await Promise.all([
      store.dispatch(fetchDemandAdCreativesAction()),
      store.dispatch(fetchDemandAdCreativeGroupsAction()),
      store.dispatch(fetchCategoriesAction()),
    ]);
  }

  const params = queryString.parse(history.location.search);

  let defaultRefineColumn: RefineColumn = "name";
  switch (params["column"]) {
    case "site":
      defaultRefineColumn = "siteName";
      break;
    case "category":
      defaultRefineColumn = "categoryName";
      break;
  }

  const defaultRefineValue = params["search"] as string;

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: (
      <DemandAdCreativesManager
        location={history.location}
        defaultRefineColumn={defaultRefineColumn}
        defaultRefineValue={defaultRefineValue}
      />
    ),
  };
}
