import { reducerWithInitialState } from "typescript-fsa-reducers";

import { BidStrapReport } from "../../../../domain/bidStrapReport";
import { createBidStrapReport } from "../../../../domain/bidStrapReport/entity";
import { downloadBidStrapCsvAction } from "../actions/downloadBidStrapCsvAction";
import { fetchBidStrapReportAction } from "../actions/fetchBidStrapReportAction";

export type BidStrapReportState = {
  fetch: {
    requesting: boolean;
    data: BidStrapReport | null;
  };
  downloadBidStrapCsv: {
    requesting: boolean;
  };
};

export const initialState: BidStrapReportState = {
  fetch: {
    requesting: false,
    data: null,
  },
  downloadBidStrapCsv: {
    requesting: false,
  },
};

export const bidStrapReportReducer = reducerWithInitialState(initialState)
  /**
   * Fetch BidStrapReport
   */
  .case(fetchBidStrapReportAction.started, (state) => ({
    ...state,
    fetch: {
      // requesting時にdataをnullにすると、画面が構成できなくなる為、dataがある場合はそのまま残しておく
      ...state.fetch,
      requesting: true,
    },
  }))
  .case(fetchBidStrapReportAction.done, (state, action) => ({
    ...state,
    fetch: {
      requesting: false,
      data: createBidStrapReport(action.result.bidstrap_reports),
    },
  }))
  .case(fetchBidStrapReportAction.failed, (state) => ({
    ...state,
    fetch: {
      // fail時にdataをnullにすると、画面が構成できなくなる為、dataがある場合はそのまま残しておく
      ...state.fetch,
      requesting: false,
    },
  }))
  /**
   * Download CSV BidStrapReport
   */
  .case(downloadBidStrapCsvAction.started, (state) => ({
    ...state,
    downloadBidStrapCsv: {
      requesting: true,
    },
  }))
  .case(downloadBidStrapCsvAction.done, (state) => ({
    ...state,
    downloadBidStrapCsv: {
      requesting: false,
    },
  }))
  .case(downloadBidStrapCsvAction.failed, (state) => ({
    ...state,
    downloadBidStrapCsv: {
      requesting: false,
    },
  }));
