import { createBrowserHistory } from "history";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { AppContainer } from "./containers/AppContainer";
import { createRouter } from "./routing";
import { RouteRenderer } from "./routing/RouteRenderer";
import { createCompanyMiddleware } from "./routing/middlewares/CompanyMiddleware";
import { createRootMiddleware } from "./routing/middlewares/RootMiddleware";
import { configureStore } from "./store";
import { handleRedirectCallback } from "./store/modules/auth/actions/handleRedirectCallbackAction";
import { updateAuthError } from "./store/modules/auth/actions/updateAuthErrorAction";
import { watchNetworkCondition } from "./store/modules/network/actions/watchNetworkConditionAction";
import { createAPIClient, createOpenAPIClient } from "./utils/APIClientUtils";

const initialApp = async () => {
  try {
    const apiClient = createAPIClient();
    const openApiClient = createOpenAPIClient(apiClient);

    const history = createBrowserHistory();
    const store = configureStore({ history, apiClient, openApiClient });

    const router = createRouter(
      { store, history },
      createRootMiddleware(),
      createCompanyMiddleware(),
    );

    store.dispatch(watchNetworkCondition());

    // Auth0で認証後、?code= の状態でページに戻ってくるので、認証情報を書き込みリダイレクトする
    if (history.location.search.includes("code=")) {
      await store.dispatch(handleRedirectCallback());
    }

    // Auth0で認証後、ログインできなかった場合は?error= の状態でページに戻ってくるので、ハンドリングする
    if (history.location.search.includes("error=")) {
      store.dispatch(updateAuthError(true));
    }

    const rootElement = document.getElementById("app") as HTMLElement;
    const root = createRoot(rootElement);

    root.render(
      <Provider store={store}>
        <RouteRenderer
          history={history}
          router={router}
          // initialLayout={layout}
          component={AppContainer as any} // TODO: 型の見直し
        />
      </Provider>,
    );
  } catch (e) {}
};
initialApp();
