import { DataTable } from "ingred-ui";
import * as React from "react";

import { BidStrapReport } from "../../../../../domain/bidStrapReport";

import * as Styled from "./styled";
import { createColumns } from "./utils";

export type Props = {
  data: BidStrapReport;
};

class ReportTable extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { data } = this.props;
    return (
      <Styled.Container>
        <DataTable
          dataKey={"id"}
          enableRuledLine={true}
          fullWidth={true}
          tableMaxHeight={"90vh"}
          horizontalScrollable={true}
          data={data.records}
          columns={createColumns(data)}
        />
      </Styled.Container>
    );
  }
}

export { ReportTable };
