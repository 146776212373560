import { DemandAccountReportStatus } from "../../../../../../api-client";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchAllReportStatus
 */
export type FetchAllReportStatusParams = void;
export type FetchAllReportStatusSuccess = DemandAccountReportStatus[];
export type FetchAllReportStatusFailure = Error;

export const fetchAllReportStatusAction = actionCreator.async<
  FetchAllReportStatusParams,
  FetchAllReportStatusSuccess,
  FetchAllReportStatusFailure
>("FETCH_ALL_REPORT_STATUS");

export function fetchAllReportStatus(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }

    dispatch(fetchAllReportStatusAction.started());

    try {
      const { data } = await openApiClient.rpzApiViewsReportStatusList(
        currentCompany.company.id,
      );
      dispatch(fetchAllReportStatusAction.done({ result: data }));
    } catch (err) {
      dispatch(fetchAllReportStatusAction.failed(err));
    }
  };
}
