import { reducerWithInitialState } from "typescript-fsa-reducers";

import { DashboardItem } from "../../../../domain/dashboardItem";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { arrayToEntityMap } from "../../../../utils/ReducerUtils";
import { deleteDashboardItemAction } from "../actions/deleteDashboardItemAction";
import { fetchDashboardItemsAction } from "../actions/fetchDashboardItemsAction";
import { patchDashboardItemAction } from "../actions/patchDashboardItemAction";
import { postDashboardItemAction } from "../actions/postDashboardItemAction";

export type DashboardItemEntityState = {
  byId: {
    [key: number]: DashboardItem;
  };
  allIds: number[];
};

export const initialState: DashboardItemEntityState = {
  byId: {},
  allIds: [],
};

export const dashboardItemEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All dashboardItem
   */
  .case(fetchDashboardItemsAction.done, (state, action) => ({
    ...state,
    byId: arrayToEntityMap(action.result.items),
    allIds: action.result.items.map((dashboardItem) => dashboardItem.id),
  }))
  /**
   * Post dashboardItem
   */
  .case(postDashboardItemAction.done, (state, action) => {
    const dashboardItem = action.result;
    return {
      ...state,
      byId: {
        ...state.byId,
        [dashboardItem.id]: dashboardItem,
      },
      allIds: uniquePush(state.allIds, dashboardItem.id),
    };
  })
  /**
   * Patch dashboardItem
   */
  .case(patchDashboardItemAction.done, (state, action) => {
    const dashboardItem = action.result;
    return {
      ...state,
      byId: {
        ...state.byId,
        [dashboardItem.id]: dashboardItem,
      },
    };
  })
  /**
   * Delete dashboardItem
   */
  .case(deleteDashboardItemAction.done, (state, action) => {
    const dashboardItemId = action.result.item_id;
    const byIdState = { ...state.byId };
    delete byIdState[dashboardItemId];
    return {
      ...state,
      byId: { ...byIdState },
      allIds: state.allIds.filter((id) => id !== dashboardItemId),
    };
  });
