/* eslint-disable react/jsx-handler-names */
import { Spacer, Spinner } from "ingred-ui";
import * as React from "react";

import { Helmet } from "../../../containers/Helmet";
import {
  Site,
  MarketTrendEcpm,
  MarketTrendSalesCompositionRatio,
} from "../../../domain/marketTrend";
import { downloadMarketTrendCsv } from "../../../store/modules/marketTrend/actions/downloadMarketTrendCsvAction";
import { fetchMarketTrendEcpm } from "../../../store/modules/marketTrend/actions/fetchMarketTrendEcpmAction";
import { fetchMarketTrendSalesCompositionRatio } from "../../../store/modules/marketTrend/actions/fetchMarketTrendSalesCompositionRatioAction";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import { PageContainer } from "../../elements/PageContainer";

import { Ecpm } from "./internal/Ecpm";
import { SalesCompositionRatio } from "./internal/SalesCompositionRatio";
import * as Styled from "./styled";

type InjectProps = {
  sitesRequesting: boolean;
  ecpmRequesting: boolean;
  salesCompositionRatioRequesting: boolean;
  downloadCSVRequesting: boolean;
  ecpmData: { sites: Site[]; ecpms: MarketTrendEcpm };
  salesCompositionRatioData: MarketTrendSalesCompositionRatio;
  fetchMarketTrendEcpm: DispatchableAction<typeof fetchMarketTrendEcpm>;
  fetchMarketTrendSalesCompositionRatio: DispatchableAction<
    typeof fetchMarketTrendSalesCompositionRatio
  >;
  downloadMarketTrendCsv: DispatchableAction<typeof downloadMarketTrendCsv>;
};

type Props = {};

export type InjectedProps = Props & InjectProps;

export const MarketTrend: React.FunctionComponent<InjectedProps> = ({
  sitesRequesting,
  ecpmRequesting,
  salesCompositionRatioRequesting,
  downloadCSVRequesting,
  ecpmData,
  salesCompositionRatioData,
  fetchMarketTrendEcpm,
  fetchMarketTrendSalesCompositionRatio,
  downloadMarketTrendCsv,
}) => {
  return (
    <>
      <Helmet title="マーケットトレンド | DATA STRAP" />
      <PageContainer>
        <Styled.Container>
          <Ecpm data={ecpmData} onSubmit={fetchMarketTrendEcpm} />
          <Spacer pt={3} />
          <SalesCompositionRatio
            data={salesCompositionRatioData}
            onSubmit={fetchMarketTrendSalesCompositionRatio}
            onDownloadCSV={downloadMarketTrendCsv}
          />
        </Styled.Container>
      </PageContainer>
      {(sitesRequesting ||
        ecpmRequesting ||
        salesCompositionRatioRequesting ||
        downloadCSVRequesting) && (
        <Styled.LoadingContainer>
          <Spinner />
        </Styled.LoadingContainer>
      )}
    </>
  );
};
