import styled from "styled-components";

import { Color, Radius, Size } from "../../../styles/variables";

export const Container = styled.div`
  position: relative;
`;

export const ModalHandler = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing * 4}px;
  right: 0;
  min-width: 264px;
  background-color: ${Color.background.default};
  box-shadow: 0px 0px ${({ theme }) => theme.spacing * 2}px #041c3315;
  border-radius: ${Radius.SMALL};
  z-index: 1;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

export const TopContainer = styled.div`
  border-bottom: ${Size.Border.Normal} solid ${Color.gray.light};
`;

export const Ul = styled.ul`
  padding-bottom: ${({ theme }) => theme.spacing * 3}px;
  list-style: none;
`;

export const Li = styled.li<{ selected: boolean }>`
  padding: ${({ theme }) => theme.spacing * 1}px
    ${({ theme }) => theme.spacing * 2}px;
  background-color: ${(prpps) => prpps.selected && Color.gray.light};
  cursor: pointer;
  &:hover {
    background-color: ${Color.gray.light};
  }
`;

export const BottomContainer = styled.div`
  padding: ${({ theme }) => theme.spacing * 2}px;
`;

export const Button = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;
