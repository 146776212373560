import { reducerWithInitialState } from "typescript-fsa-reducers";

import {
  MarketTrendEcpm,
  MarketTrendSalesCompositionRatio,
} from "../../../../domain/marketTrend";
import { createMarketTrendSalesCompositionRatio } from "../../../../domain/marketTrend/entity";
import { downloadMarketTrendCsvAction } from "../actions/downloadMarketTrendCsvAction";
import { fetchMarketTrendEcpmAction } from "../actions/fetchMarketTrendEcpmAction";
import { fetchMarketTrendSalesCompositionRatioAction } from "../actions/fetchMarketTrendSalesCompositionRatioAction";

export type MarketTrendState = {
  fetchMarketTrendEcpm: {
    requesting: boolean;
    data: MarketTrendEcpm | null;
  };
  fetchMarketTrendSalesCompositionRatio: {
    requesting: boolean;
    data: MarketTrendSalesCompositionRatio | null;
  };
  downloadMarketTrendCsv: {
    requesting: boolean;
  };
};

export const initialState: MarketTrendState = {
  fetchMarketTrendEcpm: {
    requesting: false,
    data: null,
  },
  fetchMarketTrendSalesCompositionRatio: {
    requesting: false,
    data: null,
  },
  downloadMarketTrendCsv: {
    requesting: false,
  },
};

export const marketTrendReducer = reducerWithInitialState(initialState)
  /**
   * Fetch MarketTrendEcpm
   */
  .case(fetchMarketTrendEcpmAction.started, (state) => ({
    ...state,
    fetchMarketTrendEcpm: {
      ...state.fetchMarketTrendEcpm,
      requesting: true,
    },
  }))
  .case(fetchMarketTrendEcpmAction.done, (state, action) => ({
    ...state,
    fetchMarketTrendEcpm: {
      data: action.result,
      requesting: false,
    },
  }))
  .case(fetchMarketTrendEcpmAction.failed, (state) => ({
    ...state,
    fetchMarketTrendEcpm: {
      requesting: false,
      data: null,
    },
  }))

  /**
   * Fetch MarketTrendSalesCompositionRatio
   */
  .case(fetchMarketTrendSalesCompositionRatioAction.started, (state) => ({
    ...state,
    fetchMarketTrendSalesCompositionRatio: {
      ...state.fetchMarketTrendSalesCompositionRatio,
      requesting: true,
    },
  }))
  .case(fetchMarketTrendSalesCompositionRatioAction.done, (state, action) => ({
    ...state,
    fetchMarketTrendSalesCompositionRatio: {
      requesting: false,
      data: createMarketTrendSalesCompositionRatio(action.result),
    },
  }))
  .case(fetchMarketTrendSalesCompositionRatioAction.failed, (state) => ({
    ...state,
    fetchMarketTrendSalesCompositionRatio: {
      requesting: false,
      data: null,
    },
  }))

  /**
   * Download CSV MarketTrend
   */
  .case(downloadMarketTrendCsvAction.started, (state) => ({
    ...state,
    downloadMarketTrendCsv: {
      requesting: true,
    },
  }))
  .case(downloadMarketTrendCsvAction.done, (state) => ({
    ...state,
    downloadMarketTrendCsv: {
      requesting: false,
    },
  }))
  .case(downloadMarketTrendCsvAction.failed, (state) => ({
    ...state,
    downloadMarketTrendCsv: {
      requesting: false,
    },
  }));
