import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing * 1}px;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const TableContainer = styled.div<{ tableMaxHeight?: string }>`
  width: 100%;
  height: ${({ tableMaxHeight }) => tableMaxHeight || "40vh"};
`;
