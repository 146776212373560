import { BidStrapGamAdUnit } from "../../../../domain/bidStrapGamAdUnit";
import { RootState } from "../../../reducer";

export const getBidStrapGamAdUnits = (
  state: RootState,
): BidStrapGamAdUnit[] => {
  const { allIds, byId } = state.entities.bidStrapGamAdUnit;
  return allIds.map((id) => byId[id]);
};

export const getBidStrapGamAdUnitById = (
  state: RootState,
  id: number,
): BidStrapGamAdUnit | null => {
  const byId = state.entities.bidStrapGamAdUnit.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
