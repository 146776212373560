import { reducerWithInitialState } from "typescript-fsa-reducers";

import { BidStrapGamAdUnit } from "../../../../domain/bidStrapGamAdUnit";
import { arrayToEntityMap } from "../../../../utils/ReducerUtils";
import { fetchBidStrapGamAdUnitsAction } from "../actions/fetchBidStrapGamAdUnitsAction";
export type BidStrapGamAdUnitEntityState = {
  byId: {
    [key: number]: BidStrapGamAdUnit;
  };
  allIds: number[];
};

export const initialState: BidStrapGamAdUnitEntityState = {
  byId: {},
  allIds: [],
};

export const bidStrapGamAdUnitEntityReducer = reducerWithInitialState(
  initialState,
)
  /**
   * Fetch All bidStrapGamAdUnit
   */
  .case(fetchBidStrapGamAdUnitsAction.done, (state, action) => ({
    ...state,
    byId: arrayToEntityMap(action.result.gam_ad_units),
    allIds: action.result.gam_ad_units.map((gam_ad_unit) => gam_ad_unit.id),
  }));
