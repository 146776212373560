import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";
import { fetchUser } from "./fetchUserAction";

/**
 * GetUser
 */
export type InitializeUserParams = void;
export type InitializeUserSuccess = void;
export type InitializeUserFailure = Error;

export const initializeUserAction = actionCreator.async<
  InitializeUserParams,
  InitializeUserSuccess,
  InitializeUserFailure
>("INITIALIZE_USER");

export function initializeUser(): ThunkAction<Promise<void>, any> {
  return async (dispatch) => {
    dispatch(initializeUserAction.started());
    try {
      await dispatch(fetchUser());
      dispatch(initializeUserAction.done({}));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(initializeUserAction.failed({ error }));
      }
    }
  };
}
