import { Action, History, Location } from "history";
import React from "react";
import shallowEqual from "shallowequal";
import Router from "universal-router";

import { TransitionProgress } from "../../components/elements/TransitionProgress";
import { ErrorView } from "../../components/pages/ErrorView";
import { Loading } from "../../components/pages/Loading";
import { GTMEventType } from "../../infra/gtm/GTMEventType";
import { updateFatal } from "../../store/modules/app/actions/UpdateFatalAction";
import { AppState } from "../../store/modules/app/reducers";
import { sendToGTMAction } from "../../store/modules/gtm/actions/SendToGTMAction";
import { HistoryContext } from "../../store/modules/routing/HistoryContext";
import { locationChange } from "../../store/modules/routing/actions/LocationChangeAction";
import { replace } from "../../store/modules/routing/actions/ReplaceAction";
import { RouteState, RouteParams } from "../../store/modules/routing/reducer";
import { UserState } from "../../store/modules/user/reducers";
import { DispatchableAction } from "../../store/utils/dispatchable";
import { HttpStatusCode } from "../../utils/StatusCodeUtils";
import { RouterContext, RouteLayout } from "../types";

type Position = {
  x: number;
  y: number;
};

const scrollPositionMap = new Map<string, Position>();

const defaultLayout: RouteLayout = {
  component: React.Fragment,
  props: {},
};

type ComponentProps = {
  children?: React.ReactNode;
};

export type Props = {
  history: History;
  router: Router<any, RouterContext>;
  component: React.FC<ComponentProps>;
  initialLayout?: RouteLayout;
} & ComponentProps;

export type InjectProps = {
  app: AppState;
  routing: RouteState;
  updateFatal: DispatchableAction<typeof updateFatal>;
  replace: DispatchableAction<typeof replace>;
  sendToGTM: DispatchableAction<typeof sendToGTMAction>;
  locationChange: DispatchableAction<typeof locationChange>;
  user: UserState | null;
};

type InjectedProps = Props & InjectProps;

type State = {
  showTransitionProgress: boolean;
  layout: RouteLayout;
  children: React.ReactNode | null;
};

export class RouteRenderer extends React.PureComponent<InjectedProps, State> {
  public constructor(props: InjectedProps) {
    super(props);

    const { history, initialLayout } = this.props;

    this.state = {
      showTransitionProgress: true,
      layout: initialLayout != null ? initialLayout : defaultLayout,
      children: null,
    };

    history.listen(this.handleLocationChange);
  }

  public state: State;

  public async componentDidMount(): Promise<void> {
    this.handleLocationChange(this.props.history.location, "PUSH");

    this.adjustScrollPosition();
  }

  public componentDidUpdate(prevProps: InjectedProps, prevState: State): void {
    if (!shallowEqual(this.state.children, prevState.children)) {
      this.adjustScrollPosition();
    }

    // companyIdが変わった時だけローディングビューを表示する
    if (prevProps.user?.currentCompany != null) {
      if (
        prevProps.user.currentCompany?.company.id !==
        this.props.user?.currentCompany?.company.id
      ) {
        this.setState({ children: <Loading /> });
      }
    }
  }

  public render(): React.ReactNode {
    const { history, component: Component } = this.props;
    const {
      showTransitionProgress,
      layout: { component: LayoutComponent, props: layoutProps },
      children,
    } = this.state;

    return (
      <HistoryContext.Provider value={{ history }}>
        <Component>
          <LayoutComponent {...layoutProps}>{children}</LayoutComponent>
          <TransitionProgress show={showTransitionProgress} />
        </Component>
      </HistoryContext.Provider>
    );
  }

  private sendViewToGTM(): void {
    const { sendToGTM, history } = this.props;
    const url = history.createHref(history.location);
    sendToGTM({
      event: GTMEventType.VIEW,
      referrer: url,
    });
  }

  private locationChange(params: RouteParams, status: HttpStatusCode): void {
    const { history } = this.props;
    const { key, pathname, search, hash } = history.location;

    this.props.locationChange({
      key: key as string,
      action: history.action,
      pathname,
      search,
      hash,
      params,
      status,
    });
  }

  private storeScrollPosition(): void {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;

    scrollPositionMap.set(pathname, {
      x: window.scrollX,
      y: window.scrollY,
    });
  }

  private scrollToHashPosition(hash: string): void {
    const target = document.getElementById(hash.substr(1));
    let scrollY = 0;
    if (target) {
      scrollY = window.pageYOffset + target.getBoundingClientRect().top;
    }
    window.scrollTo(0, scrollY);
  }

  private adjustScrollPosition(): void {
    const {
      history: {
        action,
        location: { pathname, hash },
      },
    } = this.props;

    // initial view
    if (scrollPositionMap.size === 1 && action === "POP") {
      this.scrollToHashPosition(hash);

      return;
    }

    // POP
    if (action === "POP" && hash == null) {
      const { x, y } = scrollPositionMap.has(pathname)
        ? (scrollPositionMap.get(pathname) as Position)
        : { x: 0, y: 0 };
      window.scrollTo(x, y);

      return;
    }

    // push or replace with hash
    if (hash != null && hash.length >= 1) {
      this.scrollToHashPosition(hash);

      return;
    }

    // other
    window.scrollTo(0, 0);
  }

  private handleLocationChange = async (
    _location: Location,
    _action: Action,
  ): Promise<void> => {
    const { history, router, app, updateFatal } = this.props;
    const { pathname } = history.location;
    this.setState({ showTransitionProgress: true });

    // 50x 系のエラーはページ遷移のタイミングで一旦クリアする
    if (app.fatal) {
      updateFatal(false);
    }

    try {
      this.storeScrollPosition();
      const {
        redirectTo,
        params,
        content,
        statusCode,
        layout,
      } = await router.resolve(pathname);

      if (redirectTo != null) {
        // 現状 /^https?:\/\/.+/ にマッチするのはauth0でのログイン画面へリダイレクトする場合のみ
        if (redirectTo.match(/^https?:\/\/.+/)) {
          window.location.assign(redirectTo);
        } else {
          this.props.replace(redirectTo);
        }
      } else {
        const status = statusCode != null ? statusCode : HttpStatusCode.OK;

        this.setState({
          layout: layout != null ? layout : defaultLayout,
          children: content,
        });

        this.locationChange(params, status);
        this.adjustScrollPosition();

        // 社内ユーザのモニタリングをしない
        if (this.props.user?.me.data?.user_system_role.name !== "admin") {
          this.sendViewToGTM();
        }
      }
    } catch (e) {
      this.setState({
        children: <ErrorView statusCode={HttpStatusCode.NOT_FOUND} />,
      });
    }

    this.setState({
      showTransitionProgress: false,
    });
  };
}
