import { Typography, Spacer, ConfirmModal } from "ingred-ui";
import * as React from "react";

import { DemandAdCreativeGroup } from "../../../../../../domain/demandAdCreativeGroup";

type Props = {
  isOpen: boolean;
  demandAdCreativeGroup: DemandAdCreativeGroup | null;
  onClose?: () => void;
  onSubmit?: (demandId: number) => void;
  loading?: boolean;
};

const DeleteModal: React.FunctionComponent<Props> = ({
  isOpen,
  demandAdCreativeGroup,
  onClose,
  onSubmit,
  loading,
}) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (onSubmit && demandAdCreativeGroup) {
      onSubmit(demandAdCreativeGroup.id);
    }
  };
  return (
    <ConfirmModal
      isOpen={isOpen}
      title="サイトの削除"
      confirmText="削除する"
      cancelText="キャンセル"
      buttonColor="danger"
      loading={loading}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit}
    >
      <Spacer pt={2}>
        <Typography>
          {demandAdCreativeGroup?.name}を削除しますか？
          <br />
          削除するとサイト内のデマンド広告クリエイティブの紐付けは解除されます。
        </Typography>
      </Spacer>
    </ConfirmModal>
  );
};

export { DeleteModal };
