import { ReportStatus } from "../../../../domain/reportStatus";
import { RootState } from "../../../reducer";

export const getReportStatuses = (state: RootState): ReportStatus[] => {
  const allIds = state.entities.reportStatus.allIds;
  const byId = state.entities.reportStatus.byId;
  return allIds.map((id) => byId[id]);
};

export const getReportStatusById = (
  state: RootState,
  id: number,
): ReportStatus | null => {
  const byId = state.entities.reportStatus.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
