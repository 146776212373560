import { DataTable, ItemEmpty, Spacer, Typography } from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import { DemandAdCreative } from "../../../../../../domain/demandAdCreative";
import { PostDemandAdCreativesPayload } from "../../../../../../store/modules/gamAdCreative/actions/postDemandAdCreativesAction";
import { SelectableInput } from "../../../../../elements/SelectableInput";
import { StepModal } from "../../../../../elements/StepModal";

import * as Styled from "./styled";

type Props = {
  onClose: () => void;
  onSubmit: (data: PostDemandAdCreativesPayload) => void;
  loading: boolean;
  demandAdCreatives: DemandAdCreative[];
  gamFlattenedHierarchy: {
    id: number;
    creativeName: string;
    lineItemName: string;
    unitName: string;
  };
  gamAdUnitId: number;
};

const columnOptions: OptionType<"name" | "demandName" | "originalId">[] = [
  {
    label: "名前",
    value: "name",
  },
  {
    label: "デマンド",
    value: "demandName",
  },
  {
    label: "広告枠ID",
    value: "originalId",
  },
];

const AddModal: React.FunctionComponent<Props> = ({
  onClose,
  onSubmit,
  loading,
  demandAdCreatives,
  gamFlattenedHierarchy,
  gamAdUnitId,
}) => {
  const data = demandAdCreatives.map((creative) => ({
    id: creative.id,
    name: creative.original_name || creative.original_id,
    demandName: creative.demand.name,
    originalId: creative.original_id,
  }));

  const [searchText, setSearchText] = React.useState("");
  const [filterOption, setFilterOption] = React.useState<
    OptionType<"name" | "demandName" | "originalId">
  >({
    label: "名前",
    value: "name",
  });

  const searchedCreatives = React.useMemo(() => {
    const result = data.filter((item) =>
      item[filterOption.value].toLowerCase().includes(searchText.toLowerCase()),
    );
    return result;
  }, [filterOption, searchText, data]);

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [step, setStep] = React.useState<1 | 2>(1);

  const handleFilterOptionChange = (value: any) => {
    setFilterOption(value);
  };

  const handleChangeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handlePrev = () => {
    setStep(1);
  };

  const handleConfirmSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStep(2);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      gamAdUnitId: gamAdUnitId,
      gamLineItemCreativeId: gamFlattenedHierarchy.id,
      demandAccountCreativeIds: { demand_account_creative_ids: selectedIds },
    });
  };

  return (
    <StepModal
      currentStepKey={step}
      steps={[
        {
          title: `${gamFlattenedHierarchy.creativeName}に選択したデマンド広告クリエイティブを紐付ける`,
          stepKey: 1,
          cancel: {
            text: "キャンセル",
            onClick: onClose,
          },
          submit: {
            text: "確認する",
            onSubmit: handleConfirmSubmit,
            disabled: selectedIds.length === 0,
          },
          renderContent: () =>
            demandAdCreatives.length !== 0 ? (
              <>
                <Styled.TopContainer>
                  <Styled.InputContainer>
                    <SelectableInput
                      inputProps={{
                        value: searchText,
                        onChange: handleChangeSearchText,
                      }}
                      selectProps={{
                        value: filterOption,
                        options: columnOptions,
                        onChange: handleFilterOptionChange,
                      }}
                    />
                  </Styled.InputContainer>
                  <Typography size="lg">
                    {`${searchedCreatives.length}個中`}
                    <Typography
                      component="span"
                      size="lg"
                      color="primary"
                      weight="bold"
                    >
                      {`${selectedIds.length}個`}
                    </Typography>
                    を選択中
                  </Typography>
                </Styled.TopContainer>

                <Spacer px={3} pb={15}>
                  <DataTable
                    dataKey={"id"}
                    itemEmptyProps={{
                      title: "該当するクリエイティブがありません。",
                    }}
                    enablePagination={true}
                    per={10}
                    data={searchedCreatives}
                    columns={[
                      {
                        name: "名前",
                        selector: (data) => data.name,
                        sortable: true,
                      },
                      {
                        name: "デマンド",
                        selector: (data) => data.demandName,
                        sortable: true,
                      },
                      {
                        name: "広告枠ID",
                        selector: (data) => data.originalId,
                        sortable: true,
                      },
                    ]}
                    selectedRows={selectedIds}
                    onSelectRowsChange={setSelectedIds} // eslint-disable-line react/jsx-handler-names
                  />
                </Spacer>
              </>
            ) : (
              <ItemEmpty title="クリエイティブがありません。" />
            ),
        },
        {
          title: "下記のデマンド広告クリエイティブを紐付けますか？",
          stepKey: 2,
          cancel: {
            text: "戻る",
            onClick: handlePrev,
          },
          submit: {
            text: "紐付ける",
            onSubmit: handleSubmit,
            loading: loading,
          },
          renderContent: () => (
            <Spacer pt={2} px={3}>
              <DataTable
                dataKey={"id"}
                enablePagination={true}
                per={10}
                data={data.filter((d) => selectedIds.includes(d.id))}
                columns={[
                  {
                    name: "名前",
                    selector: (data) => data.name,
                    sortable: true,
                  },
                  {
                    name: "デマンド",
                    selector: (data) => data.demandName,
                    sortable: true,
                  },
                  {
                    name: "広告枠ID",
                    selector: (data) => data.originalId,
                    sortable: true,
                  },
                ]}
              />
            </Spacer>
          ),
        },
      ]}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
    />
  );
};
export { AddModal };
