import { Domain } from "api-types";
import dayjs from "dayjs";
import {
  Button,
  FullSizeConfirmModal,
  FixedPanel,
  Flex,
  Portal,
  Spacer,
  Spinner,
  ActionButton,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import { Helmet } from "../../../containers/Helmet";
import { BidStrapGamAdUnit } from "../../../domain/bidStrapGamAdUnit";
import { BidStrapReport } from "../../../domain/bidStrapReport";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { Site } from "../../../domain/site";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import { fetchDemands } from "../../../infra/demand/demandClient";
import { downloadBidStrapCsv } from "../../../store/modules/bidStrapReport/actions/downloadBidStrapCsvAction";
import { fetchBidStrapReport } from "../../../store/modules/bidStrapReport/actions/fetchBidStrapReportAction";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import {
  getDateRange,
  getDateRangeErrorMessage,
} from "../../../utils/DateRangeUtils";
import { PageContainer } from "../../elements/PageContainer";

import { DimensionSection } from "./internal/DimensionSection/DimensionSection";
import { IndicatorSection } from "./internal/IndicatorSection";
import { RefineSection } from "./internal/RefineSection";
import { ReportTable } from "./internal/ReportTable";
import * as Styled from "./styled";

const groupTypes: OptionType<Domain.GroupType>[] = [
  {
    label: "月別",
    value: "month",
  },
  {
    label: "日別",
    value: "day",
  },
  {
    label: "合計",
    value: "summary",
  },
];

export const dimensionOptions: OptionType<
  Domain.BidStrapReportDimensionType
>[] = [
  {
    label: "Bidder",
    value: "bidder",
  },
  {
    label: "広告枠",
    value: "ad_unit_name",
  },
  {
    label: "サイト名",
    value: "site_name",
  },
];

export const indicatorOptions: OptionType<
  keyof Domain.BidStrapReportIndicator
>[] = [
  {
    label: "リクエスト数(Prebid)",
    value: "auction_count",
  },
  {
    label: "非入札数",
    value: "nobid_count",
  },
  {
    label: "勝利数(Prebid)",
    value: "prebid_win_count",
  },
  {
    label: "勝率(Prebid)",
    value: "prebid_win_rate",
  },
  {
    label: "勝利数(GAM)",
    value: "bid_win_count",
  },
  {
    label: "勝率(GAM)",
    value: "bid_win_rate",
  },
  {
    label: "入札数",
    value: "valid_bid_count",
  },
  {
    label: "平均入札CPM",
    value: "avg_cpm",
  },
  {
    label: "Prebid勝利時の平均CPM",
    value: "prebid_win_avg_cpm",
  },
  {
    label: "GAM勝利時の平均CPM",
    value: "gam_win_avg_cpm",
  },
  {
    label: "タイムアウト数",
    value: "timeout_count",
  },
  {
    label: "売上(bidder)",
    value: "demand_price",
  },
  {
    label: "imps(bidder)",
    value: "demand_imps",
  },
  {
    label: "クリック数(bidder)",
    value: "demand_clicks",
  },
];

type InjectProps = {
  currentCompany: CompanyAttribute;
  bidStrapReport: BidStrapReport | null;
  fetchBidStrapReport: DispatchableAction<typeof fetchBidStrapReport>;
  downloadCsv: DispatchableAction<typeof downloadBidStrapCsv>;
  sites: Site[];
  gamAdUnits: BidStrapGamAdUnit[];
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  requesting: boolean;
  downloadCsvRequesting: boolean;
};

type Props = { fetchParamQuery?: string };

type InjectedProps = Props & InjectProps;

/* eslint-disable react/jsx-handler-names */
const BidStrapReport: React.FunctionComponent<InjectedProps> = ({
  bidStrapReport,
  fetchBidStrapReport,
  downloadCsv,
  fetchParamQuery,
  sites,
  gamAdUnits,
  requesting,
  downloadCsvRequesting,
  currentCompany,
}) => {
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const reportSectionRef = React.useRef<HTMLDivElement>(null);
  const showPanel = !useIntersectionObserver(buttonContainerRef);
  const [reportModalOpen, setReportModalOpen] = React.useState<boolean>(false);
  const [selectedIndicators, setSelectedIndicators] = React.useState<
    (keyof Domain.BidStrapReportIndicator)[]
  >(indicatorOptions.map((indicator) => indicator.value));
  const [selectedGroupType, setSelectedGroupType] = React.useState<
    Domain.GroupType
  >(groupTypes[0].value);
  const [selectedDimensions, setSelectedDimensions] = React.useState<
    Domain.BidStrapReportDimensionType[]
  >(["bidder", "ad_unit_name", "site_name"]);
  const [indicatorErrorText, setIndicatorErrorText] = React.useState<string>(
    "",
  );
  const [dimensionErrorText, setDimensionErrorText] = React.useState<string>(
    "",
  );
  const [displayPeriodErrorText, setDisplayPeriodErrorText] = React.useState<
    string
  >("");
  const [startDate, setStartDate] = React.useState<dayjs.Dayjs>(
    getDateRange("yesterday")[0],
  );
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(
    getDateRange("yesterday")[1],
  );
  const [displayPeriod, setDisplayPeriod] = React.useState<
    Domain.DateRangeType
  >();
  const [initFilters, setInitFilters] = React.useState<Domain.ReportFilters>(
    {},
  );
  const [filters, setFilters] = React.useState<Domain.BidStrapReportFilters>(
    {},
  );
  const [bidders, setBidders] = React.useState<Domain.Demand[]>([]);

  React.useEffect(() => {
    if (!fetchParamQuery) return;
    const fetchParams = JSON.parse(atob(fetchParamQuery));
    setStartDate(dayjs(fetchParams.begin_date));
    setEndDate(dayjs(fetchParams.end_date));
    setDisplayPeriod("custom_range");
    setSelectedGroupType(fetchParams.group_type_name);
    setSelectedDimensions(fetchParams.dimension_names);
    setSelectedIndicators(fetchParams.indicator_names);
    setInitFilters(fetchParams.filters);
  }, [fetchParamQuery]);

  React.useEffect(() => {
    const f = async () => {
      const bidders = await fetchDemands(currentCompany.company.id).catch(
        (_error) => {
          enqueueSystemNotification({
            message: "Bidder一覧の取得に失敗しました",
            variant: SystemNotificationVariant.ERROR,
          });
        },
      );
      if (!bidders) {
        setBidders([]);
        return;
      }
      setBidders(
        bidders.filter((demand: Domain.Demand) =>
          demand.name.includes("bidder_"),
        ),
      );
    };
    f();
  }, [currentCompany]);

  const validate = () => {
    const message = getDateRangeErrorMessage(
      startDate,
      endDate,
      selectedGroupType,
    );
    if (message !== "") {
      setDisplayPeriodErrorText(message);
      return false;
    }
    setDisplayPeriodErrorText("");
    if (selectedDimensions.length === 0) {
      setDimensionErrorText("どれか1つは必ず選択してください。");
      return false;
    }
    setDimensionErrorText("");
    if (selectedIndicators.length === 0) {
      setIndicatorErrorText("どれか1つは必ず選択してください。");
      return false;
    }
    setIndicatorErrorText("");
    return true;
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }
    await fetchBidStrapReport({
      begin_date: (startDate as dayjs.Dayjs).format("YYYY-MM-DD"),
      end_date: (endDate as dayjs.Dayjs).format("YYYY-MM-DD"),
      group_type_name: selectedGroupType,
      dimension_names: selectedDimensions,
      indicator_names: selectedIndicators,
      filters: filters,
    });
    setReportModalOpen(true);
    // MEMO: ページ側のscrollをブロックしてもいいかも
    // setReportModalOpen(false) 時に解除
  };
  const handleCsvDownload = () => {
    if (!validate()) {
      return;
    }
    downloadCsv({
      begin_date: (startDate as dayjs.Dayjs).format("YYYY-MM-DD"),
      end_date: (endDate as dayjs.Dayjs).format("YYYY-MM-DD"),
      group_type_name: selectedGroupType,
      dimension_names: selectedDimensions,
      indicator_names: selectedIndicators,
      // 例えば imp 数によるフィルタを表示・非表示に使うフィルタ(displayFilters)として使っているが、API 上では同じフィルタである
      filters: filters,
    });
  };

  const handleClose = () => {
    setReportModalOpen(false);
  };

  return (
    <>
      <Helmet title="BID STRAP レポート | DATA STRAP" />
      <Portal>
        <FixedPanel isOpen={showPanel}>
          <Spacer py={2} px={3}>
            <Flex display="flex" justifyContent="flex-end">
              <Button
                size="large"
                inline={true}
                className="gaev-detailreport-btn_query_execution"
                onClick={handleSubmit}
              >
                クエリを実行
              </Button>
            </Flex>
          </Spacer>
        </FixedPanel>
      </Portal>
      <PageContainer>
        <Styled.Container>
          <Flex display="flex" justifyContent="flex-end">
            <div ref={buttonContainerRef}>
              <Button
                inline={true}
                className="btn_query_execution"
                onClick={handleSubmit}
              >
                クエリを実行
              </Button>
            </div>
          </Flex>
          <Spacer pt={3} />
          <DimensionSection
            groupTypes={groupTypes}
            selectedDimensions={selectedDimensions}
            selectedGroupType={selectedGroupType}
            selectedDisplayPeriod={displayPeriod}
            displayPeriodErrorText={displayPeriodErrorText}
            dimensionErrorText={dimensionErrorText}
            startDate={startDate}
            endDate={endDate}
            onDimensionsChange={setSelectedDimensions}
            onGroupTypeChange={setSelectedGroupType}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            onPeriodChange={setDisplayPeriod}
          />
          <Spacer pt={3} />
          <IndicatorSection
            errorText={indicatorErrorText}
            indicatorOptions={indicatorOptions}
            selectedIndicators={selectedIndicators}
            onSelect={setSelectedIndicators}
          />
          <Spacer pt={3} />
          <RefineSection
            selectedFilters={initFilters}
            sites={sites}
            bidders={bidders}
            gamAdUnits={gamAdUnits}
            onChange={setFilters}
          />
          <div ref={reportSectionRef} />
          {bidStrapReport != null && (
            <FullSizeConfirmModal
              isOpen={reportModalOpen}
              title="レポート結果"
              disableHorizontalPadding={true}
              loading={downloadCsvRequesting}
              subActions={[
                <ActionButton
                  key="download-csv"
                  icon="export"
                  type="button"
                  onClick={handleCsvDownload}
                >
                  CSV形式でダウンロード
                </ActionButton>,
              ]}
              onClose={handleClose}
            >
              <Spacer pt={2} />
              <ReportTable data={bidStrapReport} />
            </FullSizeConfirmModal>
          )}
          {requesting && (
            <Styled.LoadingContainer>
              <Spinner />
            </Styled.LoadingContainer>
          )}
        </Styled.Container>
      </PageContainer>
    </>
  );
};

export { BidStrapReport };
