import * as React from "react";

import { DisplayFormat } from "../../../../DetailReport";

import DateGraphActive from "./icons/DateGraphActive";
import DateGraphFill from "./icons/DateGraphFill";
import DimensionGraphActive from "./icons/DimensionGraphActive";
import DimensionGraphFill from "./icons/DimensionGraphFill";
import LichTableActive from "./icons/LichTableActive";
import LichTableFill from "./icons/LichTableFill";
import TableActive from "./icons/TableActive";
import TableFill from "./icons/TableFill";
import * as Styled from "./styled";

const ImageTypeMap: {
  [key in DisplayFormat]: {
    normal: JSX.Element;
    active: JSX.Element;
  };
} = {
  table_only: {
    normal: <TableFill />,
    active: <TableActive />,
  },
  rich_table_only: {
    normal: <LichTableFill />,
    active: <LichTableActive />,
  },
  date_axis: {
    normal: <DateGraphFill />,
    active: <DateGraphActive />,
  },
  dimension_axis: {
    normal: <DimensionGraphFill />,
    active: <DimensionGraphActive />,
  },
};

type Props = {
  type: DisplayFormat;
  active: boolean;
};

const DisplayFormatImage: React.FunctionComponent<Props> = ({
  type,
  active,
}) => {
  let image: JSX.Element;
  if (active) {
    image = ImageTypeMap[type].active;
  } else {
    image = ImageTypeMap[type].normal;
  }

  return <Styled.Container active={active}>{image}</Styled.Container>;
};

export { DisplayFormatImage };
