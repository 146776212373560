import { Domain } from "api-types";
import { Typography, Spinner, Spacer, Flex, Icon, ErrorText } from "ingred-ui";
import React, { useCallback, useEffect, useState } from "react";

import {
  fetchBidStrapReport,
  SearchBidStrapReportParams,
} from "../../../../../infra/bidStrapReport/bidStrapReportClient";
import { createAPIClient } from "../../../../../utils/APIClientUtils";
import { groupBy } from "../../../../../utils/ArrayUtils";
import { MultipleBar } from "../../../../elements/MultipleBar";
import { indicatorOptions } from "../../../BidStrapReport/BidStrapReport";

import * as Styled from "./styled";

type Props = {
  companyId: number;
  span?: number;
  fetchParams: SearchBidStrapReportParams;
  onClickReportPageLink: (params: SearchBidStrapReportParams) => void;
};

const makeChartRecord = (record: Partial<Domain.BidStrapReportIndicator>) => {
  const chartDatas: [string, number][] = [
    ["リクエスト数(Prebid)", 0],
    ["入札数", 0],
    ["勝利数(Prebid)", 0],
    ["勝利数(GAM)", 0],
    ["imps(bidder)", 0],
  ];
  Object.keys(record).forEach((key) => {
    if (!record[key as keyof Partial<Domain.BidStrapReportIndicator>]) return;
    const option = indicatorOptions.find(
      (option) => option.value === (key as Domain.BidStrapReportIndicatorType),
    );
    if (!option) return;
    const index = chartDatas.findIndex((data) => data[0] === option.label);
    if (!chartDatas[index]) return;
    chartDatas[index][1] = record[
      key as keyof Partial<Domain.BidStrapReportIndicator>
    ] as number;
  });
  return chartDatas;
};

export const makeChartData = (
  records: Partial<Domain.BidStrapReportRecord>[],
) => {
  const reportsGroupByBidder = groupBy<
    string | undefined,
    Partial<Domain.BidStrapReportRecord>
  >(records, (record) => record.bidder);
  return reportsGroupByBidder.map((report) => ({
    name: report[0]?.replace("bidder_", ""),
    data: makeChartRecord(report[1][0]),
  })) as Domain.ChartData[];
};
export const BidStrapUnitCompareCard: React.FC<Props> = ({
  companyId,
  span = 1,
  fetchParams,
  onClickReportPageLink,
}) => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [chartData, setChartData] = useState<Domain.ChartData[]>([
    // MultipleBarは初期値がないとエラーが出てしまう
    {
      name: "",
      data: [],
    },
  ]);

  const barYAxisFormatter = (value: number) => {
    return `${value / 10000}万`;
  };

  const getData = useCallback(async () => {
    setDataLoading(true);
    setErrorText("");
    const data = await fetchBidStrapReport(
      createAPIClient(),
      fetchParams,
      companyId,
    ).catch((error) => {
      setErrorText(error.data.errors[0].message);
    });
    if (!data) return;
    setChartData(makeChartData(data.bidstrap_reports.records));
    setDataLoading(false);
  }, [companyId, fetchParams]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!chartData.length) setErrorText("データが存在しません");
  }, [chartData]);

  const handleClick = () => {
    onClickReportPageLink(fetchParams);
  };

  return (
    <Styled.Container span={span}>
      <Styled.TitleContainer>
        <Typography weight="bold" size="xl">
          ユニットの指標比較
        </Typography>
      </Styled.TitleContainer>
      <Styled.Content>
        {errorText && <ErrorText>{errorText}</ErrorText>}
        {chartData.length ? (
          <div>
            {!dataLoading && (
              <MultipleBar
                data={chartData}
                xAxisType="dimension"
                yAxisFormatter={barYAxisFormatter}
              />
            )}
          </div>
        ) : (
          <div />
        )}
        <Spacer pt={2}>
          <Flex display="flex" alignItems="center" justifyContent="flex-end">
            <Styled.QueryDetailLink onClick={handleClick}>
              <Typography color="link">クエリの詳細を見る</Typography>
              <Icon name="arrow_right" color="active" />
            </Styled.QueryDetailLink>
          </Flex>
        </Spacer>

        {dataLoading && (
          <Styled.LoadingContainer>
            <Spinner />
          </Styled.LoadingContainer>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
