import { reducerWithInitialState } from "typescript-fsa-reducers";

import { CSVImportableDemandAccounts } from "../../../../domain/csvImport";
import { fetchCSVImportableDemandAccountsAction } from "../actions/fetchCSVImportableDemandAccounts";

export type csvImportableDemandAccountsEntityState = {
  demand_accounts: CSVImportableDemandAccounts[];
};

export const initialState: csvImportableDemandAccountsEntityState = {
  demand_accounts: [],
};

export const csvImportableDemandAccountsEntityReducer = reducerWithInitialState(
  initialState,
)
  /**
   * Fetch All CSV Importable Demands
   */
  .case(fetchCSVImportableDemandAccountsAction.done, (state, action) => ({
    ...state,
    demand_accounts: action.result,
  }));
