import { reducerWithInitialState } from "typescript-fsa-reducers";

import { Site } from "../../../../domain/site";
import { arrayToEntityMap } from "../../../../utils/ReducerUtils";
import { fetchBidStrapSitesAction } from "../actions/fetchBidStrapSitesAction";
export type BidStrapSiteEntityState = {
  byId: {
    [key: number]: Site;
  };
  allIds: number[];
};

export const initialState: BidStrapSiteEntityState = {
  byId: {},
  allIds: [],
};

export const bidStrapSiteEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All bidStrapSite
   */
  .case(fetchBidStrapSitesAction.done, (state, action) => ({
    ...state,
    byId: arrayToEntityMap(action.result.sites),
    allIds: action.result.sites.map((site) => site.id),
  }));
