import { Category } from "../../../../domain/category";
import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchDemandAdCreatives
 */
export type PatchDemandAdCreativesPayload = {
  toCategory: Category;
  demandAdCreatives: DemandAdCreative[];
};
export type PatchDemandAdCreativesParams = void;
export type PatchDemandAdCreativesSuccess = PatchDemandAdCreativesPayload;
export type PatchDemandAdCreativesFailure = Error;

export const patchDemandAdCreativesAction = actionCreator.async<
  PatchDemandAdCreativesParams,
  PatchDemandAdCreativesSuccess,
  PatchDemandAdCreativesFailure
>("PATCH_DEMAND_AD_CREATIVES");

export function patchDemandAdCreatives(
  payload: PatchDemandAdCreativesPayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { category, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchDemandAdCreativesAction.started());

    try {
      await category.patchDemandAdCreatives(
        apiClient,
        {
          category_id: payload.toCategory.id,
          demand_account_creative_ids: payload.demandAdCreatives.map(
            (demandAdCreative) => demandAdCreative.id,
          ),
        },
        currentCompany.company.id,
      );
      dispatch(
        patchDemandAdCreativesAction.done({
          result: payload,
        }),
      );
    } catch (err) {
      dispatch(patchDemandAdCreativesAction.failed(err));
    }
  };
}
