import { Domain } from "api-types";
import dayjs from "dayjs";
import { OptionType } from "ingred-ui/dist/components/Select/Select";

export type DateRangeType = Exclude<
  Domain.DateRangeType,
  "custom_range" | "custom_single"
>;

export const displayPeriodOptions: OptionType<Domain.DateRangeType>[] = [
  {
    label: "昨日",
    value: "yesterday",
  },
  {
    label: "過去7日間",
    value: "last_7_days",
  },
  {
    label: "過去30日間",
    value: "last_30_days",
  },
  {
    label: "過去3ヶ月間",
    value: "last_3_months",
  },
  {
    label: "過去6ヶ月間",
    value: "last_6_months",
  },
  {
    label: "過去1年間",
    value: "last_12_months",
  },
  {
    label: "今月",
    value: "this_month",
  },
  {
    label: "前月",
    value: "last_month",
  },
  {
    label: "カスタム(期間)",
    value: "custom_range",
  },
  {
    label: "カスタム(単一日)",
    value: "custom_single",
  },
];

export function getDateRange(type: Domain.DateRangeType) {
  switch (type) {
    case "yesterday":
      return [dayjs().add(-1, "day"), dayjs().add(-1, "day")];
    case "last_7_days":
      return [dayjs().add(-7, "day"), dayjs().add(-1, "day")];
    case "last_30_days":
      return [dayjs().add(-30, "day"), dayjs().add(-1, "day")];
    case "last_365_days":
      return [dayjs().add(-365, "day"), dayjs().add(-1, "day")];
    case "last_730_days":
      return [dayjs().add(-730, "day"), dayjs().add(-1, "day")];

    // last_n_months
    // DATA STRAPではnヶ月前はnヶ月前の月初から前日まで
    case "last_3_months":
      return [dayjs().add(-3, "month").set("date", 1), dayjs().add(-1, "day")];
    case "last_6_months":
      return [dayjs().add(-6, "month").set("date", 1), dayjs().add(-1, "day")];
    case "last_12_months":
      return [dayjs().add(-12, "month").set("date", 1), dayjs().add(-1, "day")];
    case "this_month":
      return [dayjs().set("date", 1), dayjs()];
    case "last_month":
      return [
        dayjs().add(-1, "month").set("date", 1),
        dayjs().add(-dayjs().date(), "day"),
      ];
    case "this_year":
      return [dayjs().set("month", 0).set("date", 1), dayjs().add(-1, "day")];
    default:
      return [dayjs(), dayjs()];
  }
}

export function getDateRangeErrorMessage(
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
  groupType: Domain.GroupType,
): string {
  if (!startDate || !endDate) {
    return "表示期間が指定されていません。";
  }
  if (startDate.isAfter(endDate)) {
    return "開始日を最終日より後の日付にすることはできません。";
  }
  if (groupType === "day" && endDate.diff(startDate, "days") > 365 * 2) {
    return "「日別」選択時の表示期間は最大2年間までです。";
  }
  if (groupType === "month" && endDate.diff(startDate, "days") > 365 * 2) {
    return "「月別」選択時の表示期間は最大2年間までです。";
  }
  if (groupType === "summary" && endDate.diff(startDate, "days") > 365 * 2) {
    return "「合計」選択時の表示期間は最大2年間までです。";
  }
  return "";
}
