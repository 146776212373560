import * as React from "react";

export const foreword =
  "本規約は、本規約の内容を承諾の上、株式会社fluct（以下「当社」といいます。）指定の方法により、当社または当社が指定する第三者（第5条第2項に定める代理店を含みますがこれに限りません。）に対して本件サービス（第1条第1項にて定義します。）の利用申込を行う利用者（以下「利用者」といいます。）と当社との間に適用されます。";

export const terms: { title: string; content: React.ReactNode }[] = [
  {
    title: "第1条（本件サービス）",
    content: (
      <ol>
        <li>
          本規約に基づき、当社が利用者にその利用権を付与する「本件サービス」とは、当社がDATA
          STRAPという名称を冠して提供するサービス（一部の機能をASP（アプリケーション・サービス・プロバイダ）方式により提供します）、広告コンサルティングサービス、広告運用代行サービス、Yahoo!ニュースの利用者の掲載記事における「関連記事」の自動化最適化サービス（FTO（fluct
          traffic
          optimization））をいいます。なお、本件サービスの詳細な内容、仕様、本件サービスの提供条件およびサービスレベル等は、当社が別途作成する資料（以下「本件サービス資料」といいます。）に定めるものとします。
        </li>
        <li>
          本規約に基づき利用者が当社より付与される本件サービスの利用権は、取消可能、譲渡不能、かつ、再許諾不能で、非独占的なものです。
        </li>
        <li>
          利用者は、本件サービスの内容の信頼性、正確性、適法性、有用性および業界団体の内部規則等への適合性等について利用者自身で判断し、本件サービスを利用者自身の責任で利用するものとします。
        </li>
        <li>
          利用者は、本件サービス利用のために必要となる作業（主として利用者が本件サービスを利用する環境下における設定作業を指しますが、これに限りません。）を当社が求めたときは、当社の指示に従い、自らの責任と費用負担においてこれを実施するものとします。本件サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備および維持は、利用者の責任と費用負担において行うものとします。
        </li>
      </ol>
    ),
  },
  {
    title: "第2条（利用規約）",
    content: (
      <ol>
        <li>
          当社は、利用者による本件サービスへの申込を承諾するときは、申込の段階で確認する利用者の連絡先（住所、FAX番号または電子メールアドレス）にその旨通知するものとします。当社がかかる通知を送信した時点をもって、当社、利用者間における本規約に基づく利用契約（以下「利用契約」といいます。）が成立するものします。
        </li>
        <li>
          当社は、利用者の連絡先への電子メール等による通知、当社ウェブサイト上への掲載、その他当社が適当と認める方法によって通知することにより、随時本規約を改訂することができるものとします。変更後の内容は、当社が通知を発信した日または本項第一文記載の当社ウェブサイト上に掲載が開始された日から１５日以内に利用者が異議を述べなかった場合、または本件サービスを利用した場合、利用者が当該変更内容に同意したものとみなします。
        </li>
        <li>
          前項に基づき本規約が改訂された場合であって、当該改訂内容につき、利用者から１５日以内に異議があった場合、当社と利用者は利用契約の継続または解約につき協議を行うものとし、協議が不調となった場合には、利用者はお申し出当月末日をもって利用契約を解約することができるものとします。
        </li>
        <li>
          本規約のいずれかの条項が理由の如何にかかわらず執行不能と判示された場合、当該条項は、可能な限り両当事者の意図が実現する形で執行可能なものとするために必要な範囲で改定し、本規約の残りの条項は引き続き完全な効力を有するものとします。
        </li>
      </ol>
    ),
  },
  {
    title: "第3条（ID・パスワードの管理）",
    content: (
      <ol>
        <li>
          利用者は、当社から開示されたIDおよびパスワードについて、第三者に知られないように管理し、盗用を防止する措置を利用者の責任において行うものとします。
        </li>
        <li>
          当社は、本件サービスの提供にあたって、当社の責に帰さない事由による、または利用者もしくは第三者によるIDおよびパスワードの不正使用その他の事故があっても、そのために生じた損害については、一切責任を負いません。
        </li>
        <li>
          第1項により開示されたIDおよびパスワードは、利用者による利用のためのみに提供されるものであり、当社の許諾なく第三者に対してかかるIDおよびパスワードを提供することはできません。
        </li>
      </ol>
    ),
  },
  {
    title: "第4条（データの取扱）",
    content: (
      <ol>
        <li>
          利用者は、本件サービスの利用を通じて取得される、利用者のウェブサイトにかかるユーザーの利用動向、広告効果の測定および分析等にかかるデータ（以下「効果データ」といいます）が、当社または当社が委託するサーバー内に蓄積されること、および当該サーバーの管理上発生する恐れのある効果データに関する一切の不利益について、当社は責任を負わないことを予め承諾するものとします。
        </li>
        <li>
          効果データの取扱については、以下の各号のとおりとし、利用者はこれを予め承諾するものとします。
          <ol>
            <li>
              当社は、利用者を特定する情報を含まない情報により構成される効果データを、利用契約の有効期間中および利用契約終了後も統計的資料として利用契約以外の目的についても利用することができるものとします。
            </li>
            <li>
              利用者は、効果データを自己の責任において、定期的にバックアップするものとし、当社は効果データの紛失等について一切の責任を負わないものとします。
            </li>
          </ol>
        </li>
      </ol>
    ),
  },
  {
    title: "第5条（委託）",
    content: (
      <ol>
        <li>
          当社は、本件サービスにかかるシステムの開発・改修・保守、本件サービスの提供にかかる支援・助言提供その他の業務を、当社が任意に選定する第三者に委託する場合があります。この場合、当社は、当該第三者に対して当該業務を遂行するために必要かつ合理的な範囲の利用者の情報（効果データを含みます（ただし、最大限、利用者が特定できないように開示するものとします。）がこれに限られません。）を開示することができます。
        </li>
        <li>
          当社は、当社が指定する第三者に対し、本件サービスの代理販売にかかる以下の業務を委託することができるものとします（以下、当社から代理販売の委託を受けた者を「代理店」といいます）。この場合であっても、代理店を介し本件サービスを申込む利用者は、本規約を遵守しなければならず、また、代理店が本規約に関する説明を怠り、または虚偽の説明等を行ったことその他代理店の責に帰すべき事由により発生した利用者の損害については、当社は免責されるものとします。
          <ol>
            <li>本件サービスおよび本規約の説明を行う業務</li>
            <li>
              利用者からの申込みを受け、利用者の代理で当社に申込みを申請する業務
            </li>
            <li>
              利用者に対して、当社の代理で本件サービスの対価を請求し、支払いを受ける業務
            </li>
          </ol>
        </li>
      </ol>
    ),
  },
  {
    title: "第6条（利用料金および支払方法）",
    content: (
      <ol>
        <li>
          利用者は、本件サービス利用の対価（以下「本件サービス利用料金」といいます。）として、以下の各号記載の金員を当社または当社が指定する金融機関に支払うものとします。なお、具体的金額は、申込みの承諾時点で当社が承諾したとおりの金額とします。ただし、前条第２項に定めるとおり、代理店を介して本件サービスに申込む利用者については、代理店を通じてこれを支払うものとし、次項は適用されないものとします。この場合、本件サービス利用料金の支払い方法の詳細については別途当社と代理店、および利用者と代理店との間で契約を締結することにより定めるものとします。
          <ol>
            <li>
              月額費用：本件サービス利用の対価として、毎月生じる利用料をいいます。
            </li>
            <li>
              その他費用：前2号以外の本件サービス利用の対価として、当社が定めるものをいいます。
            </li>
          </ol>
        </li>
        <li>
          当社は、本件サービス利用料金を毎月末日に締め、利用料金債権を確定するとともに、当該債権を当社が指定する第三者に譲渡される場合があるものとし、利用者は異議なくこれを承諾します。なお、当該債権が譲渡される際には、当社より利用者に通知を行うものとします。
        </li>
        <li>
          利用者は、当月分の本件サービス利用料金を翌月末日までに、当社または当社より譲渡された第三者の定める方法により、支払うものとします。なお、振込手数料は利用者の負担とします。
        </li>
        <li>
          利用者が､利用契約に基づく債務を所定の支払期日が過ぎてもなお履行しない場合、利用者は、所定の支払期日の翌日から支払日の前日までの日数に年率14.6％の利率で計算した金額を遅延損害金として、本件サービス利用料その他の債務と一括して、当社または金融機関等が指定する期日までに当社または金融機関等の指定する方法により支払うものとします。
        </li>
        <li>
          当社による利用者への最初の本件サービスの提供が、月の途中から開始する場合であっても、当月の月額費用は減額されないものとします。
        </li>
      </ol>
    ),
  },
  {
    title: "第7条（本件サービスの休止）",
    content: (
      <ol>
        <li>
          利用者は、次の各号のいずれかに該当する事由が生じたとき、またはこれらに該当するおそれがあると当社が判断したときは、当社が本件サービスの一部または全部の提供を休止する場合があることを承諾するものとします。
          <ol>
            <li>
              サーバーやソフトウェア等の点検、修理、データ更新の必要があるとき
            </li>
            <li>設備の故障等やむを得ない事情があるとき</li>
            <li>
              電気通信事業者が電気通信サービスの提供を中止することにより本件サービスの提供が困難となったとき
            </li>
            <li>
              他社ツールに、トラブル、サービス提供の中断または停止、本件サービスとの連携の停止、仕様変更等が生じたとき
            </li>
            <li>その他、当社が休止を必要と判断したとき</li>
          </ol>
        </li>
        <li>
          当社は、本件サービスの提供を休止する場合、事前に休止の理由および期間を利用者に通知します。但し、やむを得ない場合は、事後遅滞なく通知することで足りるものとします。
        </li>
        <li>
          当社は、本条に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任を負いません。
        </li>
      </ol>
    ),
  },
  {
    title: "第8条（本件サービスの終了・変更）",
    content: (
      <ol>
        <li>
          当社は、本件サービスの全部もしくは一部の終了を決定した場合には、利用者に当該終了の１ヶ月前までに通知することで、本件サービスの一部または全部の提供を終了することができるものとします。但し、緊急でやむを得ない場合については、可能な限りすみやかに通知することとします。
        </li>
        <li>
          当社は、利用者が第12条第1項各号のいずれかに該当すると当社が判断した場合、利用者への通知・催告なしに本件サービスの一部または全部の提供を終了することができるものとします。
        </li>
        <li>
          当社は、当社が必要と判断した場合には、本件サービスの内容を変更できるものとします。
        </li>
        <li>
          当社は、本条に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任を負いません。
        </li>
      </ol>
    ),
  },
  {
    title: "第9条（知的財産権の帰属）",
    content: (
      <ol>
        <li>
          本件サービスに関する著作権、工業所有権等の知的財産権その他の権利は、当社またはサービスベンダーに帰属し、これらの権利が利用者に移転することはないものとします。
          また、利用者は、当社の承諾なく当社の商号、商標ならびにロゴマークを使用してはならないものとします。
        </li>
        <li>
          利用者は、本件サービスにかかるソフトウェア等の複製、改変、リバースエンジニアリング、逆コンパイル、逆アセンブルその他本件サービスにかかる当社の知的財産権を侵害する行為を一切行ってはならないものとします。
        </li>
      </ol>
    ),
  },
  {
    title: "第10条（禁止事項）",
    content: (
      <ol>
        <li>
          利用者は、本件サービスの利用に関して、以下の各号に該当する行為および該当するおそれのある行為を行わないものとします。
          <ol>
            <li>法令の定めに違反する行為</li>
            <li>詐欺その他犯罪に結びつく行為</li>
            <li>公序良俗に違反する行為</li>
            <li>
              当社または第三者の著作権、商標権等の知的財産権その他の権利を侵害する行為
            </li>
            <li>第三者の肖像権およびプライバシーを侵害する行為</li>
            <li>
              当社もしくは第三者の設備または本件サービスを提供するにあたり必要な設備などの利用もしくは業務の運営・維持に支障を与える行為
            </li>
            <li>
              第三者になりすまして本件サービスを利用し、または情報を送信もしくは表示する行為
            </li>
            <li>
              本件サービスの内容や本件サービスにより利用しうる情報を改ざんまたは消去する行為
            </li>
            <li>第三者に本件サービスを利用させる行為</li>
            <li>
              当社または第三者を差別もしくは誹謗中傷し、またはその名誉もしくは信用を毀損する行為
            </li>
            <li>
              日本法に反するわいせつ、児童ポルノまたは児童虐待にあたる画像､文書等を送信または掲載する行為
            </li>
            <li>無限連鎖講を開設し、またはこれを勧誘する行為</li>
            <li>
              ウイルス等の有害なコンピュータープログラム等を送信または掲載する行為
            </li>
            <li>
              無断で第三者に広告、宣伝もしくは勧誘のメールを送信する行為、または第三者が嫌悪感を抱く、もしくはそのおそれのあるメール（嫌がらせメール）を送信する行為
            </li>
            <li>
              本件サービスと類似または同様のシステムもしくは機能を開発し、販売する行為。なお、本号の規定は利用契約終了後も3年間有効とします。
            </li>
            <li>当社に許諾された範囲を逸脱して、本件サービスを利用する行為</li>
            <li>本件サービスの提供を阻害する一切の行為</li>
            <li>その他、当社もしくは第三者に不利益を与える行為</li>
            <li>前各号のいずれかに該当する行為を助長または誘引する行為</li>
          </ol>
        </li>
        <li>
          利用者は、自己が前項各号のいずれかに該当する行為をなした場合は、直ちに当社に通知するものとします。
        </li>
        <li>
          当社は、本件サービスの利用に関して、利用者または利用者の関係会社が第1項各号のいずれかに該当する行為または該当するおそれのある行為を行った場合、事前に利用者に通知することなく、本件サービスの全部または一部の提供を一時停止し、または第1項各号に該当し、または該当するおそれのある行為に関連する情報を削除することができるものとし、これによって利用者に発生した損害について一切の責任を負わないものとします。
        </li>
      </ol>
    ),
  },
  {
    title: "第11条（第三者利用の条件）",
    content: (
      <>
        利用者は前条第１項に関わらず、自己の責任と管理の下、本契約において利用者が負うのと同様の義務を課すこと及び当社の許諾を条件として、第三者に対して本サービスを利用させることができるものとします。ただし、利用者が第三者に利用させる場合には、利用者は当社の求めに応じ、当該第三者および委託内容等を書面にて通知するものとし、当該第三者の行為およびそれにより生じた結果のすべてについて、当社に対し責任を負うものとします。
      </>
    ),
  },
  {
    title: "第12条（契約の解約および解除）",
    content: (
      <ol>
        <li>
          利用者または当社が次の各号の一に該当する場合は、相手方は何等の催告を要せずして通知のみで利用契約を解除することができるものとします。なお、利用契約が解除された場合、解除された当事者は期限の利益を喪失し、相手方に対する債務を直ちに弁済しなければならないものとします。
          <ol>
            <li>
              差押・仮差押・仮処分もしくは競売を申し立てられ、または滞納処分を受けたとき。
            </li>
            <li>
              民事再生、会社更生、破産、特別清算または特定調停等の法的整理手続の申立または開始があったとき。
            </li>
            <li>監督官庁から、営業停止、営業取消等の命令を受けたとき。</li>
            <li>
              解散（但し、合併による場合を除く）、営業停止の決議をしたとき。
            </li>
            <li>
              自ら振出しまたは引受けた手形、小切手につき不渡り処分を受ける、電子記録債権が支払不能となる等、支払停止状態になったとき。
            </li>
            <li>
              財産状況が著しく悪化し、利用契約の履行が困難であると認められるとき、またはその恐れがあるとき。
            </li>
            <li>天災等の不可抗力により利用契約の履行が不可能となったとき。</li>
            <li>
              その他本規約の条項または本件サービス資料の条件に違反する行為があり、相当期間を定めて行った通知催告後もその行為が是正されないとき。
            </li>
            <li>
              第15条に定める表明保証の内容が正確または真実でないことが判明したとき。
            </li>
            <li>法令に違反する行為を行ったとき。</li>
            <li>
              利用者が第10条第1項に定める禁止事項に該当したと当社が判断したとき、または第15条第2項もしくは第3項に基づいて当社が必要な措置を講ずるよう求めたにもかかわ
              らず、正当な理由なくこれを拒否したとき（本号に基づき利用契約の解除権を有するのは、当社のみとします）。
            </li>
          </ol>
        </li>
        <li>
          前項に定める場合を除き、利用者は本件サービスの利用期間の残期間分の本件サービス利用料金を支払ったうえ、当社所定の解約手続きを経ることにより、利用者による解約の意思表示が当社に到達した日の属する月の翌月末日をもって、利用契約を解約することができるものとします。なおこの場合、本件サービスの利用の有無を問わず、本件サービス利用料金は減額されないものとします。
        </li>
      </ol>
    ),
  },
  {
    title: "第13条（免責）",
    content: (
      <ol>
        <li>
          当社は、当社に故意又は重過失がある場合を除き、利用者または第三者に対する本件サービスの提供、利用者による本件サービスの利用に起因して発生した損害の責任を負わないものとします。
        </li>
        <li>
          前項の損害賠償責任を免責する規定にかかわらず当社が利用者に対して損害賠償責任を負う場合においても、当社の賠償責任は、損害の事由が生じた時点から遡って過去３ヶ月間の期間に利用者から現実に受領した本件サービス利用料金の総額を上限とします。
        </li>
      </ol>
    ),
  },
  {
    title: "第14条（機密保持）",
    content: (
      <ol>
        <li>
          利用者および当社は、別段の定めがある場合を除き、本件サービスに関して相手方から知り得た一切の情報（効果データの内容を含む。以下「機密情報」といいます。）を、利用契約遂行の目的以外で使用し、または第三者に公表・漏洩してはならないものとします。
        </li>
        <li>
          前項の規定によらず、次の各号に定める情報は機密情報に該当しないものとします。
          <ol>
            <li>
              第三者に対する開示について事前に書面による情報開示者の承諾を得た情報。
            </li>
            <li>開示を受けた時、既に公知の情報。</li>
            <li>開示を受けた後、情報受領者の責めによらず公知となった情報。</li>
            <li>開示を受けた時、既に情報受領者が適法に占有していた情報。</li>
          </ol>
        </li>
        <li>
          前2項の規定にかかわらず、利用者および当社は、法律、裁判所または政府機関の強制力を伴う命令、要求または要請に基づき、相手方の機密情報を開示することができるものとします。但し、当該命令、要求または要請があった場合、速やかにその旨を相手方に通知しなければならないものとします。
        </li>
        <li>
          本条の規定は、利用契約終了後も3年間有効に存続し、適用されるものとします。
        </li>
      </ol>
    ),
  },
  {
    title: "第15条（反社会的勢力との関係断絶）",
    content: (
      <ol>
        <li>
          利用者は、自己が反社会的勢力（犯罪対策閣僚会議幹事会申合せ『企業が反社会的勢力による被害を防止するための指針』において説明される「反社会的勢力」をいいます。以下同じ）に該当しないこと、および反社会的勢力と一切関係を有していないことを表明し、保証するものとします。
        </li>
        <li>
          利用者が利用契約に関連して締結した契約（以下｢関連契約｣といいます。）の当事者またはその代理人もしくはその締結を媒介した者が反社会的勢力であることが判明した場合には、当社は、利用者に対し、当該関連契約の解除その他の必要な措置を講ずるよう求めることができるものとします。
        </li>
        <li>
          当社は、利用者の取締役、監査役、従業員その他の構成員、株主、取引先、もしくは顧問その他のアドバイザーが反社会的勢力であること、または利用者が資金提供その他を通じて反社会的勢力の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力との何らかの交流もしくは関与を行っていることが判明した場合、利用者に対し、その解消を求めることができるものとします。
        </li>
      </ol>
    ),
  },
  {
    title: "第16条（損害賠償）",
    content: (
      <>
        利用者が本規約に違反したことにより、当社が損害を被った場合、利用者は、その損害および費用を賠償しなければなりません。
      </>
    ),
  },
  {
    title: "第17条（権利義務の譲渡の禁止）",
    content: (
      <ol>
        <li>
          利用者は、当社の書面による承認がない限り、利用契約から生ずる権利を第三者に譲渡し、賃貸し、または担保に供することはできません。
        </li>
        <li>
          当社は本件サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利および義務並びに利用者の登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
        </li>
      </ol>
    ),
  },
  {
    title: "第18条（準拠法・管轄裁判所）",
    content: (
      <>
        本規約の準拠法は日本法とし、本件サービスまたは本規約に関する係争については、その訴額に応じて東京簡易裁判所または東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </>
    ),
  },
  {
    title: "第19条（協議事項）",
    content: (
      <>
        利用者および当社は、相互に協力のうえ、利用契約を履行するものとし、本規約に定めのない事項および疑義の生じた事項については、誠意をもって協議しこれを解決するものとします。
      </>
    ),
  },
];
