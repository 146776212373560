import { reducerWithInitialState } from "typescript-fsa-reducers";

import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { User } from "../../../../domain/user";
import { setCurrentCompanyIdWithStorage } from "../../../../utils/CompanyUtils";
import { fetchUserAction } from "../actions/fetchUserAction";
import { updateCompanyAction } from "../actions/updateCompanyAction";

export type UserState = {
  currentCompany: CompanyAttribute | null;
  me: {
    requesting: boolean;
    data: User | null;
    error: boolean;
  };
};

export const initialState: UserState = {
  currentCompany: null,
  me: {
    requesting: false,
    data: null,
    error: false,
  },
};

export const userReducer = reducerWithInitialState(initialState)
  /**
   * Update Company
   */
  .case(updateCompanyAction, (state, action) => {
    setCurrentCompanyIdWithStorage(action.company.company.id);
    return {
      ...state,
      currentCompany: action.company,
    };
  })
  /**
   * Fetch User
   */
  .case(fetchUserAction.started, (state) => ({
    ...state,
    me: {
      ...state.me,
      requesting: true,
    },
  }))
  .case(fetchUserAction.done, (state, action) => ({
    ...state,
    me: {
      ...state.me,
      requesting: false,
      data: action.result.user,
    },
  }))
  .case(fetchUserAction.failed, (state) => ({
    ...state,
    me: {
      ...state.me,
      requesting: false,
      error: true,
    },
  }));
