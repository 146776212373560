import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchYieldsetting
 */
export type PatchYieldsettingParams = void;
export type PatchYieldsettingSuccess = Response.GamLineItem.YieldSetting.Patch;
export type PatchYieldsettingFailure = Error;

export const patchYieldSettingAction = actionCreator.async<
  PatchYieldsettingParams,
  PatchYieldsettingSuccess,
  PatchYieldsettingFailure
>("PATCH_YIELD_SETTING");

export function patchYieldSetting(
  payload: Request.GamLineItem.YieldSetting.Patch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { gamLineItem, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchYieldSettingAction.started());

    try {
      const res = await gamLineItem.patchYieldSetting(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(patchYieldSettingAction.done({ result: res }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(patchYieldSettingAction.failed({ error }));
      }
    }
  };
}
