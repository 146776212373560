/* tslint:disable */
/* eslint-disable */
/**
 * DataStrap API
 * DataStrapの内部向けAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 分析機能の公開設定
 * @export
 * @enum {string}
 */

export const AnalyticsFeaturePublishType = {
    Published: 'published',
    NotPublished: 'not_published',
    OnlyAdmin: 'only_admin'
} as const;

export type AnalyticsFeaturePublishType = typeof AnalyticsFeaturePublishType[keyof typeof AnalyticsFeaturePublishType];



