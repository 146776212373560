import { Typography, Icon, Spacer, useTheme, TextField } from "ingred-ui";
import * as React from "react";
import { Scrollbars as CustomScrollbars } from "react-custom-scrollbars-2";

import { config } from "../../../config";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { User } from "../../../domain/user";
import { selectCompany } from "../../../store/modules/user/actions/selectCompanyAction";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import { getAuth0Client } from "../../../utils/Auth0Utils";
import { createRegExpStr } from "../../../utils/StringUtils";

import * as Styled from "./styled";

type Props = {
  user: User;
  currentCompany: CompanyAttribute;
  selectCompany: DispatchableAction<typeof selectCompany>;
};

const SelectCompany: React.FunctionComponent<Props> = ({
  user,
  currentCompany,
  selectCompany,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState<string | null>(null);
  const [cursoredAttributeIndex, setCursoredAttributeIndex] = React.useState<
    number
  >(-1);
  const scrollbarRef = React.useRef<CustomScrollbars>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  // 枠の高さ
  const clientHeight = scrollbarRef.current?.getClientHeight();
  // スクロール部分全体の高さ
  const scrollHeight = scrollbarRef.current?.getScrollHeight();

  React.useEffect(() => {
    // modal表示時に検索inputをfocus
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // modalを閉じた時に検索をリセット
    setSearchValue(null);
  }, [isOpen]);

  const theme = useTheme();

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleSelect = (attribute: CompanyAttribute) => () => {
    selectCompany({ company: attribute });
    setIsOpen(false);
  };
  const handleLogout = async () => {
    const auth0Client = await getAuth0Client();
    auth0Client.logout({
      client_id: config.AUTH0_CLIENT_ID,
      returnTo: `${window.location.origin}`,
    });
  };
  const filteredCompanyAttributes = React.useCallback(
    (attributes: CompanyAttribute[]) => {
      return attributes.filter(
        (attribute) => attribute.company.id !== currentCompany.company.id,
      );
    },
    [currentCompany.company.id],
  );
  const SearchedCompanyAttributes = React.useCallback(
    (attributes: CompanyAttribute[]) => {
      if (searchValue != null) {
        const regExpStr = createRegExpStr(searchValue);
        return attributes.filter(
          (attribute) => attribute.company.name.search(regExpStr) !== -1,
        );
      }
      return attributes;
    },
    [searchValue],
  );
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value == "" ? null : value);
    setCursoredAttributeIndex(-1);
  };

  const getMenuLength = React.useCallback(() => {
    return filteredCompanyAttributes(
      SearchedCompanyAttributes(user.company_attributes),
    ).length;
  }, [
    SearchedCompanyAttributes,
    filteredCompanyAttributes,
    user.company_attributes,
  ]);

  React.useEffect(() => {
    const selectedHeight =
      ((scrollHeight as number) * cursoredAttributeIndex) / getMenuLength();
    scrollbarRef.current?.scrollTop(
      selectedHeight - (clientHeight as number) + 50,
    );
  }, [cursoredAttributeIndex, clientHeight, getMenuLength, scrollHeight]);

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "ArrowDown":
        setCursoredAttributeIndex(
          cursoredAttributeIndex === getMenuLength() - 1
            ? 0
            : cursoredAttributeIndex + 1,
        );
        break;
      case "ArrowUp":
        if (cursoredAttributeIndex === -1) {
          break;
        }
        setCursoredAttributeIndex(
          cursoredAttributeIndex === 0
            ? getMenuLength() - 1
            : cursoredAttributeIndex - 1,
        );
        break;
      case "Enter":
        if (cursoredAttributeIndex !== -1) {
          handleSelect(
            filteredCompanyAttributes(
              SearchedCompanyAttributes(user.company_attributes),
            )[cursoredAttributeIndex],
          )();
        }
        break;
      default:
        return;
    }
  };

  return (
    <Styled.Container>
      <Styled.ModalHandler onClick={handleOpen}>
        <Spacer pr={0.5}>
          <Typography size="sm">{currentCompany.company.name}</Typography>
        </Spacer>
        <Icon name="arrow_bottom" color={theme.palette.black} />
      </Styled.ModalHandler>
      {isOpen && (
        <>
          <Styled.ModalBackground onClick={handleClose} />
          <Styled.ModalContainer>
            {filteredCompanyAttributes(user.company_attributes).length > 0 && (
              <Styled.TopContainer>
                <Spacer p={2} pb={1.5}>
                  <TextField
                    icon="search"
                    placeholder="検索"
                    inputRef={inputRef}
                    onChange={handleSearchChange}
                    onKeyDown={handleOnKeyDown}
                  />
                </Spacer>
                {getMenuLength() > 0 ? (
                  <CustomScrollbars
                    ref={scrollbarRef}
                    autoHeight={true}
                    autoHeightMin={274}
                  >
                    <Styled.Ul>
                      {filteredCompanyAttributes(
                        SearchedCompanyAttributes(user.company_attributes),
                      ).map((attribute, index) => {
                        return (
                          <Styled.Li
                            key={attribute.company.id}
                            selected={index === cursoredAttributeIndex}
                            onClick={handleSelect(attribute)}
                          >
                            <Typography size="sm">
                              {attribute.company.name}
                            </Typography>
                          </Styled.Li>
                        );
                      })}
                    </Styled.Ul>
                  </CustomScrollbars>
                ) : (
                  <Spacer p={2} pt={1}>
                    <Typography size="md" color="hint" align="center">
                      見つかりませんでした
                    </Typography>
                  </Spacer>
                )}
              </Styled.TopContainer>
            )}
            <Styled.BottomContainer>
              <Styled.Button onClick={handleLogout}>
                <Icon name="logout" size="lg" color={theme.palette.black} />
                <Typography size="sm">ログアウト</Typography>
              </Styled.Button>
            </Styled.BottomContainer>
          </Styled.ModalContainer>
        </>
      )}
    </Styled.Container>
  );
};

export { SelectCompany };
