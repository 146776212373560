import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../../containers/AuthedAppShell";
import { GamAdUnit } from "../../../../domain/gamAdUnit";
import { GamLineItem } from "../../../../domain/gamLineItem";
import {
  RouteActionContext,
  RouteActionPayload,
} from "../../../../routing/types";
import { fetchDemandAdCreativesBasedOnGam as fetchDemandAdCreativesBasedOnGamAction } from "../../../../store/modules/demandAdCreative/actions/fetchDemandAdCreativesBasedOnGamAction";
import { getUnmappedToGamDemandAdCreatives } from "../../../../store/modules/demandAdCreative/selectors/demandAdCreativeSelector";
import { deleteDemandAdCreatives as deleteDemandAdCreativesAction } from "../../../../store/modules/gamAdCreative/actions/deleteDemandAdCreativesAction";
import { fetchGamAdCreatives as fetchGamAdCreativesAction } from "../../../../store/modules/gamAdCreative/actions/fetchGamAdCreativesAction";
import { postDemandAdCreatives as postDemandAdCreativesAction } from "../../../../store/modules/gamAdCreative/actions/postDemandAdCreativesAction";
import { getGamAdCreativesByGamLineItemId } from "../../../../store/modules/gamAdCreative/selectors/gamAdCreativeSelector";
import { getGamAdUnitById } from "../../../../store/modules/gamAdUnit/selectors/gamAdUnitSelector";
import { getGamLineItemById } from "../../../../store/modules/gamLineItem/selectors/gamLineItemSelector";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../../store/reducer";
import { dispatchable } from "../../../../store/utils/dispatchable";
import { forward404 } from "../../../../utils/HttpUtils";

import { GamAdCreatives as Component, Props } from "./GamAdCreatives";

const GamAdCreatives = connect(
  (state: RootState, ownProps: Props) => ({
    gamAdCreatives: getGamAdCreativesByGamLineItemId(
      state,
      ownProps.parentGamLineItemId,
    ),
    demandAdCreatives: getUnmappedToGamDemandAdCreatives(state),
    postRequesting: state.gamAdCreative.demandAdCreative.post.requesting,
    postSucceeded: state.gamAdCreative.demandAdCreative.post.success,
    deleteRequesting: state.gamAdCreative.demandAdCreative.delete.requesting,
    deleteSucceeded: state.gamAdCreative.demandAdCreative.delete.success,
    parentGamAdUnit: getGamAdUnitById(
      state,
      ownProps.parentGamAdUnitId,
    ) as GamAdUnit, // fetchGamAdCreativesActionで取得している
    parentGamLineItem: getGamLineItemById(
      state,
      ownProps.parentGamLineItemId,
    ) as GamLineItem, // fetchGamAdCreativesActionで取得している
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    postDemandAdCreatives: dispatchable(dispatch, postDemandAdCreativesAction),
    deleteDemandAdCreatives: dispatchable(
      dispatch,
      deleteDemandAdCreativesAction,
    ),
  }),
)(Component);

export async function action({
  store,
  firstOrPush,
  params,
}: RouteActionContext): Promise<RouteActionPayload> {
  const unitId = parseInt(params["unit_id"] as string, 10);
  const lineItemId = parseInt(params["line_item_id"] as string, 10);
  if (firstOrPush) {
    await Promise.all([
      store.dispatch(
        fetchGamAdCreativesAction({
          gam_line_item_id: lineItemId,
        }),
      ),
      store.dispatch(
        fetchDemandAdCreativesBasedOnGamAction({
          status: "unmapped",
        }),
      ),
    ]);
  }

  const state = store.getState();
  const gamAdUnit = getGamAdUnitById(state, unitId);
  const gamLineItem = getGamLineItemById(state, lineItemId);

  if (gamAdUnit == null || gamLineItem == null) {
    throw forward404();
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: (
      <GamAdCreatives
        parentGamAdUnitId={unitId}
        parentGamLineItemId={lineItemId}
      />
    ),
  };
}
