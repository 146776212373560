import { Domain } from "api-types";
import { Location } from "history";
import {
  ActionButton,
  Button,
  ButtonGroup,
  Column,
  DataTable,
  DropdownButton,
  FixedPanel,
  Flex,
  Portal,
  Select,
  Spacer,
  Typography,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import React from "react";
import { v4 as uuid } from "uuid";

import { channelTypeName } from "../../../../constants/channelType";
import { deviceTypeName } from "../../../../constants/deviceType";
import { osTypeName } from "../../../../constants/osType";
import { Helmet } from "../../../../containers/Helmet";
import { Category } from "../../../../domain/category";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { DemandAdCreativeGroup } from "../../../../domain/demandAdCreativeGroup";
import { useIntersectionObserver } from "../../../../hooks/useIntersectionObserver";
import { deleteDemandAdCreatives2 as unlinkCategory2DemandAdCreatives } from "../../../../store/modules/category/actions/deleteDemandAdCreatives2Action";
import { deleteDemandAdCreatives as unlinkCategoryDemandAdCreatives } from "../../../../store/modules/category/actions/deleteDemandAdCreativesAction";
import { patchDemandAdCreatives2 as linkCategory2DemandAdCreatives } from "../../../../store/modules/category/actions/patchDemandAdCreatives2Action";
import { patchDemandAdCreatives as linkCategoryDemandAdCreatives } from "../../../../store/modules/category/actions/patchDemandAdCreativesAction";
import { postCategory } from "../../../../store/modules/category/actions/postCategoryAction";
import { activateDemandAdCreatives } from "../../../../store/modules/demandAdCreative/actions/activateDemandAdCreativesAction";
import { deleteDemandAdCreatives } from "../../../../store/modules/demandAdCreative/actions/deleteDemandAdCreativesAction";
import { patchChannelType } from "../../../../store/modules/demandAdCreative/actions/patchChannelTypeAction";
import { patchDeviceType } from "../../../../store/modules/demandAdCreative/actions/patchDeviceTypeAction";
import { patchOsType } from "../../../../store/modules/demandAdCreative/actions/patchOsTypeAction";
import { deleteDemandAdCreatives as unlinkSiteDemandAdCreatives } from "../../../../store/modules/demandAdCreativeGroup/actions/deleteDemandAdCreativesAction";
import { patchDemandAdCreatives as linkSiteDemandAdCreatives } from "../../../../store/modules/demandAdCreativeGroup/actions/patchDemandAdCreativesAction";
import { postDemandAdCreativeGroup } from "../../../../store/modules/demandAdCreativeGroup/actions/postDemandAdCreativeGroupAction";
import { push } from "../../../../store/modules/routing/actions/PushAction";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../../store/utils/dispatchable";
import { Breadcrumbs } from "../../../elements/Breadcrumbs";
import { PageContainer } from "../../../elements/PageContainer";
import { PageContent } from "../../../elements/PageContent";
import { Popover } from "../../../elements/Popover";
import { SelectableInput } from "../../../elements/SelectableInput";

import { CategoryMoveModal } from "./internal/CategoryMoveModal";
import { ConfirmModal } from "./internal/ConfirmModal";
import { ConfirmModalType } from "./internal/ConfirmModal/ConfirmModal";
import { MoveModal } from "./internal/MoveModal";
import { BaseItem, ModalType } from "./internal/MoveModal/MoveModal";
import { SiteMoveModal } from "./internal/SiteMoveModal";
import * as Styled from "./styled";

type RefineDimension =
  | "none"
  | "device_type"
  | "site"
  | "category"
  | "category2"
  | "os_type";
const refineDimensionOptions: OptionType<RefineDimension>[] = [
  {
    label: "未選択",
    value: "none",
  },
  {
    label: "デバイス",
    value: "device_type",
  },
  {
    label: "サイト",
    value: "site",
  },
  {
    label: "カテゴリ",
    value: "category",
  },
  {
    label: "カテゴリ2",
    value: "category2",
  },
  {
    label: "OS",
    value: "os_type",
  },
];

type RefineStatus = "none" | "unmapped" | "mapped";
const refineStateOptions: OptionType<RefineStatus>[] = [
  {
    label: "未選択",
    value: "none",
  },
  {
    label: "紐付け未完了",
    value: "unmapped",
  },
  {
    label: "紐付け完了",
    value: "mapped",
  },
];

export type RefineColumn =
  | "originalId"
  | "name"
  | "demandName"
  | "channelTypeName"
  | "deviceTypeName"
  | "osTypeName"
  | "siteName"
  | "categoryName"
  | "category2Name"
  | "hbDemandTypeName"
  | "gamAdUnitName";

function getRefineColumnOptions(filterItems: FilterItem[]) {
  const refineColumnOptions: OptionType<RefineColumn>[] = [
    {
      label: "広告枠ID",
      value: "originalId",
    },
    {
      label: "クリエイティブ",
      value: "name",
    },
    {
      label: "デマンド",
      value: "demandName",
    },
    {
      label: "チャネル",
      value: "channelTypeName",
    },
    {
      label: "デバイス",
      value: "deviceTypeName",
    },
    {
      label: "OS",
      value: "osTypeName",
    },
    {
      label: "サイト",
      value: "siteName",
    },
    {
      label: "カテゴリ",
      value: "categoryName",
    },
    {
      label: "カテゴリ2",
      value: "category2Name",
    },
    {
      label: "HBビッダー",
      value: "hbDemandTypeName",
    },
    {
      label: "GAM広告ユニット",
      value: "gamAdUnitName",
    },
  ];
  return refineColumnOptions.filter(
    (option) =>
      !filterItems
        .map((item) => item.refineColumn.value)
        .includes(option.value),
  );
}

export const channelTypes: {
  id: number;
  name: string;
  value: Domain.ChannelType;
}[] = Domain.ChannelTypes.map((ct, idx) => {
  return {
    id: idx + 1,
    name: channelTypeName[ct],
    value: ct,
  };
});

export const deviceTypes: {
  id: number;
  name: string;
  value: Domain.DeviceType;
}[] = [
  {
    id: 1,
    name: deviceTypeName["app"],
    value: "app",
  },
  {
    id: 2,
    name: deviceTypeName["pc_web"],
    value: "pc_web",
  },
  {
    id: 3,
    name: deviceTypeName["sp_amp"],
    value: "sp_amp",
  },
  {
    id: 4,
    name: deviceTypeName["sp_web"],
    value: "sp_web",
  },
  {
    id: 5,
    name: deviceTypeName["unknown"],
    value: "unknown",
  },
];

export const osTypes: {
  id: number;
  name: string;
  value: Domain.OsType;
}[] = [
  {
    id: 1,
    name: osTypeName["none"],
    value: "none",
  },
  {
    id: 2,
    name: osTypeName["unknown"],
    value: "unknown",
  },
  {
    id: 3,
    name: osTypeName["android"],
    value: "android",
  },
  {
    id: 4,
    name: osTypeName["ios"],
    value: "ios",
  },
  {
    id: 5,
    name: osTypeName["ipad_os"],
    value: "ipad_os",
  },
];

type FilterItem = {
  id: string;
  refineColumn: OptionType<RefineColumn>;
  value: string;
};

type MoveModalId = ModalType | "link_site" | "link_category" | "link_category2";
type ConfirmModalId = ConfirmModalType;

const getFormatedData = (creatives: DemandAdCreative[]) => {
  return creatives.map((creative) => ({
    id: creative.id,
    originalId: creative.original_id,
    name: creative.original_name
      ? creative.original_name
      : creative.original_id,
    demandName: creative.demand.name,
    channelTypeName: channelTypeName[creative.channel_type_name],
    deviceTypeName:
      creative.device_type_name === "unknown" ? "" : creative.device_type_name,
    osTypeName: creative.os_type_name === null ? "" : creative.os_type_name,
    siteName: creative.site ? creative.site.name : "",
    isActive: creative.is_active,
    mappedToGam: creative.mappedToGam,
    mappedToSite: creative.mappedToGroup,
    categoryName: creative.category ? creative.category.name : "",
    category2Name: creative.category2 ? creative.category2.name : "",
    hbDemandTypeName:
      creative.hb_demand_type_name === "none"
        ? "-"
        : creative.hb_demand_type_name, //noneはそもそもHBではない
    gamAdUnitName: creative.gamAdUnitInfo?.original_ad_unit_name
      ? creative.gamAdUnitInfo.original_ad_unit_name
      : "",
  }));
};

function getDefaultRefineColumn(value?: RefineColumn) {
  const options = getRefineColumnOptions([]);
  const matchOption = options.find((option) => option.value === value);
  if (matchOption) {
    return matchOption;
  }
  return options[1]; // クリエイティブ
}

const columns: Column<ReturnType<typeof getFormatedData>[0]>[] = [
  {
    name: "広告枠ID",
    selector: (data) => data.originalId,
    sortable: true,
    width: "160px",
  },
  {
    name: "クリエイティブ",
    selector: (data) => data.name,
    sortable: true,
    width: "250px",
  },
  {
    name: "デマンド",
    selector: (data) => data.demandName,
    sortable: true,
    width: "120px",
  },
  {
    name: "HBビッダー",
    selector: (data) => data.hbDemandTypeName,
    sortable: true,
    width: "180px",
  },
  {
    name: "チャネル",
    selector: (data) => data.channelTypeName,
    sortable: true,
    width: "140px",
  },
  {
    name: "デバイス",
    selector: (data) => data.deviceTypeName || "-",
    sortable: true,
    width: "160px",
  },
  {
    name: "OS",
    selector: (data) => data.osTypeName || "-",
    sortable: true,
    width: "140px",
  },
  {
    name: "サイト",
    selector: (data) => data.siteName || "-",
    sortable: true,
    width: "160px",
  },
  {
    name: "カテゴリ",
    selector: (data) => data.categoryName || "-",
    sortable: true,
    width: "160px",
  },
  {
    name: "カテゴリ2",
    selector: (data) => data.category2Name || "-",
    sortable: true,
    width: "160px",
  },
  {
    name: "GAM広告ユニット",
    selector: (data) => data.gamAdUnitName || "-",
    sortable: true,
    width: "180px",
  },
];

type RequestState = {
  requesting: boolean;
  success: boolean;
};

type InjectProps = {
  location: Location;
  currentCompany: CompanyAttribute;
  demandAdCreatives: DemandAdCreative[];
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  categories: Category[];
  push: DispatchableAction<typeof push>;
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  postDemandAdCreativeGroup: DispatchableAction<
    typeof postDemandAdCreativeGroup
  >;
  postDemandAdCreativeGroupState: RequestState;
  postCategory: DispatchableAction<typeof postCategory>;
  postCategoryState: RequestState;
  linkSiteDemandAdCreatives: DispatchableAction<
    typeof linkSiteDemandAdCreatives
  >;
  linkSiteDemandAdCreativesState: RequestState;
  linkCategoryDemandAdCreatives: DispatchableAction<
    typeof linkCategoryDemandAdCreatives
  >;
  linkCategoryDemandAdCreativesState: RequestState;
  unlinkSiteDemandAdCreatives: DispatchableAction<
    typeof unlinkSiteDemandAdCreatives
  >;
  unlinkSiteDemandAdCreativesState: RequestState;
  unlinkCategoryDemandAdCreatives: DispatchableAction<
    typeof unlinkCategoryDemandAdCreatives
  >;
  unlinkCategoryDemandAdCreativesState: RequestState;
  linkCategory2DemandAdCreatives: DispatchableAction<
    typeof linkCategory2DemandAdCreatives
  >;
  linkCategory2DemandAdCreativesState: RequestState;
  unlinkCategory2DemandAdCreativesState: RequestState;
  unlinkCategory2DemandAdCreatives: DispatchableAction<
    typeof unlinkCategory2DemandAdCreatives
  >;
  patchChannelType: DispatchableAction<typeof patchChannelType>;
  patchChannelTypeState: RequestState;
  patchDeviceType: DispatchableAction<typeof patchDeviceType>;
  patchDeviceTypeState: RequestState;
  patchOsType: DispatchableAction<typeof patchOsType>;
  patchOsTypeState: RequestState;
  activateDemandAdCreatives: DispatchableAction<
    typeof activateDemandAdCreatives
  >;
  activateDemandAdCreativesState: RequestState;
  deleteDemandAdCreatives: DispatchableAction<typeof deleteDemandAdCreatives>;
  deleteDemandAdCreativesState: RequestState;
};

type Props = {
  defaultRefineColumn?: RefineColumn;
  defaultRefineValue?: string;
};

type InjectedProps = Props & InjectProps;

const DemandAdCreativesManager: React.FunctionComponent<InjectedProps> = ({
  push,
  currentCompany,
  demandAdCreatives,
  demandAdCreativeGroups,
  categories,
  enqueueSystemNotification,
  postDemandAdCreativeGroup,
  postDemandAdCreativeGroupState,
  linkSiteDemandAdCreatives,
  linkSiteDemandAdCreativesState,
  linkCategoryDemandAdCreatives,
  linkCategoryDemandAdCreativesState,
  unlinkSiteDemandAdCreatives,
  unlinkSiteDemandAdCreativesState,
  unlinkCategoryDemandAdCreatives,
  unlinkCategoryDemandAdCreativesState,
  linkCategory2DemandAdCreatives,
  linkCategory2DemandAdCreativesState,
  unlinkCategory2DemandAdCreativesState,
  unlinkCategory2DemandAdCreatives,
  defaultRefineColumn,
  defaultRefineValue,
  location,
  patchChannelType,
  patchChannelTypeState,
  patchDeviceType,
  patchDeviceTypeState,
  patchOsType,
  patchOsTypeState,
  postCategory,
  postCategoryState,
  activateDemandAdCreatives,
  activateDemandAdCreativesState,
  deleteDemandAdCreatives,
  deleteDemandAdCreativesState,
}) => {
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const showPanel = !useIntersectionObserver(buttonContainerRef);

  const [moveModalId, setMoveModalId] = React.useState<MoveModalId | null>(
    null,
  );
  const [
    confirmModalId,
    setConfirmModalId,
  ] = React.useState<ConfirmModalId | null>(null);
  const [selectedCreatives, setSelectedCreatives] = React.useState<string[]>(
    [],
  );
  const [selectedRefineDimension, setSelectedRefineDimension] = React.useState<
    OptionType<RefineDimension>
  >(refineDimensionOptions[0]); // 未選択
  const [selectedRefineState, setSelectedRefineState] = React.useState<
    OptionType<RefineStatus>
  >(refineStateOptions[0]); // 未選択
  const [filterItems, setFilterItems] = React.useState<FilterItem[]>([
    {
      id: uuid(),
      refineColumn: getDefaultRefineColumn(defaultRefineColumn),
      value: defaultRefineValue || "",
    },
  ]);

  const [
    linkSiteDemandAdCreativesToasted,
    setLinkSiteDemandAdCreativesToasted,
  ] = React.useState(linkSiteDemandAdCreativesState.success);
  React.useEffect(() => {
    if (
      !linkSiteDemandAdCreativesToasted &&
      linkSiteDemandAdCreativesState.success
    ) {
      setMoveModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブをサイトに紐付けました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setLinkSiteDemandAdCreativesToasted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    linkSiteDemandAdCreativesToasted,
    linkSiteDemandAdCreativesState.success,
  ]);

  const [
    unlinkSiteDemandAdCreaticesToasted,
    setUnLinkSiteDemandAdCreativesToasted,
  ] = React.useState(unlinkSiteDemandAdCreativesState.success);
  React.useEffect(() => {
    if (
      !unlinkSiteDemandAdCreaticesToasted &&
      unlinkSiteDemandAdCreativesState.success
    ) {
      setConfirmModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブの紐付けを解除しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setUnLinkSiteDemandAdCreativesToasted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    unlinkSiteDemandAdCreaticesToasted,
    unlinkSiteDemandAdCreativesState.success,
  ]);

  const [patchChannelTypeToosted, setpatchChannelTypeToosted] = React.useState(
    patchChannelTypeState.success,
  );
  React.useEffect(() => {
    if (!patchChannelTypeToosted && patchChannelTypeState.success) {
      setMoveModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブのチャネルを変更しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setpatchChannelTypeToosted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    patchChannelTypeToosted,
    patchChannelTypeState.success,
  ]);

  const [patchDeviceTypeToosted, setpatchDeviceTypeToosted] = React.useState(
    patchDeviceTypeState.success,
  );
  React.useEffect(() => {
    if (!patchDeviceTypeToosted && patchDeviceTypeState.success) {
      setMoveModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブのデバイスを変更しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setpatchDeviceTypeToosted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    patchDeviceTypeToosted,
    patchDeviceTypeState.success,
  ]);

  const [patchOsTypeToosted, setpatchOsTypeToosted] = React.useState(
    patchOsTypeState.success,
  );
  React.useEffect(() => {
    if (!patchOsTypeToosted && patchOsTypeState.success) {
      setMoveModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブのOSを変更しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setpatchOsTypeToosted(true);
      setSelectedCreatives([]);
    }
  }, [enqueueSystemNotification, patchOsTypeToosted, patchOsTypeState.success]);

  const [
    linkCategoryDemandAdCreativesToasted,
    setLinkCategoryDemandAdCreativesToasted,
  ] = React.useState(linkCategoryDemandAdCreativesState.success);
  React.useEffect(() => {
    if (
      !linkCategoryDemandAdCreativesToasted &&
      linkCategoryDemandAdCreativesState.success
    ) {
      setMoveModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブをカテゴリに紐付けました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setLinkCategoryDemandAdCreativesToasted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    linkCategoryDemandAdCreativesToasted,
    linkCategoryDemandAdCreativesState.success,
  ]);

  const [
    linkCategory2DemandAdCreativesToasted,
    setLinkCategory2DemandAdCreativesToasted,
  ] = React.useState(linkCategory2DemandAdCreativesState.success);
  React.useEffect(() => {
    if (
      !linkCategory2DemandAdCreativesToasted &&
      linkCategory2DemandAdCreativesState.success
    ) {
      setMoveModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブをカテゴリ2に紐付けました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setLinkCategory2DemandAdCreativesToasted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    linkCategory2DemandAdCreativesToasted,
    linkCategory2DemandAdCreativesState.success,
  ]);

  const [
    unlinkCategoryDemandAdCreativesToasted,
    setUnLinkCategoryDemandAdCreativesToasted,
  ] = React.useState(unlinkCategoryDemandAdCreativesState.success);
  React.useEffect(() => {
    if (
      !unlinkCategoryDemandAdCreativesToasted &&
      unlinkCategoryDemandAdCreativesState.success
    ) {
      setConfirmModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブの紐付けを解除しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setUnLinkCategoryDemandAdCreativesToasted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    unlinkCategoryDemandAdCreativesToasted,
    unlinkCategoryDemandAdCreativesState.success,
  ]);

  const [
    unlinkCategory2DemandAdCreativesToasted,
    setUnLinkCategory2DemandAdCreativesToasted,
  ] = React.useState(unlinkCategory2DemandAdCreativesState.success);
  React.useEffect(() => {
    if (
      !unlinkCategory2DemandAdCreativesToasted &&
      unlinkCategory2DemandAdCreativesState.success
    ) {
      setConfirmModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブの紐付けを解除しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setUnLinkCategory2DemandAdCreativesToasted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    unlinkCategory2DemandAdCreativesToasted,
    unlinkCategory2DemandAdCreativesState.success,
  ]);

  const [
    activateDemandAdCreativesToasted,
    setActivateDemandAdCreativesToasted,
  ] = React.useState(activateDemandAdCreativesState.success);
  React.useEffect(() => {
    if (
      !activateDemandAdCreativesToasted &&
      activateDemandAdCreativesState.success
    ) {
      setConfirmModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブの利用を再開しました",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setActivateDemandAdCreativesToasted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    activateDemandAdCreativesToasted,
    activateDemandAdCreativesState.success,
  ]);

  const [
    deleteDemandAdCreativesToasted,
    setdeleteDemandAdCreativesToasted,
  ] = React.useState(deleteDemandAdCreativesState.success);
  React.useEffect(() => {
    if (
      !deleteDemandAdCreativesToasted &&
      deleteDemandAdCreativesState.success
    ) {
      setConfirmModalId(null);
      enqueueSystemNotification({
        message: "クリエイティブの利用を停止しました",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setdeleteDemandAdCreativesToasted(true);
      setSelectedCreatives([]);
    }
  }, [
    enqueueSystemNotification,
    deleteDemandAdCreativesToasted,
    deleteDemandAdCreativesState.success,
  ]);

  const handleMoveModalIdChange = (id: MoveModalId | null) => () => {
    setMoveModalId(id);
  };

  const handleDeleteModalIdChange = (id: ConfirmModalId | null) => () =>
    setConfirmModalId(id);

  const handleLinkSiteDemandAdCreatives = (site: DemandAdCreativeGroup) => {
    linkSiteDemandAdCreatives({
      toSite: site,
      demandAdCreatives: demandAdCreatives.filter((creative) =>
        selectedCreatives.includes(creative.id),
      ),
    });
    setLinkSiteDemandAdCreativesToasted(false);
  };

  const handleLinkCategoryCreatives = (category: Category) => {
    linkCategoryDemandAdCreatives({
      toCategory: category,
      demandAdCreatives: demandAdCreatives.filter((creative) =>
        selectedCreatives.includes(creative.id),
      ),
    });
    setLinkCategoryDemandAdCreativesToasted(false);
  };

  const handleLinkCategory2Creatives = (category: Category) => {
    linkCategory2DemandAdCreatives({
      toCategory: category,
      demandAdCreatives: demandAdCreatives.filter((creative) =>
        selectedCreatives.includes(creative.id),
      ),
    });
    setLinkCategory2DemandAdCreativesToasted(false);
  };

  const handleChannelTypeChange = (item: BaseItem<Domain.ChannelType>) => {
    patchChannelType({
      channelType: item.value,
      demandAdCreatives: demandAdCreatives.filter((creative) =>
        selectedCreatives.includes(creative.id),
      ),
    });
    setpatchChannelTypeToosted(false);
  };

  const handleDeviceTypeChange = (item: BaseItem<Domain.DeviceType>) => {
    patchDeviceType({
      deviceType: item.value,
      demandAdCreatives: demandAdCreatives.filter((creative) =>
        selectedCreatives.includes(creative.id),
      ),
    });
    setpatchDeviceTypeToosted(false);
  };

  const handleOsTypeChange = (item: BaseItem<Domain.OsType>) => {
    patchOsType({
      osType: item.value,
      demandAdCreatives: demandAdCreatives.filter((creative) =>
        selectedCreatives.includes(creative.id),
      ),
    });
    setpatchOsTypeToosted(false);
  };

  const handleUnlinkSiteDemandAdCreatives = () => {
    unlinkSiteDemandAdCreatives({
      demandAdCreatives: demandAdCreatives.filter((creative) =>
        selectedCreatives.includes(creative.id),
      ),
    });
    setUnLinkSiteDemandAdCreativesToasted(false);
  };

  const handleUnlinkCategoryDemandAdCreatives = () => {
    unlinkCategoryDemandAdCreatives({
      demandAdCreatives: demandAdCreatives.filter((creative) =>
        selectedCreatives.includes(creative.id),
      ),
    });
    setUnLinkCategoryDemandAdCreativesToasted(false);
  };

  const handleUnlinkCategory2DemandAdCreatives = () => {
    unlinkCategory2DemandAdCreatives({
      demandAdCreatives: demandAdCreatives.filter((creative) =>
        selectedCreatives.includes(creative.id),
      ),
    });
    setUnLinkCategory2DemandAdCreativesToasted(false);
  };

  const handleActivateDemandAdCreatives = () => {
    activateDemandAdCreatives({
      demand_account_creative_ids: selectedCreatives,
    });
    setActivateDemandAdCreativesToasted(false);
  };

  const handleDeleteDemandAdCreatives = () => {
    deleteDemandAdCreatives({
      demand_account_creative_ids: selectedCreatives,
    });
    setdeleteDemandAdCreativesToasted(false);
  };

  const handleSelect = (selectedRows: string[]) => {
    setSelectedCreatives(selectedRows);
  };

  const handleLinkClick = (href: string) => () => {
    push(href);
  };

  const handleRefineDimensionSelect = (option: any) => {
    setSelectedRefineDimension(option);
  };

  const handleRefineStateSelect = (option: any) => {
    setSelectedRefineState(option);
  };

  const refinedItems = React.useMemo(() => {
    const sourceData = getFormatedData(demandAdCreatives);
    if (
      selectedRefineDimension.value === "none" ||
      selectedRefineState.value === "none"
    ) {
      return sourceData;
    }
    switch (selectedRefineDimension.value) {
      case "site":
        return sourceData.filter((item) =>
          selectedRefineState.value === "mapped"
            ? item.mappedToSite
            : !item.mappedToSite,
        );
      case "device_type":
        return sourceData.filter((item) =>
          selectedRefineState.value === "mapped"
            ? item.deviceTypeName !== ""
            : item.deviceTypeName === "",
        );
      case "category":
        return sourceData.filter((item) =>
          selectedRefineState.value === "mapped"
            ? item.categoryName !== ""
            : item.categoryName === "",
        );
      case "category2":
        return sourceData.filter((item) =>
          selectedRefineState.value === "mapped"
            ? item.category2Name !== ""
            : item.category2Name === "",
        );
      case "os_type":
        return sourceData.filter((item) =>
          selectedRefineState.value === "mapped"
            ? item.osTypeName !== ""
            : item.osTypeName === "",
        );
    }
  }, [selectedRefineDimension, selectedRefineState, demandAdCreatives]);

  const handleRefineColumnChange = (id: string) => (option: any) => {
    const resultFilterItems = filterItems.map((resultFilterItem) => {
      if (resultFilterItem.id === id) {
        return {
          ...resultFilterItem,
          refineColumn: option,
        };
      }
      return resultFilterItem;
    });
    setFilterItems(resultFilterItems);
  };

  const searchedItems = React.useMemo(() => {
    let result = refinedItems;
    for (const filterItem of filterItems) {
      result = result.filter((item) =>
        item[filterItem.refineColumn.value]
          .toLowerCase()
          .includes(filterItem.value.toLowerCase()),
      );
    }
    return result;
  }, [refinedItems, filterItems]);

  const handleSearchTextChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const resultFilterItems = filterItems.map((resultFilterItem) => {
      if (resultFilterItem.id === id) {
        return {
          ...resultFilterItem,
          value: event.target.value,
        };
      }
      return resultFilterItem;
    });
    setFilterItems(resultFilterItems);
  };

  const handleFilterItemAdd = () => {
    setFilterItems([
      ...filterItems,
      {
        id: uuid(),
        refineColumn: getRefineColumnOptions(filterItems)[0],
        value: "",
      },
    ]);
  };

  const handleFilterItemDelete = (id: string) => () => {
    setFilterItems(filterItems.filter((filterItem) => filterItem.id !== id));
  };

  const handleReset = () => {
    setSelectedRefineDimension(refineDimensionOptions[0]);
    setSelectedRefineState(refineStateOptions[0]);
    setFilterItems([
      {
        id: uuid(),
        refineColumn: getDefaultRefineColumn(),
        value: "",
      },
    ]);
    // urlの?以降を消す
    history.pushState({}, "", location.pathname);
  };

  const dropDownContents = [
    {
      text: "サイトの紐付けを解除する",
      onClick: handleDeleteModalIdChange("unlink_site"),
    },
    {
      text: "チャネルを変更する",
      onClick: handleMoveModalIdChange("change_channel_type"),
    },
    {
      text: "デバイスを変更する",
      onClick: handleMoveModalIdChange("change_device_type"),
    },
    {
      text: "OSを変更する",
      onClick: handleMoveModalIdChange("change_os_type"),
    },
    {
      text: "カテゴリに紐付ける",
      onClick: handleMoveModalIdChange("link_category"),
    },
    {
      text: "カテゴリの紐付けを解除する",
      onClick: handleDeleteModalIdChange("unlink_category"),
    },
    {
      text: "カテゴリ2に紐付ける",
      onClick: handleMoveModalIdChange("link_category2"),
    },
    {
      text: "カテゴリ2の紐付けを解除する",
      onClick: handleDeleteModalIdChange("unlink_category2"),
    },
    {
      text: "利用中にする",
      onClick: handleDeleteModalIdChange("activate"),
    },
    {
      text: "利用停止にする",
      onClick: handleDeleteModalIdChange("delete"),
    },
  ];

  return (
    <>
      <Helmet title="デマンド広告クリエイティブ管理 | DATA STRAP" />
      <Portal>
        <FixedPanel isOpen={showPanel}>
          <Spacer py={2} px={3}>
            <Flex display="flex" justifyContent="flex-end" alignItems="center">
              <Typography weight="bold" size="sm" align="center">
                選択した
                <Typography
                  component="span"
                  weight="bold"
                  color="primary"
                  size="sm"
                >
                  {selectedCreatives.length}/{demandAdCreatives.length}個
                </Typography>
                のデマンド広告クリエイティブを
              </Typography>
              <Spacer pl={3} />
              <DropdownButton
                disabled={selectedCreatives.length < 1}
                split={true}
                contents={dropDownContents}
                onClick={handleMoveModalIdChange("link_site")}
              >
                サイトに紐付ける
              </DropdownButton>
            </Flex>
          </Spacer>
        </FixedPanel>
      </Portal>
      <PageContainer>
        <Flex display="flex" justifyContent="space-between" alignItems="center">
          <Breadcrumbs
            items={[
              {
                title: "デマンド広告クリエイティブ管理",
              },
            ]}
          />
          {/* FIXME(@yutaro1031): <Button />にもforwardRefを適用する */}
          <div ref={buttonContainerRef}>
            <Flex display="flex" alignItems="center">
              <Typography weight="bold" size="sm" align="center">
                選択した
                <Typography
                  component="span"
                  weight="bold"
                  color="primary"
                  size="sm"
                >
                  {selectedCreatives.length}/{demandAdCreatives.length}個
                </Typography>
                のデマンド広告クリエイティブを
              </Typography>
              <Spacer pl={3} />
              <DropdownButton
                disabled={selectedCreatives.length < 1}
                split={true}
                contents={dropDownContents}
                onClick={handleMoveModalIdChange("link_site")}
              >
                サイトに紐付ける
              </DropdownButton>
            </Flex>
          </div>
        </Flex>
        <Spacer pt={3} />
        <PageContent
          title="デマンド広告クリエイティブ一覧"
          popoverElement={
            <>
              <Typography size="sm" lineHeight="1.7">
                広告クリエイティブを任意のサイトに紐付けることで、管理がしやすくなります。
              </Typography>
              <br />
              <Typography size="sm" lineHeight="1.7">
                ＜サイトとは？＞
              </Typography>
              <Typography size="sm" lineHeight="1.7">
                デマンド広告クリエイティブをメディア単位（デバイス毎）でグルーピングして管理するための機能です。
              </Typography>
              <br />
              <Typography size="sm" lineHeight="1.7">
                ＜カテゴリとは？＞
              </Typography>
              <Typography size="sm" lineHeight="1.7">
                デマンド広告クリエイティブを任意の枠組みで括って管理したいケースに利用できる機能です。
              </Typography>
            </>
          }
        >
          <Spacer px={3} pt={3}>
            <Flex
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Flex
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <div>
                  <Flex display="flex" alignItems="center">
                    <Typography color="secondary" weight="bold" size="sm">
                      紐付け区分
                    </Typography>
                    <Spacer pr={0.5} />
                    <Popover>
                      <Typography size="sm" lineHeight="1.7">
                        紐付け区分とはクリエイティブとの紐付けが可能な区分「デバイス」「サイト」「カテゴリ」の3つを指します。
                      </Typography>
                    </Popover>
                  </Flex>
                  <Spacer pt={1} />
                  <Select
                    value={selectedRefineDimension}
                    minWidth="150px"
                    options={refineDimensionOptions}
                    isClearable={false}
                    onChange={handleRefineDimensionSelect}
                  />
                </div>
                <Spacer pr={1} />
                <div>
                  <Typography color="secondary" weight="bold" size="sm">
                    紐付け状態
                  </Typography>
                  <Spacer pt={1} />
                  <Select
                    isDisabled={selectedRefineDimension.value === "none"}
                    value={selectedRefineState}
                    minWidth="150px"
                    options={refineStateOptions}
                    isClearable={false}
                    onChange={handleRefineStateSelect}
                  />
                </div>
              </Flex>
              <ButtonGroup>
                <Button
                  onClick={handleLinkClick(
                    `/company/${currentCompany.company.id}/settings/demand_ad_creatives_manager/sites`,
                  )}
                >
                  サイト一覧
                </Button>
                <Button
                  onClick={handleLinkClick(
                    `/company/${currentCompany.company.id}/settings/demand_ad_creatives_manager/categories`,
                  )}
                >
                  カテゴリ一覧
                </Button>
              </ButtonGroup>
            </Flex>
            <Spacer pt={2} />

            <Typography size="sm" color="secondary" weight="bold">
              名前で絞り込み
            </Typography>
            {filterItems.map((filterItem, i) => (
              <Spacer key={filterItem.id} pt={1}>
                <Flex display="flex" alignItems="center">
                  <SelectableInput
                    inputProps={{
                      value: filterItem.value,
                      onChange: handleSearchTextChange(filterItem.id),
                    }}
                    selectProps={{
                      value: filterItem.refineColumn,
                      options: getRefineColumnOptions(filterItems),
                      onChange: handleRefineColumnChange(filterItem.id),
                    }}
                  />
                  {i !== 0 && (
                    <Spacer pl={1}>
                      <ActionButton
                        icon="delete_bin"
                        onClick={handleFilterItemDelete(filterItem.id)}
                      >
                        削除
                      </ActionButton>
                    </Spacer>
                  )}
                </Flex>
              </Spacer>
            ))}
            {getRefineColumnOptions(filterItems).length > 0 && (
              <Spacer pt={1}>
                <ActionButton icon="add_line" onClick={handleFilterItemAdd}>
                  フィルタアイテムを追加
                </ActionButton>
              </Spacer>
            )}
            <Spacer py={2}>
              <Button
                inline={true}
                color="secondary"
                size="small"
                onClick={handleReset}
              >
                全てリセット
              </Button>
            </Spacer>
            <Styled.TableContainer>
              <DataTable
                dataKey={"id"}
                itemEmptyProps={{
                  title: "該当するクリエイティブがありません。",
                }}
                enablePagination={true}
                horizontalScrollable={true}
                data={searchedItems}
                tabs={[
                  {
                    label: "利用中",
                    filter: (data) => data.filter((item) => item.isActive),
                  },
                  {
                    label: "利用停止中",
                    filter: (data) => data.filter((item) => !item.isActive),
                  },
                ]}
                columns={columns}
                selectedRows={selectedCreatives}
                onSelectRowsChange={handleSelect}
              />
            </Styled.TableContainer>
          </Spacer>
        </PageContent>

        {moveModalId === "link_site" && (
          <SiteMoveModal
            createLoading={postDemandAdCreativeGroupState.requesting}
            createSucceeded={postDemandAdCreativeGroupState.success}
            loading={linkSiteDemandAdCreativesState.requesting}
            demandAdCreativeGroups={demandAdCreativeGroups}
            selectedDemandAdCreatives={demandAdCreatives.filter((creative) =>
              selectedCreatives.includes(creative.id),
            )}
            onClose={handleMoveModalIdChange(null)}
            onCreateGroupSubmit={postDemandAdCreativeGroup} // eslint-disable-line react/jsx-handler-names
            onSubmit={handleLinkSiteDemandAdCreatives}
          />
        )}
        <ConfirmModal
          isOpen={confirmModalId === "unlink_site"}
          type="unlink_site"
          title="下記の広告クリエイティブのサイト紐付けを解除しますか？"
          confirmText="解除する"
          cancelText="キャンセル"
          buttonColor="danger"
          loading={unlinkSiteDemandAdCreativesState.requesting}
          selectedDemandAdCreatives={demandAdCreatives.filter((group) =>
            selectedCreatives.includes(group.id),
          )}
          onClose={handleDeleteModalIdChange(null)}
          onSubmit={handleUnlinkSiteDemandAdCreatives}
        />
        {moveModalId === "change_channel_type" && (
          <MoveModal
            loading={patchChannelTypeState.requesting}
            selectedDemandAdCreatives={demandAdCreatives.filter((creative) =>
              selectedCreatives.includes(creative.id),
            )}
            baseItems={channelTypes}
            type="change_channel_type"
            onClose={handleMoveModalIdChange(null)}
            onSubmit={handleChannelTypeChange}
          />
        )}
        {moveModalId === "change_device_type" && (
          <MoveModal
            loading={patchDeviceTypeState.requesting}
            selectedDemandAdCreatives={demandAdCreatives.filter((creative) =>
              selectedCreatives.includes(creative.id),
            )}
            baseItems={deviceTypes}
            type="change_device_type"
            onClose={handleMoveModalIdChange(null)}
            onSubmit={handleDeviceTypeChange}
          />
        )}
        {moveModalId === "change_os_type" && (
          <MoveModal
            loading={patchOsTypeState.requesting}
            selectedDemandAdCreatives={demandAdCreatives.filter((creative) =>
              selectedCreatives.includes(creative.id),
            )}
            baseItems={osTypes}
            type="change_os_type"
            onClose={handleMoveModalIdChange(null)}
            onSubmit={handleOsTypeChange}
          />
        )}
        {(moveModalId === "link_category" ||
          moveModalId === "link_category2") && (
          <CategoryMoveModal
            moveModalId={moveModalId}
            createLoading={postCategoryState.requesting}
            createSucceeded={postCategoryState.success}
            loading={
              moveModalId === "link_category"
                ? linkCategoryDemandAdCreativesState.requesting
                : linkCategory2DemandAdCreativesState.requesting
            }
            categories={categories}
            selectedDemandAdCreatives={demandAdCreatives.filter((creative) =>
              selectedCreatives.includes(creative.id),
            )}
            onClose={handleMoveModalIdChange(null)}
            onCreateCategorySubmit={postCategory} // eslint-disable-line react/jsx-handler-names
            // eslint-disable-next-line react/jsx-handler-names
            onSubmit={
              moveModalId === "link_category"
                ? handleLinkCategoryCreatives
                : handleLinkCategory2Creatives
            }
          />
        )}
        {confirmModalId != null && (
          <ConfirmModal
            isOpen={
              confirmModalId === "unlink_category" ||
              confirmModalId === "unlink_category2"
            }
            type={
              confirmModalId === "unlink_category"
                ? "unlink_category"
                : "unlink_category2"
            }
            title="下記の広告クリエイティブのカテゴリ紐付けを解除しますか？"
            confirmText="解除する"
            cancelText="キャンセル"
            buttonColor="danger"
            loading={
              confirmModalId === "unlink_category"
                ? unlinkCategoryDemandAdCreativesState.requesting
                : unlinkCategory2DemandAdCreativesState.requesting
            }
            selectedDemandAdCreatives={demandAdCreatives.filter((group) =>
              selectedCreatives.includes(group.id),
            )}
            onClose={handleDeleteModalIdChange(null)} // eslint-disable-next-line react/jsx-handler-names
            onSubmit={
              confirmModalId === "unlink_category"
                ? handleUnlinkCategoryDemandAdCreatives
                : handleUnlinkCategory2DemandAdCreatives
            }
          />
        )}
        <ConfirmModal
          isOpen={confirmModalId === "activate"}
          type="activate"
          title="下記の広告クリエイティブを利用中にしますか？"
          confirmText="利用中にする"
          cancelText="キャンセル"
          loading={activateDemandAdCreativesState.requesting}
          selectedDemandAdCreatives={demandAdCreatives.filter((group) =>
            selectedCreatives.includes(group.id),
          )}
          onClose={handleDeleteModalIdChange(null)}
          onSubmit={handleActivateDemandAdCreatives}
        />
        <ConfirmModal
          isOpen={confirmModalId === "delete"}
          type="delete"
          title="下記の広告クリエイティブの利用を停止しますか？"
          confirmText="停止する"
          cancelText="キャンセル"
          buttonColor="danger"
          loading={deleteDemandAdCreativesState.requesting}
          selectedDemandAdCreatives={demandAdCreatives.filter((group) =>
            selectedCreatives.includes(group.id),
          )}
          onClose={handleDeleteModalIdChange(null)}
          onSubmit={handleDeleteDemandAdCreatives}
        />
      </PageContainer>
    </>
  );
};

export { DemandAdCreativesManager };
