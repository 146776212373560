import { Domain, Request } from "api-types";
import {
  ActionButton,
  Button,
  DataTable,
  FixedPanel,
  Flex,
  ItemEmpty,
  Portal,
  Spacer,
  ToggleButton,
  Typography,
} from "ingred-ui";
import React from "react";

import { Helmet } from "../../../../containers/Helmet";
import { DemandAccountWithScraperStatus } from "../../../../domain/demandAccount";
import { useIntersectionObserver } from "../../../../hooks/useIntersectionObserver";
import { deleteDemandAccount } from "../../../../store/modules/demandAccount/actions/deleteDemandAccountAction";
import { fetchDemandAccount } from "../../../../store/modules/demandAccount/actions/fetchDemandAccountAction";
import { patchDemandAccount } from "../../../../store/modules/demandAccount/actions/patchDemandAccountAction";
import { postDemandAccount } from "../../../../store/modules/demandAccount/actions/postDemandAccountAction";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../../store/utils/dispatchable";
import { Breadcrumbs } from "../../../elements/Breadcrumbs";
import { PageContainer } from "../../../elements/PageContainer";
import { PageContent } from "../../../elements/PageContent";

import { CreateModal } from "./internal/CreateModal";
import { DeleteModal } from "./internal/DeleteModal";
import { EditModal } from "./internal/EditModal";
import { ScraperEnabledModal } from "./internal/ScraperEnabledModal";

type InjectProps = {
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  fetchDemandAccount: DispatchableAction<typeof fetchDemandAccount>;
  createDemandAccount: DispatchableAction<typeof postDemandAccount>;
  editDemandAccount: DispatchableAction<typeof patchDemandAccount>;
  deleteDemandAccount: DispatchableAction<typeof deleteDemandAccount>;
  fetchDemandAccountRequesting: boolean;
  createDemandAccountRequesting: boolean;
  createSucceeded: boolean;
  editDemandAccountRequesting: boolean;
  editSucceeded: boolean;
  deleteDemandAccountRequesting: boolean;
  deleteSucceeded: boolean;
};
type Props = {
  demandAccountId: number;
  demandAccounts: DemandAccountWithScraperStatus[];
  demandForms: Domain.DemandForm[];
};

type InjectedProps = Props & InjectProps;

const Top: React.FunctionComponent<InjectedProps> = ({
  enqueueSystemNotification,
  demandAccountId,
  demandAccounts,
  demandForms,
  fetchDemandAccount,
  fetchDemandAccountRequesting,
  createDemandAccount,
  createDemandAccountRequesting,
  createSucceeded,
  editDemandAccount,
  editDemandAccountRequesting,
  editSucceeded,
  deleteDemandAccount,
  deleteDemandAccountRequesting,
  deleteSucceeded,
}) => {
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const showPanel = !useIntersectionObserver(buttonContainerRef);

  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [
    editModalRow,
    setEditModalRow,
  ] = React.useState<DemandAccountWithScraperStatus | null>(null);

  const [
    scraperEnabledModalRow,
    setScraperEnabledModalRow,
  ] = React.useState<DemandAccountWithScraperStatus | null>(null);
  const [
    deleteModalRow,
    setDeleteModalRow,
  ] = React.useState<DemandAccountWithScraperStatus | null>(null);
  // NOTE(kinokoruumu): storeを初期化せずにunmount -> didmountするとトーストが出てしまうため、
  // storeのstatusを初期値に入れている
  const [createToasted, setCreateToasted] = React.useState(createSucceeded);
  const [editToasted, setEditToasted] = React.useState(editSucceeded);
  const [deleteToasted, setDeleteToasted] = React.useState(deleteSucceeded);

  React.useEffect(() => {
    if (!createToasted && createSucceeded) {
      setCreateModalOpen(false);
      enqueueSystemNotification({
        message: "新規登録が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setCreateToasted(true);
    }
  }, [enqueueSystemNotification, createToasted, createSucceeded]);

  React.useEffect(() => {
    if (!editToasted && editSucceeded) {
      setEditModalRow(null);
      setScraperEnabledModalRow(null);
      enqueueSystemNotification({
        message: "編集が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setEditToasted(true);
    }
  }, [enqueueSystemNotification, editToasted, editSucceeded]);

  React.useEffect(() => {
    if (!deleteToasted && deleteSucceeded) {
      setDeleteModalRow(null);
      enqueueSystemNotification({
        message: "削除が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setDeleteToasted(true);
    }
  }, [enqueueSystemNotification, deleteToasted, deleteSucceeded]);

  React.useEffect(() => {
    // settings?demand_account_id=で指定されたdemandAccountが存在する場合は、編集モダールを開く
    const row = demandAccounts.find(
      (account) => account.id === demandAccountId,
    );
    if (!row) {
      return;
    }
    setEditModalRow(row);
    // demandAccountsを依存配列に入れると、demandAccountが変更された時に再度編集モダールが開いてしまう
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandAccountId]);

  const checkNoScraperDemandAccountById = (demandId: number) => {
    const demandAccountSetting = demandForms.find(
      (setting) => setting.demand.id === demandId,
    );
    if (demandAccountSetting) return !demandAccountSetting.scraper_exist;
    return false;
  };

  const handleChangeCreateModalOpen = (isOpen: boolean) => () =>
    setCreateModalOpen(isOpen);
  const handleChangeEditModalRow = (
    row: DemandAccountWithScraperStatus | null,
  ) => () => setEditModalRow(row);
  const handleChangeDeleteModalRow = (
    row: DemandAccountWithScraperStatus | null,
  ) => () => setDeleteModalRow(row);
  const handleChangeScraperModalRow = (
    row: DemandAccountWithScraperStatus | null,
  ) => () => setScraperEnabledModalRow(row);

  const handleCreate = (data: Request.DemandAccount.Create) => {
    createDemandAccount(data);
    setCreateToasted(false);
  };
  const handleEdit = (data: Request.DemandAccount.Patch) => {
    editDemandAccount(data);
    setEditToasted(false);
  };
  const handleDelete = (demandAccountId: number) => {
    deleteDemandAccount({ demand_account_id: demandAccountId });
    setDeleteToasted(false);
  };
  const handleScraperEnabled = (data: Request.DemandAccount.Patch) => {
    editDemandAccount(data);
    setEditToasted(false);
  };

  return (
    <>
      <Helmet title="デマンド設定 | DATA STRAP" />
      <Portal>
        <FixedPanel isOpen={showPanel}>
          <Spacer py={2} px={3}>
            <Flex display="flex" justifyContent="flex-end">
              <Button
                size="large"
                inline={true}
                onClick={handleChangeCreateModalOpen(true)}
              >
                新規登録
              </Button>
            </Flex>
          </Spacer>
        </FixedPanel>
      </Portal>
      <PageContainer>
        <Flex display="flex" justifyContent="space-between" alignItems="center">
          <Breadcrumbs
            items={[
              {
                title: "デマンド認証",
              },
            ]}
          />
          {/* FIXME(@yutaro1031): <Button />にもforwardRefを適用する */}
          <div ref={buttonContainerRef}>
            <Button inline={true} onClick={handleChangeCreateModalOpen(true)}>
              新規登録
            </Button>
          </div>
        </Flex>
        <Spacer pt={3} />
        <PageContent
          title="デマンド認証"
          popoverElement={
            <Typography size="sm" lineHeight="1.7">
              データ取得したいデマンドの設定を管理します。
            </Typography>
          }
        >
          <Spacer pt={2} px={2}>
            {demandAccounts.length > 0 ? (
              <DataTable
                dataKey={"id"}
                enablePagination={true}
                data={demandAccounts}
                columns={[
                  {
                    name: "デマンドアカウントID",
                    selector: (row) => row.id,
                    sortable: true,
                  },
                  {
                    name: "デマンド",
                    selector: (row) => row.demand.name,
                    sortable: true,
                  },
                  {
                    name: "レポート自動取得",
                    selector: (row) => `${row.scraper_enabled}`,
                    sortable: true,
                    renderCell: (row) => (
                      <Flex display="flex" alignItems="center">
                        <ToggleButton
                          checked={row.scraper_enabled}
                          disabled={checkNoScraperDemandAccountById(
                            row.demand.id,
                          )}
                          onChange={handleChangeScraperModalRow(row)}
                        />
                      </Flex>
                    ),
                  },
                  {
                    name: "操作",
                    selector: (row) => row.demand.name,
                    width: "180px",
                    renderCell: (row) => (
                      <Flex display="flex" alignItems="center">
                        <ActionButton
                          icon="pencil"
                          onClick={handleChangeEditModalRow(row)}
                        >
                          編集
                        </ActionButton>
                        <Spacer pr={0.5} />
                        <ActionButton
                          icon="delete_bin"
                          onClick={handleChangeDeleteModalRow(row)}
                        >
                          削除
                        </ActionButton>
                      </Flex>
                    ),
                  },
                ]}
              />
            ) : (
              <ItemEmpty
                title="デマンドが登録されていません"
                subtitle="画面右の「新規登録」ボタンをクリックしてデマンド情報を登録してください。"
              />
            )}
          </Spacer>
        </PageContent>
        <CreateModal
          isOpen={createModalOpen}
          demandForms={demandForms}
          loading={createDemandAccountRequesting}
          onClose={handleChangeCreateModalOpen(false)}
          onSubmit={handleCreate}
        />
        <ScraperEnabledModal
          isOpen={!!scraperEnabledModalRow}
          fetchDemandAccount={fetchDemandAccount}
          demandAccountId={scraperEnabledModalRow?.id}
          fetchRequesting={fetchDemandAccountRequesting}
          scraperEnabledRequesting={editDemandAccountRequesting}
          onClose={handleChangeScraperModalRow(null)}
          onSubmit={handleScraperEnabled}
        />
        <EditModal
          isOpen={!!editModalRow}
          fetchDemandAccount={fetchDemandAccount}
          demandAccountId={editModalRow?.id}
          fetchRequesting={fetchDemandAccountRequesting}
          editRequesting={editDemandAccountRequesting}
          onClose={handleChangeEditModalRow(null)}
          onSubmit={handleEdit}
        />
        <DeleteModal
          isOpen={!!deleteModalRow}
          demandAccount={deleteModalRow}
          loading={deleteDemandAccountRequesting}
          onClose={handleChangeDeleteModalRow(null)}
          onSubmit={handleDelete}
        />
      </PageContainer>
    </>
  );
};
export { Top };
