import { Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DownloadCsv
 */
export type DownloadMarketTrendCsvParams = void;
export type DownloadMarketTrendCsvSuccess = void;
export type DownloadMarketTrendCsvFailure = Error;

export const downloadMarketTrendCsvAction = actionCreator.async<
  DownloadMarketTrendCsvParams,
  DownloadMarketTrendCsvSuccess,
  DownloadMarketTrendCsvFailure
>("DOWNLOAD_MARKET_TREND_CSV");

export function downloadMarketTrendCsv(
  payload: Request.MarketTrend.FetchSalesCompositionRatio & {
    trend_type_name: "mine" | "market";
  },
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, marketTrend }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(downloadMarketTrendCsvAction.started());

    try {
      await marketTrend.downloadMarketTrendCSV(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(downloadMarketTrendCsvAction.done({}));
    } catch (err) {
      dispatch(downloadMarketTrendCsvAction.failed(err));
    }
  };
}
