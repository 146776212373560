import { connect } from "react-redux";

import { push } from "../../../store/modules/routing/actions/PushAction";
import { dispatchable } from "../../../store/utils/dispatchable";

import { Link as Container } from "./Link";

export const Link = connect(null, (dispatch) => ({
  push: dispatchable(dispatch, push),
}))(Container);
