import { Domain } from "api-types";

import { RootState } from "../../../reducer";

export const getDemandAccountForms = (
  state: RootState,
): Domain.DemandForm[] => {
  const { allIds, byId } = state.entities.demandAccountForm;
  return allIds.map((id) => byId[id]);
};
