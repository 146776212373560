import styled from "styled-components";

import { Color, Size } from "../../../styles/variables";

export const Container = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing * 3}px;
  border-bottom: ${Size.Border.Small} solid ${Color.gray.light};
`;

export const Content = styled.div<{ paddingDisabled: boolean }>`
  padding: ${({ paddingDisabled, theme }) =>
    paddingDisabled ? "auto" : `${theme.spacing * 3}px`};
`;
