import { GamAdCreative } from "../../../../domain/gamAdCreative";
import { GamLineItem } from "../../../../domain/gamLineItem";
import { RootState } from "../../../reducer";
import { getGamAdCreativeById } from "../../gamAdCreative/selectors/gamAdCreativeSelector";

export const getGamLineItems = (state: RootState): GamLineItem[] => {
  const { allIds, byId } = state.entities.gamLineItem;
  return allIds.map((id) => ({
    ...byId[id],
    gam_ad_creatives: byId[id].gam_ad_creatives.map(
      (creativeId) => getGamAdCreativeById(state, creativeId) as GamAdCreative,
    ),
  }));
};

export const getGamLineItemById = (
  state: RootState,
  id: number,
): GamLineItem | null => {
  const byId = state.entities.gamLineItem.byId;
  if (byId[id]) {
    return {
      ...byId[id],
      gam_ad_creatives: byId[id].gam_ad_creatives.map(
        (creativeId) =>
          getGamAdCreativeById(state, creativeId) as GamAdCreative,
      ),
    };
  }
  return null;
};

export const getGamLineItemsByGamAdUnitId = (
  state: RootState,
  id: number,
): GamLineItem[] => {
  const { gamAdUnit } = state.entities;
  if (gamAdUnit.byId[id]) {
    return gamAdUnit.byId[id].gam_line_items.map(
      (lineItemId) => getGamLineItemById(state, lineItemId) as GamLineItem,
    );
  }
  return [];
};
