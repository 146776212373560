import styled from "styled-components";

import { Radius } from "../../../../../styles/variables/radius";
import { Size } from "../../../../../styles/variables/size";

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing * 3}px 0;
  border-bottom: ${Size.Border.Small} solid
    ${({ theme }) => theme.palette.divider};
`;

export const IconContainer = styled.div<{ isClickable: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "auto")};
  padding: ${({ theme }) => theme.spacing * 0.5}px;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const Content = styled.div`
  min-width: 480px;
  max-width: 60%;
  cursor: pointer;
`;

export const TextContainer = styled.div<{ height: number }>`
  overflow: hidden;
  transition: height 0.2s;
  height: ${({ height }) => height}px;
`;

export const Text = styled.div`
  word-break: normal;
`;

export const SeeNextButton = styled.button`
  margin: 0;
  text-decoration: none;
  letter-spacing: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  outline: none;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing * 0.5}px
    ${({ theme }) => theme.spacing}px;
  border: 0;
  cursor: pointer;
  border-radius: ${Radius.SMALL};
  background-color: ${({ theme }) => theme.palette.background.hint};
  white-space: nowrap;
  transition: all 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.palette.background.active};
  }
  &:disabled {
    box-shadow: none;
    text-shadow: none;
    cursor: not-allowed;
  }
`;

export const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? "180deg" : "0")});
`;
