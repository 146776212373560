import { Request, Response } from "api-types";
import dayjs from "dayjs";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchMarketTrendEcpmParams = Request.MarketTrend.FetchEcpm;
export type FetchMarketTrendEcpmResponse = Response.MarketTrend.FetchEcpm;

export async function fetchMarketTrendEcpm(
  apiClient: APIClient,
  params: FetchMarketTrendEcpmParams,
  companyId: number,
): Promise<FetchMarketTrendEcpmResponse> {
  const { data } = await apiClient.post<FetchMarketTrendEcpmResponse>(
    "/v1/market_trend/ecpm",
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );
  return data;
}

export type FetchMarketTrendSalesCompositionRatioParams = Request.MarketTrend.FetchSalesCompositionRatio;
export type FetchMarketTrendSalesCompositionRatioResponse = Response.MarketTrend.FetchSalesCompositionRatio;

export async function fetchMarketTrendSalesCompositionRatio(
  apiClient: APIClient,
  params: FetchMarketTrendSalesCompositionRatioParams,
  companyId: number,
): Promise<FetchMarketTrendSalesCompositionRatioResponse> {
  const { data } = await apiClient.post<
    FetchMarketTrendSalesCompositionRatioResponse
  >("/v1/market_trend/sales_composition_ratio", params, {
    headers: {
      "X-Company-Id": companyId,
    },
  });
  return data;
}

export type DownloadCsvParams = Request.MarketTrend.FetchSalesCompositionRatio & {
  trend_type_name: "mine" | "market";
};
export type DownloadCsvResponse = string;

export async function downloadMarketTrendCSV(
  apiClient: APIClient,
  params: DownloadCsvParams,
  companyId: number,
): Promise<void> {
  const { data } = await apiClient.post<DownloadCsvResponse>(
    "/v1/market_trend/download",
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  // TODO: SVをクライアント側で生成しなくていいようにする
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const blob = new Blob([bom, data], { type: "text/csv" });

  // headerから["content-disposition"]を取り出せなかったので同じ基準でファイル名を生成する
  const fileName = `${dayjs().format("YYYYMMDDHHmmss")}.csv`;

  const element = document.createElement("a");
  element.href = window.URL.createObjectURL(blob);
  element.setAttribute("download", fileName);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
