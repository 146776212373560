import {
  DemandAccountCreativeDetail,
  DemandAccountCreativeIds,
} from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * ActivateDemandAdCreatives
 */
export type ActivateDemandAdCreativesParams = void;
export type ActivateDemandAdCreativesSuccess = DemandAccountCreativeDetail[];
export type ActivateDemandAdCreativesFailure = Error;

export const activateDemandAdCreativesAction = actionCreator.async<
  ActivateDemandAdCreativesParams,
  ActivateDemandAdCreativesSuccess,
  ActivateDemandAdCreativesFailure
>("ACTIVATE_DEMAND_AD_CREATIVES");

export function activateDemandAdCreatives(
  payload: DemandAccountCreativeIds,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(activateDemandAdCreativesAction.started());

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsDemandAccountCreativeActivate(
        currentCompany.company.id,
        {
          demand_account_creative_ids: payload.demand_account_creative_ids,
        },
      );
      dispatch(activateDemandAdCreativesAction.done({ result: data }));
    } catch (err) {
      dispatch(activateDemandAdCreativesAction.failed(err));
    }
  };
}
