import { Response } from "api-types";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchAllHbDemandTypeParams = void;
export type FetchAllHbDemandTypeResponse = Response.HbDemandType.FetchAll;

export async function fetchAllHbDemandType(
  apiClient: APIClient,
  companyId: number,
): Promise<FetchAllHbDemandTypeResponse> {
  const { data } = await apiClient.get<FetchAllHbDemandTypeResponse>(
    "/v1/hb_demand_types",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}
