import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import {
  RouteActionContext,
  RouteActionPayload,
} from "../../../../routing/types";
import { fetchGamAdUnits as fetchGamAdUnitsAction } from "../../../../store/modules/gamAdUnit/actions/fetchGamAdUnitsAction";
import { getGamAdUnits } from "../../../../store/modules/gamAdUnit/selectors/gamAdUnitSelector";
import { RootState } from "../../../../store/reducer";

import { GamAdCreativesManager as Component } from "./GamAdCreativesManager";

const GamAdCreativesManager = connect((state: RootState) => ({
  // /company/:id 以下なので選択済み
  currentCompany: state.user.currentCompany as CompanyAttribute,
  gamAdUnits: getGamAdUnits(state),
}))(Component);

export async function action({
  store,
  firstOrPush,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await store.dispatch(fetchGamAdUnitsAction());
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <GamAdCreativesManager />,
  };
}
