import { Select, TextField, SelectProps, InputProps } from "ingred-ui";
import * as React from "react";

import * as Styled from "./styled";

type Props = {
  selectProps: SelectProps<string | number, false>;
  inputProps?: InputProps;
};

const SelectableInput: React.FunctionComponent<Props> = ({
  selectProps,
  inputProps,
}) => (
  <Styled.Container>
    <Select
      minWidth="160px"
      isClearable={false}
      isSearchable={false}
      {...selectProps}
    />
    <TextField icon="search" placeholder="検索ワードを入力" {...inputProps} />
  </Styled.Container>
);

export { SelectableInput };
