import { reducerWithInitialState } from "typescript-fsa-reducers";

import { updateNetworkStatus } from "../actions/updateNetworkStatusAction";
import { NetworkStatus } from "../constants";

export type NetworkState = {
  status: NetworkStatus;
};

export const initialState = {
  status: NetworkStatus.ONLINE,
};

export const networkReducer = reducerWithInitialState<NetworkState>(
  initialState,
).case(updateNetworkStatus, (state, payload) => ({
  ...state,
  status: payload,
}));
