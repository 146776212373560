import { reducerWithInitialState } from "typescript-fsa-reducers";

import { deleteDashboardItemAction } from "../actions/deleteDashboardItemAction";
import { fetchDashboardItemsAction } from "../actions/fetchDashboardItemsAction";
import { patchDashboardItemAction } from "../actions/patchDashboardItemAction";
import { postDashboardItemAction } from "../actions/postDashboardItemAction";

export type DashboardItemState = {
  fetchAll: {
    requesting: boolean;
  };
  post: {
    requesting: boolean;
    success: boolean;
  };
  patch: {
    requesting: boolean;
    success: boolean;
  };
  delete: {
    requesting: boolean;
    success: boolean;
  };
};

export const initialState: DashboardItemState = {
  fetchAll: {
    requesting: false,
  },
  post: {
    requesting: false,
    success: false,
  },
  patch: {
    requesting: false,
    success: false,
  },
  delete: {
    requesting: false,
    success: false,
  },
};

export const dashboardItemReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All DashboardItem
   */
  .case(fetchDashboardItemsAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchDashboardItemsAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchDashboardItemsAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  /**
   * Post DashboardItem
   */
  .case(postDashboardItemAction.started, (state) => ({
    ...state,
    post: {
      requesting: true,
      success: false,
    },
  }))
  .case(postDashboardItemAction.done, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: true,
    },
  }))
  .case(postDashboardItemAction.failed, (state) => ({
    ...state,
    post: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Patch DashboardItem
   */
  .case(patchDashboardItemAction.started, (state) => ({
    ...state,
    patch: {
      requesting: true,
      success: false,
    },
  }))
  .case(patchDashboardItemAction.done, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: true,
    },
  }))
  .case(patchDashboardItemAction.failed, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Delete DashboardItem
   */
  .case(deleteDashboardItemAction.started, (state) => ({
    ...state,
    delete: {
      requesting: true,
      success: false,
    },
  }))
  .case(deleteDashboardItemAction.done, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: true,
    },
  }))
  .case(deleteDashboardItemAction.failed, (state) => ({
    ...state,
    delete: {
      requesting: false,
      success: false,
    },
  }));
