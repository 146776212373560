import { SavedQuery } from "../../../../domain/savedQuery";
import { RootState } from "../../../reducer";

export const getSavedQueries = (state: RootState): SavedQuery[] => {
  const allIds = state.entities.savedQuery.allIds;
  const byId = state.entities.savedQuery.byId;
  return allIds.map((id) => byId[id]);
};

export const getSavedQueryById = (
  state: RootState,
  id: number,
): SavedQuery | null => {
  const byId = state.entities.savedQuery.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
