import { Typography, Spacer, ConfirmModal } from "ingred-ui";
import * as React from "react";

import { SavedQuery } from "../../../../../domain/savedQuery";

type Props = {
  isOpen: boolean;
  savedQuery: SavedQuery | null;
  onClose?: () => void;
  onSubmit?: (savedQueryId: number) => void;
  loading?: boolean;
};

const DeleteModal: React.FunctionComponent<Props> = ({
  isOpen,
  savedQuery,
  onClose,
  onSubmit,
  loading,
}) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (onSubmit && savedQuery?.id) {
      onSubmit(savedQuery.id);
    }
  };
  return (
    <ConfirmModal
      isOpen={isOpen}
      title="保存クエリ削除"
      confirmText="削除する"
      cancelText="キャンセル"
      buttonColor="danger"
      loading={loading}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit}
    >
      <Spacer pt={2}>
        <Typography lineHeight="1.7">
          {savedQuery?.name}を削除してもよろしいですか？
        </Typography>
      </Spacer>
    </ConfirmModal>
  );
};

export { DeleteModal };
