import { Domain } from "api-types";
import { ActionButton, Spacer, Table } from "ingred-ui";
import * as React from "react";
import { v4 as uuid } from "uuid";

import { Category } from "../../../../../domain/category";
import { Demand } from "../../../../../domain/demand";
import { DemandAdCreativeGroup } from "../../../../../domain/demandAdCreativeGroup";
import { GamAdUnit } from "../../../../../domain/gamAdUnit";
import { HbDemandType } from "../../../../../domain/hbDemandType";
import { PageSection } from "../../../../elements/PageSection";

import { FilterItem, filterOptions } from "./internal/FilterItem";

export type FilterType =
  | "site"
  | "category"
  | "category2"
  | "demand"
  | "gam_ad_unit"
  | "demand_ad_creative"
  | "device_type"
  | "channel_type"
  | "hb_demand_type"
  | "os_type";

export const FILTER_KEY_MAP: {
  [key in keyof Domain.ReportFilters]: FilterType;
} = {
  site_ids: "site",
  gam_ad_unit_ids: "gam_ad_unit",
  demand_account_creative_ids: "demand_ad_creative",
  demand_ids: "demand",
  category_ids: "category",
  category2_ids: "category2",
  device_type_names: "device_type",
  channel_type_names: "channel_type",
  hb_demand_type_names: "hb_demand_type",
  os_type_names: "os_type",
} as const;

export type FilterItemStruct = {
  id: string;
  key: FilterType | null;
  values: string[];
};

export type Props = {
  selectedDimensions: Domain.ReportDimensionType[];
  selectedFilters: Domain.ReportFilters;
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  demands: Demand[];
  gamAdUnits: GamAdUnit[];
  categories: Category[];
  categories2: Category[];
  hbDemandTypes: HbDemandType[];
  onChange: (filters: Domain.ReportFilters) => void;
};

const RefineSection: React.FunctionComponent<Props> = ({
  selectedFilters,
  demandAdCreativeGroups,
  demands,
  gamAdUnits,
  categories,
  categories2,
  hbDemandTypes,
  onChange,
}) => {
  const [filterItems, setFilterItems] = React.useState<FilterItemStruct[]>([
    { id: uuid(), key: null, values: [] },
  ]);
  const handleAdd = () => {
    setFilterItems([...filterItems, { id: uuid(), key: null, values: [] }]);
  };
  const handleEdit = (
    id: string,
    value: Partial<Omit<FilterItemStruct, "id">>,
  ) => {
    let resultFilterItems = [...filterItems];
    const filterItem = filterItems.find((filterItem) => filterItem.id === id);
    if (filterItem) {
      const editedItem = {
        ...filterItem,
        ...value,
      };
      resultFilterItems = resultFilterItems.map((resultFilterItem) => {
        if (resultFilterItem.id === editedItem.id) {
          return editedItem;
        }
        return resultFilterItem;
      });
    }
    setFilterItems(resultFilterItems);
  };
  const handleDelete = (id: string) => {
    setFilterItems(filterItems.filter((filterItem) => filterItem.id !== id));
  };

  React.useEffect(() => {
    if (!Object.keys(selectedFilters).length) return;
    const initFilterItems: FilterItemStruct[] = [];

    Object.keys(selectedFilters).forEach((itemKey) => {
      if (FILTER_KEY_MAP[itemKey as keyof Domain.ReportFilters]) {
        initFilterItems.push({
          id: uuid(),
          key: FILTER_KEY_MAP[itemKey as keyof Domain.ReportFilters],
          values: selectedFilters[itemKey as keyof Domain.ReportFilters],
        } as FilterItemStruct);
      }
    });
    setFilterItems(initFilterItems);
  }, [selectedFilters]);

  React.useEffect(() => {
    const fi = (label: FilterType): string[] | undefined => {
      return filterItems.find((f) => f.key == label)?.values;
    };
    const filters: Domain.ReportFilters = {
      site_ids: fi("site")?.map((v) => parseInt(v)),
      demand_ids: fi("demand")?.map((v) => parseInt(v)),
      gam_ad_unit_ids: fi("gam_ad_unit")?.map((v) => parseInt(v)),
      demand_account_creative_ids: fi("demand_ad_creative"),
      category_ids: fi("category")?.map((v) => parseInt(v)),
      category2_ids: fi("category2")?.map((v) => parseInt(v)),
      channel_type_names: fi("channel_type"),
      hb_demand_type_names: fi("hb_demand_type"),
      device_type_names: fi("device_type"),
      os_type_names: fi("os_type"),
    };
    onChange(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  return (
    <PageSection title="絞り込み">
      <Table>
        <Table.Body>
          {filterItems.map((filterItem) => (
            <FilterItem
              key={filterItem.id}
              id={filterItem.id}
              filterItem={filterItem}
              selectedKeys={
                filterItems
                  .filter((filterItem) => filterItem.key != null)
                  .map((filterItem) => filterItem.key) as string[]
              }
              demandAdCreativeGroups={demandAdCreativeGroups}
              demands={demands}
              gamAdUnits={gamAdUnits}
              categories={categories}
              categories2={categories2}
              hbDemandTypes={hbDemandTypes}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}
        </Table.Body>
      </Table>
      {filterItems.length < filterOptions.length && (
        <Spacer pt={1}>
          <ActionButton
            icon="add_line"
            className="gaev-detailreport-btn-add_filteritem"
            onClick={handleAdd}
          >
            フィルタアイテムを追加
          </ActionButton>
        </Spacer>
      )}
    </PageSection>
  );
};

export { RefineSection };
