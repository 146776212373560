import { Request } from "api-types";
import {
  Typography,
  Spacer,
  ActionButton,
  Icon,
  Flex,
  Button,
  useTheme,
  DataTable,
  Portal,
  FixedPanel,
} from "ingred-ui";
import React from "react";

import { Helmet } from "../../../../containers/Helmet";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { DemandAdCreativeGroup } from "../../../../domain/demandAdCreativeGroup";
import { useIntersectionObserver } from "../../../../hooks/useIntersectionObserver";
import { deleteDemandAdCreativeGroup } from "../../../../store/modules/demandAdCreativeGroup/actions/deleteDemandAdCreativeGroupAction";
import { patchDemandAdCreativeGroup } from "../../../../store/modules/demandAdCreativeGroup/actions/patchDemandAdCreativeGroupAction";
import { postDemandAdCreativeGroup } from "../../../../store/modules/demandAdCreativeGroup/actions/postDemandAdCreativeGroupAction";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../../store/utils/dispatchable";
import { Link } from "../../../atoms/Link";
import { Breadcrumbs } from "../../../elements/Breadcrumbs";
import { PageContainer } from "../../../elements/PageContainer";
import { PageContent } from "../../../elements/PageContent";

import { CreateModal } from "./internal/CreateModal";
import { DeleteModal } from "./internal/DeleteModal";
import { EditModal } from "./internal/EditModal";

type InjectProps = {
  currentCompany: CompanyAttribute;
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  postDemandAdCreativeGroup: DispatchableAction<
    typeof postDemandAdCreativeGroup
  >;
  postRequesting: boolean;
  postSucceeded: boolean;
  patchDemandAdCreativeGroup: DispatchableAction<
    typeof patchDemandAdCreativeGroup
  >;
  patchRequesting: boolean;
  patchSucceeded: boolean;
  deleteDemandAdCreativeGroup: DispatchableAction<
    typeof deleteDemandAdCreativeGroup
  >;
  deleteRequesting: boolean;
  deleteSucceeded: boolean;
};

type Props = {};

type InjectedProps = Props & InjectProps;

const Sites: React.FunctionComponent<InjectedProps> = ({
  currentCompany,
  demandAdCreativeGroups,
  enqueueSystemNotification,
  postDemandAdCreativeGroup,
  postRequesting,
  postSucceeded,
  patchDemandAdCreativeGroup,
  patchRequesting,
  patchSucceeded,
  deleteDemandAdCreativeGroup,
  deleteRequesting,
  deleteSucceeded,
}) => {
  const theme = useTheme();

  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const showPanel = !useIntersectionObserver(buttonContainerRef);

  const [postModalOpen, setPostModalOpen] = React.useState(false);
  const [
    editModalRow,
    setEditModalRow,
  ] = React.useState<DemandAdCreativeGroup | null>(null);
  const [
    deleteModalRow,
    setDeleteModalRow,
  ] = React.useState<DemandAdCreativeGroup | null>(null);
  // NOTE(kinokoruumu): storeを初期化せずにunmount -> didmountするとトーストが出てしまうため、
  // storeのstatusを初期値に入れている
  const [postToasted, setPostToasted] = React.useState(postSucceeded);
  const [editToasted, setEditToasted] = React.useState(patchSucceeded);
  const [deleteToasted, setDeleteToasted] = React.useState(deleteSucceeded);

  React.useEffect(() => {
    if (!postToasted && postSucceeded) {
      setPostModalOpen(false);
      enqueueSystemNotification({
        message: "サイトの作成が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setPostToasted(true);
    }
  }, [enqueueSystemNotification, postToasted, postSucceeded]);

  React.useEffect(() => {
    if (!editToasted && patchSucceeded) {
      setEditModalRow(null);
      enqueueSystemNotification({
        message: "編集が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setEditToasted(true);
    }
  }, [enqueueSystemNotification, editToasted, patchSucceeded]);

  React.useEffect(() => {
    if (!deleteToasted && deleteSucceeded) {
      setDeleteModalRow(null);
      enqueueSystemNotification({
        message: "削除が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setDeleteToasted(true);
    }
  }, [enqueueSystemNotification, deleteToasted, deleteSucceeded]);

  const handleChangePostModalOpen = (isOpen: boolean) => () =>
    setPostModalOpen(isOpen);
  const handleChangeEditModalRow = (row: DemandAdCreativeGroup | null) => () =>
    setEditModalRow(row);
  const handleChangeDeleteModalRow = (
    row: DemandAdCreativeGroup | null,
  ) => () => setDeleteModalRow(row);

  const handlePost = (data: Request.Site.Post) => {
    postDemandAdCreativeGroup(data);
    setPostToasted(false);
  };
  const handleEdit = (data: Request.Site.Patch) => {
    patchDemandAdCreativeGroup(data);
    setEditToasted(false);
  };
  const handleDelete = (demandAdCreativeSiteId: number) => {
    deleteDemandAdCreativeGroup({
      site_id: demandAdCreativeSiteId,
    });
    setDeleteToasted(false);
  };

  const [searchText, setSearchText] = React.useState("");
  const searchedItems = React.useMemo(() => {
    return demandAdCreativeGroups.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [demandAdCreativeGroups, searchText]);

  return (
    <>
      <Helmet title="サイト一覧 | DATA STRAP" />
      <Portal>
        <FixedPanel isOpen={showPanel}>
          <Spacer py={2} px={3}>
            <Flex display="flex" justifyContent="flex-end">
              <Button
                size="large"
                inline={true}
                onClick={handleChangePostModalOpen(true)}
              >
                サイトの新規作成
              </Button>
            </Flex>
          </Spacer>
        </FixedPanel>
      </Portal>
      <PageContainer>
        <Flex display="flex" justifyContent="space-between" alignItems="center">
          <Breadcrumbs
            items={[
              {
                title: "デマンド広告クリエイティブ管理",
                link: "/settings/demand_ad_creatives_manager",
              },
              {
                title: "サイト一覧",
              },
            ]}
          />
          {/* FIXME(@yutaro1031): <Button />にもforwardRefを適用する */}
          <div ref={buttonContainerRef}>
            <Button inline={true} onClick={handleChangePostModalOpen(true)}>
              サイトの新規作成
            </Button>
          </div>
        </Flex>
        <Spacer pt={3} />
        <PageContent
          title="サイト一覧"
          placeholder="サイト検索"
          searchText={searchText}
          onChange={setSearchText} // eslint-disable-line react/jsx-handler-names
        >
          <Spacer pt={2} px={2}>
            <DataTable
              dataKey={"id"}
              itemEmptyProps={{
                title: "該当するサイトがありません。",
                subtitle:
                  "画面右の「サイトを作成」ボタンをクリックして新しくサイトを作成してください。",
              }}
              enablePagination={true}
              data={searchedItems}
              columns={[
                {
                  name: "ID",
                  selector: (data) => data.id,
                  sortable: true,
                },
                {
                  name: "名前",
                  selector: (data) => data.name,
                  sortable: true,
                  renderCell: (data) => (
                    <Link
                      href={`/company/${currentCompany.company.id}/settings/demand_ad_creatives_manager?column=site&search=${data.name}`}
                    >
                      <Flex display="flex" alignItems="center">
                        <Spacer pr={1}>
                          <Icon
                            name="folder"
                            color={theme.palette.primary.main}
                          />
                        </Spacer>
                        <Typography color="primary">{data.name}</Typography>
                      </Flex>
                    </Link>
                  ),
                },
                {
                  name: "操作",
                  selector: (data) => data.id,
                  width: "180px",
                  renderCell: (data) => (
                    <Flex display="flex" alignItems="center">
                      <ActionButton
                        icon="pencil"
                        onClick={handleChangeEditModalRow(data)}
                      >
                        編集
                      </ActionButton>
                      <Spacer pr={0.5} />
                      <ActionButton
                        icon="delete_bin"
                        onClick={handleChangeDeleteModalRow(data)}
                      >
                        削除
                      </ActionButton>
                    </Flex>
                  ),
                },
              ]}
            />
          </Spacer>
        </PageContent>
        <CreateModal
          isOpen={postModalOpen}
          demandAdCreativeGroups={demandAdCreativeGroups}
          loading={postRequesting}
          onClose={handleChangePostModalOpen(false)}
          onSubmit={handlePost}
        />
        <EditModal
          isOpen={!!editModalRow}
          demandAdCreativeGroups={demandAdCreativeGroups}
          demandAdCreativeGroup={editModalRow}
          loading={patchRequesting}
          onClose={handleChangeEditModalRow(null)}
          onSubmit={handleEdit}
        />
        <DeleteModal
          isOpen={!!deleteModalRow}
          demandAdCreativeGroup={deleteModalRow}
          loading={deleteRequesting}
          onClose={handleChangeDeleteModalRow(null)}
          onSubmit={handleDelete}
        />
      </PageContainer>
    </>
  );
};

export { Sites };
