import { Domain } from "api-types";

import { DemandAccountCreativeDetail } from "../../../../../../api-client/model";
import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PatchChannelType
 */
export type PatchChannelTypePayload = {
  channelType: Domain.ChannelType;
  demandAdCreatives: DemandAdCreative[];
};
export type PatchChannelTypeParams = void;
export type PatchChannelTypeSuccess = DemandAccountCreativeDetail[];
export type PatchChannelTypeFailure = Error;

export const patchChannelTypeAction = actionCreator.async<
  PatchChannelTypeParams,
  PatchChannelTypeSuccess,
  PatchChannelTypeFailure
>("PATCH_CHANNEL_TYPE");

export function patchChannelType(
  payload: PatchChannelTypePayload,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(patchChannelTypeAction.started());

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsDemandAccountCreativeUpdateChannelType(
        currentCompany.company.id,
        {
          name: payload.channelType,
          demand_account_creative_ids: payload.demandAdCreatives.map(
            (dac) => dac.id,
          ),
        },
      );
      dispatch(patchChannelTypeAction.done({ result: data }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(patchChannelTypeAction.failed({ error }));
      }
    }
  };
}
