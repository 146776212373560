import { reducerWithInitialState } from "typescript-fsa-reducers";

import { Information } from "../../../../domain/information";
import { arrayToEntityMap } from "../../../../utils/ReducerUtils";
import { fetchInformationsAction } from "../actions/fetchInformationsAction";
import { patchInformationReadAction } from "../actions/patchInformationReadAction";

export type InformationEntityState = {
  byId: {
    [key: number]: Information;
  };
  allIds: number[];
};

export const initialState: InformationEntityState = {
  byId: {},
  allIds: [],
};

export const informationEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All information
   */
  .case(fetchInformationsAction.done, (state, action) => ({
    ...state,
    byId: arrayToEntityMap(action.result),
    allIds: action.result.map((item) => item.id),
  }))
  /**
   * 既読にする
   */
  .case(patchInformationReadAction.done, (state, action) => ({
    ...state,
    byId: {
      ...state.byId,
      [action.result.id]: {
        ...state.byId[action.result.id],
        is_already_read: action.result.is_already_read,
      },
    },
  }));
