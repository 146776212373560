import { Domain } from "api-types";
import { ActionButton, Spacer, Table } from "ingred-ui";
import * as React from "react";
import { v4 as uuid } from "uuid";

import { BidStrapGamAdUnit } from "../../../../../domain/bidStrapGamAdUnit";
import { Site } from "../../../../../domain/site";
import { PageSection } from "../../../../elements/PageSection";

import { FilterItem, filterOptions } from "./internal/FilterItem";

export type FilterType = "site" | "gam_ad_unit" | "bidder";

export const FILTER_KEY_MAP: {
  [key in keyof Domain.BidStrapReportFilters]: FilterType;
} = {
  site_ids: "site",
  ad_unit_ids: "gam_ad_unit",
  bidder_ids: "bidder",
} as const;

export type FilterItemStruct = {
  id: string;
  key: FilterType | null;
  values: number[];
};

export type Props = {
  selectedFilters: Domain.BidStrapReportFilters;
  bidders: Domain.Demand[];
  sites: Site[];
  gamAdUnits: BidStrapGamAdUnit[];
  onChange: (filters: Domain.BidStrapReportFilters) => void;
};

const RefineSection: React.FunctionComponent<Props> = ({
  selectedFilters,
  bidders,
  sites,
  gamAdUnits,
  onChange,
}) => {
  const [filterItems, setFilterItems] = React.useState<FilterItemStruct[]>([
    { id: uuid(), key: null, values: [] },
  ]);
  const handleAdd = () => {
    setFilterItems([...filterItems, { id: uuid(), key: null, values: [] }]);
  };
  const handleEdit = (
    id: string,
    value: Partial<Omit<FilterItemStruct, "id">>,
  ) => {
    let resultFilterItems = [...filterItems];
    const filterItem = filterItems.find((filterItem) => filterItem.id === id);
    if (filterItem) {
      const editedItem = {
        ...filterItem,
        ...value,
      };
      resultFilterItems = resultFilterItems.map((resultFilterItem) => {
        if (resultFilterItem.id === editedItem.id) {
          return editedItem;
        }
        return resultFilterItem;
      });
    }
    setFilterItems(resultFilterItems);
  };
  const handleDelete = (id: string) => {
    setFilterItems(filterItems.filter((filterItem) => filterItem.id !== id));
  };

  React.useEffect(() => {
    if (!Object.keys(selectedFilters).length) return;
    const initFilterItems: FilterItemStruct[] = [];

    Object.keys(selectedFilters).forEach((itemKey) => {
      if (FILTER_KEY_MAP[itemKey as keyof Domain.BidStrapReportFilters]) {
        initFilterItems.push({
          id: uuid(),
          key: FILTER_KEY_MAP[itemKey as keyof Domain.BidStrapReportFilters],
          values:
            selectedFilters[itemKey as keyof Domain.BidStrapReportFilters],
        } as FilterItemStruct);
      }
    });
    setFilterItems(initFilterItems);
  }, [selectedFilters]);

  React.useEffect(() => {
    const fi = (label: FilterType): number[] | undefined => {
      return filterItems.find((f) => f.key == label)?.values;
    };
    const filters: Domain.BidStrapReportFilters = {
      site_ids: fi("site"),
      ad_unit_ids: fi("gam_ad_unit"),
      bidder_ids: fi("bidder"),
    };
    onChange(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  return (
    <PageSection title="絞り込み">
      <Table>
        <Table.Body>
          {filterItems.map((filterItem) => (
            <FilterItem
              key={filterItem.id}
              id={filterItem.id}
              filterItem={filterItem}
              selectedKeys={
                filterItems
                  .filter((filterItem) => filterItem.key != null)
                  .map((filterItem) => filterItem.key) as string[]
              }
              sites={sites}
              gamAdUnits={gamAdUnits}
              bidders={bidders}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}
        </Table.Body>
      </Table>
      {filterItems.length < filterOptions.length && (
        <Spacer pt={1}>
          <ActionButton
            icon="add_line"
            className="gaev-detailreport-btn-add_filteritem"
            onClick={handleAdd}
          >
            フィルタアイテムを追加
          </ActionButton>
        </Spacer>
      )}
    </PageSection>
  );
};

export { RefineSection };
