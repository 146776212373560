import { AxiosError } from "axios";

import { UserStruct } from "../../../../../../api-client";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchUser
 */
export type FetchUserParams = void;
export type FetchUserSuccess = UserStruct;
export type FetchUserFailure = AxiosError;

export const fetchUserAction = actionCreator.async<
  FetchUserParams,
  FetchUserSuccess,
  FetchUserFailure
>("FETCH_USER");

export function fetchUser(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    dispatch(fetchUserAction.started());

    try {
      const { data } = await openApiClient.rpzApiViewsUserGetMe();
      dispatch(fetchUserAction.done({ result: data }));
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.code && parseInt(error.code) < 500) {
          dispatch(fetchUserAction.failed({ error }));
        } else {
          dispatch(fetchUserAction.failed({ error }, { fatal: true }));
        }
      } else {
        throw error;
      }
    }
  };
}
