import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PostDashboardItem
 */
export type PostDashboardItemParams = void;
export type PostDashboardItemSuccess = Response.DashboardItem.Post;
export type PostDashboardItemFailure = Error;

export const postDashboardItemAction = actionCreator.async<
  PostDashboardItemParams,
  PostDashboardItemSuccess,
  PostDashboardItemFailure
>("POST_DASHBOARD_ITEM");

export function postDashboardItem(
  payload: Request.DashboardItem.Post,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, dashboardItem }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(postDashboardItemAction.started());

    try {
      const res = await dashboardItem.postDashboardItem(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(postDashboardItemAction.done({ result: res }));
    } catch (err) {
      dispatch(postDashboardItemAction.failed(err));
    }
  };
}
