import { DashboardItem } from "../../../../domain/dashboardItem";
import { RootState } from "../../../reducer";

export const getDashboardItems = (state: RootState): DashboardItem[] => {
  const allIds = state.entities.dashboardItem.allIds;
  const byId = state.entities.dashboardItem.byId;
  return allIds.map((id) => byId[id]);
};

export const getDashboardItemById = (
  state: RootState,
  id: number,
): DashboardItem | null => {
  const byId = state.entities.dashboardItem.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
