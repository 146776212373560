const dsMiami = [
  "#C0EDFD", // 指標で並べて 8 位以降の項目に利用する色
  "#63C427",
  "#7F3BF5",
  "#DD49F7",
  "#71DCFB",
  "#E73D6F",
  "#F7D34C",
  "#0B82F4", // DS メインカラー
];

// const miami = [
//  "#63C427",
//  "#7F3BF5",
//  "#DD49F7",
//  "#71DCFB",
//  "#E73D6F",
//  "#F7D34C",
//  "#3E6BF6",
// ];
// const pastel = [
//   "#EA6C7C",
//   "#885EDB",
//   "#7FBDF5",
//   "#F3BC78",
//   "#82DE92",
//   "#EFA2EE",
//   "#E8F876",
// ];
//
// const ammitsu = [
//   "#364557",
//   "#D0A588",
//   "#7F9E53",
//   "#94364D",
//   "#E37935",
//   "#E3C9E8",
//   "#271E15",
// ];
//
// const metro = [
//   "#63A3D0",
//   "#D99A4B",
//   "#BC3C30",
//   "#A3ADB6",
//   "#5D9456",
//   "#D0C16C",
//   "#927FB2",
// ];
//
// const datastrap = [
//   "#b2efff",
//   "#87c9ff",
//   "#58a5ff",
//   "#0b82f4",
//   "#0061cd",
//   "#0042a8",
//   "#022575",
// ];

export const ChartColors = dsMiami;
// ["#03348D", "#0864D1", "#0B82F4", "#6AC3FB", "#9CDDFD", "#CDF0FE"]
