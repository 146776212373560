import { Request } from "api-types";
import { Spacer, Typography, TextField, ConfirmModal, Flex } from "ingred-ui";
import * as React from "react";
import { useForm, ValidationRule } from "react-hook-form";
import { useSelector } from "react-redux";

import { fetchDemandAccount } from "../../../../../../store/modules/demandAccount/actions/fetchDemandAccountAction";
import { getDemandAccountById } from "../../../../../../store/modules/demandAccount/selectors/demandAccountsSelector";
import { RootState } from "../../../../../../store/reducer";
import { DispatchableAction } from "../../../../../../store/utils/dispatchable";
import { colors } from "../../../../../../styles/variables/color";
import { registerIui } from "../../../../../../utils/registerIui";
import { Loading } from "../../../../Loading";
import { demandFormValidations } from "../../constants";

import * as Styled from "./styled";
import YahooAuthModal from "./yahooAuthModal";

const getDemandFormValidation: (
  demand_id: number,
  form_name: string,
) => ValidationRule<RegExp> | undefined = (demand_id, form_name) => {
  return demandFormValidations[demand_id]?.[form_name];
};

type EditDemandForm = {
  [key: string]: string;
};

type Props = {
  isOpen: boolean;
  fetchDemandAccount: DispatchableAction<typeof fetchDemandAccount>;
  onClose?: () => void;
  demandAccountId?: number;
  onSubmit?: (data: Request.DemandAccount.Patch) => void;
  fetchRequesting?: boolean;
  editRequesting?: boolean;
};

const EditModal: React.FunctionComponent<Props> = ({
  isOpen,
  fetchDemandAccount,
  onClose,
  demandAccountId,
  onSubmit,
  fetchRequesting,
  editRequesting,
}) => {
  const demandAccount = useSelector((state: RootState) =>
    demandAccountId ? getDemandAccountById(state, demandAccountId) : null,
  );

  React.useEffect(() => {
    if (!demandAccountId) return;
    fetchDemandAccount({ demand_account_id: demandAccountId });
  }, [fetchDemandAccount, demandAccountId]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<EditDemandForm>();

  React.useEffect(() => {
    demandAccount?.form_columns.forEach((form) => {
      setValue(`${form.name}` as const, form.value);
    });
  }, [demandAccount, setValue]);

  const submit = (data: EditDemandForm) => {
    const columns: { name: string; value: string }[] = [];
    Object.keys(data).map((key) => {
      columns.push({
        name: key,
        value: data[key],
      });
    });

    if (onSubmit && demandAccountId) {
      onSubmit({
        demand_account_id: demandAccountId,
        form_columns: columns,
        scraper_enabled: null,
      });
    }
  };

  const hasDifference =
    demandAccount &&
    demandAccount.form_columns.filter(
      (form) =>
        watch(`${form.name}` as const) &&
        watch(`${form.name}` as const, form.value) !== form.value,
    ).length > 0;

  //yahoo専用の認可モーダルを表示する
  const isSpecialDemand = demandAccount?.demand.id === 7; // yahoo
  if (isSpecialDemand) {
    return (
      <YahooAuthModal
        demandAccount={demandAccount}
        isOpen={isOpen}
        fetchRequesting={fetchRequesting}
        // eslint-disable-next-line react/jsx-handler-names
        onClose={onClose} // eslint-disable-next-line react/jsx-handler-names
        onSubmit={submit}
      />
    );
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      title="デマンド編集"
      confirmText="保存する"
      cancelText="キャンセル"
      buttonColor="primary"
      disabled={!hasDifference}
      loading={editRequesting}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit(submit)}
    >
      <Styled.FormContainer>
        {fetchRequesting ? (
          <Spacer pt={3} pb={2}>
            <Flex display="flex" justifyContent="center" alignItems="center">
              <Loading />
            </Flex>
          </Spacer>
        ) : (
          <>
            <Styled.FormGroup>
              <Styled.FormGroupLeft>
                <Spacer pr={5}>デマンド</Spacer>
              </Styled.FormGroupLeft>
              <Styled.FormGroupRight>
                <Styled.TextContainer>
                  {demandAccount?.demand.name}
                </Styled.TextContainer>
              </Styled.FormGroupRight>
            </Styled.FormGroup>
            <Styled.DiscriptionBackground>
              <Typography color={colors.basic[800]} size="sm">
                {demandAccount?.description !== ""
                  ? demandAccount?.description
                  : "認証情報を各デマンド側の管理画面で管理している為、DATA STRAPでは編集出来ません。"}
              </Typography>
            </Styled.DiscriptionBackground>
            {demandAccount?.form_columns.map((form) => (
              <Styled.FormGroup key={form.name}>
                <Styled.FormGroupLeft>
                  <Spacer pr={5}>{form.label}</Spacer>
                </Styled.FormGroupLeft>
                <Styled.FormGroupRight>
                  <TextField
                    {...registerIui(
                      register(`${form.name}` as const, {
                        required: true,
                        pattern:
                          getDemandFormValidation(
                            demandAccount?.demand.id,
                            form.name,
                          ) || undefined,
                      }),
                    )}
                    errorText={
                      errors[form.name]
                        ? errors[form.name]?.message || "入力されていません"
                        : ""
                    }
                    type={form.is_secure ? "password" : "text"}
                  />
                </Styled.FormGroupRight>
              </Styled.FormGroup>
            ))}
          </>
        )}
      </Styled.FormContainer>
    </ConfirmModal>
  );
};

export { EditModal };
