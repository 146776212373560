import { Domain } from "api-types";

export const osTypeName: {
  [key in Domain.OsType]: string;
} = {
  none: "解除",
  unknown: "unknown",
  android: "android",
  ios: "ios",
  ipad_os: "ipad_os",
};
