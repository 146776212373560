import { Domain, Request } from "api-types";
import dayjs from "dayjs";
import * as Highcharts from "highcharts";
import {
  Button,
  Card,
  ConfirmModal,
  DatePicker,
  DateRangePicker,
  Flex,
  Select,
  Spacer,
  Spinner,
  Theme,
  Typography,
  useTheme,
  Icon,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import React, { useMemo } from "react";

import "react-dates/lib/css/_datepicker.css";
import Dstnote from "../../../../assets/images/dstnote.svg";
import maintenanceGirl from "../../../../assets/images/maintenance_girl.png";
import { ChartColors } from "../../../constants/chartColors";
import { Helmet } from "../../../containers/Helmet";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { Dashboard } from "../../../domain/dashboard";
import { DemandAccountWithScraperStatus } from "../../../domain/demandAccount";
import { DemandAdCreativeGroup } from "../../../domain/demandAdCreativeGroup";
import { fetchDashboardForecast } from "../../../infra/dashboard/dashboardClient";
import { fetchDashboard } from "../../../store/modules/dashboard/actions/fetchDashboardAction";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import {
  displayPeriodOptions,
  getDateRange,
  getDateRangeErrorMessage,
} from "../../../utils/DateRangeUtils";
import { Link } from "../../atoms/Link";
import { LiteGraph } from "../../elements/LiteGraph/LiteGraph";
import { MultipleLine } from "../../elements/MultipleLine";
import { PageContainer } from "../../elements/PageContainer";
import { PageSection } from "../../elements/PageSection";
import { Popover } from "../../elements/Popover";
import { StackedBar } from "../../elements/StackedBar";

import { SCRAPER_STATUS } from "./constants";
import * as Styled from "./styled";

// Highchartsのグローバルオプション設定
Highcharts.setOptions({
  lang: {
    resetZoom: "リセット",
  },
});

function priceYAxisFormatter(value: number) {
  return `${value / 10000}万円`;
}

function impYAxisFormatter(value: number) {
  return `${value / 10000}万`;
}

function ecpmYAxisFormatter(value: number) {
  return `${value}円`;
}

const displayIntervalOptions: OptionType<Domain.GroupType>[] = [
  {
    label: "日別",
    value: "day",
  },
  {
    label: "月別",
    value: "month",
  },
];

function getEcpmGraphData(data: Dashboard, theme: Theme): Domain.ChartData[] {
  const result = data.ecpms.slice();
  result.push(
    ...data.total_ecpms.map((totalEcpm) => ({
      ...totalEcpm,
      name: "全体",
      color: theme.palette.gray.dark,
    })),
  );
  return result;
}

type InjectProps = {
  data: Dashboard | null;
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  demandAccounts: DemandAccountWithScraperStatus[];
  currentCompany: CompanyAttribute;
  requesting: boolean;
  fetchDashboard: DispatchableAction<typeof fetchDashboard>;
};

type Props = {};

type InjectedProps = Props & InjectProps;

const Dashboard: React.FunctionComponent<InjectedProps> = ({
  demandAdCreativeGroups,
  currentCompany,
  demandAccounts,
  data,
  requesting,
  fetchDashboard,
}) => {
  const theme = useTheme();
  const options = demandAdCreativeGroups.map((demandAdCreativeGroup) => ({
    label: demandAdCreativeGroup.name,
    value: demandAdCreativeGroup.id.toString(),
  }));
  if (!data) {
    data = {
      total_imp: 0,
      total_price: 0,
      average_ecpm: 0,
      prices: [],
      imps: [],
      ecpms: [],
      total_ecpms: [],
    };
  }

  const [selectedGroups, setSelectedGroups] = React.useState<number[]>([]);
  const [displayPeriod, setDisplayPeriod] = React.useState<
    OptionType<Domain.DateRangeType>
  >(displayPeriodOptions[2]);
  const [startDate, setStartDate] = React.useState<dayjs.Dayjs>(
    getDateRange("last_30_days")[0],
  );
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(
    getDateRange("last_30_days")[1],
  );
  const [displayInterval, setDisplayInterval] = React.useState<
    OptionType<Domain.GroupType>
  >({
    label: "日別",
    value: "day",
  });
  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);
  const [errorText, setErrorText] = React.useState("");
  const [noDemandModalisOpen, setNoDemandModalisOpen] = React.useState<boolean>(
    !demandAccounts.length,
  );
  const media = window.matchMedia("(max-width: 1384px)");
  const [bannerActive, setBannerActive] = React.useState<boolean>(
    !media.matches,
  );
  const [forecast, setForecast] = React.useState<Domain.Forecast>();

  media.addEventListener("change", (event) => {
    setBannerActive(!event.matches);
  });

  const fetchForecast = React.useCallback(
    async (selectedGroups?: number[]) => {
      const filter = { filter: {} };
      if (selectedGroups) {
        filter.filter = { sites: selectedGroups };
      }
      setForecast({ price: 0, imps: 0, status: "loading" });
      fetchDashboardForecast(filter, currentCompany.company.id)
        .then((data) => {
          setForecast(data);
        })
        .catch(() => {
          setForecast({ price: 0, imps: 0, status: "error" });
        });
    },
    [currentCompany.company.id],
  );

  const handleSelectChange = (value: any) => {
    setIsButtonDisabled(false);
    if (value == null) {
      setSelectedGroups([]);
    } else {
      const options = value as OptionType[];
      setSelectedGroups(options.map((option) => parseInt(option.value, 10)));
    }
  };
  const handleSubmit = () => {
    const message = getDateRangeErrorMessage(
      startDate,
      endDate,
      displayInterval.value,
    );
    if (message !== "") {
      setErrorText(message);
      return;
    }
    setErrorText("");
    setIsButtonDisabled(true);
    const params: Request.Dashboard.Fetch = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      begin_date: startDate!.format("YYYY-MM-DD"),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      end_date: endDate!.format("YYYY-MM-DD"),
      site_ids: selectedGroups,
      group_type_name: displayInterval.value,
    };
    fetchDashboard(params);
    fetchForecast(selectedGroups);
  };
  const handleDisplayIntervalChange = (option: any) => {
    setIsButtonDisabled(false);
    setDisplayInterval(option);
  };
  const handleDisplayPeriodChange = (option: any) => {
    if (option) {
      setIsButtonDisabled(false);
      setDisplayPeriod(option);
      if (option.value === "custom_range") {
        return;
      }
      if (option.value === "custom_single") {
        setStartDate(dayjs().add(-1, "day"));
        return;
      }
      const [startDate, endDate] = getDateRange(option.value);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };
  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    setIsButtonDisabled(false);
    setStartDate(startDate);
    setEndDate(endDate);
    setDisplayPeriod({
      label: "カスタム(期間)",
      value: "custom_range",
    });
  };
  const handleDateChange = (date: dayjs.Dayjs) => {
    setStartDate(date);
    setEndDate(date);
  };
  const handleNoDemandModalClose = () => {
    setNoDemandModalisOpen(false);
  };
  const createColorMapping = (data: Dashboard) => {
    /*
    売上の多い順番に色を割り当てるための辞書を作成する
    keyはlegend name(ダッシュボードだと主にデマンド名)でvalueは色コード

    色の設定はStackedBarコンポーネント内でやっているが、コンポーネントにデータを渡す前に色を設定していれば
    StackedBarコンポーネント内での着色は行われない

    処理手順
    1. priceの大きい順にlegend nameをソート
    2. ChartColorsの色を重要度順に並べる
    3. 1と2で添字が一緒のものをlegend nameの色コードとする
    4. ChartColorsの添字よりも順位が大きい、ランク外のものは最低重要度の色にする

    カスタムダッシュボードなどでも使える処理になるかもしれないが、その場合は
    DashboardやChartDataに依存しない引数を受け取り、色のマッピングを返す
    モジュールを作る。
    */
    const sorted: { name: string; total: number }[] = data.prices
      .map((a: Domain.ChartData) => {
        const total = a.data.reduce((acc: number, val) => {
          if (val === null) return acc;
          return acc + (val as [number, number])[1]; // [timestamp, value]
        }, 0);
        return { name: a.name, total: total };
      })
      .sort((a, b) => b.total - a.total);

    // ChartColorsはインデックス番号が大きいほど色を強調している
    // sortedの順番に合わせるためにインデックス番号が小さいほど色を強調するように
    const colors = ChartColors.reverse();

    return sorted.reduce((acc: { [key: string]: string }, d, idx) => {
      if (colors[idx] !== undefined) {
        acc[d.name] = colors[idx];
      } else {
        // 最後の色は上位外の項目につける色
        acc[d.name] = colors.slice(-1)[0];
      }
      return acc;
    }, {});
  };

  const colorMapping = createColorMapping(data);

  const mapColor = (cds: Domain.ChartData[]): Domain.ChartData[] => {
    return cds.map((cd) => {
      // 「全体」など既に色付けされている凡例はそのまま
      if (cd.color === undefined) {
        cd.color = colorMapping[cd.name];
      }
      return cd;
    });
  };

  React.useEffect(() => {
    fetchForecast();
  }, [fetchForecast]);

  const actions = useMemo(
    () =>
      displayPeriodOptions.map((option) => ({
        text: option.label,
        onClick: () => {
          handleDisplayPeriodChange(option);
        },
      })),
    [],
  );

  return (
    <>
      <Helmet title="ダッシュボード | DATA STRAP" />
      <PageContainer>
        {demandAccounts.map(
          ({ id, demand, scraper_status, scraper_enabled }) => {
            const hasAuthError =
              scraper_status === SCRAPER_STATUS.AUTHENTICATION_ERROR;
            return hasAuthError && scraper_enabled ? (
              <Styled.AlertBox key={id} p={3} my={2} display="flex">
                <Icon
                  color={theme.palette.danger.main}
                  name="exclamation"
                  size="md"
                />
                <Spacer pr={1} />
                <Typography color={theme.palette.danger.main}>
                  {`デマンド ${demand.name}の認証情報が正しくありません。※レポートを取得するためには、`}
                  <Link
                    href={`/company/${currentCompany.company.id}/settings?demand_account_id=${id}`}
                  >
                    デマンド編集
                  </Link>
                  {`より該当デマンドの認証情報を更新してください。`}
                </Typography>
              </Styled.AlertBox>
            ) : null;
          },
        )}

        <Flex display="flex" justifyContent="space-between">
          <Card p={3}>
            <Flex display="flex" alignItems="flex-start" flexWrap="wrap">
              <div>
                <Flex display="flex" alignItems="flex-start" flexWrap="wrap">
                  <Typography color="secondary" size="sm" weight="bold">
                    サイトで絞り込み
                  </Typography>
                  <Spacer ml={1}>
                    <Popover>
                      <Typography size="sm" lineHeight="1.7">
                        指定したサイトと紐付いているクリエイティブのみがサイトとして選択できます。
                        サイトとの紐付けは「
                        <Link
                          href={`/company/${currentCompany.company.id}/settings/demand_ad_creatives_manager`}
                        >
                          設定＞デマンド広告クリエイティブ管理
                        </Link>
                        」から行えます。
                      </Typography>
                    </Popover>
                  </Spacer>
                </Flex>
                <Spacer pb={1} />
                <Styled.InputContainer>
                  <Select
                    isMulti={true}
                    options={options}
                    placeholder="すべて"
                    onChange={handleSelectChange}
                  />
                </Styled.InputContainer>
              </div>
              <Spacer pr={2} />
              <div>
                <Typography color="secondary" size="sm" weight="bold">
                  表示間隔
                </Typography>
                <Spacer pb={1} />
                <Styled.InputContainer width="84px">
                  <Select
                    isClearable={false}
                    value={displayInterval}
                    options={displayIntervalOptions}
                    onChange={handleDisplayIntervalChange}
                  />
                </Styled.InputContainer>
              </div>
              <Spacer pr={2} />
              <div>
                <Flex display="flex" alignItems="flex-end" flexWrap="wrap">
                  <div>
                    <Typography color="secondary" size="sm" weight="bold">
                      表示期間
                    </Typography>
                    <Spacer pb={1} />
                    {displayPeriod?.value === "custom_single" ? (
                      <DatePicker
                        date={startDate}
                        isOutsideRange={(day: dayjs.Dayjs) =>
                          day.isAfter(dayjs())
                        }
                        defaultClickAction={displayPeriod.label}
                        actions={actions}
                        onDateChange={handleDateChange}
                      />
                    ) : (
                      <DateRangePicker
                        errorText={errorText}
                        startDate={startDate}
                        endDate={endDate}
                        isOutsideRange={(day: dayjs.Dayjs) =>
                          day.isAfter(dayjs())
                        }
                        defaultClickAction={displayPeriod.label}
                        actions={actions}
                        onDatesChange={handleDatesChange}
                      />
                    )}
                  </div>
                  <Spacer pr={3} />
                  <Button
                    disabled={isButtonDisabled}
                    inline={true}
                    size="medium"
                    className="gaev-dashboard-btn_apply"
                    onClick={handleSubmit}
                  >
                    適用
                  </Button>
                </Flex>
              </div>
            </Flex>
          </Card>
          {bannerActive && (
            <>
              <Spacer pr={3} />
              <Styled.NoteBanner
                href="https://note.com/data_strap"
                target="_blank"
              >
                <Flex display="flex" height="100%" alignItems="center">
                  <Dstnote />
                </Flex>
              </Styled.NoteBanner>
            </>
          )}
        </Flex>
        <Spacer pb={1.5} />
        <Styled.LiteGraphs>
          <Styled.LiteGraphContainer>
            <LiteGraph
              title="全体売上 (税抜)"
              value={data.total_price}
              unitType="price"
              forecast_value={forecast?.price}
              forecast_status={forecast?.status}
            />
          </Styled.LiteGraphContainer>
          <Styled.LiteGraphContainer>
            <LiteGraph
              title="全体imp"
              value={data.total_imp}
              forecast_value={forecast?.imps}
              forecast_status={forecast?.status}
            />
          </Styled.LiteGraphContainer>
          <Styled.LiteGraphContainer>
            <LiteGraph
              title="平均eCPM"
              value={data.average_ecpm}
              digits={1}
              unitType="price"
            />
          </Styled.LiteGraphContainer>
        </Styled.LiteGraphs>
        <PageSection title="売上 (税抜)">
          <StackedBar
            data={mapColor(data.prices)}
            unitType="price"
            yAxisFormatter={priceYAxisFormatter}
          />
        </PageSection>
        <Styled.RowGroup>
          <Styled.HalfContent>
            <PageSection title="imp">
              <StackedBar
                data={mapColor(data.imps)}
                yAxisFormatter={impYAxisFormatter}
              />
            </PageSection>
          </Styled.HalfContent>
          <Styled.HalfContent>
            <PageSection title="eCPM">
              <MultipleLine
                unitType="price"
                data={mapColor(getEcpmGraphData(data, theme))}
                yAxisFormatter={ecpmYAxisFormatter}
              />
            </PageSection>
          </Styled.HalfContent>
        </Styled.RowGroup>
        <Spacer pt={2}>
          <Typography color="secondary" size="xs" lineHeight="1.7">
            ※データ集計後に各社管理画面側で数値の変更が生じた場合、DATA
            STRAP側ではその変更が反映されない場合がございます。
          </Typography>
        </Spacer>
        <ConfirmModal
          title="現在広告収益データの反映中です"
          isOpen={noDemandModalisOpen}
          overflowYScroll={false}
          onClose={handleNoDemandModalClose}
        >
          <Styled.ImageContainer>
            <img src={maintenanceGirl} alt="現在広告収益データの反映中です" />
          </Styled.ImageContainer>
          <Spacer pt={2} />
          <Typography size="md" lineHeight="1.7">
            初回のデータ反映が完了するまで最短で約1営業日かかります。
          </Typography>
          <Typography size="md" lineHeight="1.7">
            弊社の担当者からご連絡差し上げますので、しばらくお待ちください。
          </Typography>
        </ConfirmModal>
        {requesting && (
          <Styled.LoadingContainer>
            <Spinner />
          </Styled.LoadingContainer>
        )}
      </PageContainer>
    </>
  );
};

export { Dashboard };
