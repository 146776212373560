import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchAllReportStatusAction } from "../actions/fetchAllReportStatusAction";

export type ReportStatusState = {
  fetchAll: {
    requesting: boolean;
  };
};

export const initialState: ReportStatusState = {
  fetchAll: {
    requesting: false,
  },
};

export const reportStatusReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All ReportStatus
   */
  .case(fetchAllReportStatusAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchAllReportStatusAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchAllReportStatusAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }));
