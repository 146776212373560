import { Typography, Spacer } from "ingred-ui";
import * as React from "react";

import { LinkWithBlank } from "../LinkWithBlank";

import * as Styled from "./styled";

type Props = {
  space?: number;
  children?: React.ReactNode;
};

const Footer: React.FunctionComponent<Props> = ({ space = 3 }) => (
  <Styled.Container>
    <Spacer pb={space}>
      <Styled.Ul>
        <Styled.Li>
          <LinkWithBlank href="https://corp.fluct.jp/">
            <Typography size="xs" color="secondary">
              運営会社
            </Typography>
          </LinkWithBlank>
        </Styled.Li>
        <Styled.Li>
          <LinkWithBlank href="https://corp.fluct.jp/privacy/">
            <Typography size="xs" color="secondary">
              プライバシーポリシー
            </Typography>
          </LinkWithBlank>
        </Styled.Li>
        <Styled.Li>
          <LinkWithBlank href="/terms">
            <Typography size="xs" color="secondary">
              利用規約
            </Typography>
          </LinkWithBlank>
        </Styled.Li>
      </Styled.Ul>
    </Spacer>
    <Typography size="xs" color="secondary">
      © 2019 fluct,inc.
    </Typography>
  </Styled.Container>
);

export { Footer };
