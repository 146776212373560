import { reducerWithInitialState } from "typescript-fsa-reducers";

import { fetchDashboardUrlAction } from "../actions/fetchDashboardUrlAction";

export type DashboardUrlState = {
  fetch: {
    requesting: boolean;
    success: boolean;
  };
};

export const initialState: DashboardUrlState = {
  fetch: {
    requesting: false,
    success: false,
  },
};

export const dashboardUrlReducer = reducerWithInitialState(initialState)
  .case(fetchDashboardUrlAction.started, (state) => ({
    ...state,
    fetch: {
      requesting: true,
      success: false,
    },
  }))
  .case(fetchDashboardUrlAction.done, (state) => ({
    ...state,
    fetch: {
      requesting: false,
      success: true,
    },
  }))
  .case(fetchDashboardUrlAction.failed, (state) => ({
    ...state,
    fetch: {
      requesting: false,
      success: false,
    },
  }));
