import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing}px;
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  & > * {
    padding-top: ${({ theme }) => theme.spacing * 2}px;
  }
`;

export const Content = styled.div`
  min-width: ${({ theme }) => theme.spacing * 18}px;
  padding: ${({ theme }) => theme.spacing * 1}px
    ${({ theme }) => theme.spacing * 2}px;
  white-space: nowrap;
`;
