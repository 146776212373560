import { Site } from "../../../../domain/site";
import { RootState } from "../../../reducer";

export const getBidStrapSites = (state: RootState): Site[] => {
  const { allIds, byId } = state.entities.bidStrapSite;
  return allIds.map((id) => byId[id]);
};

export const getBidStrapSiteById = (
  state: RootState,
  id: number,
): Site | null => {
  const byId = state.entities.bidStrapSite.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
