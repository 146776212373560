import { Request, Response } from "api-types";

import { APIClient, createAPIClient } from "../../utils/APIClientUtils";

export type FetchDashboardParams = Request.Dashboard.Fetch;
export type FetchDashboardResponse = Response.Dashboard.Fetch;

export async function fetchDashboard(
  apiClient: APIClient,
  params: FetchDashboardParams,
  companyId: number,
) {
  const { data } = await apiClient.post<FetchDashboardResponse>(
    `/v1/demand_reports/search`,
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type FetchDashboardForecastParams = Request.Dashboard.FetchForecast;
export type FetchDashboardForecastResponse = Response.Dashboard.FetchForecast;

// ForecastはDashboardでしか使わず、永続化するメリットが弱いのでReduxにのせず直接叩く
export async function fetchDashboardForecast(
  params: FetchDashboardForecastParams,
  companyId: number,
) {
  const apiClient = createAPIClient();
  const { data } = await apiClient.post<FetchDashboardForecastResponse>(
    `/v1/dashboard/forecast`,
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}
