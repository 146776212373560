import axios, { AxiosInstance } from "axios";

import { DefaultApi, Configuration } from "../../../api-client";
import { config } from "../config";

import { getAuth0Client } from "./Auth0Utils";

export type APIClient = AxiosInstance;

/**
 * Factory for api client
 */
export function createAPIClient(): APIClient {
  const apiClient = axios.create({
    baseURL: config.API_DOMAIN,
    // timeout: config.API_TIMEOUT,
  });

  /**
   * Request
   */
  apiClient.interceptors.request.use(async (request) => {
    request.headers.set(request.headers != null ? request.headers : {});
    request.params = request.params != null ? request.params : {};

    const auth0Client = await getAuth0Client();
    const token = await auth0Client.getTokenSilently();

    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }

    return request;
  });

  /**
   * Response
   */
  apiClient.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error),
  );

  return apiClient;
}

//自動生成したクライアントを利用するためのwrapper
export function createOpenAPIClient(apiClient: APIClient): DefaultApi {
  return new DefaultApi(new Configuration(), "", apiClient);
}
