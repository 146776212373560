export function arrayEquals(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((v, i) => v === arr2[i]);
}

export function uniquePush<V extends number | string>(
  arr: V[],
  value: V | V[],
) {
  // 配列に配列を入れる
  if (Array.isArray(value)) {
    const result: V[] = [...arr];
    for (const v of value) {
      if (!result.includes(v)) {
        result.push(v);
      }
    }
    return result;
  }

  // 配列に値を入れる
  if (!arr.includes(value)) {
    return arr.concat(value);
  }
  return arr;
}

export function groupBy<K, V>(
  arr: V[],
  getKey: (cur: V, idx: number, src: V[]) => K,
) {
  return Array.from(
    arr.reduce((map, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const list = map.get(key);
      if (list) list.push(cur);
      else map.set(key, [cur]);
      return map;
    }, new Map<K, V[]>()),
  );
}
