import { Response } from "api-types";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchAnalyticsFeatureDashboardUrlResponse = Response.AnalyticsFeature.FetchDashboardUrl;

export async function fetchAnalyticsFeatureDashboardUrl(
  apiClient: APIClient,
  companyId: number,
): Promise<FetchAnalyticsFeatureDashboardUrlResponse> {
  const { data } = await apiClient.get<
    FetchAnalyticsFeatureDashboardUrlResponse
  >("/v1/analytics_feature/dashboard", {
    headers: {
      "X-Company-Id": companyId,
    },
  });

  return data;
}
