import { Spacer, Typography } from "ingred-ui";
import * as React from "react";

import { Helmet } from "../../../containers/Helmet";

import * as Styled from "./styled";
import { foreword, terms } from "./text";

type Props = {};

const Terms: React.FunctionComponent<Props> = () => {
  return (
    <>
      <Helmet title="利用規約 | DATA STRAP" />
      <Styled.Container>
        <Spacer py={5}>
          <Typography component="h2" align="center" weight="bold" size="xxxxxl">
            利用規約
          </Typography>
        </Spacer>
        <Typography lineHeight="24px">{foreword}</Typography>
        <Spacer py={5}>
          {terms.map((term) => (
            <>
              <Typography component="h3" weight="bold" size="xxxxl">
                {term.title}
              </Typography>
              <Styled.TextContainer>{term.content}</Styled.TextContainer>
            </>
          ))}
          <Typography>最終改定&emsp;2019年12月3日</Typography>
        </Spacer>
      </Styled.Container>
    </>
  );
};

export { Terms };
