import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchDemandAdCreatives
 */
export type FetchDemandAdCreativesParams = void;
export type FetchDemandAdCreativesSuccess = Response.DemandAccountCreative.FetchAll;
export type FetchDemandAdCreativesFailure = Error;

export const fetchDemandAdCreativesAction = actionCreator.async<
  FetchDemandAdCreativesParams,
  FetchDemandAdCreativesSuccess,
  FetchDemandAdCreativesFailure
>("FETCH_DEMAND_AD_CREATIVES");

export function fetchDemandAdCreatives(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { demandAccountCreative, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchDemandAdCreativesAction.started());
    try {
      const res = await demandAccountCreative.fetchDemandAccountCreatives(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(
        fetchDemandAdCreativesAction.done({
          result: res,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchDemandAdCreativesAction.failed({ error }));
      }
    }
  };
}
