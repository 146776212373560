import { fetchAnalyticsFeatureDashboardUrl } from "./analyticsFeature/analyticsFeatureClient";
import {
  downloadBidStrapCSV,
  fetchBidStrapReport,
} from "./bidStrapReport/bidStrapReportClient";
import {
  deleteCategoryById,
  deleteDemandAdCreatives,
  fetchCategories,
  patchCategoryById,
  patchDemandAdCreatives,
  postCategory,
} from "./category/categoryClient";
import { uploadCSV } from "./csvImport/csvImportClient";
import { fetchDashboard } from "./dashboard/dashboardClient";
import {
  fetchDashboardItems,
  postDashboardItem,
  patchDashboardItemById,
  deleteDashboardItemById,
} from "./dashboardItem/dashboardItemClient";
import {
  deleteDemandAccountById,
  fetchDemandAccounts,
  patchDemandAccountById,
  postDemandAccount,
} from "./demandAccount/demandAccountClient";
import { fetchDemandAccountSettings } from "./demandAccount/demandAccountSettingClient";
import {
  fetchDemandAccountCreatives,
  patchDeviceType,
  patchOsType,
} from "./demandAccountCreative/demandAccountCreativeClient";
import {
  fetchGamAdUnits,
  fetchMappedGamAdUnits,
  fetchBidStrapGamAdUnits,
} from "./gamAdUnit/gamAdUnitClient";
import {
  fetchGamLineItemsByGamAdUnitId,
  patchYieldSetting,
} from "./gamLineItem/gamLineItemClient";
import {
  fetchGamLineItemCreativesByGamLineItemId,
  fetchUnmappedGamFlattenedHierarchies,
  patchGamLineItemCreative,
} from "./gamLineItemCreative/gamLineItemCreativeClient";
import { fetchAllHbDemandType } from "./hbDemandType/hbDemandTypeClient";
import {
  fetchInformations,
  patchInformationRead,
} from "./information/informationClient";
import {
  downloadMarketTrendCSV,
  fetchMarketTrendEcpm,
  fetchMarketTrendSalesCompositionRatio,
} from "./marketTrend/marketTrendClient";
import {
  deleteSavedQueryById,
  fetchSavedQueries,
  fetchSavedQueryById,
  fetchSavedQueryDataById,
  postSavedQuery,
  patchSavedQuery,
} from "./savedQuery/savedQueryClient";
import {
  deleteSiteById,
  fetchSites,
  fetchBidStrapSites,
  patchSiteById,
  postSite,
} from "./site/siteClient";

export const infrastructure = {
  demandAccount: {
    fetchDemandAccounts,
    postDemandAccount,
    patchDemandAccountById,
    deleteDemandAccountById,
    setting: {
      fetchDemandAccountSettings,
    },
  },
  demandAccountCreative: {
    fetchDemandAccountCreatives,
    patchDeviceType,
    patchOsType,
  },
  site: {
    fetchSites,
    fetchBidStrapSites,
    postSite,
    patchSiteById,
    deleteSiteById,
  },
  gamAdUnit: {
    fetchGamAdUnits,
    fetchMappedGamAdUnits,
    fetchBidStrapGamAdUnits,
  },
  gamLineItem: {
    fetchGamLineItemsByGamAdUnitId,
    patchYieldSetting,
  },
  gamLineItemCreative: {
    fetchGamLineItemCreativesByGamLineItemId,
    patchGamLineItemCreative,
    fetchUnmappedGamFlattenedHierarchies,
  },
  dashboard: {
    fetchDashboard,
  },
  dashboardItem: {
    fetchDashboardItems,
    postDashboardItem,
    patchDashboardItemById,
    deleteDashboardItemById,
  },
  category: {
    fetchCategories,
    postCategory,
    deleteCategoryById,
    patchCategoryById,
    patchDemandAdCreatives,
    deleteDemandAdCreatives,
  },
  information: {
    fetchInformations,
    patchInformationRead,
  },
  marketTrend: {
    fetchSites,
    fetchMarketTrendEcpm,
    fetchMarketTrendSalesCompositionRatio,
    downloadMarketTrendCSV,
  },
  hbDemandType: {
    fetchAllHbDemandType,
  },
  csvImport: {
    uploadCSV,
  },
  savedQuery: {
    fetchSavedQueries,
    fetchSavedQueryById,
    fetchSavedQueryDataById,
    postSavedQuery,
    patchSavedQuery,
    deleteSavedQueryById,
  },
  bidStrapReport: {
    fetchBidStrapReport,
    downloadBidStrapCSV,
  },
  analyticsFeature: {
    fetchAnalyticsFeatureDashboardUrl,
  },
};

export type Infrastructure = typeof infrastructure;
