import { Request, Response } from "api-types";
import * as queryString from "query-string";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchGamLineItemCreativesByGamLineItemIdParams = Request.GamLineItemCreative.FetchAll;
export type FetchGamLineItemCreativesByGamLineItemIdResponse = Response.GamLineItemCreative.FetchAll;

export async function fetchGamLineItemCreativesByGamLineItemId(
  apiClient: APIClient,
  params: FetchGamLineItemCreativesByGamLineItemIdParams,
  companyId: number,
) {
  const { data } = await apiClient.get<
    FetchGamLineItemCreativesByGamLineItemIdResponse
  >(`/v1/gam_line_item_creatives?${queryString.stringify(params)}`, {
    headers: {
      "X-Company-Id": companyId,
    },
  });

  return data;
}

export type PatchGamLineItemCreativeParams = Request.GamLineItemCreative.Patch;
export type PatchGamLineItemCreativeResponse = Response.GamLineItemCreative.Patch;

export async function patchGamLineItemCreative(
  apiClient: APIClient,
  params: PatchGamLineItemCreativeParams,
  companyId: number,
) {
  const { data } = await apiClient.patch<PatchGamLineItemCreativeResponse>(
    `/v1/gam_line_item_creatives/${params.gam_line_item_creative_id}`,
    {
      is_mappable: params.is_mappable,
    },
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type FetchUnmappedGamFlattenedHierarchiesParams = void;
export type FetchUnmappedGamFlattenedHierarchiesResponse = Response.GamFlattenedHierarchy.FetchUnmapped;

export async function fetchUnmappedGamFlattenedHierarchies(
  apiClient: APIClient,
  companyId: number,
) {
  const { data } = await apiClient.get<
    FetchUnmappedGamFlattenedHierarchiesResponse
  >("/v1/gam_line_item_creatives/unmapped", {
    headers: {
      "X-Company-Id": companyId,
    },
  });

  return data;
}
