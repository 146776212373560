import styled from "styled-components";

import { Color } from "../../../styles/variables";

export const Container = styled.div`
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns: 1fr 1fr;
`;

export const InputContainer = styled.div<{ width?: string }>`
  min-width: ${({ width }) => (width != null ? "auto" : "240px")};
  max-width: 400px;
  width: ${({ width }) => width || "auto"};
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const ReporSettingtAction = styled.a`
  color: ${Color.text.primary};
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;
