import { Request } from "api-types";
import { Spacer, TextField, ConfirmModal } from "ingred-ui";
import * as React from "react";
import { useForm } from "react-hook-form";

import { DemandAdCreativeGroup } from "../../../../../../domain/demandAdCreativeGroup";
import { registerIui } from "../../../../../../utils/registerIui";

import * as Styled from "./styled";

const getErrorText = (errorName: string | undefined): string => {
  switch (errorName) {
    case "required":
      return "入力されていません";
    case "validate":
      return "すでに同じ名前のサイトが存在します";
    default:
      return "";
  }
};

type CreateDemandAdCreativeGroup = {
  name: string;
};
type Props = {
  isOpen: boolean;
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  onClose: () => void;
  onSubmit?: (data: Request.Site.Post) => void;
  loading?: boolean;
};

const CreateModal: React.FunctionComponent<Props> = ({
  isOpen,
  demandAdCreativeGroups,
  onClose,
  onSubmit,
  loading = false,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateDemandAdCreativeGroup>();

  const submit = (data: CreateDemandAdCreativeGroup) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title="サイトの作成"
      confirmText="作成する"
      cancelText="キャンセル"
      buttonColor="primary"
      loading={loading}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit(submit)}
    >
      <Styled.FormContainer>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Spacer pr={5}>サイト名</Spacer>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <TextField
              {...registerIui(
                register("name", {
                  required: true,
                  validate: (value) =>
                    demandAdCreativeGroups.every(
                      (group) => group.name !== value,
                    ),
                }),
              )}
              errorText={getErrorText(errors.name?.type)}
            />
          </Styled.FormGroupRight>
        </Styled.FormGroup>
      </Styled.FormContainer>
    </ConfirmModal>
  );
};

export { CreateModal };
