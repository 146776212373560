import {
  FullSizeConfirmModal,
  DataTable,
  Spacer,
  ConfirmModalProps,
} from "ingred-ui";
import * as React from "react";

import { DemandAdCreative } from "../../../../../../domain/demandAdCreative";

const convertToDataTableArray = (creatives: DemandAdCreative[]) =>
  creatives.map((creative) => ({
    ...creative,
    name: creative.original_name || creative.original_id,
  }));

export type ConfirmModalType =
  | "unlink_site"
  | "unlink_category"
  | "unlink_category2"
  | "activate"
  | "delete";

const FormatMap: {
  [key in ConfirmModalType]: {
    columnName: string;
    selector: (data: ReturnType<typeof convertToDataTableArray>[0]) => string;
  };
} = {
  unlink_site: {
    columnName: "サイト",
    selector: (data) => (data.site != null ? data.site.name : "-"),
  },
  unlink_category: {
    columnName: "カテゴリ",
    selector: (data) => (data.category != null ? data.category.name : "-"),
  },
  unlink_category2: {
    columnName: "カテゴリ2",
    selector: (data) => (data.category2 != null ? data.category2.name : "-"),
  },
  activate: {
    columnName: "状態",
    selector: (data) => (data.is_active ? "利用中" : "利用停止中"),
  },
  delete: {
    columnName: "状態",
    selector: (data) => (data.is_active ? "利用中" : "利用停止中"),
  },
};

type Props = ConfirmModalProps & {
  type: ConfirmModalType;
  onSubmit: () => void;
  selectedDemandAdCreatives: DemandAdCreative[];
};

const ConfirmModal: React.FunctionComponent<Props> = ({
  isOpen,
  type,
  title,
  confirmText,
  cancelText,
  buttonColor,
  onClose,
  onSubmit,
  loading = false,
  selectedDemandAdCreatives,
}) => {
  const format = FormatMap[type];
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <FullSizeConfirmModal
      isOpen={isOpen}
      title={title}
      confirmText={confirmText}
      cancelText={cancelText}
      buttonColor={buttonColor}
      loading={loading}
      disableHorizontalPadding={true}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit}
    >
      <Spacer pt={2} px={3} pb={15}>
        <DataTable
          dataKey={"id"}
          enablePagination={true}
          per={10}
          data={convertToDataTableArray(selectedDemandAdCreatives)}
          columns={[
            {
              name: "名前",
              selector: (data) => data.name,
              sortable: true,
            },
            {
              name: format.columnName,
              selector: format.selector,
              sortable: true,
            },
          ]}
        />
      </Spacer>
    </FullSizeConfirmModal>
  );
};

export { ConfirmModal };
