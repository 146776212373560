import styled from "styled-components";

import { Link as RouterLink } from "../../../components/atoms/Link";
import { Color } from "../../../styles/variables";

export const Container = styled.div`
  position: relative;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const ReporSettingtAction = styled.a`
  color: ${Color.text.primary};
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Link = styled(RouterLink)`
  color: ${Color.danger.main};
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const TipContentContainer = styled.div`
  min-width: ${({ theme }) =>
    360 - (theme.spacing * 2 * 2 + theme.spacing * 2 + 18)}px;
  max-width: ${({ theme }) =>
    512 - (theme.spacing * 2 * 2 + theme.spacing * 2 + 18)}px;
`;
