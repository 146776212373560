import { HbDemandType } from "../../../../domain/hbDemandType";
import { RootState } from "../../../reducer";

export const getHbDemandTypes = (state: RootState): HbDemandType[] => {
  const allIds = state.entities.hbDemandType.allIds;
  const byId = state.entities.hbDemandType.byId;
  return allIds.map((id) => byId[id]);
};

export const getHbDemandTypeById = (
  state: RootState,
  id: number,
): HbDemandType | null => {
  const byId = state.entities.hbDemandType.byId;
  if (byId[id]) {
    return byId[id];
  }
  return null;
};
