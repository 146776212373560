import { Domain } from "api-types";
import {
  Flex,
  Typography,
  Spacer,
  Switch,
  Snackbar,
  DataTable,
} from "ingred-ui";
import * as React from "react";
import { ThemeContext } from "styled-components";

import { DetailReport } from "../../../../../domain/detailReport";
import { MultipleLine } from "../../../../elements/MultipleLine";
import { StackedBar } from "../../../../elements/StackedBar";
import {
  DisplayFormat,
  demandIndicatorOptions,
  gamIndicatorOptions,
  getDimensionOptions,
} from "../../DetailReport";

import * as NewDataTable from "./DataTable";
import { TotalValueCards } from "./TotalValueCards";
import * as Styled from "./styled";
import { createColumns, createGridColumns } from "./utils";

type GraphType = "bar" | "line";

function getYAxis(selectedIndicator: Domain.ReportIndicatorType | undefined) {
  const indicators = [...gamIndicatorOptions, ...demandIndicatorOptions];
  const indicator = indicators.find(
    (indicator) => indicator.value === selectedIndicator,
  );
  if (indicator) {
    return indicator.label;
  }
  return "";
}

function getXAxis(
  selectedDimension: Domain.ReportDimensionType | undefined,
  displayFormat: DisplayFormat,
) {
  if (displayFormat === "date_axis") {
    return "日付";
  }
  const dimensions = getDimensionOptions(displayFormat);
  const dimension = dimensions.find(
    (dimension) => dimension.value === selectedDimension,
  );
  if (dimension) {
    return dimension.label;
  }
  return "";
}

function setEmptyDataName(data: DetailReport) {
  if (data.chart.length === 1) {
    data.chart[0].name = "全体";
  } else {
    data.chart.forEach((chartData) => {
      chartData.name = chartData.name ? chartData.name : "未分類";
    });
  }
}
export type Props = {
  displayFormat: DisplayFormat;
  data: DetailReport;
  indicator?: Domain.ReportIndicatorType;
  dimension?: Domain.ReportDimensionType;
};

type State = {
  graphType: GraphType;
  snackbarOpen: boolean;
};

class ReportTable extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      graphType: "bar",
      snackbarOpen: props.data.current_count < props.data.all_count,
    };
  }

  public render() {
    const { data, displayFormat, indicator, dimension } = this.props;
    const { graphType, snackbarOpen } = this.state;
    const xAxisType =
      displayFormat === "dimension_axis" ? "dimension" : "datetime";
    const isTotalValueDisplayed = data.current_count >= data.all_count;
    // vhで指定しないと機能しない
    const maxTableHeight = data.chart.length > 0 ? "50vh" : "75vh";

    setEmptyDataName(data);

    return (
      <Styled.Container>
        {data.chart.length > 0 && (
          <>
            <Spacer py={2} px={3}>
              <Flex
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography size="xl" weight="bold">
                  {`${getYAxis(indicator)} x ${getXAxis(
                    dimension,
                    displayFormat,
                  )}`}
                </Typography>
                <Switch
                  value={graphType}
                  cases={[
                    {
                      name: "棒グラフ",
                      icon: "bar_chart_framed",
                      value: "bar",
                    },
                    {
                      name: "折れ線グラフ",
                      icon: "line_chart_framed",
                      value: "line",
                    },
                  ]}
                  onChange={this.handleSwitchChange}
                />
              </Flex>
            </Spacer>
            {graphType === "bar" ? (
              <StackedBar data={data.chart} xAxisType={xAxisType} />
            ) : (
              <MultipleLine data={data.chart} xAxisType={xAxisType} />
            )}
          </>
        )}
        {data.chart.length === 0 && (
          <Spacer pt={1}>
            <TotalValueCards
              isDisplayed={isTotalValueDisplayed}
              data={data.records}
              headers={data.headers}
            />
          </Spacer>
        )}
        {displayFormat !== "rich_table_only" && (
          <DataTable
            dataKey={"id"}
            tableMaxHeight={maxTableHeight}
            enableRuledLine={true}
            fullWidth={true}
            horizontalScrollable={true}
            data={data.records}
            columns={createColumns(data)}
          />
        )}
        {displayFormat === "rich_table_only" && (
          <NewDataTable.DataTable
            tableMaxHeight={maxTableHeight}
            data={data.records}
            columns={createGridColumns(data)}
          />
        )}
        <ThemeContext.Consumer>
          {(theme) => (
            <Snackbar
              isOpen={snackbarOpen}
              color="warning"
              onClose={this.handleSnackbarClose}
            >
              <Typography
                size="sm"
                weight="bold"
                color={theme.palette.warning.deepDark}
              >
                レポート結果が10000行を越えています
              </Typography>
              <Spacer pt={0.5} />
              <Typography size="sm" color={theme.palette.warning.deepDark}>
                10000行より先の結果は表示されません。すべての行を確認するには[CSV形式でダウンロード]ボタンをクリックしてダウンロードしてください。
              </Typography>
            </Snackbar>
          )}
        </ThemeContext.Consumer>
      </Styled.Container>
    );
  }

  public handleSwitchChange = (value: GraphType) => {
    this.setState({
      graphType: value,
    });
  };

  public handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
}

export { ReportTable };
