import styled from "styled-components";

import { Radius } from "../../../styles/variables";
import { colors } from "../../../styles/variables/color";

export const Container = styled.div``;

export const InputContainer = styled.div<{ width?: string }>`
  min-width: ${({ width }) => (width != null ? "auto" : "240px")};
  max-width: 400px;
  width: ${({ width }) => width || "auto"};
`;

export const ItemContainer = styled.div`
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns: 1fr 1fr;
`;

export const NoteBanner = styled.a`
  height: 122px;
  border-radius: 9px;
  background-color: #41c9b4;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;

export const AddItemButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${Radius.MEDIUM};
  border: 2px dashed ${({ theme }) => theme.palette.text.disabled};
  background-color: ${colors.basic[100]};
  min-width: 496px;
  height: 576px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Radius.MEDIUM};
  border: 2px solid ${({ theme }) => theme.palette.gray.deepDark};
  width: 40px;
  height: 40px;
`;
