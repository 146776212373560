import { Request, Response } from "api-types";
import * as queryString from "query-string";

import { APIClient } from "../../utils/APIClientUtils";

export type FetchGamLineItemsByGamAdUnitIdParams = Request.GamLineItem.FetchAll;
export type FetchGamLineItemsByGamAdUnitIdResponse = Response.GamLineItem.FetchAll;

export async function fetchGamLineItemsByGamAdUnitId(
  apiClient: APIClient,
  params: FetchGamLineItemsByGamAdUnitIdParams,
  companyId: number,
) {
  const { data } = await apiClient.get<FetchGamLineItemsByGamAdUnitIdResponse>(
    `/v1/gam_line_items?${queryString.stringify(params)}`,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type PatchYieldSettingsParams = Request.GamLineItem.YieldSetting.Patch;
export type PatchYieldSettingsResponse = Response.GamLineItem.YieldSetting.Patch;

export async function patchYieldSetting(
  apiClient: APIClient,
  params: PatchYieldSettingsParams,
  companyId: number,
) {
  const { data } = await apiClient.patch<PatchYieldSettingsResponse>(
    `/v1/gam_line_item_yield_settings`,
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}
