import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import {
  RouteActionContext,
  RouteActionPayload,
} from "../../../../routing/types";
import { deleteDemandAdCreativeGroup as deleteDemandAdCreativeGroupAction } from "../../../../store/modules/demandAdCreativeGroup/actions/deleteDemandAdCreativeGroupAction";
import { fetchDemandAdCreativeGroups as fetchDemandAdCreativeGroupsAction } from "../../../../store/modules/demandAdCreativeGroup/actions/fetchDemandAdCreativeGroupsAction";
import { patchDemandAdCreativeGroup as patchDemandAdCreativeGroupAction } from "../../../../store/modules/demandAdCreativeGroup/actions/patchDemandAdCreativeGroupAction";
import { postDemandAdCreativeGroup as postDemandAdCreativeGroupAction } from "../../../../store/modules/demandAdCreativeGroup/actions/postDemandAdCreativeGroupAction";
import { getDemandAdCreativeGroups } from "../../../../store/modules/demandAdCreativeGroup/selectors/demandAdCreativeGroupSelector";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../../store/reducer";
import { dispatchable } from "../../../../store/utils/dispatchable";

import { Sites as Component } from "./Sites";

const Sites = connect(
  (state: RootState) => ({
    // /company/:id 以下なので選択済み
    currentCompany: state.user.currentCompany as CompanyAttribute,
    demandAdCreativeGroups: getDemandAdCreativeGroups(state),
    postRequesting: state.demandAdCreativeGroup.post.requesting,
    postSucceeded: state.demandAdCreativeGroup.post.success,
    patchRequesting: state.demandAdCreativeGroup.patch.requesting,
    patchSucceeded: state.demandAdCreativeGroup.patch.success,
    deleteRequesting: state.demandAdCreativeGroup.delete.requesting,
    deleteSucceeded: state.demandAdCreativeGroup.delete.success,
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    postDemandAdCreativeGroup: dispatchable(
      dispatch,
      postDemandAdCreativeGroupAction,
    ),
    patchDemandAdCreativeGroup: dispatchable(
      dispatch,
      patchDemandAdCreativeGroupAction,
    ),
    deleteDemandAdCreativeGroup: dispatchable(
      dispatch,
      deleteDemandAdCreativeGroupAction,
    ),
  }),
)(Component);

export async function action({
  store,
  firstOrPush,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await store.dispatch(fetchDemandAdCreativeGroupsAction());
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <Sites />,
  };
}
