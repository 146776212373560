import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { RouteActionPayload, RouteActionContext } from "../../../routing/types";
import { fetchInformations as fetchInformationsAction } from "../../../store/modules/information/actions/fetchInformationsAction";
import { patchInformationRead as patchInformationReadAction } from "../../../store/modules/information/actions/patchInformationReadAction";
import { getInformations } from "../../../store/modules/information/selectors/informationSelector";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { Informations as Component } from "./Informations";

const Informations = connect(
  (state: RootState) => ({
    informations: getInformations(state),
  }),
  (dispatch) => ({
    patchInformationRead: dispatchable(dispatch, patchInformationReadAction),
  }),
)(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await store.dispatch(fetchInformationsAction());
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <Informations />,
  };
}
