import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * PostCategory
 */
export type PostCategoryParams = void;
export type PostCategorySuccess = Response.Category.Post;
export type PostCategoryFailure = Error;

export const postCategoryAction = actionCreator.async<
  PostCategoryParams,
  PostCategorySuccess,
  PostCategoryFailure
>("POST_CATEGORY");

export function postCategory(
  payload: Request.Category.Post,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, category }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(postCategoryAction.started());

    try {
      const res = await category.postCategory(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(postCategoryAction.done({ result: res }));
    } catch (err) {
      dispatch(postCategoryAction.failed(err));
    }
  };
}
