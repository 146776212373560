import styled, { keyframes } from "styled-components";

// TODO: プロパティを結構減らしたけど、無問題なのかを再確認
export const Container = styled.div``;

const cssAnimation = keyframes`
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
`;
export const ScrollableIconContainer = styled.div`
  position: fixed;
  top: 265px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${cssAnimation} 0s ease-in 5s forwards;
`;
