import styled from "styled-components";

export const InputContainer = styled.div`
  width: 340px;
`;

export const TableContainer = styled.div`
  table {
    table-layout: fixed;
    white-space: normal;
  }
`;
