import { Domain } from "api-types";

export const deviceTypeName: {
  [key in Domain.DeviceType]: string;
} = {
  app: "app",
  pc_web: "pc_web",
  sp_web: "sp_web",
  sp_amp: "sp_amp",
  unknown: "unknown",
};
