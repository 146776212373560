import { DemandFormConfig } from "../../../../../../api-client/model/demand-form-config";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchDemandAccountSettings
 */
export type FetchDemandAccountSettingsParams = void;
export type FetchDemandAccountSettingsSuccess = DemandFormConfig[];
export type FetchDemandAccountSettingsFailure = Error;

export const fetchDemandAccountSettingsAction = actionCreator.async<
  FetchDemandAccountSettingsParams,
  FetchDemandAccountSettingsSuccess,
  FetchDemandAccountSettingsFailure
>("FETCH_DEMAND_ACCOUNT_SETTINGS");

export function fetchDemandAccountSettings(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchDemandAccountSettingsAction.started());

    try {
      const {
        data,
      } = await openApiClient.rpzApiViewsDemandAccountGetSettingList(
        currentCompany.company.id,
      );
      dispatch(fetchDemandAccountSettingsAction.done({ result: data }));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchDemandAccountSettingsAction.failed({ error }));
      }
    }
  };
}
