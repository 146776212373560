import * as React from "react";
import { connect } from "react-redux";

import { updateAuthError } from "../../../store/modules/auth/actions/updateAuthErrorAction";
import { push as pushAction } from "../../../store/modules/routing/actions/PushAction";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { Login as Component } from "./Login";

const Login = connect(
  (state: RootState) => ({
    authError: state.auth.error,
  }),
  (dispatch) => ({
    updateAuthError: dispatchable(dispatch, updateAuthError),
    push: dispatchable(dispatch, pushAction),
  }),
)(Component);

export async function action() {
  return {
    content: <Login />,
  };
}
