import dayjs from "dayjs";

import { CustomReportSearch } from "../../../../../../api-client/model";
import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DownloadCsv
 */
export type DownloadCsvParams = void;
export type DownloadCsvSuccess = void;
export type DownloadCsvFailure = Error;

export const downloadCsvAction = actionCreator.async<
  DownloadCsvParams,
  DownloadCsvSuccess,
  DownloadCsvFailure
>("DOWNLOAD_CSV");

export function downloadCsv(
  payload: CustomReportSearch,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { openApiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(downloadCsvAction.started());

    try {
      const { data } = await openApiClient.rpzApiViewsCustomReportsDownload(
        currentCompany.company.id,
        payload,
      );
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      // TODO: SVをクライアント側で生成しなくていいようにする
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const blob = new Blob([bom, data], { type: "text/csv" });

      // headerから["content-disposition"]を取り出せなかったので同じ基準でファイル名を生成する
      const fileName = `${dayjs().format("YYYYMMDDHHmmss")}.csv`;

      const element = document.createElement("a");
      element.href = window.URL.createObjectURL(blob);
      element.setAttribute("download", fileName);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      dispatch(downloadCsvAction.done({}));
    } catch (err) {
      dispatch(downloadCsvAction.failed(err));
    }
  };
}
