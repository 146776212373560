import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchDashboardItems
 */
export type FetchDashboardItemsParams = void;
export type FetchDashboardItemsSuccess = Response.DashboardItem.FetchAll;
export type FetchDashboardItemsFailure = Error;

export const fetchDashboardItemsAction = actionCreator.async<
  FetchDashboardItemsParams,
  FetchDashboardItemsSuccess,
  FetchDashboardItemsFailure
>("FETCH_DASHBOARD_ITEMS");

export function fetchDashboardItems(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, dashboardItem }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchDashboardItemsAction.started());

    try {
      const res = await dashboardItem.fetchDashboardItems(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchDashboardItemsAction.done({ result: res }));
    } catch (err) {
      dispatch(fetchDashboardItemsAction.failed(err));
    }
  };
}
