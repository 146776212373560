import { Request } from "api-types";
import {
  Spacer,
  Icon,
  Flex,
  Typography,
  Button,
  useTheme,
  TextField,
  DataTable,
  ItemEmpty,
} from "ingred-ui";
import * as React from "react";
import { useForm } from "react-hook-form";

import { DemandAdCreative } from "../../../../../../domain/demandAdCreative";
import { DemandAdCreativeGroup } from "../../../../../../domain/demandAdCreativeGroup";
import { registerIui } from "../../../../../../utils/registerIui";
import { StepModal } from "../../../../../elements/StepModal";

import * as Styled from "./styled";

const getErrorText = (errorName: string | undefined): string => {
  switch (errorName) {
    case "required":
      return "入力されていません";
    case "validate":
      return "すでに同じ名前のサイトが存在します";
    default:
      return "";
  }
};

type CreateDemandAdCreativeGroup = {
  name: string;
};
type Props = {
  onClose: () => void;
  onCreateGroupSubmit: (data: Request.Site.Post) => void;
  onSubmit: (site: DemandAdCreativeGroup) => void;
  createLoading: boolean;
  createSucceeded: boolean;
  loading: boolean;
  demandAdCreativeGroups: DemandAdCreativeGroup[];
  selectedDemandAdCreatives: DemandAdCreative[];
};

const SiteMoveModal: React.FunctionComponent<Props> = ({
  onClose,
  onCreateGroupSubmit,
  onSubmit,
  createLoading,
  createSucceeded,
  loading,
  demandAdCreativeGroups,
  selectedDemandAdCreatives,
}) => {
  const theme = useTheme();
  const [searchText, setSearchText] = React.useState("");

  const searchedItems = React.useMemo(
    () =>
      demandAdCreativeGroups.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [demandAdCreativeGroups, searchText],
  );

  const [
    selectedGroup,
    setSelectedGroup,
  ] = React.useState<DemandAdCreativeGroup | null>(null);

  const [step, setStep] = React.useState<1 | 2 | 3>(1);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateDemandAdCreativeGroup>();

  const handleChangeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleCreateGroupSubmit = (data: CreateDemandAdCreativeGroup) => {
    onCreateGroupSubmit(data);
  };

  const handleSelectGroup = (selectedGroupId: number) => {
    const selectedGroup = demandAdCreativeGroups.find(
      (group) => group.id === selectedGroupId,
    );
    setSelectedGroup(selectedGroup || null);
  };

  const handleConfirmSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStep(2);
  };

  const handleAddCreativesSubmit = (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (selectedGroup) {
      onSubmit(selectedGroup);
    }
  };

  const handlePrev = () => {
    setStep(1);
  };

  const handleCreateGroup = () => {
    setStep(3);
  };

  React.useEffect(() => {
    if (createSucceeded) {
      reset();
      setStep(1);
    }
  }, [createSucceeded, reset]);

  return (
    <StepModal
      currentStepKey={step}
      steps={[
        {
          title: "デマンド広告クリエイティブをサイトに紐付ける",
          stepKey: 1,
          cancel: {
            text: "キャンセル",
            onClick: onClose,
          },
          submit: {
            text: "確認する",
            onSubmit: handleConfirmSubmit,
            disabled: !selectedGroup,
          },
          renderContent: () =>
            demandAdCreativeGroups.length !== 0 ? (
              <>
                <Styled.TopContainer>
                  <Styled.InputContainer>
                    <TextField
                      icon="search"
                      placeholder="サイトを検索"
                      value={searchText}
                      onChange={handleChangeSearchText}
                    />
                  </Styled.InputContainer>
                  <Button
                    size="small"
                    inline={true}
                    type="button"
                    onClick={handleCreateGroup}
                  >
                    サイトを作成
                  </Button>
                </Styled.TopContainer>
                <Spacer px={3} pb={15}>
                  <DataTable
                    itemEmptyProps={{
                      title: "該当するサイトがありません。",
                    }}
                    enablePagination={true}
                    per={10}
                    data={searchedItems}
                    dataKey={"id"}
                    columns={[
                      {
                        name: "名前",
                        selector: (data) => data.name,
                        sortable: true,
                        renderCell: (data) => (
                          <Flex display="flex" alignItems="center">
                            <Spacer pr={0.5}>
                              <Icon
                                name="folder"
                                color={theme.palette.primary.main}
                              />
                            </Spacer>
                            <Typography>{data.name}</Typography>
                          </Flex>
                        ),
                      },
                    ]}
                    selectedRow={selectedGroup?.id}
                    onRadioChange={handleSelectGroup}
                  />
                </Spacer>
              </>
            ) : (
              <>
                <ItemEmpty
                  title="サイトが存在しません。"
                  subtitle="画面下の「サイトを作成」ボタンをクリックして新しくサイトを作成してください。"
                />
                <Flex display="flex" justifyContent="center">
                  <Button
                    size="medium"
                    inline={true}
                    type="button"
                    onClick={handleCreateGroup}
                  >
                    サイトを作成
                  </Button>
                </Flex>
              </>
            ),
        },
        {
          title: `サイト ${selectedGroup?.name} に下記のデマンド広告クリエイティブを紐付けますか？`,
          stepKey: 2,
          cancel: {
            text: "戻る",
            onClick: handlePrev,
          },
          submit: {
            text: "紐付ける",
            onSubmit: handleAddCreativesSubmit,
            loading: loading,
          },
          renderContent: () => (
            <Spacer pt={2} px={3} pb={15}>
              <DataTable
                enablePagination={true}
                per={10}
                data={selectedDemandAdCreatives}
                dataKey={"original_id"}
                columns={[
                  {
                    name: "名前",
                    selector: (data) => data.original_name ?? data.original_id,
                    sortable: true,
                  },
                  {
                    name: "サイト",
                    selector: (data) =>
                      data.site != null ? data.site.name : "-",
                    sortable: true,
                  },
                ]}
              />
            </Spacer>
          ),
        },
        {
          title: "サイトを作成",
          stepKey: 3,
          cancel: {
            text: "戻る",
            onClick: handlePrev,
          },
          submit: {
            text: "作成する",
            onSubmit: handleSubmit(handleCreateGroupSubmit),
            loading: createLoading,
          },
          renderContent: () => (
            <Styled.FormContainer>
              <Styled.FormGroup>
                <Styled.FormGroupLeft>
                  <Spacer pr={5}>サイト名</Spacer>
                </Styled.FormGroupLeft>
                <Styled.FormGroupRight>
                  <TextField
                    {...registerIui(
                      register("name", {
                        required: true,
                        validate: (value) =>
                          demandAdCreativeGroups.every(
                            (group) => group.name !== value,
                          ),
                      }),
                    )}
                    errorText={getErrorText(errors.name?.type)}
                  />
                </Styled.FormGroupRight>
              </Styled.FormGroup>
            </Styled.FormContainer>
          ),
        },
      ]}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
    />
  );
};

export { SiteMoveModal };
