import styled from "styled-components";

import { Link as RouterLink } from "../../components/atoms/Link";

import { SideNavWidth } from "./AuthedAppShell";
import {
  TOP_NAVIGATION_HEIGHT,
  TASKS_CONTAINER_HEIGHT,
  FOOTER_CONTAINER_HEIGHT,
} from "./constants";

export const Container = styled.div`
  display: flex;
  height: 100vh;
`;

export const TopNavigationContainer = styled.div<{ showTasks: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.dark};
  height: ${({ showTasks }) =>
    showTasks
      ? `calc(${TOP_NAVIGATION_HEIGHT} + ${TASKS_CONTAINER_HEIGHT})`
      : TOP_NAVIGATION_HEIGHT};
`;

export const Content = styled.div<{ showTasks: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.dark};
  min-height: ${({ showTasks }) =>
    showTasks
      ? `calc(100% - (${TOP_NAVIGATION_HEIGHT} + ${TASKS_CONTAINER_HEIGHT} + ${FOOTER_CONTAINER_HEIGHT}))`
      : `calc(100% - (${TOP_NAVIGATION_HEIGHT} + ${FOOTER_CONTAINER_HEIGHT}))`};
`;

export const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.dark};
  padding: 40px;
`;

export const NavHeaderContainer = styled.div`
  margin: ${({ theme }) => theme.spacing * 2}px 0
    ${({ theme }) => theme.spacing * 7}px;
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
`;

export const LogoContainer = styled.div<{ isOpen: boolean }>`
  width: ${({ isOpen }) => (isOpen ? SideNavWidth.WIDE : SideNavWidth.NARROW)};
  transition: width 0.3s;
  display: flex;
  align-items: center;
  height: 34px;
  overflow: hidden;
  padding-left: 19.5px;
`;

export const Logo = styled.div<{ isOpen: boolean }>`
  width: 35px;
  transition: margin-right 0.3s;
  margin-right: ${({ isOpen, theme }) =>
    isOpen ? `3px` : `${theme.spacing * 3}px`};
`;
