import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchGamAdCreatives
 */
export type FetchGamAdCreativesParams = void;
export type FetchGamAdCreativesSuccess = Response.GamLineItemCreative.FetchAll;
export type FetchGamAdCreativesFailure = Error;

export const fetchGamAdCreativesAction = actionCreator.async<
  FetchGamAdCreativesParams,
  FetchGamAdCreativesSuccess,
  FetchGamAdCreativesFailure
>("FETCH_GAM_AD_CREATIVES");

export function fetchGamAdCreatives(
  payload: Request.GamLineItemCreative.FetchAll,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { gamLineItemCreative, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchGamAdCreativesAction.started());

    try {
      const res = await gamLineItemCreative.fetchGamLineItemCreativesByGamLineItemId(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(fetchGamAdCreativesAction.done({ result: res }));
    } catch (err) {
      dispatch(fetchGamAdCreativesAction.failed(err));
    }
  };
}
