export function createRegExpStr(value: string) {
  let regExpStr = "";
  value.split("").map((str) => {
    regExpStr += "[";

    switch (true) {
      case /[a-zA-Z]/.test(str):
        regExpStr += str.toUpperCase();
        regExpStr += str.toLowerCase();
        break;
      // eslint-disable-next-line no-useless-escape
      case /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/.test(str):
        regExpStr += "\\";
        regExpStr += str;
        break;
      default:
        regExpStr += str;
    }

    regExpStr += "]";
  });
  return regExpStr;
}
