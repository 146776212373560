import { reducerWithInitialState } from "typescript-fsa-reducers";

import { SavedQuery } from "../../../../domain/savedQuery";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { arrayToEntityMap } from "../../../../utils/ReducerUtils";
import { deleteSavedQueryAction } from "../actions/deleteSavedQueryAction";
import { fetchSavedQueriesAction } from "../actions/fetchSavedQueriesAction";
import { patchSavedQueryAction } from "../actions/patchSavedQueryAction";
import { postSavedQueryAction } from "../actions/postSavedQueryAction";

export type SavedQueryEntityState = {
  byId: {
    [key: number]: SavedQuery;
  };
  allIds: number[];
};

export const initialState: SavedQueryEntityState = {
  byId: {},
  allIds: [],
};

export const savedQueryEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All savedQuery
   */
  .case(fetchSavedQueriesAction.done, (state, action) => ({
    ...state,
    byId: arrayToEntityMap(action.result),
    allIds: action.result.map((savedQuery) => savedQuery.id),
  }))
  /**
   * Post savedQuery
   */
  .case(postSavedQueryAction.done, (state, action) => {
    const savedQuery = action.result;
    return {
      ...state,
      byId: {
        ...state.byId,
        [savedQuery.id]: savedQuery,
      },
      allIds: uniquePush(state.allIds, savedQuery.id),
    };
  })
  /**
   * Patch savedQuery
   */
  .case(patchSavedQueryAction.done, (state, action) => {
    const savedQuery = action.result;
    return {
      ...state,
      byId: {
        ...state.byId,
        [savedQuery.id]: savedQuery,
      },
      allIds: uniquePush(state.allIds, savedQuery.id),
    };
  })
  /**
   * Delete savedQuery
   */
  .case(deleteSavedQueryAction.done, (state, action) => {
    const savedQueryId = action.result.saved_query_id;
    const byIdState = { ...state.byId };
    delete byIdState[savedQueryId];
    return {
      ...state,
      byId: { ...byIdState },
      allIds: state.allIds.filter((id) => id !== savedQueryId),
    };
  });
