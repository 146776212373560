import { Request, Response } from "api-types";
import dayjs from "dayjs";

import { APIClient } from "../../utils/APIClientUtils";

export type SearchBidStrapReportParams = Request.BidStrapReport.Fetch;
export type SearchBidStrapReportResponse = Response.BidStrapReport.Fetch;

export async function fetchBidStrapReport(
  apiClient: APIClient,
  params: SearchBidStrapReportParams,
  companyId: number,
): Promise<SearchBidStrapReportResponse> {
  const { data } = await apiClient.post<SearchBidStrapReportResponse>(
    "/v1/bid_strap_reports",
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export type DownloadCsvParams = Request.BidStrapReport.Fetch;
export type DownloadCsvResponse = string;

export async function downloadBidStrapCSV(
  apiClient: APIClient,
  params: DownloadCsvParams,
  companyId: number,
): Promise<void> {
  const { data } = await apiClient.post<DownloadCsvResponse>(
    "/v1/bid_strap_reports/download",
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  // TODO: SVをクライアント側で生成しなくていいようにする
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const blob = new Blob([bom, data], { type: "text/csv" });

  // headerから["content-disposition"]を取り出せなかったので同じ基準でファイル名を生成する
  const fileName = `${dayjs().format("YYYYMMDDHHmmss")}.csv`;

  const element = document.createElement("a");
  element.href = window.URL.createObjectURL(blob);
  element.setAttribute("download", fileName);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
