import * as queryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { SavedQueryDetail as SavedQueryDetailType } from "../../../domain/savedQuery";
import { RouteActionContext, RouteActionPayload } from "../../../routing/types";
import { fetchCategories as fetchCategoriesAction } from "../../../store/modules/category/actions/fetchCategoriesAction";
import { getCategories } from "../../../store/modules/category/selectors/categorySelector";
import { fetchDemandAccounts as fetchDemandAccountsAction } from "../../../store/modules/demandAccount/actions/fetchDemandAccountsAction";
import { getDemandAccounts } from "../../../store/modules/demandAccount/selectors/demandAccountsSelector";
import { getUnmappedToGamDemandAdCreatives } from "../../../store/modules/demandAdCreative/selectors/demandAdCreativeSelector";
import { fetchDemandAdCreativeGroups as fetchDemandAdCreativeGroupsAction } from "../../../store/modules/demandAdCreativeGroup/actions/fetchDemandAdCreativeGroupsAction";
import { getDemandAdCreativeGroups } from "../../../store/modules/demandAdCreativeGroup/selectors/demandAdCreativeGroupSelector";
import { downloadCsv as downloadCsvAction } from "../../../store/modules/detailReport/actions/downloadCsvAction";
import { searchDetailReport } from "../../../store/modules/detailReport/actions/searchDetailReportAction";
import { fetchMappedGamAdUnits as fetchMappedGamAdUnitsAction } from "../../../store/modules/gamAdUnit/actions/fetchMappedGamAdUnitsAction";
import { getMappedGamAdUnits } from "../../../store/modules/gamAdUnit/selectors/gamAdUnitSelector";
import { fetchAllHbDemandType as fetchHbDemandTypesAction } from "../../../store/modules/hbDemandType/actions/fetchAllHbDemandTypeAction";
import { getHbDemandTypes } from "../../../store/modules/hbDemandType/selectors/hbDemandTypeSelector";
import { fetchAllReportStatus } from "../../../store/modules/reportStatus/actions/fetchAllReportStatusAction";
import { getReportStatuses } from "../../../store/modules/reportStatus/selectors/reportStatusSelector";
import { fetchSavedQuery as fetchSavedQueryAction } from "../../../store/modules/savedQuery/actions/fetchSavedQueryAction";
import { patchSavedQuery as patchSavedQueryAction } from "../../../store/modules/savedQuery/actions/patchSavedQueryAction";
import { postSavedQuery as postSavedQueryAction } from "../../../store/modules/savedQuery/actions/postSavedQueryAction";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../store/reducer";
import { dispatchable } from "../../../store/utils/dispatchable";

import { DetailReport as Component } from "./DetailReport";

const DetailReport = connect(
  (state: RootState) => ({
    currentCompany: state.user.currentCompany as CompanyAttribute,
    detailReport: state.detailReport.search.data,
    requesting: state.detailReport.search.requesting,
    demandAccounts: getDemandAccounts(state),
    demandAdCreativeGroups: getDemandAdCreativeGroups(state),
    categories: getCategories(state),
    categories2: getCategories(state),
    gamAdUnits: getMappedGamAdUnits(state),
    demandAdCreatives: getUnmappedToGamDemandAdCreatives(state),
    hbDemandTypes: getHbDemandTypes(state),
    reportStatuses: getReportStatuses(state),
    downloadCsvRequesting: state.detailReport.downloadCsv.requesting,
    fetchSavedQueryRequesting: state.savedQuery.fetch.requesting,
    postSavedQueryRequesting: state.savedQuery.post.requesting,
    postSavedQuerySucceeded: state.savedQuery.post.success,
    patchSavedQueryRequesting: state.savedQuery.patch.requesting,
    patchSavedQuerySucceeded: state.savedQuery.patch.success,
    data: state.savedQuery.fetch.data as SavedQueryDetailType,
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    searchDetailReport: dispatchable(dispatch, searchDetailReport),
    downloadCsv: dispatchable(dispatch, downloadCsvAction),
    fetchSavedQuery: dispatchable(dispatch, fetchSavedQueryAction),
    postSavedQuery: dispatchable(dispatch, postSavedQueryAction),
    patchSavedQuery: dispatchable(dispatch, patchSavedQueryAction),
  }),
)(Component);

export async function action({
  store,
  firstOrPush,
  history,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await Promise.all([
      store.dispatch(fetchDemandAdCreativeGroupsAction()),
      store.dispatch(fetchDemandAccountsAction()),
      store.dispatch(fetchMappedGamAdUnitsAction()),
      store.dispatch(fetchCategoriesAction()),
      store.dispatch(fetchHbDemandTypesAction()),
      store.dispatch(fetchAllReportStatus()),
    ]);
  }

  const params = queryString.parse(history.location.search);
  const savedQueryId = Number(params["saved_query_id"]);
  const savedQueryName = params["name"] as string | undefined;

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: (
      <DetailReport
        savedQueryId={savedQueryId}
        savedQueryName={savedQueryName}
      />
    ),
  };
}
