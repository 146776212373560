import { Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * DeleteCategory
 */
export type DeleteCategoryParams = void;
export type DeleteCategorySuccess = { category_id: number };
export type DeleteCategoryFailure = Error;

export const deleteCategoryAction = actionCreator.async<
  DeleteCategoryParams,
  DeleteCategorySuccess,
  DeleteCategoryFailure
>("DELETE_CATEGORY");

export function deleteCategory(
  payload: Request.Category.Delete,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, category }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(deleteCategoryAction.started());

    try {
      await category.deleteCategoryById(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(
        deleteCategoryAction.done({
          result: { category_id: payload.category_id },
        }),
      );
    } catch (err) {
      dispatch(deleteCategoryAction.failed(err));
    }
  };
}
