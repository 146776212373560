import { reducerWithInitialState } from "typescript-fsa-reducers";

import { HbDemandType } from "../../../../domain/hbDemandType";
import { arrayToEntityMap } from "../../../../utils/ReducerUtils";
import { fetchAllHbDemandTypeAction } from "../actions/fetchAllHbDemandTypeAction";

export type HbDemandTypeEntityState = {
  byId: {
    [key: number]: HbDemandType;
  };
  allIds: number[];
};

export const initialState: HbDemandTypeEntityState = {
  byId: {},
  allIds: [],
};

export const hbDemandTypeEntityReducer = reducerWithInitialState(initialState)
  /**
   * Fetch All hbDemandType
   */
  .case(fetchAllHbDemandTypeAction.done, (state, action) => ({
    ...state,
    byId: arrayToEntityMap(action.result),
    allIds: action.result.map((item) => item.id),
  }));
