import { Request, Domain } from "api-types";
import {
  Typography,
  Spacer,
  ConfirmModal,
  Select,
  TextField,
  Badge,
  RadioButton,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { SavedQuery } from "../../../../../domain/savedQuery";
import { registerIui } from "../../../../../utils/registerIui";
import { ItemSizeSelector } from "../ItemSizeSelector";

import * as Styled from "./styled";

type Props = {
  isOpen: boolean;
  savedQueries: SavedQuery[];
  dashboardItem: Domain.DashboardItem;
  onClose?: () => void;
  onSubmit?: (data: Request.DashboardItem.Patch) => void;
  loading?: boolean;
};

const EditItemModal: React.FunctionComponent<Props> = ({
  isOpen,
  savedQueries,
  dashboardItem,
  onClose,
  onSubmit,
  loading,
}) => {
  const options: OptionType<number>[] = savedQueries.map((query) => {
    return { label: query.name, value: query.id };
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Request.DashboardItem.Patch>({
    defaultValues: {
      item_id: dashboardItem.id,
      query_id: dashboardItem.query_id,
      title: dashboardItem.title,
      display_type: dashboardItem.display_type,
      size: dashboardItem.size,
      order: dashboardItem.order,
    },
  });
  const watchTitle = watch("title");
  const watchQueryId = watch("query_id");
  const [selectedQueryId, setSelectedQueryId] = useState<OptionType<number>>(
    options.find((item) => item.value === dashboardItem.query_id) as OptionType<
      number
    >,
  );
  const [selectedDisplayType, setSelectedDisplayType] = useState<
    Domain.DashboardItemDisplayType
  >(dashboardItem.display_type);
  const [selectedItemSize, setSelectedItemSize] = useState<number>(
    dashboardItem.size,
  );

  const handleQueryIdChange = (selectedOption: any) => {
    setSelectedQueryId(selectedOption);
  };

  const handleDisplayTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value as Domain.DashboardItemDisplayType;
    setSelectedDisplayType(value);
  };

  const handleItemSizeChange = (size: number) => () => {
    setSelectedItemSize(size);
  };

  useEffect(() => {
    register("query_id");
    register("display_type");
    register("size");
    register("item_id");
    register("order");
  }, [register]);

  useEffect(() => {
    if (!selectedQueryId?.value) return;
    setValue("query_id", selectedQueryId.value);
  }, [selectedQueryId, setValue]);

  useEffect(() => {
    setValue("display_type", selectedDisplayType);
  }, [selectedDisplayType, setValue]);

  useEffect(() => {
    setValue("size", selectedItemSize);
  }, [selectedItemSize, setValue]);

  const submit = (data: Request.DashboardItem.Patch) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title="アイテムを編集"
      confirmText="保存する"
      cancelText="キャンセル"
      buttonColor="primary"
      loading={loading}
      disabled={!watchTitle || !watchQueryId}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      onSubmit={handleSubmit(submit)}
    >
      <Styled.FormContainer>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Typography>クエリ選択</Typography>
            <Spacer pr={1} />
            <Badge color="danger">必須</Badge>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <Styled.InputContainer>
              <Select
                options={options}
                isClearable={false}
                value={selectedQueryId}
                onChange={handleQueryIdChange}
              />
            </Styled.InputContainer>
            <Spacer pt={0.5} />
            <Typography color="secondary" size="sm" lineHeight="1.7">
              <span style={{ fontWeight: "bold" }}>「詳細レポート」</span>
              ページで作成したクエリが選択できます。
            </Typography>
          </Styled.FormGroupRight>
        </Styled.FormGroup>

        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Typography>名前</Typography>
            <Spacer pr={1} />
            <Badge color="danger">必須</Badge>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <TextField
              {...registerIui(
                register("title", {
                  required: "必須項目です",
                  maxLength: {
                    value: 30,
                    message: "30文字以内で入力してください",
                  },
                }),
              )}
              errorText={errors.title != null ? errors.title.message : ""}
            />
            <Spacer pt={0.5} />
            <Typography color="secondary" size="sm" lineHeight="1.7">
              最大30文字までです。
            </Typography>
          </Styled.FormGroupRight>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Typography>表示形式</Typography>
            <Spacer pr={1} />
            <Badge color="danger">必須</Badge>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <Styled.RadioContainer>
              <RadioButton
                checked={selectedDisplayType === "table"}
                value="table"
                onChange={handleDisplayTypeChange}
              >
                表
              </RadioButton>
              <RadioButton
                checked={selectedDisplayType === "bar"}
                value="bar"
                onChange={handleDisplayTypeChange}
              >
                棒グラフ
              </RadioButton>
              <RadioButton
                checked={selectedDisplayType === "line"}
                value="line"
                onChange={handleDisplayTypeChange}
              >
                折れ線グラフ
              </RadioButton>
            </Styled.RadioContainer>
          </Styled.FormGroupRight>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Typography>レイアウト</Typography>
            <Spacer pr={1} />
            <Badge color="danger">必須</Badge>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <ItemSizeSelector
              itemSize={selectedItemSize}
              onClick={handleItemSizeChange}
            />
          </Styled.FormGroupRight>
        </Styled.FormGroup>
      </Styled.FormContainer>
    </ConfirmModal>
  );
};

export { EditItemModal };
