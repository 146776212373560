import { Domain, Request } from "api-types";
import dayjs from "dayjs";
import {
  Card,
  ErrorText,
  Button,
  Spacer,
  Typography,
  Flex,
  Theme,
  useTheme,
  OptionType,
  DatePicker,
  DateRangePicker,
} from "ingred-ui";
import * as React from "react";

import { Site, MarketTrendEcpm } from "../../../../../domain/marketTrend";
import {
  displayPeriodOptions,
  getDateRange,
  getDateRangeErrorMessage,
} from "../../../../../utils/DateRangeUtils";
import { MultipleLine } from "../../../../elements/MultipleLine";
import { PageContent } from "../../../../elements/PageContent";

import * as Styled from "./styled";

type Props = {
  data: {
    sites: Site[];
    ecpms: MarketTrendEcpm;
  };
  onSubmit: (payload: Request.MarketTrend.FetchEcpm) => void;
};

const displayIntervalOptions: OptionType<Domain.GroupType>[] = [
  {
    label: "日別",
    value: "day",
  },
  {
    label: "月別",
    value: "month",
  },
];

const demandOptions: OptionType<Domain.MtDemandType>[] = [
  {
    label: "AdX",
    value: "adx",
  },
];

function yAxisFormatter(value: number) {
  return `${value}円`;
}

function getGraphData(data: MarketTrendEcpm, theme: Theme): Domain.ChartData[] {
  const result: Domain.ChartData[] = [];
  result.push(
    ...data.ecpms.map((data: Domain.ChartData) => ({
      ...data,
      color: data.name === "マーケット" ? theme.palette.primary.main : "",
    })),
  );
  return result;
}

export const Ecpm: React.FunctionComponent<Props> = ({ data, onSubmit }) => {
  const theme = useTheme();

  const sites = data.sites.map((site) => ({
    label: site.name,
    value: site.id,
  }));

  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);
  const [errorText, setErrorText] = React.useState("");

  const [selectedDemand, setSelectedDemand] = React.useState<
    OptionType<Domain.MtDemandType>
  >({
    label: "AdX",
    value: "adx",
  });

  const [selectedSites, setSelectedSites] = React.useState<
    OptionType<number>[]
  >(sites);

  const [displayPeriod, setDisplayPeriod] = React.useState<
    OptionType<Domain.DateRangeType>
  >(displayPeriodOptions[2]);

  const [startDate, setStartDate] = React.useState<dayjs.Dayjs>(
    getDateRange("last_30_days")[0],
  );
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(
    getDateRange("last_30_days")[1],
  );
  const [displayInterval, setDisplayInterval] = React.useState<
    OptionType<Domain.GroupType>
  >({
    label: "日別",
    value: "day",
  });

  const handleDemandChange = (option: any) => {
    setIsButtonDisabled(false);
    setSelectedDemand(option);
  };

  const handleDisplayIntervalChange = (option: any) => {
    setIsButtonDisabled(false);
    setDisplayInterval(option);
  };

  const handleSiteChange = (option: any) => {
    setIsButtonDisabled(false);
    // 何も選択されていない場合 null になる
    setSelectedSites(option || []);
  };

  const handleDisplayPeriodChange = (option: any) => {
    if (option) {
      setIsButtonDisabled(false);
      setDisplayPeriod(option);
      if (option.value === "custom_range") {
        return;
      }
      if (option.value === "custom_single") {
        setStartDate(dayjs().add(-1, "day"));
        return;
      }
      const [startDate, endDate] = getDateRange(option.value);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    setIsButtonDisabled(false);
    setStartDate(startDate);
    setEndDate(endDate);
    setDisplayPeriod({
      label: "カスタム(期間)",
      value: "custom_range",
    });
  };

  const handleDateChange = (date: dayjs.Dayjs) => {
    setStartDate(date);
    setEndDate(date);
  };

  const handleSubmit = () => {
    const message = getDateRangeErrorMessage(
      startDate,
      endDate,
      displayInterval.value,
    );
    if (message !== "") {
      setErrorText(message);
      return;
    }
    setErrorText("");
    const params: Request.MarketTrend.FetchEcpm = {
      begin_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
      group_type_name: displayInterval.value,
      demand_type_name: selectedDemand.value,
      site_ids: selectedSites.map((site) => site.value),
    };
    onSubmit(params);
  };
  const actions = React.useMemo(
    () =>
      displayPeriodOptions.map((option) => ({
        text: option.label,
        onClick: () => {
          handleDisplayPeriodChange(option);
        },
      })),
    [],
  );

  return (
    <PageContent
      title="eCPMの推移比較"
      description="デマンドごとの比較ができます。"
    >
      <Spacer p={3}>
        <Styled.SearchBox>
          <Typography weight="bold">設定</Typography>
          <Spacer pt={2} />
          <Card p={2}>
            <Flex display="flex" alignItems="flex-start" flexWrap="wrap">
              <div>
                <Typography color="secondary" size="sm" weight="bold">
                  デマンド
                </Typography>
                <Spacer pb={1} />
                <Styled.Select
                  width="120px"
                  isClearable={false}
                  value={selectedDemand}
                  options={demandOptions}
                  onChange={handleDemandChange}
                />
              </div>
              <Spacer pr={2} />
              <div>
                <Typography color="secondary" size="sm" weight="bold">
                  サイト
                </Typography>
                <Spacer pb={1} />
                <Styled.Select
                  isMulti={true}
                  value={selectedSites}
                  options={sites}
                  placeholder="すべて"
                  onChange={handleSiteChange}
                />
              </div>
              <Spacer pr={2} />
              <div>
                <Typography color="secondary" size="sm" weight="bold">
                  表示間隔
                </Typography>
                <Spacer pb={1} />
                <Styled.Select
                  width="84px"
                  isClearable={false}
                  value={displayInterval}
                  options={displayIntervalOptions}
                  onChange={handleDisplayIntervalChange}
                />
              </div>
              <Spacer pr={2} />
              <Flex display="flex" alignItems="flex-end" flexWrap="wrap">
                <div>
                  <Typography color="secondary" size="sm" weight="bold">
                    表示期間
                  </Typography>
                  <Spacer pb={1} />
                  {displayPeriod.value === "custom_single" ? (
                    <DatePicker
                      date={startDate}
                      isOutsideRange={(day: dayjs.Dayjs) =>
                        day.isAfter(dayjs())
                      }
                      defaultClickAction={displayPeriod.label}
                      actions={actions}
                      onDateChange={handleDateChange}
                    />
                  ) : (
                    <DateRangePicker
                      errorText={errorText}
                      startDate={startDate}
                      endDate={endDate}
                      isOutsideRange={(day: dayjs.Dayjs) =>
                        day.isAfter(dayjs())
                      }
                      defaultClickAction={displayPeriod.label}
                      actions={actions}
                      onDatesChange={handleDatesChange}
                    />
                  )}
                </div>
                <Spacer pr={1} />
                <Button
                  disabled={isButtonDisabled}
                  inline={true}
                  size="medium"
                  className="gaev-markettrend-ecpm-btn-apply"
                  onClick={handleSubmit}
                >
                  適用
                </Button>
              </Flex>
            </Flex>
            {!!errorText && (
              <Spacer
                pt={1}
                className="gaev-markettrend-ecpm-error_setting_viewperiod"
              >
                <ErrorText>{errorText}</ErrorText>
              </Spacer>
            )}
          </Card>
        </Styled.SearchBox>
        <Spacer pt={7} />
        <MultipleLine
          unitType="price"
          data={getGraphData(data.ecpms, theme)}
          yAxisFormatter={yAxisFormatter}
        />
        <Spacer pl={1}>
          <Styled.AnnotationText size="sm">
            ※マーケットデータと自社データの比較を行うためには、該当するデマンドのデマンド認証を行う必要があります。
          </Styled.AnnotationText>
        </Spacer>
      </Spacer>
    </PageContent>
  );
};
