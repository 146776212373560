import { reducerWithInitialState } from "typescript-fsa-reducers";

import { DetailReport } from "../../../../domain/detailReport";
import { createDetailReport } from "../../../../domain/detailReport/entity";
import { downloadCsvAction } from "../actions/downloadCsvAction";
import { searchDetailReportAction } from "../actions/searchDetailReportAction";

export type DetailReportState = {
  search: {
    requesting: boolean;
    data: DetailReport | null;
  };
  downloadCsv: {
    requesting: boolean;
  };
};

export const initialState: DetailReportState = {
  search: {
    requesting: false,
    data: null,
  },
  downloadCsv: {
    requesting: false,
  },
};

export const detailReportReducer = reducerWithInitialState(initialState)
  /**
   * Search DetailReport
   */
  .case(searchDetailReportAction.started, (state) => ({
    ...state,
    search: {
      // requesting時にdataをnullにすると、画面が構成できなくなる為、dataがある場合はそのまま残しておく
      ...state.search,
      requesting: true,
    },
  }))
  .case(searchDetailReportAction.done, (state, action) => ({
    ...state,
    search: {
      requesting: false,
      data: createDetailReport(action.result),
    },
  }))
  .case(searchDetailReportAction.failed, (state) => ({
    ...state,
    search: {
      // fail時にdataをnullにすると、画面が構成できなくなる為、dataがある場合はそのまま残しておく
      ...state.search,
      requesting: false,
    },
  }))
  /**
   * Download CSV DetailReport
   */
  .case(downloadCsvAction.started, (state) => ({
    ...state,
    downloadCsv: {
      requesting: true,
    },
  }))
  .case(downloadCsvAction.done, (state) => ({
    ...state,
    downloadCsv: {
      requesting: false,
    },
  }))
  .case(downloadCsvAction.failed, (state) => ({
    ...state,
    downloadCsv: {
      requesting: false,
    },
  }));
