import {
  Typography,
  ThemeProvider,
  createTheme,
  Toast,
  LocaleProvider,
  jaJP,
} from "ingred-ui";
import React, { useEffect } from "react";

import { ErrorBoundary } from "../../components/boundaries/ErrorBoundary";
import { ErrorView } from "../../components/pages/ErrorView";
import { AppState } from "../../store/modules/app/reducers";
import { GlobalStyle } from "../../styles/globalStyle";
import { AppErrorCode } from "../../utils/StatusCodeUtils";
import { SystemNotificationProvider } from "../SystemNotificationProvider";

import * as Styled from "./styled";

const isDev = process.env.ENV !== "prod";

export type Props = {};

export type InjectProps = {
  app: AppState;
  children?: React.ReactNode;
};

type InjectedProps = Props & InjectProps;

export const AppContainer: React.FC<InjectedProps> = ({ app, children }) => {
  const theme = createTheme();

  useEffect(() => {
    if (app.unavailable) {
      window.location.reload();
    }
  }, [app.unavailable]);

  return (
    <ThemeProvider theme={theme}>
      <LocaleProvider locale={jaJP}>
        <GlobalStyle />
        <Toast.Provider placement="top-center">
          <Styled.Main>
            <ErrorBoundary
              fallbackElement={
                <ErrorView
                  statusCode={AppErrorCode.UNEXPECTED_COMPONENT_RUNTIME}
                />
              }
            >
              {children}
            </ErrorBoundary>
            {isDev && (
              <Styled.DevIconContainer>
                <Styled.DevIcon>
                  <Typography weight="bold" color="white">
                    DEV
                  </Typography>
                </Styled.DevIcon>
              </Styled.DevIconContainer>
            )}
          </Styled.Main>
          <SystemNotificationProvider />
        </Toast.Provider>
      </LocaleProvider>
    </ThemeProvider>
  );
};
