import { connect } from "react-redux";

import { removeSystemNotification } from "../../store/modules/systemNotification/actions/RemoveSystemNotificationAction";
import { RootState } from "../../store/reducer";

import { SystemNotificationProvider as Component } from "./SystemNotificationProvider";

export const SystemNotificationProvider = connect(
  (state: RootState) => ({
    entries: state.systemNotification.entries,
  }),
  (dispatch) => ({
    removeSystemNotification: (cid: string) =>
      dispatch(removeSystemNotification(cid)),
  }),
)(Component);
