import { Response } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchDashboardUrl
 */
export type FetchDashboardUrlParams = void;
export type FetchDashboardUrlSuccess = Response.AnalyticsFeature.FetchDashboardUrl;
export type FetchDashboardUrlFailure = Error;

export const fetchDashboardUrlAction = actionCreator.async<
  FetchDashboardUrlParams,
  FetchDashboardUrlSuccess,
  FetchDashboardUrlFailure
>("FETCH_DASHBOARD_URL");

export function fetchDashboardUrl(): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { apiClient, analyticsFeature }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchDashboardUrlAction.started());

    try {
      const res = await analyticsFeature.fetchAnalyticsFeatureDashboardUrl(
        apiClient,
        currentCompany.company.id,
      );
      dispatch(fetchDashboardUrlAction.done({ result: res }));
    } catch (err) {
      dispatch(fetchDashboardUrlAction.failed(err));
    }
  };
}
