import { Request, Response } from "api-types";

import { APIClient, createAPIClient } from "../../utils/APIClientUtils";
import { getCurrentCompanyIdByStorage } from "../../utils/CompanyUtils";

export type FetchDemandAccountCreativesParams = void;
export type FetchDemandAccountCreativesResponse = Response.DemandAccountCreative.FetchAll;

export async function fetchDemandAccountCreatives(
  apiClient: APIClient,
  companyId: number,
) {
  const { data } = await apiClient.get<FetchDemandAccountCreativesResponse>(
    "/v1/demand_account_creatives",
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );

  return data;
}

export async function fetchMyDemandAccountCreatives() {
  const apiClient = createAPIClient();
  const companyId = getCurrentCompanyIdByStorage();
  if (companyId == null) {
    throw new Error("媒体社IDがありません");
  }
  return fetchDemandAccountCreatives(apiClient, companyId);
}

export type PatchDeviceTypeParams = Request.DemandAccountCreative.PatchDeviceType;
export type PatchDeviceTypeResponse = Response.DemandAccountCreative.PatchDeviceType;

export async function patchDeviceType(
  apiClient: APIClient,
  params: PatchDeviceTypeParams,
  companyId: number,
) {
  const { data } = await apiClient.patch<PatchDeviceTypeResponse>(
    "/v1/demand_account_creatives/device_type",
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );
  return data;
}

export type PatchOsTypeParams = Request.DemandAccountCreative.PatchOsType;
export type PatchOsTypeResponse = Response.DemandAccountCreative.PatchOsType;

export async function patchOsType(
  apiClient: APIClient,
  params: PatchOsTypeParams,
  companyId: number,
) {
  const { data } = await apiClient.patch<PatchOsTypeResponse>(
    "/v1/demand_account_creatives/os_type",
    params,
    {
      headers: {
        "X-Company-Id": companyId,
      },
    },
  );
  return data;
}
