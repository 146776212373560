import { AnyAction, combineReducers } from "redux";

import {
  dashboardUrlEntityReducer,
  dashboardUrlReducer,
} from "./modules/analyticsFeature/reducers";
import { appReducer } from "./modules/app/reducers";
import { authReducer } from "./modules/auth/reducer";
import { bidStrapGamAdUnitReducer } from "./modules/bidStrapGamAdUnit/reducers";
import { bidStrapGamAdUnitEntityReducer } from "./modules/bidStrapGamAdUnit/reducers/bidStrapGamAdUnitEntity";
import { bidStrapReportReducer } from "./modules/bidStrapReport/reducers";
import {
  bidStrapSiteEntityReducer,
  bidStrapSiteReducer,
} from "./modules/bidStrapSite/reducers";
import {
  categoryEntityReducer,
  categoryReducer,
} from "./modules/category/reducers";
import {
  csvImportableDemandAccountsEntityReducer,
  csvImportReducer,
} from "./modules/csvImport/reducers";
import { dashboardReducer } from "./modules/dashboard/reducers";
import {
  dashboardItemReducer,
  dashboardItemEntityReducer,
} from "./modules/dashboardItem/reducers";
import {
  demandAccountEntityReducer,
  demandAccountFormEntityReducer,
  demandAccountReducer,
} from "./modules/demandAccount/reducers";
import {
  demandAdCreativeEntityReducer,
  demandAdCreativeReducer,
} from "./modules/demandAdCreative/reducers";
import {
  demandAdCreativeGroupEntityReducer,
  demandAdCreativeGroupReducer,
} from "./modules/demandAdCreativeGroup/reducers";
import { detailReportReducer } from "./modules/detailReport/reducers";
import {
  gamAdCreativeEntityReducer,
  gamAdCreativeReducer,
} from "./modules/gamAdCreative/reducers";
import {
  gamAdUnitEntityReducer,
  gamAdUnitReducer,
} from "./modules/gamAdUnit/reducers";
import {
  gamFlattenedHierarchyEntityReducer,
  gamFlattenedHierarchyReducer,
} from "./modules/gamFlattenedHierarchy/reducers";
import {
  gamLineItemEntityReducer,
  gamLineItemReducer,
} from "./modules/gamLineItem/reducers";
import {
  hbDemandTypeEntityReducer,
  hbDemandTypeReducer,
} from "./modules/hbDemandType/reducers";
import {
  informationEntityReducer,
  informationReducer,
} from "./modules/information/reducers";
import { marketTrendReducer } from "./modules/marketTrend/reducers";
import { networkReducer } from "./modules/network/reducers";
import {
  reportStatusEntityReducer,
  reportStatusReducer,
} from "./modules/reportStatus/reducers";
import { routeReducer } from "./modules/routing/reducer";
import {
  savedQueryEntityReducer,
  savedQueryReducer,
} from "./modules/savedQuery/reducers";
import { systemNotificationReducer } from "./modules/systemNotification/reducers";
import { updateCompanyAction } from "./modules/user/actions/updateCompanyAction";
import { userReducer } from "./modules/user/reducers";

// company間共通で利用したいreducerたち
export const generalReducer = {
  auth: authReducer,
  app: appReducer,
  routing: routeReducer,
  systemNotification: systemNotificationReducer,
  user: userReducer,
  network: networkReducer,
};

// companyIdごとに切り替えたいreducerたち
export const companyReducer = {
  demandAccount: demandAccountReducer,
  demandAdCreative: demandAdCreativeReducer,
  demandAdCreativeGroup: demandAdCreativeGroupReducer,
  gamAdUnit: gamAdUnitReducer,
  gamLineItem: gamLineItemReducer,
  gamAdCreative: gamAdCreativeReducer,
  gamFlattenedHierarchy: gamFlattenedHierarchyReducer,
  dashboard: dashboardReducer,
  dashboardItem: dashboardItemReducer,
  detailReport: detailReportReducer,
  category: categoryReducer,
  information: informationReducer,
  marketTrend: marketTrendReducer,
  reportStatus: reportStatusReducer,
  hbDemandType: hbDemandTypeReducer,
  csvImport: csvImportReducer,
  savedQuery: savedQueryReducer,
  bidStrapReport: bidStrapReportReducer,
  bidStrapSite: bidStrapSiteReducer,
  bidStrapGamAdUnit: bidStrapGamAdUnitReducer,
  analyticsFeature: dashboardUrlReducer,
  entities: combineReducers({
    demandAccount: demandAccountEntityReducer,
    demandAccountForm: demandAccountFormEntityReducer,
    demandAdCreative: demandAdCreativeEntityReducer,
    demandAdCreativeGroup: demandAdCreativeGroupEntityReducer,
    dashboardItem: dashboardItemEntityReducer,
    gamAdUnit: gamAdUnitEntityReducer,
    gamLineItem: gamLineItemEntityReducer,
    gamAdCreative: gamAdCreativeEntityReducer,
    gamFlattenedHierarchy: gamFlattenedHierarchyEntityReducer,
    category: categoryEntityReducer,
    information: informationEntityReducer,
    reportStatus: reportStatusEntityReducer,
    hbDemandType: hbDemandTypeEntityReducer,
    csvImportableDemandAccounts: csvImportableDemandAccountsEntityReducer,
    savedQuery: savedQueryEntityReducer,
    bidStrapSite: bidStrapSiteEntityReducer,
    bidStrapGamAdUnit: bidStrapGamAdUnitEntityReducer,
    analyticsFeature: dashboardUrlEntityReducer,
  }),
};

export const combinedReducer = combineReducers({
  ...generalReducer,
  ...companyReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

type CompanyState = Omit<RootState, keyof typeof generalReducer>;

// company idごとにstateを保持する
const companyStateHistory: { [id: number]: CompanyState } = {};

export const rootReducer = (
  state: RootState | undefined,
  action: AnyAction,
) => {
  if (updateCompanyAction.match(action)) {
    const {
      company: { id: nextId },
    } = action.payload.company;
    const {
      app,
      auth,
      routing,
      systemNotification,
      user,
      ...currentCompanyState
    } = state as RootState;
    if (user.currentCompany) {
      const currentId = user.currentCompany.company.id;
      companyStateHistory[currentId] = currentCompanyState;
    }
    let companyState = {};
    if (companyStateHistory[nextId]) {
      companyState = companyStateHistory[nextId];
    }
    return combinedReducer(
      {
        app,
        auth,
        routing,
        systemNotification,
        user,
        ...companyState,
      } as RootState,
      action,
    );
  }
  return combinedReducer(state, action);
};
