import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { User } from "../../../../domain/user";
import { selectCompany as selectCompanyAction } from "../../../../store/modules/user/actions/selectCompanyAction";
import { currentCompanySelector } from "../../../../store/modules/user/selector/currentCompanySelector";
import { userSelector } from "../../../../store/modules/user/selector/userSelector";
import { dispatchable } from "../../../../store/utils/dispatchable";
import { ActiveNavItem } from "../../AuthedAppShell";
import { Task } from "../Tasks/Tasks";

import { TopNavigation } from "./TopNavigation";

type Props = {
  navItem: ActiveNavItem;
  tasks: Task[];
};

const TopNavigationContainer: React.FunctionComponent<Props> = ({
  navItem,
  tasks,
}) => {
  const user = useSelector(userSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const dispatch = useDispatch();
  const selectCompany = dispatchable(dispatch, selectCompanyAction);

  if (currentCompany == null) {
    return <p>企業が選択されていません</p>;
  }
  return (
    <TopNavigation
      currentCompany={currentCompany}
      navItem={navItem}
      user={user as User}
      selectCompany={selectCompany}
      tasks={tasks}
    />
  );
};

export default TopNavigationContainer;
