import {
  Typography,
  Icon,
  Spacer,
  Flex,
  Button,
  Spinner,
  useTheme,
} from "ingred-ui";
import * as React from "react";

import * as Styled from "./styled";

export type StepProps = {
  title: string;
  stepKey: number;
  cancel: {
    text: string;
    onClick: () => void;
  };
  submit: {
    text: string;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    disabled?: boolean;
    loading?: boolean;
  };
  renderContent: () => React.ReactNode;
};

export type Props = {
  onClose?: () => void;
  steps: StepProps[];
  currentStepKey: number;
};

/* eslint-disable react/jsx-handler-names */
const StepModal: React.FunctionComponent<Props> = ({
  onClose,
  steps,
  currentStepKey,
}) => {
  const theme = useTheme();

  // currentStepKeyをミスると死ぬ
  const currentStep = steps.find(
    (step) => step.stepKey === currentStepKey,
  ) as StepProps;
  const getPosition = (stepKey: number) => {
    if (stepKey === currentStepKey) {
      return "0";
    }
    if (stepKey === currentStepKey - 1) {
      return "100%";
    }
    return "-100%";
  };

  return (
    <Styled.Container>
      <Styled.ModalContainer>
        <Styled.Form onSubmit={currentStep.submit.onSubmit}>
          <Styled.ScrollContainer>
            <Styled.ModalHeader>
              <Styled.TitleContainer>
                <Typography weight="bold" size="xxxl">
                  {currentStep.title}
                </Typography>
              </Styled.TitleContainer>
              <Styled.IconContainer onClick={onClose}>
                <Icon name="close" size="lg" color={theme.palette.black} />
              </Styled.IconContainer>
            </Styled.ModalHeader>

            <Styled.ModalContent>
              {steps.map((step) => (
                <Styled.StepContainer
                  key={step.stepKey}
                  right={getPosition(step.stepKey)}
                  isCurrentStep={step.stepKey === currentStepKey}
                >
                  {step.renderContent()}
                </Styled.StepContainer>
              ))}
            </Styled.ModalContent>
          </Styled.ScrollContainer>
          <Styled.ModalFooter>
            <Flex display="flex" alignItems="center">
              <Button
                type="button"
                color="secondary"
                inline={true}
                onClick={currentStep.cancel.onClick}
              >
                {currentStep.cancel.text}
              </Button>
              <Spacer pr={2} />
              <Button
                type="submit"
                color="primary"
                inline={true}
                disabled={currentStep.submit.disabled}
              >
                {currentStep.submit.text}
              </Button>
            </Flex>
          </Styled.ModalFooter>
        </Styled.Form>
        {currentStep.submit.loading && (
          <Styled.LoadingContainer>
            <Spinner />
          </Styled.LoadingContainer>
        )}
      </Styled.ModalContainer>
    </Styled.Container>
  );
};

export { StepModal };
