import { Domain } from "api-types";
import { reducerWithInitialState } from "typescript-fsa-reducers";

import { DemandAdCreativeByIdState } from "../../../../domain/demandAdCreative";
import { uniquePush } from "../../../../utils/ArrayUtils";
import { deleteDemandAdCreatives2Action as deleteDemandAdCreatives2BasedOnCategoryAction } from "../../category/actions/deleteDemandAdCreatives2Action";
import { deleteDemandAdCreativesAction as deleteDemandAdCreativesBasedOnCategoryAction } from "../../category/actions/deleteDemandAdCreativesAction";
import { patchDemandAdCreatives2Action as patchDemandAdCreatives2BasedOnCategoryAction } from "../../category/actions/patchDemandAdCreatives2Action";
import { patchDemandAdCreativesAction as patchDemandAdCreativesBasedOnCategoryAction } from "../../category/actions/patchDemandAdCreativesAction";
import { deleteDemandAdCreativeGroupAction } from "../../demandAdCreativeGroup/actions/deleteDemandAdCreativeGroupAction";
import { deleteDemandAdCreativesAction as deleteDemandAdCreativesBasedOnGroupAction } from "../../demandAdCreativeGroup/actions/deleteDemandAdCreativesAction";
import { patchDemandAdCreativesAction as patchDemandAdCreativesBasedOnSiteAction } from "../../demandAdCreativeGroup/actions/patchDemandAdCreativesAction";
import { deleteDemandAdCreativesAction as deleteDemandAdCreativesBasedOnGamAction } from "../../gamAdCreative/actions/deleteDemandAdCreativesAction";
import { fetchGamAdCreativesAction } from "../../gamAdCreative/actions/fetchGamAdCreativesAction";
import { postDemandAdCreativesAction as postDemandAdCreativesBasedOnGamAction } from "../../gamAdCreative/actions/postDemandAdCreativesAction";
import { activateDemandAdCreativesAction } from "../actions/activateDemandAdCreativesAction";
import { deleteDemandAdCreativesAction } from "../actions/deleteDemandAdCreativesAction";
import { fetchDemandAdCreativesAction } from "../actions/fetchDemandAdCreativesAction";
import { fetchDemandAdCreativesBasedOnGamAction } from "../actions/fetchDemandAdCreativesBasedOnGamAction";
import { patchChannelTypeAction } from "../actions/patchChannelTypeAction";
import { patchDeviceTypeAction } from "../actions/patchDeviceTypeAction";
import { patchOsTypeAction } from "../actions/patchOsTypeAction";

export type DemandAdCreativeEntityState = {
  byId: {
    [key: string]: DemandAdCreativeByIdState;
  };
  allIds: string[];
};

export const initialState: DemandAdCreativeEntityState = {
  byId: {},
  allIds: [],
};

export const demandAdCreativeEntityReducer = reducerWithInitialState(
  initialState,
)
  .case(fetchDemandAdCreativesAction.done, (state, action) => {
    const dacs = state.byId;
    action.result.forEach((dac: Domain.DemandAccountCreativeDetail) => {
      dacs[dac.demand_account_creative.id] = {
        ...dac.demand_account_creative,
        site: dac.site != null ? dac.site.id : null,
        category: dac.category != null ? dac.category.id : null,
        category2: dac.category2 != null ? dac.category2.id : null,
        mappedToGroup: dac.site != null,
        mappedToGam: dac.gam_ad_unit != null,
        gamAdUnitInfo:
          dac.gam_ad_unit != null
            ? {
                id: dac.gam_ad_unit.id,
                original_ad_unit_name: dac.gam_ad_unit.original_ad_unit_name,
              }
            : null,
      };
    });
    const store = {
      ...state,
      byId: dacs,
      allIds: uniquePush(
        action.result.map((creative) => creative.demand_account_creative.id),
        state.allIds,
      ),
    };
    return store;
  })
  .case(fetchDemandAdCreativesBasedOnGamAction.done, (state, action) => ({
    ...state,
    byId: action.result.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          ...cur,
          site: state.byId[cur.id] ? state.byId[cur.id].site : null,
          category: null,
          category2: null,
          // NOTE(kinokoruumu) すでにグループに紐付いているdemandAccountCreativeがある場合はそちらのmappedToGroupを優先する
          mappedToGroup: state.byId[cur.id]
            ? state.byId[cur.id].mappedToGroup
            : false,
          mappedToGam: false,
          gamAdUnitInfo: null,
        },
      }),
      state.byId,
    ),
    allIds: uniquePush(
      state.allIds,
      action.result.map((creative) => creative.id),
    ),
  }))
  .case(postDemandAdCreativesBasedOnGamAction.done, (state, action) => {
    const byIdState = { ...state.byId };
    for (const id of action.result.demandAdCreativeIds) {
      byIdState[id].mappedToGam = true;
    }
    return {
      ...state,
      byId: {
        ...state.byId,
        ...byIdState,
      },
    };
  })
  .case(fetchGamAdCreativesAction.done, (state, action) => {
    const demandAdCreatives: Domain.DemandAccountCreative[] = [];
    for (const gamCreative of action.result.gam_line_item_creative_details) {
      demandAdCreatives.push(...gamCreative.demand_account_creatives);
    }
    return {
      ...state,
      byId: demandAdCreatives.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: {
            ...cur,
            site: state.byId[cur.id] ? state.byId[cur.id].site : null,
            category: null,
            category2: null,
            // NOTE(kinokoruumu) すでにグループに紐付いているdemandAccountCreativeがある場合はそちらのmappedToGroupを優先する
            mappedToGroup: state.byId[cur.id]
              ? state.byId[cur.id].mappedToGroup
              : false,
            mappedToGam: true,
            gamAdUnitInfo: null,
          },
        }),
        state.byId,
      ),
      allIds: uniquePush(
        state.allIds,
        demandAdCreatives.map((creative) => creative.id),
      ),
    };
  })
  .case(deleteDemandAdCreativesBasedOnGroupAction.done, (state, action) => ({
    ...state,
    byId: action.result.demandAdCreatives.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          ...state.byId[cur.id],
          mappedToGroup: false,
          site: null,
        },
      }),
      state.byId,
    ),
  }))
  .case(deleteDemandAdCreativesBasedOnGamAction.done, (state, action) => ({
    ...state,
    byId: action.result.demandAdCreativeIds.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: {
          ...state.byId[cur],
          mappedToGam: false,
        },
      }),
      state.byId,
    ),
  }))
  .case(deleteDemandAdCreativeGroupAction.done, (state, action) => ({
    ...state,
    byId: action.result.unmappedDemandAdCreativeIds.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: {
          ...state.byId[cur],
          mappedToGroup: false,
          site: null,
        },
      }),
      state.byId,
    ),
  }))
  // サイトへの紐付け(新規 + 付け替え)
  .case(patchDemandAdCreativesBasedOnSiteAction.done, (state, action) => {
    const { toSite, demandAdCreatives } = action.result;
    const resultById = { ...state.byId };
    for (const demandAdCreative of demandAdCreatives) {
      const id = demandAdCreative.id;
      resultById[id] = {
        ...resultById[id],
        site: toSite.id,
        mappedToGroup: true,
      };
    }
    return {
      ...state,
      byId: resultById,
    };
  })
  // チャネルの変更
  .case(patchChannelTypeAction.done, (state, action) => {
    const dacDetails = action.result;
    const resultById = { ...state.byId };
    for (const dacDetail of dacDetails) {
      const id = dacDetail.demand_account_creative.id;
      resultById[id] = {
        ...resultById[id],
        channel_type_name: dacDetail.demand_account_creative.channel_type_name,
      };
    }
    return {
      ...state,
      byId: resultById,
    };
  })
  // デバイスの変更
  .case(patchDeviceTypeAction.done, (state, action) => {
    const dacDetails = action.result;
    const resultById = { ...state.byId };
    for (const dacDetail of dacDetails) {
      const id = dacDetail.demand_account_creative.id;
      resultById[id] = {
        ...resultById[id],
        device_type_name: dacDetail.demand_account_creative.device_type_name,
      };
    }
    return {
      ...state,
      byId: resultById,
    };
  })
  // OSの変更
  .case(patchOsTypeAction.done, (state, action) => {
    const dacs = action.result;
    const resultById = { ...state.byId };
    for (const dac of dacs) {
      const id = dac.id;
      resultById[id] = {
        ...resultById[id],
        os_type_name: dac.os_type_name,
      };
    }
    return {
      ...state,
      byId: resultById,
    };
  })
  // カテゴリへの紐付け(新規 + 付け替え)
  .case(patchDemandAdCreativesBasedOnCategoryAction.done, (state, action) => {
    const { toCategory, demandAdCreatives } = action.result;
    const resultById = { ...state.byId };
    for (const demandAdCreative of demandAdCreatives) {
      const id = demandAdCreative.id;
      resultById[id] = {
        ...resultById[id],
        category: toCategory.id,
      };
    }
    return {
      ...state,
      byId: resultById,
    };
  })
  // カテゴリの紐付け解除
  .case(deleteDemandAdCreativesBasedOnCategoryAction.done, (state, action) => ({
    ...state,
    byId: action.result.demandAdCreatives.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          ...state.byId[cur.id],
          category: null,
        },
      }),
      state.byId,
    ),
  }))
  // DACを利用中にする
  .case(activateDemandAdCreativesAction.done, (state, action) => ({
    ...state,
    byId: action.result.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.demand_account_creative.id]: {
          ...state.byId[cur.demand_account_creative.id],
          is_active: true,
        },
      }),
      state.byId,
    ),
  }))
  // DACを利用停止する
  .case(deleteDemandAdCreativesAction.done, (state, action) => ({
    ...state,
    byId: action.result.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.demand_account_creative.id]: {
          ...state.byId[cur.demand_account_creative.id],
          is_active: false,
        },
      }),
      state.byId,
    ),
  }))
  // カテゴリ2への紐付け(新規 + 付け替え)
  .case(patchDemandAdCreatives2BasedOnCategoryAction.done, (state, action) => {
    const { toCategory, demandAdCreatives } = action.result;
    const resultById = { ...state.byId };
    for (const demandAdCreative of demandAdCreatives) {
      const id = demandAdCreative.id;
      resultById[id] = {
        ...resultById[id],
        category2: toCategory.id,
      };
    }
    return {
      ...state,
      byId: resultById,
    };
  })
  // カテゴリ2の紐付け解除
  .case(
    deleteDemandAdCreatives2BasedOnCategoryAction.done,
    (state, action) => ({
      ...state,
      byId: action.result.demandAdCreatives.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: {
            ...state.byId[cur.id],
            category2: null,
          },
        }),
        state.byId,
      ),
    }),
  );
