import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;

// 表示される枠全体の大きさ - (テキストエリアのpadding + closeIconの大きさ)で計算して
// テキストエリアサイズを設定して表示される枠の大きさをデザイン通りになるようにする
export const ContentContainer = styled.div`
  min-width: ${({ theme }) =>
    360 - (theme.spacing * 2 * 2 + theme.spacing * 2 + 18)}px;
  max-width: ${({ theme }) =>
    512 - (theme.spacing * 2 * 2 + theme.spacing * 2 + 18)}px;
`;
