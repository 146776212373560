import { Toast } from "ingred-ui";
import * as React from "react";

import { SystemNotificationVariant } from "../../store/modules/systemNotification/constants";
import { SystemNotificationEntry } from "../../store/modules/systemNotification/reducers/systemNotification";

export type Props = {};

export type InjectProps = {
  entries: SystemNotificationEntry[];
  removeSystemNotification(cid: string): void;
};

type InjectedProps = Props & InjectProps;

export const SystemNotificationProvider: React.FunctionComponent<InjectedProps> = ({
  entries,
  removeSystemNotification,
}) => {
  const { addToast } = Toast.useToasts();
  React.useEffect(() => {
    for (const entry of entries) {
      addToast(entry.message, {
        appearance: entry.variant,
        autoDismiss: entry.variant !== SystemNotificationVariant.ERROR,
      });

      removeSystemNotification(entry.cid);
    }
  }, [addToast, entries, removeSystemNotification]);
  return null;
};
