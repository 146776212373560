import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../containers/AuthedAppShell";
import { RouteActionPayload, RouteActionContext } from "../../../routing/types";
import { fetchAllReportStatus } from "../../../store/modules/reportStatus/actions/fetchAllReportStatusAction";
import { getReportStatuses } from "../../../store/modules/reportStatus/selectors/reportStatusSelector";
import { RootState } from "../../../store/reducer";

import { ReportStatuses as Component } from "./ReportStatuses";

const ReportStatuses = connect((state: RootState) => ({
  reportStatuses: getReportStatuses(state),
}))(Component);

export async function action({
  firstOrPush,
  store,
}: RouteActionContext): Promise<RouteActionPayload> {
  if (firstOrPush) {
    await store.dispatch(fetchAllReportStatus());
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <ReportStatuses />,
  };
}
