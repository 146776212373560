import { StorageKey } from "../constants/storageKeys";
import { CompanyAttribute } from "../domain/companyAttribute";
import { User } from "../domain/user";
import { getCompanyAttributeByCompanyId } from "../store/modules/user/selector/companyAttributeSelector";

export function getCurrentCompanyIdByUrl(pathname: string): number | null {
  const match = pathname.match(/^\/company\/(\d+)\/?/);
  let currentCompanyId = null;
  if (match && match[1]) {
    currentCompanyId = parseInt(match[1], 10);
  }
  return currentCompanyId;
}

export function getCurrentCompanyIdByStorage(): number | null {
  const ccid = localStorage.getItem(StorageKey.CURRENT_COMPANY_ID);
  if (ccid != null) {
    return parseInt(ccid, 10);
  }
  return null;
}

export function setCurrentCompanyIdWithStorage(id: number): void {
  return localStorage.setItem(StorageKey.CURRENT_COMPANY_ID, id.toString());
}

export function getCurrentCompany(user: User | null): CompanyAttribute | null {
  const id = getCurrentCompanyIdByStorage();
  if (user != null) {
    if (id != null) {
      const companyAttribute = getCompanyAttributeByCompanyId(user, id);
      if (companyAttribute != null) {
        return companyAttribute;
      }
    } else if (user.company_attributes.length === 1) {
      // 所属企業が一つしかなければ指定それを指定する
      const companyAttribute = getCompanyAttributeByCompanyId(
        user,
        user.company_attributes[0].company.id,
      );
      if (companyAttribute != null) {
        return companyAttribute;
      }
    }
  }
  return null;
}
