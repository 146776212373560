import * as React from "react";
import { RouteParams } from "universal-router";

import { ErrorView } from "../../components/pages/ErrorView";
import { AuthedAppShell } from "../../containers/AuthedAppShell";
import { getCurrentCompanyIdByUrl } from "../../utils/CompanyUtils";
import { FatalHttpError } from "../../utils/HttpUtils";
import { HttpStatusCode } from "../../utils/StatusCodeUtils";
import { Middleware, PageAction } from "../types";
import { createErrorResponse } from "../utils";

export function createRootMiddleware(): Middleware {
  const storedHistories = new Set<string>();

  return async (context) => {
    let action: PageAction;
    let path: string;
    let params: RouteParams;
    let redirectTo: string | undefined;
    let content: React.ReactNode | undefined;

    try {
      ({ path, params, action, redirectTo, content } = await context.next());
      if (content != null) {
        return {
          statusCode: HttpStatusCode.OK,
          content,
        };
      }
      if (redirectTo != null) {
        return {
          redirectTo,
        };
      }
    } catch (e) {
      const {
        auth: { authenticated },
      } = context.store.getState();
      if (authenticated) {
        return {
          statusCode: HttpStatusCode.NOT_FOUND,
          content: (
            <AuthedAppShell>
              <ErrorView statusCode={HttpStatusCode.NOT_FOUND} />
            </AuthedAppShell>
          ),
        };
      }
      return {
        statusCode: HttpStatusCode.NOT_FOUND,
        content: <ErrorView statusCode={HttpStatusCode.NOT_FOUND} />,
      };
    }

    try {
      // 50x 系エラーが発生している状態ではページアクションを実行させない
      if (context.store.getState().app.fatal) {
        throw new FatalHttpError();
      }

      let firstOrPush = true;
      if (storedHistories.size !== 0) {
        firstOrPush =
          !storedHistories.has(context.pathname) ||
          context.history.action === "PUSH";

        // currentCompanyIdが変更された場合は firstOrPush = true にする
        // ここでこの処理していいんだろうか・・・
        if (
          getCurrentCompanyIdByUrl(context.pathname) !==
          getCurrentCompanyIdByUrl(context.store.getState().routing.pathname)
        ) {
          firstOrPush = true;
        }
      }

      storedHistories.add(context.pathname);

      const payload = await action({
        ...context,
        firstOrPush,
        params,
      });

      return {
        path,
        params,
        ...payload,
      };
    } catch (e) {
      const {
        auth: { authenticated },
      } = context.store.getState();
      return createErrorResponse(e, authenticated);
    }
  };
}
