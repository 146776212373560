import { reducerWithInitialState } from "typescript-fsa-reducers";

import { deleteDemandAdCreativesAction } from "../actions/deleteDemandAdCreativesAction";
import { fetchGamAdCreativesAction } from "../actions/fetchGamAdCreativesAction";
import { patchGamAdCreativeAction } from "../actions/patchGamAdCreativeAction";
import { postDemandAdCreativesAction } from "../actions/postDemandAdCreativesAction";

export type GamAdCreativeState = {
  fetchAll: {
    requesting: boolean;
  };
  patch: {
    requesting: boolean;
    success: boolean;
  };
  demandAdCreative: {
    post: {
      requesting: boolean;
      success: boolean;
    };
    delete: {
      requesting: boolean;
      success: boolean;
    };
  };
};

export const initialState: GamAdCreativeState = {
  fetchAll: {
    requesting: false,
  },
  patch: {
    requesting: false,
    success: false,
  },
  demandAdCreative: {
    post: {
      requesting: false,
      success: false,
    },
    delete: {
      requesting: false,
      success: false,
    },
  },
};

export const gamAdCreativeReducer = reducerWithInitialState(initialState)
  /**
   * Fetch GamAdCreatives
   */
  .case(fetchGamAdCreativesAction.started, (state) => ({
    ...state,
    fetchAll: {
      requesting: true,
    },
  }))
  .case(fetchGamAdCreativesAction.done, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  .case(fetchGamAdCreativesAction.failed, (state) => ({
    ...state,
    fetchAll: {
      requesting: false,
    },
  }))
  /**
   * Patch GamAdCreative
   */
  .case(patchGamAdCreativeAction.started, (state) => ({
    ...state,
    patch: {
      requesting: true,
      success: false,
    },
  }))
  .case(patchGamAdCreativeAction.done, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: true,
    },
  }))
  .case(patchGamAdCreativeAction.failed, (state) => ({
    ...state,
    patch: {
      requesting: false,
      success: false,
    },
  }))
  /**
   * Post DemandAdCreatives
   */
  .case(postDemandAdCreativesAction.started, (state) => ({
    ...state,
    demandAdCreative: {
      ...state.demandAdCreative,
      post: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(postDemandAdCreativesAction.done, (state) => ({
    ...state,
    demandAdCreative: {
      ...state.demandAdCreative,
      post: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(postDemandAdCreativesAction.failed, (state) => ({
    ...state,
    demandAdCreative: {
      ...state.demandAdCreative,
      post: {
        requesting: false,
        success: false,
      },
    },
  }))
  /**
   * Delete DemandAdCreatives
   */
  .case(deleteDemandAdCreativesAction.started, (state) => ({
    ...state,
    demandAdCreative: {
      ...state.demandAdCreative,
      delete: {
        requesting: true,
        success: false,
      },
    },
  }))
  .case(deleteDemandAdCreativesAction.done, (state) => ({
    ...state,
    demandAdCreative: {
      ...state.demandAdCreative,
      delete: {
        requesting: false,
        success: true,
      },
    },
  }))
  .case(deleteDemandAdCreativesAction.failed, (state) => ({
    ...state,
    demandAdCreative: {
      ...state.demandAdCreative,
      delete: {
        requesting: false,
        success: false,
      },
    },
  }));
