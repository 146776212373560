import { Request } from "api-types";
import {
  Spacer,
  Icon,
  Flex,
  Typography,
  Button,
  useTheme,
  TextField,
  DataTable,
  ItemEmpty,
} from "ingred-ui";
import * as React from "react";
import { useForm } from "react-hook-form";

import { Category } from "../../../../../../domain/category";
import { DemandAdCreative } from "../../../../../../domain/demandAdCreative";
import { registerIui } from "../../../../../../utils/registerIui";
import { StepModal } from "../../../../../elements/StepModal";

import * as Styled from "./styled";

const getErrorText = (errorName: string | undefined): string => {
  switch (errorName) {
    case "required":
      return "入力されていません";
    case "validate":
      return "すでに同じ名前のカテゴリが存在します";
    default:
      return "";
  }
};

const convertToDataTableArray = (creatives: DemandAdCreative[]) =>
  creatives.map((creative) => ({
    ...creative,
    name: creative.original_name || creative.original_id,
  }));

type CreateCategory = {
  name: string;
};
type Props = {
  onClose: () => void;
  onCreateCategorySubmit: (data: Request.Category.Post) => void;
  onSubmit: (site: Category) => void;
  createLoading: boolean;
  createSucceeded: boolean;
  loading: boolean;
  categories: Category[];
  selectedDemandAdCreatives: DemandAdCreative[];
  moveModalId: string;
};

const CategoryMoveModal: React.FunctionComponent<Props> = ({
  onClose,
  onCreateCategorySubmit,
  onSubmit,
  createLoading,
  createSucceeded,
  loading,
  categories,
  selectedDemandAdCreatives,
  moveModalId,
}) => {
  const theme = useTheme();
  const [searchText, setSearchText] = React.useState("");

  const searchedGroups = React.useMemo(
    () =>
      categories.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [categories, searchText],
  );

  const [
    selectedCategory,
    setSelectedCategory,
  ] = React.useState<Category | null>(null);

  const [step, setStep] = React.useState<1 | 2 | 3>(1);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateCategory>();

  const handleChangeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleCreateCategorySubmit = (data: CreateCategory) => {
    onCreateCategorySubmit(data);
  };

  const handleSelect = (selectedCategoryId: number) => {
    const selectedCategory = categories.find(
      (category) => category.id === selectedCategoryId,
    );
    setSelectedCategory(selectedCategory || null);
  };

  const handleConfirmSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStep(2);
  };

  const handleAddCreativesSubmit = (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (selectedCategory) {
      onSubmit(selectedCategory);
    }
  };

  const handlePrev = () => {
    setStep(1);
  };

  const handleCreateGroup = () => {
    setStep(3);
  };

  React.useEffect(() => {
    if (createSucceeded) {
      reset();
      setStep(1);
    }
  }, [createSucceeded, reset]);

  return (
    <StepModal
      currentStepKey={step}
      steps={[
        {
          title: "デマンド広告クリエイティブをカテゴリに紐付ける",
          stepKey: 1,
          cancel: {
            text: "キャンセル",
            onClick: onClose,
          },
          submit: {
            text: "確認する",
            onSubmit: handleConfirmSubmit,
            disabled: !selectedCategory,
          },
          renderContent: () =>
            categories.length !== 0 ? (
              <>
                <Styled.TopContainer>
                  <Styled.InputContainer>
                    <TextField
                      icon="search"
                      placeholder="カテゴリを検索"
                      value={searchText}
                      onChange={handleChangeSearchText}
                    />
                  </Styled.InputContainer>
                  <Button
                    size="small"
                    inline={true}
                    type="button"
                    onClick={handleCreateGroup}
                  >
                    カテゴリを作成
                  </Button>
                </Styled.TopContainer>
                <Spacer pt={2} px={3} pb={15}>
                  <DataTable
                    dataKey={"id"}
                    itemEmptyProps={{
                      title: "該当するカテゴリがありません。",
                    }}
                    enablePagination={true}
                    per={10}
                    data={searchedGroups}
                    columns={[
                      {
                        name: "名前",
                        selector: (data) => data.name,
                        sortable: true,
                        renderCell: (data) => (
                          <Flex display="flex" alignItems="center">
                            <Spacer pr={0.5}>
                              <Icon
                                name="folder"
                                color={theme.palette.primary.main}
                              />
                            </Spacer>
                            <Typography>{data.name}</Typography>
                          </Flex>
                        ),
                      },
                    ]}
                    selectedRow={selectedCategory?.id}
                    onRadioChange={handleSelect}
                  />
                </Spacer>
              </>
            ) : (
              <>
                <ItemEmpty
                  title="カテゴリが存在しません。"
                  subtitle="画面下の「カテゴリを作成」ボタンをクリックして新しくカテゴリを作成してください。"
                />
                <Flex display="flex" justifyContent="center">
                  <Button
                    size="medium"
                    inline={true}
                    type="button"
                    onClick={handleCreateGroup}
                  >
                    カテゴリを作成
                  </Button>
                </Flex>
              </>
            ),
        },
        {
          title: `カテゴリ ${selectedCategory?.name} に下記のデマンド広告クリエイティブを紐付けますか？`,
          stepKey: 2,
          cancel: {
            text: "戻る",
            onClick: handlePrev,
          },
          submit: {
            text: "紐付ける",
            onSubmit: handleAddCreativesSubmit,
            loading: loading,
          },
          renderContent: () => (
            <Spacer pt={2} px={3} pb={15}>
              <DataTable
                dataKey={"id"}
                enablePagination={true}
                per={10}
                data={convertToDataTableArray(selectedDemandAdCreatives)}
                columns={[
                  {
                    name: "名前",
                    selector: (data) => data.name,
                    sortable: true,
                  },
                  {
                    name:
                      moveModalId === "link_category"
                        ? "カテゴリ"
                        : "カテゴリ2",
                    selector: (data) =>
                      // eslint-disable-next-line no-nested-ternary
                      moveModalId === "link_category"
                        ? data.category != null
                          ? data.category.name
                          : "-"
                        : data.category2 != null
                        ? data.category2.name
                        : "-",
                    sortable: true,
                  },
                ]}
              />
            </Spacer>
          ),
        },
        {
          title: "カテゴリを作成",
          stepKey: 3,
          cancel: {
            text: "戻る",
            onClick: handlePrev,
          },
          submit: {
            text: "作成する",
            onSubmit: handleSubmit(handleCreateCategorySubmit),
            loading: createLoading,
          },
          renderContent: () => (
            <Styled.FormContainer>
              <Styled.FormGroup>
                <Styled.FormGroupLeft>
                  <Spacer pr={5}>カテゴリ名</Spacer>
                </Styled.FormGroupLeft>
                <Styled.FormGroupRight>
                  <TextField
                    {...registerIui(
                      register("name", {
                        required: true,
                        validate: (value) =>
                          categories.every((group) => group.name !== value),
                      }),
                    )}
                    errorText={getErrorText(errors.name?.type)}
                  />
                </Styled.FormGroupRight>
              </Styled.FormGroup>
            </Styled.FormContainer>
          ),
        },
      ]}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
    />
  );
};

export { CategoryMoveModal };
