import { Request } from "api-types";
import { Spacer, Flex, ActionButton, DataTable, Typography } from "ingred-ui";
import React from "react";

import { Helmet } from "../../../../containers/Helmet";
import { DemandAdCreative } from "../../../../domain/demandAdCreative";
import { GamFlattenedHierarchy } from "../../../../domain/gamFlattenedHierarchy/entity";
import { patchGamAdCreative } from "../../../../store/modules/gamAdCreative/actions/patchGamAdCreativeAction";
import {
  postDemandAdCreatives,
  PostDemandAdCreativesPayload,
} from "../../../../store/modules/gamAdCreative/actions/postDemandAdCreativesAction";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../../store/utils/dispatchable";
import { Breadcrumbs } from "../../../elements/Breadcrumbs";
import { PageContainer } from "../../../elements/PageContainer";
import { PageContent } from "../../../elements/PageContent";

import { AddModal } from "./internal/AddModal";
import { EditModal } from "./internal/EditModal";

export type NormalizedData = {
  id: number;
  creativeName: string;
  lineItemName: string;
  unitName: string;
  unitId: number;
  isMappable: boolean;
};

type InjectProps = {
  gamFlattenedHierarchies: GamFlattenedHierarchy[];
  demandAdCreatives: DemandAdCreative[];
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  postDemandAdCreatives: DispatchableAction<typeof postDemandAdCreatives>;
  postRequesting: boolean;
  postSucceeded: boolean;
  patchGamAdCreative: DispatchableAction<typeof patchGamAdCreative>;
  patchRequesting: boolean;
  patchSucceeded: boolean;
};

type Props = {};

type InjectedProps = Props & InjectProps;

const UnmappedGAMAdCreatives: React.FunctionComponent<InjectedProps> = ({
  gamFlattenedHierarchies,
  demandAdCreatives,
  enqueueSystemNotification,
  postDemandAdCreatives,
  postRequesting,
  postSucceeded,
  patchGamAdCreative,
  patchRequesting,
  patchSucceeded,
}) => {
  const [postToasted, setPostToasted] = React.useState(postSucceeded);
  const [patchToasted, setPatchToasted] = React.useState(patchSucceeded);

  const [addModalRow, setAddModalRow] = React.useState<NormalizedData | null>(
    null,
  );
  const [
    patchModalRow,
    setPatchModalRow,
  ] = React.useState<NormalizedData | null>(null);

  const [searchText, setSearchText] = React.useState("");

  const searchedItems: NormalizedData[] = React.useMemo(() => {
    const sourceData = gamFlattenedHierarchies.map((item) => ({
      id: item.id,
      creativeName: item.original_line_item_creative_name,
      lineItemName: item.gam_line_item.original_line_item_name,
      unitName: item.gam_ad_unit.original_ad_unit_name,
      unitId: item.gam_ad_unit.id,
      isMappable: item.is_mappable,
    }));
    return sourceData.filter((item) =>
      item.creativeName.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [gamFlattenedHierarchies, searchText]);

  React.useEffect(() => {
    if (!postToasted && postSucceeded) {
      setAddModalRow(null);
      enqueueSystemNotification({
        message: "紐付けが完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setPostToasted(true);
    }
  }, [enqueueSystemNotification, postToasted, postSucceeded]);

  React.useEffect(() => {
    if (!patchToasted && patchSucceeded) {
      setPatchModalRow(null);
      enqueueSystemNotification({
        message: "完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setPatchToasted(true);
    }
  }, [enqueueSystemNotification, patchToasted, patchSucceeded]);

  const handleChangeAddModalRow = (row: NormalizedData | null) => () =>
    setAddModalRow(row);
  const handleChangePatchModalRow = (row: NormalizedData | null) => () =>
    setPatchModalRow(row);

  const handleAddSubmit = (data: PostDemandAdCreativesPayload) => {
    postDemandAdCreatives(data);
    setPostToasted(false);
  };

  const handleEditSubmit = (data: Request.GamLineItemCreative.Patch) => {
    patchGamAdCreative(data);
    setPatchToasted(false);
  };

  return (
    <>
      <Helmet title="紐付け未完了のGAM広告クリエイティブ一覧 | DATA STRAP" />
      <PageContainer>
        <Spacer pb={3}>
          <Breadcrumbs
            items={[
              {
                title: "GAM広告クリエイティブ管理",
                link: "/settings/gam_ad_creatives_manager",
              },
              {
                title: "紐付け未完了のGAM広告クリエイティブ一覧",
              },
            ]}
          />
        </Spacer>
        <Flex display="flex" alignItems="flex-start">
          <PageContent
            title="紐付け未完了のGAM広告クリエイティブ一覧"
            popoverElement={
              <Typography size="sm" lineHeight="1.7">
                デマンド広告クリエイティブが紐付けされていないGAM広告クリエイティブ一覧です。
              </Typography>
            }
            placeholder="GAM広告クリエイティブを検索"
            searchText={searchText}
            onChange={setSearchText} // eslint-disable-line react/jsx-handler-names
          >
            <Spacer pt={2} px={2}>
              <DataTable
                dataKey={"id"}
                itemEmptyProps={{
                  title: "該当するクリエイティブがありません。",
                }}
                enablePagination={true}
                data={searchedItems}
                tabs={[
                  {
                    label: "紐付け未完了",
                    filter: (data) => data.filter((item) => item.isMappable),
                  },
                  {
                    label: "紐付け不要",
                    filter: (data) => data.filter((item) => !item.isMappable),
                  },
                ]}
                columns={[
                  {
                    name: "GAM広告クリエイティブ",
                    selector: (data) => `${data.creativeName}`,
                    sortable: true,
                  },
                  {
                    name: "申込情報",
                    selector: (data) => data.lineItemName,
                    sortable: true,
                  },
                  {
                    name: "GAM広告ユニット",
                    selector: (data) => data.unitName,
                    sortable: true,
                  },
                  {
                    name: "操作",
                    selector: (data) => data.id,
                    renderCell: (data) => (
                      <div>
                        {data.isMappable && (
                          <Spacer pb={0.5}>
                            <ActionButton
                              icon="link"
                              onClick={handleChangeAddModalRow(data)}
                            >
                              紐付ける
                            </ActionButton>
                          </Spacer>
                        )}
                        <ActionButton
                          icon={data.isMappable ? "no_link" : "return_line"}
                          onClick={handleChangePatchModalRow(data)}
                        >
                          {data.isMappable
                            ? "紐付け不要にする"
                            : "紐付け対象に戻す"}
                        </ActionButton>
                      </div>
                    ),
                  },
                ]}
              />
            </Spacer>
          </PageContent>
        </Flex>
      </PageContainer>
      {!!addModalRow && (
        <AddModal
          gamAdUnitId={addModalRow.unitId}
          loading={postRequesting}
          demandAdCreatives={demandAdCreatives}
          gamFlattenedHierarchy={addModalRow}
          onClose={handleChangeAddModalRow(null)}
          onSubmit={handleAddSubmit}
        />
      )}
      <EditModal
        isOpen={!!patchModalRow}
        loading={patchRequesting}
        gamAdCreative={patchModalRow}
        onClose={handleChangePatchModalRow(null)}
        onSubmit={handleEditSubmit}
      />
    </>
  );
};

export { UnmappedGAMAdCreatives };
