import { Response, Request } from "api-types";

import { ThunkAction } from "../../../type";

import { actionCreator } from "./actionCreator";

/**
 * FetchMarketTrendSalesCompositionRatio
 */
export type FetchMarketTrendSalesCompositionRatioParams = void;
export type FetchMarketTrendSalesCompositionRatioSuccess = Response.MarketTrend.FetchSalesCompositionRatio;
export type FetchMarketTrendSalesCompositionRatioFailure = Error;

export const fetchMarketTrendSalesCompositionRatioAction = actionCreator.async<
  FetchMarketTrendSalesCompositionRatioParams,
  FetchMarketTrendSalesCompositionRatioSuccess,
  FetchMarketTrendSalesCompositionRatioFailure
>("FETCH_MARKET_TREND_SC_RATIO");

export function fetchMarketTrendSalesCompositionRatio(
  payload: Request.MarketTrend.FetchSalesCompositionRatio,
): ThunkAction<Promise<void>, any> {
  return async (dispatch, _getState, { marketTrend, apiClient }) => {
    const currentCompany = _getState().user.currentCompany;
    if (currentCompany === null) {
      return;
    }
    dispatch(fetchMarketTrendSalesCompositionRatioAction.started());

    try {
      const res = await marketTrend.fetchMarketTrendSalesCompositionRatio(
        apiClient,
        payload,
        currentCompany.company.id,
      );
      dispatch(
        fetchMarketTrendSalesCompositionRatioAction.done({ result: res }),
      );
    } catch (err) {
      dispatch(fetchMarketTrendSalesCompositionRatioAction.failed(err));
    }
  };
}
