import dayjs from "dayjs";
import { Spacer, DataTable, ActionButton } from "ingred-ui";
import React from "react";

import { Helmet } from "../../../containers/Helmet";
import { CompanyAttribute } from "../../../domain/companyAttribute";
import { SavedQuery } from "../../../domain/savedQuery";
import { deleteSavedQuery } from "../../../store/modules/savedQuery/actions/deleteSavedQueryAction";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../store/utils/dispatchable";
import { Link } from "../../atoms/Link";
import { PageContainer } from "../../elements/PageContainer";
import { PageContent } from "../../elements/PageContent";

import { DeleteModal } from "./internal/DeleteModal";

const QUERY_PERIOD_LABEL = {
  this_month: "今月",
  last_month: "前月",
  yesterday: "昨日",
  this_year: "今年",
  last_7_days: "過去7日間",
  last_30_days: "過去30日間",
  last_365_days: "過去365日間",
  last_730_days: "過去730日間",
  last_3_months: "過去3ヶ月間",
  last_6_months: "過去6ヶ月間",
  last_12_months: "過去1年間",
} as const;

type InjectProps = {
  savedQueries: SavedQuery[];
  currentCompany: CompanyAttribute;
  deleteRequesting: boolean;
  deleteSucceeded: boolean;
  deleteSavedQuery: DispatchableAction<typeof deleteSavedQuery>;
};

type Props = {};

type InjectedProps = Props & InjectProps;

const SavedQueryList: React.FunctionComponent<InjectedProps> = ({
  currentCompany,
  savedQueries,
  deleteRequesting,
  deleteSucceeded,
  deleteSavedQuery,
}) => {
  const [deleteToasted, setDeleteToasted] = React.useState(deleteSucceeded);
  const [deleteModalRow, setDeleteModalRow] = React.useState<SavedQuery | null>(
    null,
  );
  const [searchText, setSearchText] = React.useState("");
  const handleChange = React.useCallback((value: string) => {
    setSearchText(value);
  }, []);
  const searchedSavedQueries = React.useMemo(() => {
    return savedQueries.filter((savedQuery) =>
      savedQuery.name.includes(searchText),
    );
  }, [savedQueries, searchText]);

  const handleChangeDeleteModalRow = (row: SavedQuery | null) => () =>
    setDeleteModalRow(row);

  const handleDelete = (savedQueryId: number) => {
    deleteSavedQuery({ saved_query_id: savedQueryId });
    setDeleteToasted(false);
  };

  React.useEffect(() => {
    if (!deleteToasted && deleteSucceeded) {
      setDeleteModalRow(null);
      enqueueSystemNotification({
        message: "削除が完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setDeleteToasted(true);
    }
  }, [deleteToasted, deleteSucceeded]);

  return (
    <>
      <Helmet title="保存クエリ一覧 | DATA STRAP" />
      <PageContainer>
        <PageContent
          title="保存クエリ一覧"
          placeholder="保存クエリ名で検索"
          searchText={searchText}
          onChange={handleChange}
        >
          <Spacer pt={2} px={2}>
            <DataTable
              dataKey={"id"}
              itemEmptyProps={{
                title: "該当する保存クエリがありません。",
              }}
              enablePagination={true}
              data={searchedSavedQueries}
              defaultSortOrder={"asc"}
              columns={[
                {
                  name: "名前",
                  selector: (row) => row.name,
                  sortable: true,
                  renderCell: (row) => (
                    <Link
                      href={`/company/${currentCompany.company.id}/report?saved_query_id=${row.id}&name=${row.name}`}
                    >
                      {row.name}
                    </Link>
                  ),
                },
                {
                  name: "期間",
                  selector: (row) =>
                    QUERY_PERIOD_LABEL[
                      row.custom_report_period as keyof typeof QUERY_PERIOD_LABEL
                    ],
                  width: "150px",
                  sortable: true,
                },
                {
                  name: "作成者名",
                  selector: (row) => row.user.name,
                  sortable: true,
                },
                {
                  name: "作成日",
                  selector: (row) => dayjs(row.created_at).format("YYYY-MM-DD"),
                  width: "150px",
                  sortable: true,
                },
                {
                  name: "操作",
                  selector: (row) => row.id,
                  width: "110px",
                  renderCell: (row) => (
                    <ActionButton
                      icon="delete_bin"
                      onClick={handleChangeDeleteModalRow(row)}
                    >
                      削除
                    </ActionButton>
                  ),
                },
              ]}
            />
          </Spacer>
        </PageContent>
        <DeleteModal
          isOpen={!!deleteModalRow}
          savedQuery={deleteModalRow}
          loading={deleteRequesting}
          onClose={handleChangeDeleteModalRow(null)}
          onSubmit={handleDelete}
        />
      </PageContainer>
    </>
  );
};

export { SavedQueryList };
