import styled from "styled-components";

import { Color, Size, Radius } from "../../../../../../styles/variables";

export const FormContainer = styled.div`
  width: 500px;
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing * 2}px 0
    ${({ theme }) => theme.spacing * 8}px;
  & + & {
    border-top: ${Size.Border.Small} solid ${Color.gray.light};
  }
`;

export const FormGroupLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  min-height: 40px; /* <TextField/>の高さに合わせる */
`;

export const FormGroupRight = styled.div`
  width: 70%;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px; /* <TextField/>の高さに合わせる */
`;

export const DiscriptionBackground = styled.div`
  margin-top: -${({ theme }) => theme.spacing * 6}px;
  padding: ${({ theme }) => theme.spacing * 2}px;
  background-color: ${Color.gray.light};
  border-radius: ${Radius.SMALL};
  white-space: normal;
`;
